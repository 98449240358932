import { styled, css, StoreSearch, Container, AnchorButton } from '@mybonus/ui';

export const $StoreSearch = styled(StoreSearch)`
	border: 1px solid ${({ theme }) => theme.color.midGray.base};
	max-width: 600px;
`;

export const $Container = styled(Container).attrs((props) => ({
	...props,
	width: 'narrow',
}))`
	display: flex;
	flex-direction: column;
	align-items: center;
	${({ theme }) =>
		css`
			padding-top: ${theme.space.lg}px;
			padding-bottom: ${theme.space.lg}px;
		`};

	> * {
		margin-bottom: ${({ theme }) => theme.space.md}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const ToBrowseButton = styled(AnchorButton).attrs((props) => ({
	...props,
	text: 'Alla butiker',
	to: { local: '/browse' },
	size: 'lg',
	variant: 'primary',
}))``;
