import { config } from '@mybonus/public';
import { SSRContextProvider, WINDOW_DATA_PROPERTY, ISSRContext } from '@mybonus/ssr';
import { hydrateRoot, createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import superjson from 'superjson';

import { routes } from '../routes';
import { initializeMonitoring } from './monitoring';

initializeMonitoring();

const hasSSRData = typeof (window as any)[WINDOW_DATA_PROPERTY] !== 'undefined';
const router = createBrowserRouter(routes);
const rootEl = document.getElementById('app')!;

function renderWithSSRContext(ssrContextValue: ISSRContext<unknown>) {
	return (
		<SSRContextProvider value={ssrContextValue}>
			<RouterProvider router={router} />
		</SSRContextProvider>
	);
}

if (hasSSRData) {
	const ssrContextValue = superjson.deserialize<ISSRContext<unknown>>(
		(window as any)[WINDOW_DATA_PROPERTY] || {},
	);

	hydrateRoot(rootEl, renderWithSSRContext(ssrContextValue));
} else {
	initializeMonitoring();
	const root = createRoot(rootEl);
	const ssrContextValue = {
		shouldCollectDataLoadings: false,
		requests: [],
		data: {},
		title: config.web.defaultTitle,
		metaTags: [],
	};

	root.render(renderWithSSRContext(ssrContextValue));
}
