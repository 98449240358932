import styled from 'styled-components';

import { Box } from '../Box';
import type { GradientProps } from './Gradient.types';

export function Base(props: GradientProps & { className?: string }) {
	return <Box className={props.className}>{props.children}</Box>;
}

export const Gradient = styled(Base)`
	background: linear-gradient(
		${(props) => (typeof props.angle === 'number' ? props.angle : '90')}deg,
		${(props) => props.colors.join(', ')}
	);
`;
