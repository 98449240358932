import isMobilePhone from 'validator/lib/isMobilePhone';
import { z } from 'zod';

import { config } from '../config';

export const Email = z
	.string()
	.email()
	.transform((val) => val.toLowerCase().trim());
export type Email = z.infer<typeof Email>;

export const Phone = z
	.string()
	.transform((val) => val.toLowerCase().replace('+46', '0').replace(/\D/g, '').trim())
	.refine(
		(val) => isMobilePhone(val, config.defaultLocale),
		(val) => ({ message: `Phone number: ${val} is not a valid phone number` }),
	);
export type Phone = z.infer<typeof Phone>;

export const PhoneInput = z.union([Phone, z.literal('')]);
export type PhoneInput = z.infer<typeof PhoneInput>;

export const Password = z.string().min(6);
export type Password = z.infer<typeof Password>;

export const Name = z.string().min(1).max(100);
export type Name = z.infer<typeof Name>;

export const NameInput = z.union([Name, z.literal('')]);
export type NameInput = z.infer<typeof NameInput>;

export const LastFour = z.string().length(4);
export type LastFour = z.infer<typeof LastFour>;

export const SSNInput = z.string();
export type SSNInput = z.infer<typeof SSNInput>;

export const UserGender = z.enum(['MALE', 'FEMALE']);
export type UserGender = z.infer<typeof UserGender>;

export const AdminListUser = z.object({
	id: z.number(),
	fullname: z.string().nullable(),
	email: Email,
	link: z.string(),
	registered: z.date(),
	active: z.boolean(),
	allowAcknowledge: z.boolean(),
	anonymizedAt: z.date().nullable(),
	gender: UserGender.nullable(),
	phone: Phone.nullable(),
	ssn: z.string().nullable(),
});
export type AdminListUser = z.infer<typeof AdminListUser>;

export const AdminSingleUser = AdminListUser.extend({
	firstname: z.string().nullable(),
	lastname: z.string().nullable(),
});
export type AdminSingleUser = z.infer<typeof AdminSingleUser>;
