import { PageTitle, PageContainer } from '@mybonus/ui';

import {
	Description,
	$FloatBox,
	Title,
	CustomerServiceAnchor,
} from './PaymentView.parts';

export type PaymentViewProps = {
	result: 'success' | 'cancel';
};

export function PaymentView(props: PaymentViewProps) {
	function getResultMsg() {
		switch (props.result) {
			case 'success':
				return 'lyckades';
			case 'cancel':
				return 'avbröts';
		}
	}

	return (
		<PageContainer>
			<PageTitle>
				<Title>Betalningen {getResultMsg()}</Title>
			</PageTitle>

			<$FloatBox>
				{props.result === 'cancel' && (
					<Description>
						Tyvärr kunde inte ditt köp genomföras. Försök igen lite senare eller
						kontakta oss på <CustomerServiceAnchor /> så hjälper vi dig.
					</Description>
				)}
				{props.result === 'success' && (
					<>
						<Description>
							Om du fullföljde betalningen kommer vi inom kort skicka
							värdehandlingen till dig efter att betalningen har verifierats.
							Håll utkik i din mailkorg!
						</Description>
						<Description>
							Om du inte fått en bekräftelse efter 4 timmar vänligen kontrollera
							att din e-postadress är korrekt ifylld på ditt konto och kontakta
							kundtjänst: <CustomerServiceAnchor />
						</Description>
					</>
				)}
			</$FloatBox>
		</PageContainer>
	);
}
