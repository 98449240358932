import { Box } from '../../primitives';
import { styled, css } from '../../theme';

export const floatBoxStyle = css`
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
	border-radius: ${({ theme }) => theme.rounding.md}px;
	background-color: #fff;
	padding: ${({ theme }) => theme.space.md}px;

	@media ${({ theme }) => theme.device.laptop} {
		padding: ${({ theme }) => theme.space.lg}px;
	}
`;

export const FloatBox = styled(Box)`
	${floatBoxStyle}
`;
