import { TRPCClientError } from '@trpc/client';
import type { AnyRouter } from '@trpc/server';

import { Errors, errorMessages } from '../err';

export function isTRPCClientError(cause: unknown): cause is TRPCClientError<AnyRouter> {
	return cause instanceof TRPCClientError;
}

/**
 * Detect server error from raw API call, without using tRPC client. If tRPC transformer is used, the
 * decoded data should be passed to this. The error argument should be an object with "code",
 * "message", "data" attributes.
 */
export function isRawServerError(error: unknown): boolean {
	const errorName =
		error && typeof error === 'object' && ((error as any)?.data?.name as string | undefined);

	return !!errorName && Errors[errorName as keyof typeof Errors]?.name === errorName;
}

export function rethrowRawServerError(_error: unknown): void {
	if (isRawServerError(_error)) {
		const error = _error as any;
		const ErrorClass = Errors[error?.data?.name as keyof typeof Errors] || Error;

		throw new ErrorClass(error?.data?.message || errorMessages.default);
	} else {
		throw new Error(errorMessages.default);
	}
}

export function isServerError(cause: unknown, error: keyof typeof Errors | 'any' = 'any'): boolean {
	if (isTRPCClientError(cause)) {
		if (error === 'any' || Errors[error].name === cause.data.name) {
			return true;
		}
	}

	return false;
}

export function serverErrorToHuman(cause: unknown): string {
	if (!isTRPCClientError(cause)) {
		return 'Ett oväntat fel inträffade.';
	}

	return cause.data.message || errorMessages.default;
}

export function serverErrorToDebug(cause: unknown): string {
	if (isTRPCClientError(cause)) {
		if (cause.data) {
			return JSON.stringify(cause, null, 2);
		} else if (cause.stack) {
			return cause.stack;
		} else {
			return String(cause);
		}
	} else if (cause instanceof Error && cause.stack) {
		return cause.stack;
	} else {
		return String(cause);
	}
}
