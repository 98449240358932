import { Text } from '../../../primitives';
import { CashbackList } from '../../CashbackList';
import { Separator } from '../../Separator';
import { ClaimHintText, ClaimsButton, DescriptionSection, Wrapper } from './CashbackTab.parts';

export function CashbackTab() {
	return (
		<Wrapper>
			<DescriptionSection>
				<Text>Nedan ser du all din återbäring som du tjänat in.</Text>
				<ClaimHintText>
					Har ett köp inte registrerats nedan efter 48 timmar, rapportera det till oss här.
				</ClaimHintText>
				<ClaimsButton />
			</DescriptionSection>

			<Separator />
			<CashbackList />
		</Wrapper>
	);
}
