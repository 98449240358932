import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthContext, useFacebookAuthContext } from '../../context';
import { ErrorMessage } from '../ErrorMessage';
import { Form } from '../Form';
import { PageContainer } from '../PageContainer';
import { PageTitle } from '../PageTitle';
import { PasswordTextfield } from '../PasswordTextfield';
import { FacebookContinueButton } from './FacebookContinueButton';
import { ForgotPasswordText } from './ForgotPasswordText';
import { useLoginForm, useVendorAuthentication } from './LoginView.hooks';
import {
	Title,
	NotMemberText,
	$FloatBox,
	$EmailTextfield,
	OrText,
	LoginButton,
} from './LoginView.parts';

export function LoginView() {
	const auth = useAuthContext();
	const facebookAuth = useFacebookAuthContext();
	const navigate = useNavigate();
	const { form, error, mutation } = useLoginForm('USER');
	const vendorAuth = useVendorAuthentication();

	useEffect(() => {
		if (auth.account) {
			const params = new URLSearchParams(window.location.search);
			const redirect = params.get('redirect');

			navigate(redirect || '/account');
		}
	}, [auth.account]);

	const handleFacebookLogin = useCallback(async () => {
		const res = await facebookAuth.login();

		if (res) {
			vendorAuth.authenticate({
				vendorType: 'FACEBOOK',
				vendorAccessToken: res.authResponse.accessToken,
			});
		}
	}, []);

	return (
		<PageContainer>
			<PageTitle>
				<Title />
				<NotMemberText />
			</PageTitle>
			<$FloatBox>
				<FacebookContinueButton isLoading={facebookAuth.isLoading} onClick={handleFacebookLogin} />
				<ErrorMessage error={facebookAuth.error || vendorAuth.error} />
				<OrText />

				<Form onSubmit={form.handleSubmit}>
					<$EmailTextfield
						value={form.data.email || ''}
						variant={form.errors.email ? 'error' : undefined}
						onChange={form.handleChange('email')}
					/>

					<PasswordTextfield
						value={form.data.password || ''}
						variant={form.errors.password ? 'error' : undefined}
						onChange={form.handleChange('password')}
					/>

					<ErrorMessage
						error={(form.hasErrors && 'Se över informationen ovan och försök igen') || error}
					/>

					<LoginButton isLoading={mutation.isLoading} />
				</Form>

				<ForgotPasswordText email={form.data.email} />
			</$FloatBox>
		</PageContainer>
	);
}
