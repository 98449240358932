import { PlatformAlias } from '@mybonus/public';
import { z } from 'zod';

export const Env = z.object({
	APP_PLATFORM_ALIAS: PlatformAlias,
	APP_VERSION: z.string(),
	APP_ENV: z.enum(['local', 'dev', 'master']),
	SENTRY_DSN: z.string(),
	API_BASE_URL: z.string(),
	WEB_BASE_URL: z.string(),
	CDN_URL: z.string(),
	FB_APP_ID: z.string(),
});
export type Env = z.infer<typeof Env>;

export const env = Env.parse({
	APP_ENV: process.env.APP_ENV,
	APP_VERSION: process.env.APP_VERSION,
	APP_PLATFORM_ALIAS: process.env.APP_PLATFORM_ALIAS,
	SENTRY_DSN: process.env.SENTRY_DSN,
	API_BASE_URL: process.env.API_BASE_URL,
	WEB_BASE_URL: process.env.WEB_BASE_URL,
	CDN_URL: process.env.WEB_BASE_URL,
	FB_APP_ID: process.env.FB_APP_ID,
});
