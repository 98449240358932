import { styled, Center, Box, CmsContent, PageContainer, Waiter } from '@mybonus/ui';

export const Wrapper = styled(Center)`
	flex-direction: column;
	justify-content: flex-start;
	height: 100%;
	background-color: ${({ theme }) => theme.color.lighterGray.base};
`;

export const Content = styled(Box)`
	padding: ${({ theme }) => theme.space.md}px;
`;

export const $CmsContent = styled(CmsContent)`
	text-align: center;
	max-width: 700px;
	padding-top: ${({ theme }) => theme.space.md}px;
	padding-bottom: ${({ theme }) => theme.space.lg}px;
`;

export const $PageContainer = styled(PageContainer)`
	padding-top: ${({ theme }) => theme.space.md}px;
`;

export const $Waiter = styled(Waiter)`
	width: 100%;
`;
