import type { NodeOptions } from '@sentry/node';
import type { BrowserOptions } from '@sentry/react';

import { env } from './env';

export type SentryOptions = BrowserOptions | NodeOptions;

export function getIsomorphicSentryConfig(): SentryOptions {
	return {
		dsn: env.SENTRY_DSN,
		tracesSampleRate: 1.0,
		release: env.APP_VERSION,
		environment: env.APP_ENV,
		enabled: env.APP_ENV !== 'local',
	};
}
