import { config } from '@mybonus/public';
import { PageContainer, FulfillRecoverForm } from '@mybonus/ui';
import { useNavigate, useParams } from 'react-router-dom';

import { $FloatBox, $CmsContent } from './FulfillRecoverView.parts';

export function FulfillRecoverView() {
	const params = useParams();
	const navigate = useNavigate();

	return (
		<PageContainer>
			<$CmsContent code="recover_password_intro" />
			<$FloatBox>
				<FulfillRecoverForm
					token={String(params.token)}
					onLoginClick={() => navigate(config.urls.login())}
				/>
			</$FloatBox>
		</PageContainer>
	);
}
