import { styled, css } from '../../theme';
import type { TextProps } from './Text.types';

function Base(props: TextProps & { className?: string }) {
	const El = props.element || 'span';
	return <El className={props.className}>{props.children}</El>;
}

export const Text = styled(Base)`
	${(props) => {
		switch (props.style) {
			case 'bold':
				return css`
					font-family: ${({ theme }) => theme.font.bold};
				`;
			case 'italic':
				return css`
					font-family: ${({ theme }) => theme.font.italic};
				`;
			case 'normal':
			default:
				return css`
					font-family: ${({ theme }) => theme.font.base};
				`;
		}
	}}

	font-size: ${(props) =>
		props.size ? `${props.theme.fontSize[props.size]}px` : 'inherit'};
	color: ${(props) => props.color || props.theme.color.darkGray.base};
	line-height: 1;
	margin: 0;

	${({ decoration }) => {
		switch (decoration) {
			case 'underline':
				return css`
					text-decoration: underline;
				`;
			case 'strike':
				return css`
					text-decoration: line-through;
				`;
		}
	}}
	}

	${(props) =>
		props.noBreak
			? css`
					white-space: nowrap;
			  `
			: ''}
`;
