import type { APIOutput } from '../../api';
import { Avatar } from '../Avatar';
import {
	Wrapper,
	Right,
	Fullname,
	Sub,
	WhiteText,
	CashbackText,
} from './AccountOverviewPill.parts';

export type AccountOverviewPillProps = {
	overview?: APIOutput['user']['account']['overview'];
};

export function AccountOverviewPill(props: AccountOverviewPillProps) {
	const { overview } = props;

	return (
		<Wrapper>
			{overview && <Avatar avatar={overview?.user.avatar} size={40} />}
			<Right>
				<Fullname>{overview?.user.fullname || overview?.user.email}</Fullname>
				<Sub>
					<WhiteText size="sm">Total återbäring: </WhiteText>
					<CashbackText>{overview?.cashbackSummary.total.text}</CashbackText>
				</Sub>
			</Right>
		</Wrapper>
	);
}
