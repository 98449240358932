import { styled, css, Box, IconButton, StoreSearch } from '@mybonus/ui';

export const Reference = styled(Box)`
	position: relative;
`;

export const $IconButton = styled(IconButton).attrs((props) => ({
	...props,
	name: 'IconSearchWhite',
	size: 'xl',
}))``;

export const $StoreSearch = styled(StoreSearch)<{ visible: boolean }>`
	position: absolute;
	z-index: 1;
	top: 0;
	bottom: 0;
	right: 0;
	width: 0px;
	opacity: 0;
	pointer-events: none;
	transition: width 0.2s ease-in-out, opacity 0.2s ease-in-out;

	${({ visible }) =>
		visible &&
		css`
			opacity: 1;
			pointer-events: auto;
			width: 500px;
		`}
`;
