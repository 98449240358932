import { useState } from 'react';

import { useFavoriteWebNavigation } from '../../hooks';
import { IconButton } from '../IconButton';
import { PopdownMountingReference } from '../Popdown';
import { Popdown, $FavoriteList, ShowAllText, $Anchor } from './FavoritePill.parts';

export function FavoritePill(props: { className?: string }) {
	const [open, setOpen] = useState(false);
	const nav = useFavoriteWebNavigation();

	function handleSelect(store: { id: number; name: string }) {
		setOpen(false);
		nav.handleSelect(store);
	}

	function handleNoContentClick() {
		setOpen(false);
		nav.handleNoContentClick();
	}

	function handleShowAllClick() {
		setOpen(false);
		nav.handleShowAllClick();
	}

	return (
		<PopdownMountingReference className={props.className}>
			<IconButton name="IconHeartWhite" size="xl" onClick={() => setOpen(!open)} />
			<Popdown
				open={open}
				onClose={() => setOpen(false)}
				position="left"
				arrow={{ position: 'right', px: 20 }}
			>
				<$FavoriteList
					onSelect={handleSelect}
					onNoContentClick={handleNoContentClick}
					isVisible={open}
					footer={
						<ShowAllText>
							<$Anchor
								to={{
									onClick: handleShowAllClick,
								}}
							>
								Visa alla
							</$Anchor>
						</ShowAllText>
					}
				/>
			</Popdown>
		</PopdownMountingReference>
	);
}
