import { useMenuLinks } from '../../../../hooks';
import { $Waiter, $ActivePathAnchor } from './MenuLinks.parts';

export function MenuLinks() {
	const { location, isLoading, items } = useMenuLinks();

	return (
		<$Waiter isLoading={isLoading}>
			{items.map((item) => (
				<$ActivePathAnchor
					key={`${item.type}-${item.path}`}
					to={{ local: item.path }}
					active={location.pathname === item.path}
				>
					{item.text}
				</$ActivePathAnchor>
			))}
		</$Waiter>
	);
}
