import { debounce } from '@mybonus/public';
import { useEffect, useMemo } from 'react';

export const useDebounce = <A extends any[], R = void>(
	fn: (...args: A) => R,
	delay: string,
): ((...args: A) => Promise<R>) => {
	const [debouncedFun, teardown] = useMemo(
		() => debounce<A, R>(fn, delay),
		[fn, delay],
	);

	useEffect(() => () => teardown(), []);

	return debouncedFun;
};
