import { useState, useEffect, useMemo } from 'react';

import { useAPI, useQuery, useMutation, APIInput } from '../../api';
import { useNotificationContext } from '../../context';
import { useUniversalIntentNavigation, usePagination } from '../../hooks';
import type { Notification } from './NotificationList.types';

export function useNotificationList(opts: { isVisible: boolean }) {
	const { api } = useAPI();
	const { pagination: input, setPage, page } = usePagination(1, 10);
	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const [markReadIds, setMarkReadIds] = useState<number[]>([]);
	const notification = useNotificationContext();
	const nav = useUniversalIntentNavigation();

	const notifications = useQuery(
		'user.notification.list',
		(args) => api.user.notification.list.query(args),
		input,
		{
			mergeData: (data, newData) =>
				data
					? { ...data, ...newData, items: data.items.concat(newData.items) }
					: newData,
			preventAutoload: !opts.isVisible,
			ttl: false,
		},
	);

	const markRead = useMutation<APIInput['user']['notification']['markRead']>(
		(input) => api.user.notification.markRead.mutate(input),
		{ onSuccess: () => notification.invalidate() },
	);

	const unreadItemIds = useMemo(
		() =>
			(notifications.data?.items || [])
				.filter((n) => !n.isRead && !markReadIds.includes(n.id))
				.map((n) => n.id),
		[notifications.data, markReadIds],
	);

	useEffect(() => {
		if (unreadItemIds.length) {
			setMarkReadIds(markReadIds.concat(unreadItemIds));
			markRead.mutate({ ids: unreadItemIds });
		}
	}, [unreadItemIds]);

	useEffect(() => {
		if (notifications.data) {
			setIsInitialLoad(false);
		}
	}, [notifications.data]);

	function loadMore() {
		setPage(page + 1);
	}

	useEffect(() => {
		if (!opts.isVisible) {
			setPage(1);
			setIsInitialLoad(true);
			notifications.reset();
		}
	}, [opts.isVisible]);

	const list = notifications.data?.items || [];
	const hasMore =
		notifications.data &&
		notifications.data.pagination.page < notifications.data.pagination.pages;

	function onSelect(notification: Notification) {
		if (notification.intent) {
			nav.web(notification.intent);
		}
	}

	return {
		isLoading: notifications.isLoading,
		isInitialLoad,
		list,
		hasMore,
		loadMore,
		onSelect,
	};
}
