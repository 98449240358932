import { Paragraph } from '../../Paragraph';
import { Waiter } from '../../Waiter';
import { Wrapper, QRImage, Steps } from './ExternalFlow.parts';

export type ExternalFlowProps = {
	qr?: string;
};

export function ExternalFlow(props: ExternalFlowProps) {
	return (
		<Waiter isLoading={!props.qr}>
			<Wrapper>
				<Steps>
					<Paragraph>Starta BankID-appen i din mobil</Paragraph>
					<Paragraph>Tryck på "Skanna QR-kod"</Paragraph>
					<Paragraph>Rikta kameran mot QR-koden här nedanför</Paragraph>
				</Steps>
				{props.qr && <QRImage url={props.qr} />}
			</Wrapper>
		</Waiter>
	);
}
