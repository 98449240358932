import { useStatsSection } from './StatsSection.hooks';
import { Wrapper, Metric, Value, Label } from './StatsSection.parts';

export function StatsSection() {
	const { data } = useStatsSection();

	return (
		<Wrapper>
			<Metric>
				<Value>{data?.totalUsers}</Value>
				<Label>Medlemmar</Label>
			</Metric>
			<Metric>
				<Value>{data?.totalPurchases}</Value>
				<Label>Unika köp</Label>
			</Metric>
			<Metric>
				<Value>{data?.totalCashback} kr</Value>
				<Label>Genererad återbäring</Label>
			</Metric>
		</Wrapper>
	);
}
