import { InvalidOrgNrError } from '../err';

export function tryValidateOrgNr(input: string): void {
	if (!isValidOrgNr(input)) {
		throw new InvalidOrgNrError(`Invalid organization number: ${input}`);
	}
}

export function isValidOrgNr(input: string): boolean {
	return /^([0-9-]){8,12}$/.test(input);
}
