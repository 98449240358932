import { PageContainer, ErrorMessage, PageTitle, Paragraph } from '@mybonus/ui';

import { useSmsUnsubscribeView } from './SmsUnsubscribeView.hooks';
import { $FloatBox, ConfirmButton, Title } from './SmsUnsubscribeView.parts';

export function SmsUnsubscribeView() {
	const { mutation, doUnsubscribe } = useSmsUnsubscribeView();

	return (
		<PageContainer>
			<PageTitle>
				<Title>Avregistring</Title>
			</PageTitle>

			<$FloatBox>
				<Paragraph>
					Är du säker på att du vill avregistera dig från framtida SMS-utskick?
				</Paragraph>

				<ConfirmButton onClick={doUnsubscribe} />

				{!!mutation.isSuccess && (
					<Paragraph variant="info">
						Du har blivit avregistrerad från SMS-utskicket. Du kan när som helst
						uppdatera dina inställningar på ditt konto.
					</Paragraph>
				)}

				<ErrorMessage error={mutation.error} />

				{!!mutation.error && (
					<Paragraph variant="error">
						Försök igen senare eller kontakta kundtjänst.
					</Paragraph>
				)}
			</$FloatBox>
		</PageContainer>
	);
}
