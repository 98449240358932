import { PageTitle, PageContainer, Heading, CmsContent, useHead } from '@mybonus/ui';

import {
	$FloatBox,
	$DownloadPluginButton,
	Content,
	PluginImage,
	Preview,
} from './PluginView.parts';

export function PluginView() {
	useHead({ subtitle: 'Autobäring' });

	return (
		<PageContainer noTopSpace>
			<PageTitle>
				<Heading level={1}>MyBonus Autobäring</Heading>
			</PageTitle>

			<$FloatBox>
				<Content>
					<CmsContent code="plugin_text" />
					<$DownloadPluginButton vendor="chrome" />
				</Content>
				<Preview>
					<PluginImage />
				</Preview>
			</$FloatBox>
		</PageContainer>
	);
}
