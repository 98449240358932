import {
	useAPI,
	useMutation,
	useQuery,
	useAccountContext,
	type APIInput,
} from '@mybonus/ui';
import { useEffect, useMemo } from 'react';

export function useFulfillEmailChangeView(code: string) {
	const { api } = useAPI();
	const account = useAccountContext();

	const mutation = useMutation<
		APIInput['guest']['account']['fulfillEmailChange']
	>((input) => api.guest.account.fulfillEmailChange.mutate(input), {
		onSuccess: () => {
			account.invalidate();
		},
	});

	const validationInput = useMemo(() => ({ code }), [code]);
	const validation = useQuery(
		'guest.account.validateEmailChangeCode',
		(args) => api.guest.account.validateEmailChangeCode.query(args),
		validationInput,
	);

	useEffect(() => {
		if (validation.data) {
			mutation.mutate(validationInput);
		}
	}, [validation.data]);

	return { mutation, validation };
}
