import { Anchor } from '../../primitives';
import { styled } from '../../theme';
import { EmailTextfield } from '../EmailTextfield';
import { FloatBox } from '../FloatBox';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';
import { TextButton } from '../TextButton';

export const Title = styled(Heading).attrs(() => ({
	children: 'Återställ ditt lösenord',
	level: 1,
}))``;

export const $EmailTextfield = styled(EmailTextfield)`
	text-align: center;
`;

export const RecoverButton = styled(TextButton).attrs(() => ({
	variant: 'primary',
	text: 'Återställ lösenord',
	type: 'submit',
	onClick: () => {
		// noop
	},
}))`
	width: 100%;
	margin: ${({ theme }) => theme.space.sm}px 0;
`;

export const BackToLoginText = styled(Paragraph).attrs((props) => ({
	...props,
	children: <Anchor to={{ local: '/login' }}>Tillbaka till inloggning</Anchor>,
}))``;

export const $FloatBox = styled(FloatBox)`
	margin-top: 16px;
	width: 100%;
	max-width: 400px;
`;
