import { css } from '../../theme';

export const defaultHeight = 64;

export const animatedCorner = (side: 'left' | 'right') => css<{
	open: boolean;
	height?: number;
}>`
	transition: border-bottom-${side}-radius 0.2s ease-in-out;

	border-top-${side}-radius: ${(props) => (props.height || defaultHeight) / 2}px;
	border-bottom-${side}-radius: ${(props) =>
	props.open ? '0' : `${(props.height || defaultHeight) / 2}px`};
`;
