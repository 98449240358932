import type { ReactNode } from 'react';

import { Bullet, Content, HeadingText, Wrapper } from './BulletSection.parts';

export type BulletSectionProps = {
	heading: string;
	children: ReactNode;
	noIndent?: boolean;
};

export function BulletSection(
	props: BulletSectionProps & { className?: string },
) {
	return (
		<Wrapper className={props.className} noIndent={props.noIndent}>
			<Bullet />
			<HeadingText>{props.heading}</HeadingText>
			<Content>{props.children}</Content>
		</Wrapper>
	);
}
