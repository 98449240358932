import { formatMetricDisplay } from '@mybonus/public';
import constate from 'constate';

import { useAPI, useQuery } from '../api';

function useStoreCount() {
	const { api } = useAPI();

	const storeCount = useQuery(
		'guest.store.count',
		() => api.guest.store.count.query(),
		undefined,
	);

	const value = storeCount.data || 1000;

	return {
		value,
		text: formatMetricDisplay(value),
		isLoading: storeCount.isLoading,
	};
}

export const [StoreCountProvider, useStoreCountContext] =
	constate(useStoreCount);
