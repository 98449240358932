import { useParams } from 'react-router-dom';
import { z } from 'zod';

import { useMutation, useAPI, type APIInput, type APIOutput } from '../../api';
import { useAuthContext } from '../../context';
import { useForm } from '../../hooks';

export function useAccountRemovalFulfillForm() {
	const params = useParams();
	const auth = useAuthContext();
	const { api } = useAPI();

	const form = useForm<{
		hasUnderstood: boolean;
	}>({
		onSubmit: () => {
			const code = z.string().safeParse(params.code);

			if (!code.success) {
				return;
			}

			mutation.mutate({ code: code.data });
		},
	});

	const mutation = useMutation<
		APIInput['guest']['account']['anonymize']['fulfill'],
		APIOutput['guest']['account']['anonymize']['fulfill']
	>((args) => api.guest.account.anonymize.fulfill.mutate(args), {
		onSuccess: () => {
			auth.logout();
		},
	});

	const error = mutation.error;

	return {
		mutation,
		error,
		form,
	};
}
