import { IconProps, ActivePathAnchor, Icon, Text } from '@mybonus/ui';
import { useLocation } from 'react-router-dom';

import { $Row, Badge, IconWrapper } from './IconMenuItem.parts';

export type IconMenuItemProps = {
	icon: IconProps['name'];
	count?: number;
	text: string;
	path: string;
};

export function IconMenuItem(props: IconMenuItemProps) {
	const location = useLocation();

	return (
		<ActivePathAnchor
			to={{ local: props.path }}
			active={location.pathname === props.path}
		>
			<$Row>
				<IconWrapper>
					<Icon name={props.icon} size="lg" />
					{!!props.count && <Badge />}
				</IconWrapper>
				<Text>{props.text}</Text>
			</$Row>
		</ActivePathAnchor>
	);
}
