import { Heading } from '../Heading';
import {
	$FloatBox,
	TopSection,
	BottomSection,
	BuddiesImage,
	Steps,
	Step,
	Desc,
	ShopGirlImage,
	MoneyHandImage,
	BottomSectionCmsContent,
} from './RecruitmentGuideFloatBox.parts';

export function RecruitmentGuideFloatBox() {
	return (
		<$FloatBox>
			<TopSection>
				<Heading level={4}>Såhär funkar det</Heading>

				<Steps>
					<Step>
						<BuddiesImage />
						<Desc code="recruiment_step_1" />
					</Step>
					<Step>
						<ShopGirlImage />
						<Desc code="recruiment_step_2" />
					</Step>
					<Step>
						<MoneyHandImage />
						<Desc code="recruiment_step_3" />
					</Step>
				</Steps>
			</TopSection>

			<BottomSection>
				<BottomSectionCmsContent />
			</BottomSection>
		</$FloatBox>
	);
}
