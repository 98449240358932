import {
	PageContainer,
	CashbackTabs,
	AccountSummary,
	useHead,
} from '@mybonus/ui';

import {
	Layout,
	CashbackListBox,
	AccountSummaryBox,
} from './AccountView.parts';

export function AccountView() {
	useHead({ subtitle: 'Mitt konto' });

	return (
		<PageContainer>
			<Layout>
				<AccountSummaryBox>
					<AccountSummary />
				</AccountSummaryBox>
				<CashbackListBox>
					<CashbackTabs />
				</CashbackListBox>
			</Layout>
		</PageContainer>
	);
}
