import { PageContainer, PageTitle, ClaimsForm, useHead } from '@mybonus/ui';

import { $FloatBox, Title, FloatWrapper } from './ClaimView.parts';

const title = 'Rapportera in köp';

export function ClaimView() {
	useHead({ subtitle: title });

	return (
		<PageContainer>
			<PageTitle>
				<Title>{title}</Title>
			</PageTitle>

			<FloatWrapper>
				<$FloatBox>
					<ClaimsForm />
				</$FloatBox>
			</FloatWrapper>
		</PageContainer>
	);
}
