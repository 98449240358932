import { useEffect, useRef, type RefObject } from 'react';

export type UseClickOutsideOpts = {
	onClose: () => void;
	// If we need to check extra refs (exclude other elements as well)
	exceptRefs?: RefObject<any>[];
};

export function useClickOutside(opts: UseClickOutsideOpts) {
	const ref = useRef<HTMLDivElement>(null);

	const handleClickOutside = (event: MouseEvent) => {
		const checkRefs = [ref, ...(opts.exceptRefs || [])];

		const matchedRef = checkRefs.find((r) => {
			return r.current && r.current.contains(event.target as Node);
		});

		if (!matchedRef) {
			opts.onClose();
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);

		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	return ref;
}
