import {
	PageTitle,
	PageContainer,
	FloatBox,
	Heading,
	CmsContent,
	useHead,
} from '@mybonus/ui';

const title = 'Om oss';

export function AboutView() {
	useHead({ subtitle: title });

	return (
		<PageContainer width="narrow" noTopSpace>
			<PageTitle>
				<Heading level={1}>{title}</Heading>
			</PageTitle>

			<FloatBox>
				<CmsContent code="about_us_text" />
			</FloatBox>
		</PageContainer>
	);
}
