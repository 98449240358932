import { imageUrlWithSize, config } from '@mybonus/public';
import { APIOutput, ProgressBar, CmsContent, ErrorMessage, Paragraph, Anchor } from '@mybonus/ui';

import { useMemberView } from './MemberView.hooks';
import {
	Content,
	StoreLogo,
	Footer,
	OfferText,
	TermsHeading,
	AcceptButton,
	RedirectGuidanceText,
} from './MemberView.parts';

export type MemberViewProps = {
	store?: APIOutput['guest']['store']['single'];
	link?: string;
};

export function MemberView(props: MemberViewProps) {
	const { store, link } = props;
	const { showAccept, handleAccept, registration, hasRedirected, countdown } = useMemberView(props);

	return (
		<>
			<Content>
				{store?.logoUrl && (
					<StoreLogo
						url={imageUrlWithSize(store.logoUrl, {
							w: '400',
							format: 'png',
						})}
						alt={store.name}
					/>
				)}
				{store?.bestOffer && !link && (
					<OfferText>Aktiverar {store?.bestOffer?.text.toLowerCase()} återbäring...</OfferText>
				)}
				{store && showAccept && !link ? (
					<>
						{!hasRedirected && (
							<AcceptButton onClick={handleAccept} disabled={registration.isLoading} />
						)}
						{hasRedirected && !!registration.data && (
							<>
								<RedirectGuidanceText>
									Butiken bör ha öppnats i en ny flik eller fönster. Om detta inte är fallet kan du
									gå till butiken manuellt via{' '}
									<Anchor to={{ url: registration.data, newTab: true }}>denna länk</Anchor>.
								</RedirectGuidanceText>

								<Paragraph>
									Går tillbaka till {store.name} om {countdown.second} sekunder...
								</Paragraph>

								<ProgressBar min={0} max={countdown.durationSeconds} value={countdown.second} />
							</>
						)}
					</>
				) : (
					<ProgressBar min={0} max={100} value={100} animateDuration={4} />
				)}
				<ErrorMessage error={registration.error} />
			</Content>
			<Footer>
				<TermsHeading>Särskilda villkor</TermsHeading>
				<CmsContent
					{...(store?.termsSpecial
						? { html: store?.termsSpecial }
						: {
								markdown: `Gör köpet direkt i länken som genereras utan att besöka några andra sidor emellan. Använd inga externa/personliga koder/presentkort.`,
						  })}
				/>
			</Footer>
		</>
	);
}
