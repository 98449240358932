import { Textfield } from '../../../primitives';
import { styled } from '../../../theme';
import { AnchorButton } from '../../AnchorButton';
import { Column } from '../../Column';

export const FinishedButton = styled(AnchorButton).attrs(() => ({
	variant: 'primary',
	text: 'Klar',
	size: 'sm',
	to: { local: '/account' },
}))`
	width: 100%;
`;

export const EmailField = styled(Textfield).attrs((props) => ({
	...props,
	disabled: true,
	size: 'sm',
	type: 'text',
}))`
	width: 100%;
	text-align: center;
`;

export const CopyFormRow = styled(Column)`
	align-items: center;
	margin: ${({ theme }) => theme.space.md}px 0;
`;

export const FormElements = styled(Column)`
	> * {
		margin-bottom: ${({ theme }) => theme.space.sm}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;
