import {
	styled,
	Box,
	Selectfield,
	BankIdButton,
	Paragraph,
	OrderGiftcardButton,
} from '@mybonus/ui';

export const Wrapper = styled(Box)`
	width: 100%;
`;

export const OrderButton = styled(OrderGiftcardButton).attrs((props) => ({
	...props,
	variant: 'alternative',
}))`
	width: 100%;
	margin-top: ${({ theme }) => theme.space.md}px;
`;

export const RegisterText = styled(Paragraph).attrs((props) => ({
	...props,
	variant: 'warning',
}))`
	margin-top: ${({ theme }) => theme.space.md}px;
	text-align: center;
`;

export const $BankIdButton = styled(BankIdButton)`
	margin-top: ${({ theme }) => theme.space.md}px;
`;

export const OfferSelect = styled(Selectfield).attrs((props) => ({
	...props,
}))`
	width: 100%;
`;
