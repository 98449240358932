import { z } from 'zod';

export const ImageWidth = z.enum(['100', '200', '400', '800', '1000']);
export type ImageWidth = z.infer<typeof ImageWidth>;

export const ImageHeight = z.enum(['50', '100', '200', '400', '800']);
export type ImageHeight = z.infer<typeof ImageHeight>;

// Extension can only be set when we resize an image since we only can control the output image
// format when we are resizing.
export const ResizeFormat = z.enum(['jpg', 'png']);
export type ResizeFormat = z.infer<typeof ResizeFormat>;

export const ImageResizeInput = z.union([
	z
		.object({
			format: ResizeFormat,
			w: ImageWidth.optional(),
		})
		.strict(),
	z
		.object({
			format: ResizeFormat,
			h: ImageHeight.optional(),
		})
		.strict(),
	z
		.object({
			d: z.literal('1').optional(),
		})
		.strict(),
]);
export type ImageResizeInput = z.infer<typeof ImageResizeInput>;
