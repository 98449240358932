import type { ToastInstance } from '../../../context';
import { Box, Text } from '../../../primitives';
import { styled, css } from '../../../theme';
import { Icon } from '../../Icon';

export const Wrapper = styled(Box)<ToastInstance>`
	border-radius: ${({ theme }) => theme.rounding.sm}px;
	color: ${({ theme }) => theme.color.white.base};
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	padding: ${({ theme }) => theme.space.md}px;
	pointer-events: auto;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.space.md}px;
	opacity: ${({ isDismissing }) => (isDismissing ? 0 : 1)};
	overflow: hidden;
	transform: translateY(${({ isDismissing }) => (isDismissing ? 20 : 0)}%);
	transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

	${({ opts, theme }) => {
		switch (opts.type) {
			case 'success':
				return css`
					background-color: ${theme.color.green.base};
				`;
			case 'warning':
				return css`
					background-color: ${theme.color.orange.base};
				`;
			case 'error':
				return css`
					background-color: ${theme.color.red.base};
				`;
			default:
				return css`
					background-color: ${theme.color.lightGray.base};
				`;
		}
	}}
`;

export const InfoBox = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.space.sm}px;
`;

export const MessageText = styled(Text).attrs((props) => ({
	...props,
	style: 'bold',
}))`
	color: ${({ theme }) => theme.color.white.base};
`;

export const DetailText = styled(Text).attrs((props) => ({
	...props,
}))`
	color: ${({ theme }) => theme.color.white.base};
	font-size: ${({ theme }) => theme.fontSize.sm}px;
`;

export const $Icon = styled(Icon).attrs((props) => ({
	...props,
	size: 'xl',
}))``;
