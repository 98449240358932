import { CmsContent } from '../CmsContent';
import { Heading } from '../Heading';
import {
	TopArea,
	$FloatBox,
	Content,
	Left,
	Right,
	ClaimButton,
	$Heading,
} from './ClaimFloatBox.parts';

export type ClaimFloatBoxProps = {
	onClaimButtonClick: () => void;
};

export function ClaimFloatBox(props: ClaimFloatBoxProps) {
	return (
		<$FloatBox>
			<TopArea>
				<$Heading>Har ett köp inte blivit registrerat?</$Heading>
			</TopArea>
			<Content>
				<Left>
					<CmsContent code="claim_description_1" />
				</Left>
				<Right>
					<CmsContent code="claim_description_2" />
					<ClaimButton onClick={props.onClaimButtonClick} />
				</Right>
			</Content>
		</$FloatBox>
	);
}
