import { UniversalIntent, config } from '@mybonus/public';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUrl } from './url';

export function useUniversalIntentNavigation() {
	const navigate = useNavigate();
	const url = useUrl();

	const buildUniversalLink = useCallback(
		(webBaseUrl: string, intent: UniversalIntent, forceApp: boolean) => {
			let url = `${webBaseUrl}/s/${intent.target.toLowerCase().replace('_', '-')}`;

			if ('id' in intent) {
				url += `/${intent.id}`;
			}

			if (forceApp) {
				url += `?app=1`;
			}

			return url;
		},
		[],
	);

	const web = useCallback((intent: UniversalIntent) => {
		switch (intent.target) {
			case 'STORE':
				navigate(`/store/${intent.id}`);
				break;
			case 'START':
			case 'STORE_CAMPAIGN': // Not used
				navigate(`/`);
				break;
			case 'BONUS_BUDDIES':
				navigate(`/recruitment`);
				break;
			case 'STORE_REDIRECT':
				navigate(`/out/${intent.id}`);
				break;
			case 'STORE_CAMPAIGNS':
				navigate(`/store/${intent.id}/#campaigns`);
				break;
			case 'PROFILE':
			case 'TRANSACTIONS_READY':
			case 'TRANSACTIONS_INCOMING':
			case 'TRANSACTIONS_COLLECTED':
			case 'TRANSACTIONS_BONUS_BUDDIES':
				navigate(`/account`);
				break;
		}
	}, []);

	const isAndroid = useMemo(() => /Android/i.test(navigator.userAgent), []);
	const isIOS = useMemo(() => /iPhone|iPad|iPod/i.test(navigator.userAgent), []);

	const mayHaveApp = isAndroid || isIOS;

	const app = useCallback((intent: UniversalIntent) => {
		const appuri = `${config.app.scheme}/${intent.target}${
			'id' in intent ? `?id=${intent.id}` : ''
		}`;

		console.log('Trying to launch app', { intent, appuri });

		window.location.href = appuri;
	}, []);

	const auto = useCallback((intent: UniversalIntent) => {
		if ('app' in url.queryObject) {
			app(intent);

			window.setTimeout(() => {
				console.log('Fallback to app store');
				let url;

				if (isAndroid) {
					url = config.app.android.playStoreUrl;
				} else if (isIOS) {
					url = config.app.ios.appStoreUrl;
				} else {
					// In this case, if we're feeling ambitious, we should render a view that tells
					// the user that they need to download the app. In this case, we just send them
					// to app store, they'll get the point...
					url = config.app.ios.appStoreUrl;
				}

				window.location.href = url;
			}, 1000);

			return;
		}

		if (mayHaveApp) {
			app(intent);

			window.setTimeout(() => {
				console.log('Fallback to web navigation');
				web(intent);
			}, 1000);
		} else {
			web(intent);
		}
	}, []);

	return { app, web, auto, buildUniversalLink };
}
