import { z } from 'zod';

export const ListRecruitment = z.object({
	id: z.number(),
	name: z.string(),
	hasBecomeActiveMember: z.boolean(),
	active: z.boolean(),
	createdAt: z.date(),
});
export type ListRecruitment = z.infer<typeof ListRecruitment>;
