import { Text as _Text, Anchor as _Anchor } from '../../primitives';
import { styled } from '../../theme';
import { Row } from '../Row';

export const Text = styled(_Text).attrs((props) => ({
	...props,
	color: props.theme.color.white.base,
	style: 'bold',
}))`
	padding-left: ${({ theme }) => theme.space.sm}px;
`;

export const Anchor = styled(_Anchor)`
	color: ${({ theme }) => theme.color.white.base};
`;

export const Wrapper = styled(Row)`
	width: auto;
	justify-content: flex-start;
	align-items: flex-start;
	padding: ${({ theme }) => theme.space.md}px;
`;
