import { Box } from '../../primitives';
import { styled, css } from '../../theme';

export type RowProps = {
	apart?: boolean;
};

export const Row = styled(Box)<RowProps>`
	display: flex;
	width: 100%;

	${({ apart }) =>
		apart
			? css`
					justify-content: space-between;
			  `
			: ''}
`;
