import { useMutation, useAPI, type APIInput, type APIOutput } from '../../api';
import { useAccountContext } from '../../context';

export function useChangePasswordForm() {
	const account = useAccountContext();
	const { api } = useAPI();

	const mutation = useMutation<
		APIInput['guest']['auth']['credentials']['recover'],
		APIOutput['guest']['auth']['credentials']['recover']
	>((args) => api.guest.auth.credentials.recover.mutate(args));

	const email = account.overview.data?.user.email;

	function onRequestPasswordReset() {
		if (email) {
			mutation.mutate({ email });
		}
	}

	return {
		mutation,
		onRequestPasswordReset,
		email,
	};
}
