import styled from 'styled-components';

import type { BoxProps } from './Box.types';

function Base(props: BoxProps & { className?: string }) {
	return (
		<div className={props.className} onClick={props.onClick}>
			{props.children}
		</div>
	);
}

export const Box = styled(Base)`
	box-sizing: border-box;
`;
