import { PageContainer, PageTitle, SwitchButton } from '@mybonus/ui';

import { useCommunicationSettings } from './CommunicationSettingsView.hooks';
import {
	$FloatBox,
	$BackAnchor,
	Title,
	FloatWrapper,
	FloatBoxTitle,
	SettingRow,
	SettingLabel,
	SettingWrapper,
} from './CommunicationSettingsView.parts';

const settings = {
	notification: [
		{ label: 'Registrerade återbäringar', type: 'CASHBACK_UPDATE_PENDING' },
		{
			label: 'Återbäringar från Bonus Buddies',
			type: 'BONUS_BUDDY_NEW_CASHBACK',
		},
		{ label: 'Nya Bonus Buddies', type: 'BONUS_BUDDY_NEW_BUDDY' },
		{
			label: 'Nya kampanjer på favoritbutiker',
			type: 'SUBSCRIBE_STORE_CAMPAIGN_NEW',
		},
		{
			label: 'Utgående kampanjer på favoritbutiker',
			type: 'SUBSCRIBE_STORE_CAMPAIGN_EXPIRE',
		},
		{ label: 'Personliga erbjudanden', type: 'MARKETING_TARGETED' },
	],
	email: [
		{ label: 'Registrerade återbäringar', type: 'CASHBACK' },
		{ label: 'Återbäringar från Bonus Buddies', type: 'BONUS' },
		{ label: 'Nya Bonus Buddies', type: 'RECRUIT' },
		{
			label: 'Information om tjänsten och ditt användarkonto',
			type: 'UPDATES',
		},
		{
			label: 'Personliga erbjudanden',
			type: 'NEWSLETTER',
		},
	],
	sms: [
		{
			label: 'Personliga erbjudanden',
			type: 'ANNOUNCEMENT',
		},
	],
} as const;

export function CommunicationSettingsView() {
	const { sms, email, notification } = useCommunicationSettings();

	return (
		<PageContainer>
			<PageTitle>
				<Title>Notiser</Title>
			</PageTitle>

			<FloatWrapper>
				<$BackAnchor />
				<$FloatBox>
					<FloatBoxTitle>Pushnotiser</FloatBoxTitle>
					<SettingWrapper>
						{settings.notification.map((setting, key) => (
							<SettingRow key={key}>
								<SettingLabel>{setting.label}</SettingLabel>
								<SwitchButton
									value={notification.state?.[setting.type] === 'ON'}
									disabled={notification.isLoading}
									onChange={() => notification.onChange(setting.type)}
								/>
							</SettingRow>
						))}
					</SettingWrapper>
				</$FloatBox>
				<$FloatBox>
					<FloatBoxTitle>Email</FloatBoxTitle>

					<SettingWrapper>
						{settings.email.map((setting, key) => (
							<SettingRow key={key}>
								<SettingLabel>{setting.label}</SettingLabel>
								<SwitchButton
									value={email.state?.[setting.type] === 'ON'}
									disabled={email.isLoading}
									onChange={() => email.onChange(setting.type)}
								/>
							</SettingRow>
						))}
					</SettingWrapper>
				</$FloatBox>
				<$FloatBox>
					<FloatBoxTitle>SMS</FloatBoxTitle>
					<SettingWrapper>
						{settings.sms.map((setting, key) => (
							<SettingRow key={key}>
								<SettingLabel>{setting.label}</SettingLabel>
								<SwitchButton
									value={sms.state?.[setting.type] === 'ON'}
									disabled={sms.isLoading}
									onChange={() => sms.onChange(setting.type)}
								/>
							</SettingRow>
						))}
					</SettingWrapper>
				</$FloatBox>
			</FloatWrapper>
		</PageContainer>
	);
}
