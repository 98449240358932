import { z } from 'zod';

import { UniversalIntent } from '../../universal-intent';
import { MessageTypeCode } from '../types';

export const NotificationData = z.object({
	intent: UniversalIntent.optional(),
});

export type NotificationPayload = z.infer<typeof NotificationData>;

export const NotificationLevel = z.enum(['OFF', 'ON', 'PUSH']);
export type NotificationLevel = z.infer<typeof NotificationLevel>;

export const ListNotification = z.object({
	id: z.number(),
	type: MessageTypeCode,
	isRead: z.boolean(),
	message: z.string(),
	intent: UniversalIntent.nullable(),
	createdAt: z.date(),
});
export type ListNotification = z.infer<typeof ListNotification>;
