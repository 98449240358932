import { undefinedIfEmpty } from '../../hooks';
import { BankIdButton } from '../BankIdButton';
import { ErrorMessage } from '../ErrorMessage';
import { Form } from '../Form';
import { useAccountSettingsForm } from './AccountSettingsForm.hooks';
import {
	$EmailTextfield,
	FirstnameTextfield,
	LastnameTextfield,
	SaveButton,
	PhoneTextfield,
	SSNTextfield,
	SuccessMessage,
	TempEmailWarning,
} from './AccountSettingsForm.parts';

export function AccountSettingsForm() {
	const { form, mutation, account, onIdentified, hasTempEmail, user } = useAccountSettingsForm();

	return (
		<Form onSubmit={form.handleSubmit}>
			<$EmailTextfield
				disabled={!account.isIdentified}
				value={form.data.email || ''}
				variant={form.errors.email ? 'error' : hasTempEmail ? 'warning' : undefined}
				onChange={form.handleChange('email')}
			/>
			{hasTempEmail && <TempEmailWarning>{user?.tempEmail} inväntar verifiering</TempEmailWarning>}
			<FirstnameTextfield
				disabled={!account.isIdentified}
				value={form.data.firstname || ''}
				variant={form.errors.firstname ? 'error' : undefined}
				onChange={form.handleChange('firstname')}
			/>
			<LastnameTextfield
				disabled={!account.isIdentified}
				value={form.data.lastname || ''}
				variant={form.errors.lastname ? 'error' : undefined}
				onChange={form.handleChange('lastname')}
			/>
			<PhoneTextfield
				disabled={!account.isIdentified}
				value={form.data.phone || ''}
				variant={form.errors.phone ? 'error' : undefined}
				onChange={form.handleChange('phone', undefinedIfEmpty)}
			/>
			<SSNTextfield
				value={form.data.ssn || ''}
				variant={form.errors.ssn ? 'error' : undefined}
				disabled={form.data.ssn?.length > 0 || !account.isIdentified}
				onChange={form.handleChange('ssn')}
			/>
			<ErrorMessage error={mutation.error} />

			<SaveButton
				disabled={account.isLoading || !account.isIdentified || !form.isChanged}
				onClick={form.handleSubmit}
			/>

			{!account.isIdentified && (
				<BankIdButton
					onIdentified={onIdentified}
					reason="Identifiera dig för att göra ändringar."
				/>
			)}

			{mutation.isSuccess && <SuccessMessage>Inställningarna har sparats</SuccessMessage>}
		</Form>
	);
}
