import type { ToastInstance } from '../../../context';
import { Wrapper, MessageText, DetailText, $Icon, InfoBox } from './Toast.parts';

export type ToastProps = ToastInstance & {
	onClick?: () => void;
};

export function Toast(props: ToastProps) {
	return (
		<Wrapper {...props}>
			<$Icon name="IconInfoWhite" />
			<InfoBox>
				<MessageText>{props.opts.message}</MessageText>
				{!!props.opts.details && <DetailText>{props.opts.details}</DetailText>}
			</InfoBox>
		</Wrapper>
	);
}
