import { useEffect, useMemo } from 'react';

import { useAPI, useMutation, useQuery, type APIInput } from '../../api';
import { useForm, passwordValidation } from '../../hooks';

export function useFulfillRecoverForm(token: string) {
	const { api } = useAPI();

	const validationInput = useMemo(() => ({ token }), [token]);
	const validation = useQuery(
		'guest.auth.credentials.validateRecoverToken',
		(args) => api.guest.auth.credentials.validateRecoverToken.query(args),
		validationInput,
	);

	const mutation = useMutation<APIInput['guest']['auth']['credentials']['fulfillRecover']>(
		(input) => api.guest.auth.credentials.fulfillRecover.mutate(input),
	);

	const form = useForm<{ password: string; token: string }>({
		initialValues: {
			token,
		},
		validations: {
			password: passwordValidation(),
		},
		onSubmit: () => {
			mutation.mutate(form.data);
		},
	});

	useEffect(() => {
		form.reset();
	}, [mutation.isSuccess]);

	return { form, mutation, validation };
}
