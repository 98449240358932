import { Box, Text } from '../../../primitives';
import { styled, css } from '../../../theme';
import { Column } from '../../Column';

export const IconColumn = styled(Box)`
	padding: ${({ theme }) => theme.space.md}px;
`;

export const ContentColumn = styled(Column)`
	flex: 1;
	padding: ${({ theme }) => theme.space.md}px 0;
`;

export const ActionColumn = styled(Box)`
	padding: ${({ theme }) => theme.space.md}px;
`;

export const TimeText = styled(Text).attrs((props) => ({
	size: 'sm',
	color: props.theme.color.midGray.base,
	...props,
}))`
	padding-bottom: ${({ theme }) => theme.space.sm}px;
`;

export const MessageText = styled(Text)``;

export const Wrapper = styled(Box)<{
	isFirst: boolean;
	isRead: boolean;
	isIntent: boolean;
}>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	${({ isRead }) =>
		!isRead &&
		css`
			background-color: ${({ theme }) => theme.color.lightGreen.base};
		`};

	${({ isIntent }) =>
		isIntent &&
		css`
			cursor: pointer;
		`};

	border-top: ${({ isFirst, theme }) =>
		isFirst ? 'none' : `1px solid ${theme.color.lightGray.base}`};
`;
