import { config } from '@mybonus/public';

import {
	Wrapper,
	ButtonWrapper,
	Description,
	Title,
	LoginButton,
	RegisterButton,
} from './JoinPrompt.parts';

export type JoinPromptProps = {
	title?: string;
	message?: string;
	currentUrlPath?: string;
};

export function JoinPrompt(props: JoinPromptProps) {
	return (
		<Wrapper>
			<Title>{props.title || 'Bli medlem idag!'}</Title>
			<Description>
				{props.message ||
					'Det är bara medlemmar som kan komma åt denna funktion, logga in eller bli medlem för att fortsätta!'}
			</Description>

			<ButtonWrapper>
				<LoginButton
					to={{
						local: config.urls.login({
							redirectBackToPath: props.currentUrlPath,
						}),
					}}
				/>

				<RegisterButton />
			</ButtonWrapper>
		</Wrapper>
	);
}
