import {
	$GreenGradient,
	$WhiteBrightenedLoginButton,
	$WhiteRegisterButton,
	$Text,
	ButtonWrapper,
} from './LoginButtons.parts';

export function LoginButtons() {
	return (
		<$GreenGradient>
			<$Text>Bli medlem idag och spara pengar du också!</$Text>
			<ButtonWrapper>
				<$WhiteBrightenedLoginButton />
				<$WhiteRegisterButton />
			</ButtonWrapper>
		</$GreenGradient>
	);
}
