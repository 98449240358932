import { LanguageCode, mustacheReplace } from '@mybonus/public';
import { useMemo } from 'react';
import type { Options as RehypeReactOptions } from 'rehype-react';

import { useCmsContent, createParser } from './CmsContent.hooks';
import { DivWithInjectedStyle, CodeWrapper, MarkdownWrapper } from './CmsContent.parts';

export type CmsContentProps = {
	replacements?: Record<string, string>;
	components?: RehypeReactOptions['components'];
} & ({ html: string } | { code: string; languageCode?: LanguageCode } | { markdown: string });

export function CmsContent(props: CmsContentProps & { className?: string }) {
	if ('html' in props) {
		return <HtmlContent {...props} />;
	} else if ('markdown' in props) {
		return <MarkdownContent {...props} />;
	} else if ('code' in props) {
		return <CodeContent {...props} />;
	} else {
		return <></>;
	}
}

type HtmlContentProps = {
	html: string;
	replacements?: Record<string, string>;
	className?: string;
};

function HtmlContent(props: HtmlContentProps) {
	const content = props.replacements ? mustacheReplace(props.html, props.replacements) : props.html;

	return (
		<DivWithInjectedStyle
			dangerouslySetInnerHTML={{ __html: content }}
			className={props.className}
		/>
	);
}

type MarkdownContentProps = {
	markdown: string;
	replacements?: Record<string, string>;
	components?: RehypeReactOptions['components'];
	className?: string;
};

function MarkdownContent(props: MarkdownContentProps) {
	const parser = useMemo(() => createParser(props.components), [props.components]);

	const content = useMemo(
		() =>
			parser.processSync(
				props.replacements ? mustacheReplace(props.markdown, props.replacements) : props.markdown,
			).result,
		[props.markdown, props.replacements],
	);

	return <MarkdownWrapper className={props.className}>{content}</MarkdownWrapper>;
}

type CodeContentProps = {
	code: string;
	replacements?: Record<string, string>;
	languageCode?: LanguageCode;
	components?: RehypeReactOptions['components'];
	className?: string;
};

function CodeContent(props: CodeContentProps) {
	const cms = useCmsContent(props.code, props.languageCode);
	const parser = useMemo(() => createParser(props.components), [props.components]);

	const content = useMemo(
		() =>
			cms.data
				? parser.processSync(
						props.replacements ? mustacheReplace(cms.data, props.replacements) : cms.data,
				  ).result
				: undefined,
		[cms.data, props.replacements],
	);

	return (
		<CodeWrapper isLoading={cms.isLoading} className={props.className}>
			{content}
		</CodeWrapper>
	);
}
