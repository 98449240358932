import { Paragraph } from '../../Paragraph';
import { Spinner } from '../../Spinner';
import { Wrapper } from './DeviceFlow.parts';

export type DeviceFlowProps = {
	autoStartToken?: string;
};

export function DeviceFlow(props: DeviceFlowProps) {
	return (
		<Wrapper>
			<Spinner />
			<Paragraph>Letar efter BankID...</Paragraph>
		</Wrapper>
	);
}
