import { config } from '@mybonus/public';
import { useMemo } from 'react';

import { useCmsContent } from '../../hooks';

export function useHowItWorksSection() {
	const codes = useMemo(
		() => [
			'home_how_it_works_intro',
			'home_how_it_works_1',
			'home_how_it_works_2',
			'home_how_it_works_3',
		],
		[],
	);
	const content = useCmsContent({
		codes,
		languageCode: config.defaultLanguageCode,
	});

	return useMemo(() => ({ content }), [content]);
}
