import { Track, Tab } from './TabBar.parts';

export type TabBarProps = {
	tabs: readonly string[];
	active: string;
	onChange: (tab: string) => void;
};

export function TabBar(props: TabBarProps) {
	return (
		<Track>
			{props.tabs.map((tab, i) => (
				<Tab
					key={i}
					onClick={() => props.onChange(tab)}
					text={tab}
					variant={props.active === tab ? 'primary' : 'transparent'}
				/>
			))}
		</Track>
	);
}
