import { config } from '@mybonus/public';
import { Anchor } from '@mybonus/ui';

import { useConsentPopup } from './ConsentPopup.hooks';
import {
	Popup,
	Title,
	Description,
	AcceptButton,
	AcceptNecessaryButton,
	RejectButton,
	ButtonColumn,
	InfoColumn,
	Overlay,
} from './ConsentPopup.parts';

export function ConsentPopup() {
	const { show, handleOption } = useConsentPopup();

	return (
		<>
			<Overlay show={show} />
			<Popup show={show}>
				<InfoColumn>
					<Title>Vill du ha kakor?</Title>
					<Description>
						Vi använder cookies för att tjänsten ska fungera som den ska och för
						att du ska få bästa möjliga upplevelse när du använder tjänsten. Läs
						mer om varför vi använder cookie i vår{' '}
						<Anchor to={{ local: config.urls.cookiePolicy() }}>
							Cookie policy
						</Anchor>
						.
					</Description>
				</InfoColumn>
				<ButtonColumn>
					<AcceptButton onClick={() => handleOption('all')} />
					<AcceptNecessaryButton onClick={() => handleOption('necessary')} />
					<RejectButton to={{ onClick: () => handleOption('reject') }} />
				</ButtonColumn>
			</Popup>
		</>
	);
}
