import { z } from 'zod';

import { stringToNumber } from './zod-primitives';

/**
 * The purpose of "UniversalIntent" is to make it possible to create "links" on all platforms. Since
 * we don't have the concept of links in the app, we use the word "intent" instead.
 *
 * This concept could make it possible for an admin to set the target for a
 */

export const StoreIntent = z.object({
	target: z.literal('STORE'),
	id: stringToNumber,
});
export type StoreIntent = z.infer<typeof StoreIntent>;

export const StoreRedirectIntent = z.object({
	target: z.literal('STORE_REDIRECT'),
	id: stringToNumber,
});
export type StoreRedirectIntent = z.infer<typeof StoreRedirectIntent>;

export const StoreCampaignIntent = z.object({
	target: z.literal('STORE_CAMPAIGN'),
	id: stringToNumber,
});
export type StoreCampaignIntent = z.infer<typeof StoreCampaignIntent>;

export const StoreCampaignsIntent = z.object({
	target: z.literal('STORE_CAMPAIGNS'),
	id: stringToNumber,
});
export type StoreCampaignsIntent = z.infer<typeof StoreCampaignsIntent>;

export const StartIntent = z.object({
	target: z.literal('START'),
});
export type StartIntent = z.infer<typeof StartIntent>;

export const ProfileIntent = z.object({
	target: z.literal('PROFILE'),
});
export type ProfileIntent = z.infer<typeof ProfileIntent>;

export const TransactionsReadyIntent = z.object({
	target: z.literal('TRANSACTIONS_READY'),
});
export type TransactionsReadyIntent = z.infer<typeof TransactionsReadyIntent>;

export const TransactionsIncomingIntent = z.object({
	target: z.literal('TRANSACTIONS_INCOMING'),
});
export type TransactionsIncomingIntent = z.infer<typeof TransactionsIncomingIntent>;

export const TransactionsCollectedIntent = z.object({
	target: z.literal('TRANSACTIONS_COLLECTED'),
});
export type TransactionsCollectedIntent = z.infer<typeof TransactionsCollectedIntent>;

export const TransactionsBonusBuddiesIntent = z.object({
	target: z.literal('TRANSACTIONS_BONUS_BUDDIES'),
});
export type TransactionsBonusBuddiesIntent = z.infer<typeof TransactionsBonusBuddiesIntent>;

export const BonusBuddiesIntent = z.object({
	target: z.literal('BONUS_BUDDIES'),
});
export type BonusBuddiesIntent = z.infer<typeof BonusBuddiesIntent>;

export const UniversalIntent = z.discriminatedUnion('target', [
	StoreIntent,
	StoreRedirectIntent,
	StoreCampaignIntent,
	StoreCampaignsIntent,
	StartIntent,
	ProfileIntent,
	TransactionsReadyIntent,
	TransactionsIncomingIntent,
	TransactionsCollectedIntent,
	TransactionsBonusBuddiesIntent,
	BonusBuddiesIntent,
]);
export type UniversalIntent = z.infer<typeof UniversalIntent>;
