import {
	useAccountContext,
	AccountOverviewPill,
	Icon,
	Anchor,
} from '@mybonus/ui';

import { $GreenGradient } from './AccountOverview.parts';

export function AccountOverview() {
	const account = useAccountContext();

	return (
		<Anchor to={{ local: '/account' }} underlined={false}>
			<$GreenGradient>
				<AccountOverviewPill overview={account.overview.data} />
				<Icon name="IconArrowRightWhite" />
			</$GreenGradient>
		</Anchor>
	);
}
