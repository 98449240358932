import { Box } from '../../primitives';
import { styled, css, keyframes } from '../../theme';

const glowAnimation = keyframes`
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
`;

export const Wrapper = styled(Box)<{
	glow: boolean;
	glowBorderSize: number;
	glowSize: number;
	borderRadius?: number;
}>`
	position: relative;
	background: #fff;

	${({ glow, glowBorderSize, glowSize, borderRadius }) =>
		glow &&
		css`
			&::before,
			&::after {
				content: '';
				position: absolute;
				left: -${glowBorderSize}px;
				top: -${glowBorderSize}px;
				background: linear-gradient(45deg, red, orange, red, #eeb817, red);
				background-size: 400%;
				width: calc(100% + ${glowBorderSize * 2}px);
				height: calc(100% + ${glowBorderSize * 2}px);
				z-index: -2;
				animation: ${glowAnimation} 40s ease-in-out infinite;
				transform: translateZ(0);
				border-radius: ${borderRadius ? `${borderRadius}px` : 'inherit'};
			}

			&::before {
				opacity: ${glowBorderSize === 0 ? 0 : 0.7};
			}

			&::after {
				filter: blur(${glowSize}px);
				opacity: 0.5;
			}
		`}
`;
