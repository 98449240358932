import { useState, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuthContext, useAccountContext } from '../../context';

export function useAccountPill() {
	const auth = useAuthContext();
	const account = useAccountContext();
	const location = useLocation();
	const [open, setOpen] = useState(false);

	useLayoutEffect(() => {
		setOpen(false);
	}, [location.pathname]);

	const overview = account.overview;

	return {
		auth,
		overview,
		open,
		setOpen,
	};
}
