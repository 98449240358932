import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

export const DeviceId = z.string().uuid();
export type DeviceId = z.infer<typeof DeviceId>;

export const Device = z.object({
	id: DeviceId.default(uuidv4),
});

export type Device = z.infer<typeof Device>;
