import styled from 'styled-components';

import { Box } from '../../primitives';

export const PageTitle = styled(Box)`
	margin: ${({ theme }) => theme.space.sm}px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	@media ${({ theme }) => theme.device.laptop} {
		margin: ${({ theme }) => theme.space.md}px;
	}
`;
