import { z } from 'zod';

import { ListStore } from './store';

export const CampaignOrder = z.enum(['PRIORITY', 'POPULARITY', 'TIME_LEFT', 'BEST_OFFER']);

export type CampaignOrder = z.infer<typeof CampaignOrder>;

export const ListCampaign = z.object({
	id: z.number(),
	headline: z.string().nullable(),
	description: z.string().nullable(),
	terms: z.string().nullable(),
	voucherCode: z.string().nullable(),
	activeFrom: z.date().nullable(),
	activeTo: z.date().nullable(),
	isNative: z.boolean(),
	imageUrl: z.string().nullable(),
	isFeatured: z.boolean(),
	store: ListStore,
});
export type ListCampaign = z.infer<typeof ListCampaign>;

export const SingleCategory = z.object({
	id: z.number(),
	name: z.string(),
});
export type SingleCategory = z.infer<typeof SingleCategory>;

export const CategoryList = SingleCategory.extend({
	storeCount: z.number(),
}).array();
export type CategoryList = z.infer<typeof CategoryList>;
