import {
	type APIOutput,
	Waiter,
	FavoriteButton,
	useAuthContext,
	useFavoriteContext,
} from '@mybonus/ui';
import { useNavigate } from 'react-router-dom';

import { Circle } from './StoreFavorite.parts';

export type StoreFavoriteProps = {
	store?: APIOutput['guest']['store']['single'];
	isLoading: boolean;
};

export function StoreFavorite(props: StoreFavoriteProps & { className?: string }) {
	const auth = useAuthContext();
	const favorites = useFavoriteContext();
	const navigate = useNavigate();
	const { store } = props;

	function handleHeartClick() {
		if (!store) {
			return;
		}

		if (!auth.account) {
			navigate('/register');
			return;
		}

		favorites.toggleFavorite(store.id);
	}

	// TODO: Handle click, maybe a nice animation?
	return (
		<Circle className={props.className}>
			<Waiter isLoading={props.isLoading}>
				<FavoriteButton onClick={handleHeartClick} isFavorite={favorites.isFavorite(store?.id)} />
			</Waiter>
		</Circle>
	);
}
