import { Box, Text } from '../../../../primitives';
import { styled } from '../../../../theme';
import { Icon } from '../../../Icon';
import { Row } from '../../../Row';

export const Wrapper = styled(Box)`
	display: flex;
	width: 100%;
`;

export const $Icon = styled(Icon).attrs((props) => ({
	...props,
	size: 'xxxl',
}))`
	margin-right: ${({ theme }) => theme.space.sm}px;
`;

export const Content = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
`;

export const MainRow = styled(Row)`
	justify-content: space-between;
`;

export const NameText = styled(Text).attrs((props) => ({
	...props,
	style: 'bold',
}))``;

export const DateText = styled(Text).attrs((props) => ({
	...props,
	size: 'sm',
	color: props.theme.color.midGray.base,
}))``;

export const DescriptionText = styled(Text).attrs((props) => ({
	...props,
	color: props.theme.color.midGray.base,
}))``;
