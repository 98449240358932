import type { ReactNode } from 'react';

import { Wrapper } from './NumberedSteps.parts';
import { Step } from './Step';

export type NumberedStepsProps = {
	children: ReactNode[];
};

export function NumberedSteps(
	props: NumberedStepsProps & { className?: string },
) {
	return (
		<Wrapper className={props.className}>
			{props.children.map((c, i) => (
				<Step key={i} number={i + 1}>
					{c}
				</Step>
			))}
		</Wrapper>
	);
}
