import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function useScrollToTop(opts?: { onLocationChange?: boolean }) {
	const location = useLocation();

	function scrollToTop() {
		document.documentElement.scrollTo(0, 0);
	}

	if (opts?.onLocationChange) {
		useLayoutEffect(() => {
			scrollToTop();
		}, [location.pathname]);
	}

	return {
		scrollToTop,
	};
}
