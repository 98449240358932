import { Anchor } from '../../../primitives';
import { $Paragraph } from './ForgotPasswordText.parts';

export type ForgotPasswordTextProps = {
	email?: string;
};

export function ForgotPasswordText(props: ForgotPasswordTextProps) {
	return (
		<$Paragraph>
			<Anchor to={{ local: '/recover', state: { email: props.email } }}>
				Glömt lösenord?
			</Anchor>
		</$Paragraph>
	);
}
