import baseStyled, {
	ThemedStyledInterface,
	css as styledCss,
	keyframes as styledKeyframes,
	ThemeProvider as StyledThemeProvider,
} from 'styled-components';

import type { Theme } from './theme';

export const styled = baseStyled as ThemedStyledInterface<Theme>;
export const css = styledCss;
export const keyframes = styledKeyframes;
export const ThemeProvider = StyledThemeProvider;
