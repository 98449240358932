import { config, isBrowser } from '@mybonus/public';
import { useClientStorage, useDataLayer } from '@mybonus/ui';
import { useState, useEffect, useCallback } from 'react';

type ConsentSetting = 'all' | 'necessary' | 'reject';

export function useConsentPopup() {
	const storage = useClientStorage<ConsentSetting>({
		key: config.storage.keys.consent,
		type: 'persistent',
	});
	const dataLayer = useDataLayer();

	const [show, setShow] = useState<boolean>(false);

	function pushNewSettings(newSettings: Record<string, string>) {
		Object.keys(newSettings).forEach((k) => {
			dataLayer.push({ event: `consent_${k}_${newSettings[k as keyof typeof newSettings]}` });
		});
	}

	const configureConsent = useCallback((setting: ConsentSetting) => {
		if (setting === 'all') {
			const newSettings = {
				ad_storage: 'granted',
				analytics_storage: 'granted',
				functionality_storage: 'granted',
				personalization_storage: 'granted',
				security_storage: 'granted',
			};
			dataLayer.push(['consent', 'update', newSettings]);
			pushNewSettings(newSettings);
		} else if (setting === 'necessary') {
			const newSettings = {
				functionality_storage: 'granted',
				security_storage: 'granted',
			};

			dataLayer.push(['consent', 'update', newSettings]);
			pushNewSettings(newSettings);
		} else if (setting === 'reject') {
			// Use defaults...
		}

		storage.setItem(setting);
	}, []);

	useEffect(() => {
		if (isBrowser()) {
			// Set defaults
			dataLayer.push([
				'consent',
				'default',
				{
					ad_storage: 'denied',
					analytics_storage: 'denied',
					functionality_storage: 'denied',
					personalization_storage: 'denied',
					security_storage: 'denied',
				},
			]);

			const setting = storage.getItem();

			if (!setting) {
				setShow(true);
			} else {
				configureConsent(setting);
			}
		}
	}, []);

	function handleOption(option: ConsentSetting) {
		configureConsent(option);
		setShow(false);
	}

	return {
		show,
		handleOption,
	};
}
