import { styled, Box, LocalImage, CmsContent, Paragraph } from '@mybonus/ui';

export const Wrapper = styled(Box)`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: ${({ theme }) => theme.space.md}px;
	margin: ${({ theme }) => theme.space.lg}px 0;
	justify-items: center;

	@media ${({ theme }) => theme.device.tablet} {
		grid-template-columns: 1fr 1fr;
		justify-items: start;
	}
`;

export const Content = styled(Box)`
	width: 100%;
	order: 2;

	@media ${({ theme }) => theme.device.tablet} {
		order: 1;
		margin-top: ${({ theme }) => theme.space.lg}px;
	}
`;

export const Image = styled(LocalImage)`
	width: 100%;
	max-width: 460px;
	order: 1;
`;

export const Ingress = styled(Paragraph).attrs((props) => ({
	...props,
	size: 'lg',
	color: props.theme.color.gray.base,
}))`
	margin-top: ${({ theme }) => -theme.space.sm}px;
`;

export const $CmsContent = styled(CmsContent).attrs((props) => ({
	...props,
	components: {
		h6: (props: any) => <Ingress {...props} />,
	},
}))``;
