import { Textfield, Anchor } from '../../primitives';
import { styled } from '../../theme';
import { EmailTextfield } from '../EmailTextfield';
import { FloatBox } from '../FloatBox';
import { Form } from '../Form';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';
import { TextButton } from '../TextButton';

export const Title = styled(Heading).attrs((props) => ({
	...props,
	children: 'Kom igång med MyBonus idag, det är superenkelt och helt gratis!',
	level: 1,
}))`
	max-width: 550px;
`;

export const $FloatBox = styled(FloatBox)`
	margin-top: 16px;
	max-width: 400px;
	width: 100%;
`;

export const $EmailTextfield = styled(EmailTextfield)`
	text-align: center;
`;

export const RegisterButton = styled(TextButton).attrs(() => ({
	variant: 'primary',
	text: 'Gå med nu',
	type: 'submit',
	onClick: () => {
		// noop
	},
}))`
	width: 100%;
`;

export const RecruitmentCodeTextfield = styled(Textfield).attrs(() => ({
	type: 'text',
	dashed: true,
	placeholder: 'Ange eventuell inbjudningskod här',
}))`
	width: 100%;
	text-align: center;
	margin: 16px 0;
`;

export const OrText = styled(Paragraph).attrs(() => ({
	children: 'eller skriv in dina uppgifter manuellt',
}))`
	padding-top: 8px;
	padding-bottom: 4px;
	text-align: center;
`;

export const $Form = styled(Form)`
	text-align: center;
`;

export const AlreadyMemberText = styled(Paragraph).attrs((props) => ({
	...props,
	children: (
		<>
			Redan medlem? <Anchor to={{ local: '/login' }}>Logga in</Anchor>
		</>
	),
}))``;
