import type { AccountType } from '@mybonus/public';
import { useEffect } from 'react';

import { useAPI, useMutation, type APIInput, type APIOutput } from '../../api';
import { useAuthContext } from '../../context';
import { useForm, emailValidation, passwordValidation } from '../../hooks';

export function useLoginForm(accountType: AccountType) {
	const { api } = useAPI();
	const auth = useAuthContext();
	const mutation = useMutation<
		APIInput['guest']['auth']['credentials']['login'],
		APIOutput['guest']['auth']['credentials']['login']
	>((input) => api.guest.auth.credentials.login.mutate(input));

	const form = useForm<{
		email: string;
		password: string;
	}>({
		validations: {
			email: emailValidation(),
			password: passwordValidation(),
		},
		onSubmit: () => {
			mutation.mutate({ type: accountType, ...form.data });
		},
	});

	useEffect(() => {
		if (mutation.data) {
			form.reset();
			auth.login(mutation.data);
		}
	}, [mutation.data]);

	const error = mutation.error || auth.error;

	return { form, mutation, error };
}

export function useVendorAuthentication() {
	const { api } = useAPI();
	const auth = useAuthContext();
	const mutation = useMutation<
		APIInput['guest']['auth']['vendor']['authenticate'],
		APIOutput['guest']['auth']['vendor']['authenticate']
	>((input) => api.guest.auth.vendor.authenticate.mutate(input));

	function authenticate(data: APIInput['guest']['auth']['vendor']['authenticate']) {
		mutation.mutate(data);
	}

	useEffect(() => {
		if (mutation.data) {
			auth.login(mutation.data);
		}
	}, [mutation.data]);

	const error = mutation.error || auth.error;

	return { mutation, error, authenticate };
}
