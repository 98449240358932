import { z } from 'zod';

import { CurrencyCode } from './currency';
import { PlatformAlias, PlatformType } from './platform';
import { stringToDate } from './zod-primitives';

export const BaseEvent = z.object({
	userId: z.number(),
});
export type BaseEvent = z.infer<typeof BaseEvent>;

export const CommonProps = z.object({
	platform_alias: PlatformAlias,
	platform_type: PlatformType,
});

export type CommonProps = z.infer<typeof CommonProps>;

export const UserVisitedEvent = BaseEvent.extend({
	name: z.literal('USER:VISITED'),
	properties: CommonProps,
});
export type UserVisitedEvent = z.infer<typeof UserVisitedEvent>;

export const UserIdentifyEvent = BaseEvent.extend({
	name: z.literal('USER:IDENTIFY'),
	properties: CommonProps.extend({
		os_name: z.string(),
		os_version: z.string(),
		device_name: z.string(),
		utm_source: z.string().optional(),
		utm_medium: z.string().optional(),
		utm_campaign: z.string().optional(),
		utm_term: z.string().optional(),
		utm_content: z.string().optional(),
	}),
});
export type UserIdentifyEvent = z.infer<typeof UserIdentifyEvent>;

export const UserLoggedInEvent = BaseEvent.extend({
	name: z.literal('USER:LOGGED_IN'),
	properties: CommonProps,
});
export type UserLoggedInEvent = z.infer<typeof UserLoggedInEvent>;

export const UserLoggedOutEvent = BaseEvent.extend({
	name: z.literal('USER:LOGGED_OUT'),
	properties: CommonProps,
});
export type UserLoggedOutEvent = z.infer<typeof UserLoggedOutEvent>;

export const UserRegisteredEvent = BaseEvent.extend({
	name: z.literal('USER:REGISTERED'),
	properties: CommonProps.extend({
		recruiter_type: z.enum(['USER', 'AFFILIATE', 'NONE']),
	}),
});
export type UserRegisteredEvent = z.infer<typeof UserRegisteredEvent>;

export const CategoryVisitedEvent = BaseEvent.extend({
	name: z.literal('CATEGORY:VISITED'),
	properties: CommonProps.extend({
		category_id: z.number(),
		category_name: z.string(),
	}),
});
export type CategoryVisitedEvent = z.infer<typeof CategoryVisitedEvent>;

export const StoreVisitedEvent = BaseEvent.extend({
	name: z.literal('STORE:VISITED'),
	properties: CommonProps.extend({
		store_id: z.number(),
		store_name: z.string(),
	}),
});
export type StoreVisitedEvent = z.infer<typeof StoreVisitedEvent>;

export const StoreRedirectedEvent = BaseEvent.extend({
	name: z.literal('STORE:REDIRECTED'),
	properties: CommonProps.extend({
		store_id: z.number(),
		store_name: z.string(),
	}),
});
export type StoreRedirectedEvent = z.infer<typeof StoreRedirectedEvent>;

export const StoreFavoritedEvent = BaseEvent.extend({
	name: z.literal('STORE:FAVORITED'),
	properties: CommonProps.extend({
		store_id: z.number(),
		store_name: z.string(),
	}),
});
export type StoreFavoritedEvent = z.infer<typeof StoreFavoritedEvent>;

export const StoreUnfavoritedEvent = BaseEvent.extend({
	name: z.literal('STORE:UNFAVORITED'),
	properties: CommonProps.extend({
		store_id: z.number(),
		store_name: z.string(),
	}),
});
export type StoreUnfavoritedEvent = z.infer<typeof StoreUnfavoritedEvent>;

export const CampaignSubscribedEvent = BaseEvent.extend({
	name: z.literal('CAMPAIGN:SUBSCRIBED'),
	properties: CommonProps.extend({
		store_id: z.number(),
		store_name: z.string(),
		campaign_id: z.number(),
	}),
});
export type CampaignSubscribedEvent = z.infer<typeof CampaignSubscribedEvent>;

export const CampaignUnsubscribedEvent = BaseEvent.extend({
	name: z.literal('CAMPAIGN:UNSUBSCRIBED'),
	properties: CommonProps.extend({
		store_id: z.number(),
		store_name: z.string(),
		campaign_id: z.number(),
	}),
});
export type CampaignUnsubscribedEvent = z.infer<typeof CampaignUnsubscribedEvent>;

export const CampaignVisitedEvent = BaseEvent.extend({
	name: z.literal('CAMPAIGN:VISITED'),
	properties: CommonProps.extend({
		campaign_id: z.number(),
		campaign_name: z.string(),
	}),
});
export type CampaignVisitedEvent = z.infer<typeof CampaignVisitedEvent>;

export const WithdrawalInitiatedEvent = BaseEvent.extend({
	name: z.literal('WITHDRAWAL:INITIATED'),
	properties: CommonProps.extend({
		service_name: z.enum(['TRUSTLY']),
		amount: z.number(),
		currency_code: CurrencyCode,
	}),
});
export type WithdrawalInitiatedEvent = z.infer<typeof WithdrawalInitiatedEvent>;

export const WithdrawalCancelledEvent = BaseEvent.extend({
	name: z.literal('WITHDRAWAL:CANCELLED'),
	properties: CommonProps.extend({
		service_name: z.enum(['TRUSTLY']),
		amount: z.number(),
		currency_code: CurrencyCode,
	}),
});
export type WithdrawalCancelledEvent = z.infer<typeof WithdrawalCancelledEvent>;

export const WithdrawalApprovedEvent = BaseEvent.extend({
	name: z.literal('WITHDRAWAL:APPROVED'),
	properties: CommonProps.extend({
		service_name: z.enum(['TRUSTLY']),
		amount: z.number(),
		currency_code: CurrencyCode,
	}),
});
export type WithdrawalApprovedEvent = z.infer<typeof WithdrawalApprovedEvent>;

export const CashbackData = z.object({
	cashback_id: z.number(),
	status: z.enum(['PENDING', 'APPROVED', 'ACKNOWLEDGED', 'PAID', 'COLLECTED', 'DENIED']),
	purchase_date: stringToDate,
	store_id: z.number(),
	store_name: z.string(),
	purchase_value: z.number(),
	cashback_value: z.number(),
	currency_code: CurrencyCode,
	source_type: z.enum([
		'NETWORK',
		'SPECIAL',
		'RECRUIT',
		'BONUS',
		'AFFILIATE_WELCOME',
		'MANUAL',
		'FOLLOWER',
		'CLAIM_MANUAL',
		'CLAIM_NETWORK',
	]),
});
export type CashbackData = z.infer<typeof CashbackData>;
export const CashbackCreatedEvent = BaseEvent.extend({
	name: z.literal('CASHBACK:CREATED'),
	properties: CommonProps.extend(CashbackData.shape),
});
export type CashbackCreatedEvent = z.infer<typeof CashbackCreatedEvent>;

export const CashbackUpdatedEvent = BaseEvent.extend({
	name: z.literal('CASHBACK:UPDATED'),
	properties: CommonProps.extend(CashbackData.shape),
});
export type CashbackUpdatedEvent = z.infer<typeof CashbackUpdatedEvent>;

export const RecruitmentCreatedEvent = BaseEvent.extend({
	name: z.literal('RECRUITMENT:CREATED'),
	properties: CommonProps,
});
export type RecruitmentCreatedEvent = z.infer<typeof RecruitmentCreatedEvent>;

export const GiftcardCreatedEvent = BaseEvent.extend({
	name: z.literal('GIFTCARD:CREATED'),
	properties: CommonProps.extend({
		giftcard_id: z.number(),
		purchase_date: stringToDate,
		store_id: z.number(),
		store_name: z.string(),
		payment_amount: z.number(),
		payment_amount_currency: CurrencyCode,
		cashback_amount: z.number(),
		cashback_amount_currency: CurrencyCode,
	}),
});
export type GiftcardCreatedEvent = z.infer<typeof GiftcardCreatedEvent>;

export const TrackingEvent = z.union([
	UserVisitedEvent,
	UserIdentifyEvent,
	UserLoggedInEvent,
	UserLoggedOutEvent,
	UserRegisteredEvent,
	CategoryVisitedEvent,
	StoreVisitedEvent,
	StoreRedirectedEvent,
	StoreFavoritedEvent,
	StoreUnfavoritedEvent,
	CampaignSubscribedEvent,
	CampaignUnsubscribedEvent,
	CampaignVisitedEvent,
	WithdrawalInitiatedEvent,
	WithdrawalCancelledEvent,
	WithdrawalApprovedEvent,
	CashbackCreatedEvent,
	CashbackUpdatedEvent,
	RecruitmentCreatedEvent,
	GiftcardCreatedEvent,
]);
export type TrackingEvent = z.infer<typeof TrackingEvent>;

export const ClientTrackingEvent = z.union([
	UserIdentifyEvent,
	StoreVisitedEvent,
	CampaignVisitedEvent,
	CategoryVisitedEvent,
]);
export type ClientTrackingEvent = z.infer<typeof ClientTrackingEvent>;
