import { useState, useEffect } from 'react';

import { useAPI, useQuery } from '../../../api';
import { useAuthContext } from '../../../context';
import { usePagination } from '../../../hooks';

export function useRecruitmentList() {
	const { api } = useAPI();
	const { pagination: input, setPage, page } = usePagination(1, 6);
	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const auth = useAuthContext();

	const recruitments = useQuery(
		'user.recruitment.list',
		(args) => api.user.recruitment.list.query(args),
		input,
		{
			mergeData: (data, newData) =>
				data
					? { ...data, ...newData, items: data.items.concat(newData.items) }
					: newData,
			preventAutoload: !auth.account,
			ttl: false,
		},
	);

	function loadMore() {
		setPage(page + 1);
	}

	useEffect(() => {
		if (recruitments.data) {
			setIsInitialLoad(false);
		}
	}, [recruitments.data]);

	const list = recruitments.data?.items || [];
	const hasMore =
		recruitments.data &&
		recruitments.data.pagination.page < recruitments.data.pagination.pages;

	return {
		isLoading: recruitments.isLoading,
		list,
		isInitialLoad,
		loadMore,
		hasMore,
	};
}
