import type { ReactNode } from 'react';

import type { ButtonProps } from '../../primitives';
import { Icon, IconProps } from '../Icon';
import { Wrapper } from './IconButton.parts';

export type IconButtonProps = Pick<IconProps, 'name' | 'size'> &
	Pick<ButtonProps, 'onClick' | 'isLoading'> & {
		children?: ReactNode;
	};

export function IconButton(props: IconButtonProps) {
	return (
		<Wrapper onClick={props.onClick} isLoading={props.isLoading}>
			<Icon name={props.name} size={props.size} />
			{props.children}
		</Wrapper>
	);
}
