// TODO: This hook may be renamed to redirect.web.tsx to make room for
// app-specific redirection, they should have the same API interface though.

export function useRedirect() {
	function toURL(url: string, opts?: { follow: boolean }) {
		if (opts?.follow === false) {
			window.open(url, '_blank');
		} else {
			window.location.href = url;
		}
	}

	function toApp(args: { uri: string; fallbackUrl?: string }) {
		window.location.href = args.uri;

		const fallbackUrl = args.fallbackUrl;

		if (fallbackUrl) {
			setTimeout(() => {
				window.location.href = fallbackUrl;
			}, 25);
		}
	}

	return {
		toURL,
		toApp,
	};
}
