import { config } from '@mybonus/public';
import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import type { APIInput } from '../../api';
import { useStoreCountContext } from '../../context';
import { useForm, useDebounce } from '../../hooks';
import { StorePopdown } from './StorePopdown';
import { Wrapper, SearchIcon, SearchButton, Searchfield } from './StoreSearch.parts';

export type StoreSearchProps = {
	onClose?: () => void;
	className?: string;
	focus?: boolean;
};

export function StoreSearch(props: StoreSearchProps) {
	const storeCount = useStoreCountContext();
	const [storesOpen, setStoresOpen] = useState(false);
	const navigate = useNavigate();
	const wrapperRef = useRef<HTMLFormElement>(null);
	const searchFieldRef = useRef<HTMLInputElement>(null);
	const exceptRefs = useMemo(() => [wrapperRef], [wrapperRef]);

	const [storesInput, setStoresInput] = useState<APIInput['guest']['store']['list']>({
		filter: {
			categoryId: undefined,
			freetext: '',
		},
		pagination: { offset: 0, limit: 5 },
	});

	const form = useForm<{ freetext: string }>({
		validations: {
			freetext: {},
		},
		onSubmit: () => {
			// Maybe redirect directly to first store on <enter>?
		},
	});

	const setPopdownOpen = useCallback(
		(open: boolean) => {
			setStoresOpen(open);

			if (!open) {
				props.onClose?.();
			}
		},
		[props.onClose],
	);

	const handleStoreSelect = useCallback((store: { id: number; name: string }) => {
		setPopdownOpen(false);
		form.reset();
		navigate(config.urls.store({ storeId: store.id, storeName: store.name }));
	}, []);

	const handleSearchButtonClick = useCallback(() => {
		if (form.data.freetext?.length) {
			form.reset();
		}
	}, [form.data.freetext]);

	const hasFreetextFilter = useMemo(() => {
		return !!storesInput?.filter?.freetext?.length;
	}, [storesInput]);

	useEffect(() => {
		if (props.focus && searchFieldRef.current) {
			searchFieldRef.current.focus();
		}
	}, [props.focus]);

	const onSearchChange = useCallback(
		(value: string) => {
			setStoresInput({
				...storesInput,
				filter: {
					...storesInput?.filter,
					freetext: value,
				},
			});
		},
		[storesInput],
	);

	const debouncedOnSearchChange = useDebounce(onSearchChange, '250ms');

	useEffect(() => {
		setPopdownOpen(hasFreetextFilter);
	}, [storesInput]);

	return (
		<Wrapper
			onSubmit={form.handleSubmit}
			open={storesOpen}
			className={props.className}
			_ref={wrapperRef}
		>
			<Searchfield
				placeholder={`Sök bland våra ${storeCount.text} butiker`}
				value={form.data.freetext || ''}
				_ref={searchFieldRef}
				onChange={(value) => {
					form.handleChange('freetext')(value);
					debouncedOnSearchChange(value);
				}}
			/>
			<SearchButton onClick={handleSearchButtonClick}>
				<SearchIcon name={form.data.freetext?.length ? 'IconCrossGray' : 'IconSearchGray'} />
			</SearchButton>

			<StorePopdown
				open={storesOpen}
				onClose={() => setPopdownOpen(false)}
				input={storesInput}
				onSelect={handleStoreSelect}
				exceptRefs={exceptRefs}
			/>
		</Wrapper>
	);
}
