import { styled } from '../../theme';
import { Row } from '../Row';

export const FormRow = styled(Row)`
	align-items: center;

	> * {
		margin-right: ${({ theme }) => theme.space.sm}px;

		&:last-child {
			margin-right: 0;
		}
	}
`;
