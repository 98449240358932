import {
	useAPI,
	useQuery,
	useMutation,
	type APIInput,
	type APIOutput,
	useForm,
	orgNrValidation,
	companyNameValidation,
	useHead,
} from '@mybonus/ui';
import { useEffect } from 'react';

export function useBusinessSettings() {
	const { api } = useAPI();
	useHead({ subtitle: 'Företagsinställningar' });

	const company = useQuery<
		APIOutput['user']['account']['company']['single'],
		APIInput['user']['account']['company']['single']
	>(
		'user.account.company.single',
		(args) => api.user.account.company.single.query(args),
		undefined,
	);

	const mutation = useMutation<
		APIInput['user']['account']['company']['update'],
		APIOutput['user']['account']['company']['update']
	>((args) => api.user.account.company.update.mutate(args), {
		onSuccess: () => {
			company.load({ bypassCache: true, refetch: true });
		},
	});

	const form = useForm<{
		name: string;
		orgNr: string;
	}>({
		validations: {
			name: companyNameValidation(),
			orgNr: orgNrValidation(),
		},
		onChange: () => {
			mutation.reset();
		},
		onSubmit: () => {
			mutation.mutate(form.data);
		},
	});

	useEffect(() => {
		form.setData({
			name: company.data?.name || '',
			orgNr: company.data?.orgNr || '',
		});
	}, [company.data]);

	function onClear() {
		mutation.mutate({ name: '', orgNr: '' });
	}

	const isLoading = mutation.isLoading || company.isLoading;
	const error = mutation.error;
	const hasCompany = !!company.data;

	return { mutation, form, error, onClear, isLoading, hasCompany };
}
