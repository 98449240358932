import { useEffect, useMemo, useState } from 'react';

import { useAPI, useMutation, APIInput, APIOutput } from '../../api';
import { usePoll, useRedirect } from '../../hooks';
import type { BankIdButtonProps } from './BankIdButton';

export function useBankId(props: BankIdButtonProps) {
	const { api } = useAPI();
	const redirect = useRedirect();

	const [flow, setFlow] = useState<
		'initial' | 'select' | 'external' | 'device'
	>('initial');

	const init = useMutation<
		APIInput['user']['identification']['bankid']['init'],
		APIOutput['user']['identification']['bankid']['init']
	>(() => api.user.identification.bankid.init.mutate());

	const status = useMutation<
		APIInput['user']['identification']['bankid']['status'],
		APIOutput['user']['identification']['bankid']['status']
	>((args) => api.user.identification.bankid.status.mutate(args));

	const cancel = useMutation<
		APIInput['user']['identification']['bankid']['cancel'],
		APIOutput['user']['identification']['bankid']['cancel']
	>((args) => api.user.identification.bankid.cancel.mutate(args));

	const orderRef = useMemo(() => init.data?.orderRef, [init.data]);
	const autoStartToken = useMemo(() => init.data?.autoStartToken, [init.data]);
	const qr = useMemo(() => status.data?.qr, [status.data]);

	const poll = usePoll(
		async () => {
			if (orderRef) {
				await status.mutate({ orderRef });
			}
		},
		{ intervalMs: 2000 },
	);

	function handleInit() {
		init.reset();
		status.reset();
		cancel.reset();

		setFlow('select');
	}

	function handleAbort() {
		poll.stop();

		if (orderRef) {
			cancel.mutate({ orderRef });
		}

		init.reset();
		status.reset();

		setFlow('initial');
	}

	useEffect(() => {
		if (status.data?.status && status.data.status !== 'pending') {
			setFlow('initial');
		}
	}, [status.data?.status]);

	useEffect(() => {
		if (flow === 'device' && autoStartToken) {
			redirect.toApp({
				uri: `bankid:///?autostarttoken=${autoStartToken}&redirect=null`,
			});
		}
	}, [autoStartToken]);

	useEffect(() => {
		if (orderRef) {
			poll.start();
		}
	}, [orderRef]);

	useEffect(() => {
		let timerId: any;

		if (status.data?.status !== 'pending') {
			poll.stop();

			if (status.data?.status === 'complete') {
				timerId = setTimeout(() => {
					props.onIdentified();
				}, 2000);
			}
		}

		return () => clearTimeout(timerId);
	}, [status.data]);

	useEffect(() => {
		if (flow === 'external' || flow === 'device') {
			init.mutate();
		}
	}, [flow]);

	const isComplete = status.data?.status === 'complete';
	const isPending = status.data?.status === 'pending';

	const error =
		init.error ||
		status.error ||
		(status.data?.status === 'failed' &&
			'Identifieringen misslyckades, försök igen') ||
		cancel.error ||
		(status.data?.status === 'timeout' &&
			'Det tog lite för lång tid, försök igen');

	return {
		isPending,
		isComplete,
		handleAbort,
		handleInit,
		error,
		flow,
		setFlow,
		qr,
		autoStartToken,
	};
}
