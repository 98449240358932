import type { FirstStoreLetter } from '@mybonus/public';
import { Container, StoreSlideBanner, CategoryCloud, StoreLetterGroup } from '@mybonus/ui';

import { useBrowseView } from './BrowseView.hooks';
import { Filters, $SortLabel, LetterGroups, GroupContainer } from './BrowseView.parts';
import { SearchCover } from './SearchCover';

export function BrowseView() {
	const {
		isLoading,
		sortOrder,
		groups,
		sortBy,
		setSortBy,
		categoryId,
		setCategoryId,
		expandLetter,
		isLetterExpanded,
	} = useBrowseView();

	return (
		<>
			<Container width="full">
				<SearchCover />
			</Container>

			<StoreSlideBanner />

			<GroupContainer>
				<Filters>
					<CategoryCloud selectedCategoryId={categoryId} onSelect={(id) => setCategoryId(id)} />

					<$SortLabel
						options={sortOrder}
						onSelect={(id: string) => setSortBy(id as typeof sortOrder[number]['id'])}
						selected={sortBy}
					/>
				</Filters>

				<LetterGroups>
					{groups.map((group) => (
						<StoreLetterGroup
							key={`${group.letter}-${categoryId}-${sortBy}`}
							letter={group.letter as FirstStoreLetter}
							stores={group.stores}
							isLoading={isLoading}
							isExpanded={isLetterExpanded(group.letter)}
							onExpand={() => expandLetter(group.letter)}
						/>
					))}
				</LetterGroups>
			</GroupContainer>
		</>
	);
}
