import { useEffect, useCallback } from 'react';

import { useAPI, useMutation, type APIInput, type APIOutput } from '../../api';
import { useAccountContext } from '../../context';
import {
	useForm,
	emailValidation,
	nameValidation,
	phoneValidation,
	ssnValidation,
} from '../../hooks';

export function useAccountSettingsForm() {
	const { api } = useAPI();
	const mutation = useMutation<
		APIInput['user']['account']['update'],
		APIOutput['user']['account']['update']
	>((input) => api.user.account.update.mutate(input), {
		onSuccess: () => {
			account.invalidate();
		},
	});

	const account = useAccountContext();

	const onIdentified = useCallback(() => {
		account.invalidate();
	}, []);

	const form = useForm<{
		email: string;
		firstname: string;
		lastname: string;
		phone: string;
		ssn: string;
	}>({
		validations: {
			email: emailValidation(),
			firstname: nameValidation(),
			lastname: nameValidation(),
			phone: phoneValidation(),
			ssn: ssnValidation(),
		},
		onSubmit: () => {
			mutation.mutate(form.data);
		},
		onChange: () => {
			mutation.reset();
		},
	});

	const user = account.overview.data?.user;

	useEffect(() => {
		form.setData({
			email: user?.tempEmail || user?.email || '',
			firstname: user?.firstname || '',
			lastname: user?.lastname || '',
			phone: user?.phone || '',
			ssn: user?.ssn || '',
		});
	}, [user]);

	const hasTempEmail = !!user?.tempEmail;

	return {
		account,
		user,
		form,
		mutation,
		onIdentified,
		hasTempEmail,
	};
}
