import { styled, Center, Column } from '@mybonus/ui';

export const Centered = styled(Center)`
	justify-content: flex-start;
	flex-direction: column;
	flex: 1;
`;

export const Layout = styled(Column)`
	min-height: 100%;
`;
