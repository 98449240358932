import { styled } from '../../theme';
import { Row } from '../Row';
import { TextButton } from '../TextButton';

export const Track = styled(Row)`
	background-color: ${({ theme }) => theme.color.lighterGray.base};
	border-radius: 500px;
	width: 100%;
`;

export const Tab = styled(TextButton).attrs((props) => ({
	...props,
	size: 'sm',
}))`
	width: 100%;
`;
