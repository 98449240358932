import { PageContainer, NotificationList, useHead } from '@mybonus/ui';

import { $FloatBox, FloatWrapper } from './AccountNotificationsView.parts';

export function AccountNotificationsView() {
	useHead({ subtitle: 'Notifikationer' });

	return (
		<PageContainer>
			<FloatWrapper>
				<$FloatBox>
					<NotificationList isVisible={true} />
				</$FloatBox>
			</FloatWrapper>
		</PageContainer>
	);
}
