import { imageUrlWithSize, type SingleStore } from '@mybonus/public';
import { OfferList } from '@mybonus/ui';
import { useState, useEffect } from 'react';

import { GiftcardDescription } from './GiftcardDescription';
import { GiftcardPurchase } from './GiftcardPurchase';
import { NetworkDescription } from './NetworkDescription';
import {
	$FloatBox,
	Logo,
	$Center,
	RedirectButton,
	FavoriteWrapper,
	$Waiter,
} from './StoreCard.parts';
import { StoreFavorite } from './StoreFavorite';

export type StoreCardProps = {
	store?: SingleStore;
	isLoading: boolean;
};

export function StoreCard(props: StoreCardProps) {
	const { store } = props;
	const [collapseOffers, setCollapseOffers] = useState(true);

	useEffect(() => {
		if (store?.offers.length && store?.offers.length < 3) {
			setCollapseOffers(false);
		}
	}, [store?.offers.length]);

	return (
		<$FloatBox>
			<FavoriteWrapper>
				<StoreFavorite store={store} isLoading={props.isLoading} />
			</FavoriteWrapper>

			<$Waiter isLoading={props.isLoading}>
				<$Center>
					{!!store && (
						<>
							{store.logoUrl && (
								<Logo
									url={imageUrlWithSize(store.logoUrl, {
										h: '100',
										format: 'png',
									})}
								/>
							)}
							{store.type === 'NETWORK' && (
								<>
									<NetworkDescription store={store} />
									<RedirectButton to={{ local: `/out/${store.id}` }} />
									{(store.bestOffer?.isMultipleOffers || !store.bestOffer?.offer?.isStoreWide) && (
										<>
											<OfferList
												onToggleCollapse={() => setCollapseOffers(!collapseOffers)}
												collapsed={collapseOffers}
												offers={store.offers}
											/>
										</>
									)}
								</>
							)}

							{store.type === 'GIFTCARD' && (
								<>
									<GiftcardDescription store={store} />
									<GiftcardPurchase store={store} />
								</>
							)}
						</>
					)}
				</$Center>
			</$Waiter>
		</$FloatBox>
	);
}
