import { styled, css } from '../../theme';
import { Box } from '../Box';
import type { SelectfieldProps } from './Selectfield.types';

export function Selectfield(props: SelectfieldProps & { className?: string }) {
	return (
		<Wrapper {...props}>
			<Select
				value={props.value}
				defaultValue={props.placeholder ? 'default' : undefined}
				onChange={(e) =>
					props.onChange?.(
						e.target.value === 'default' ? undefined : e.target.value,
					)
				}
				_size={props.size}
			>
				{props.placeholder && (
					<option value="default">{props.placeholder}</option>
				)}
				{props.options.map((option, i) => (
					<option value={option.id} key={i}>
						{option.label}
					</option>
				))}
			</Select>
		</Wrapper>
	);
}

export const selectfieldPadding = {
	xs: { x: 14, y: 10 },
	sm: { x: 18, y: 14 },
	md: { x: 24, y: 16 },
	lg: { x: 32, y: 20 },
};

const Select = styled.select<{ _size?: SelectfieldProps['size'] }>`
	background-color: transparent;
	-webkit-appearance: none;

	background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=)
		no-repeat 100% 50%;

	font-family: ${({ theme }) => theme.font.base};
	font-size: ${({ _size, theme }) => theme.fontSize[_size || 'md']}px;
	box-sizing: border-box;
	border: none;
	width: 100%;
	outline: none;
	cursor: pointer;
	padding: ${(props) => {
		const { y } = selectfieldPadding[props._size || 'md'];
		return `${y}px 0`;
	}};
	color: ${({ theme }) => theme.color.darkGray.base};
`;

const Wrapper = styled(Box)<SelectfieldProps>`
	border-radius: 1000px;
	border: 1px solid ${({ theme }) => theme.color.midGray.base};
	transition: border-color 0.5s ease-in-out;

	${(props) => {
		switch (props.variant) {
			case 'primary':
				return css`
					border-color: ${({ theme }) => theme.color.green.base};
				`;
			case 'warning':
				return css`
					border-color: ${({ theme }) => theme.color.orange.base};
				`;
			case 'error':
				return css`
					border-color: ${({ theme }) => theme.color.red.base};
				`;
			case 'transparent':
				return css`
					border: 0;
				`;
			case 'default':
			default:
		}
	}}

	padding: ${(props) => {
		const { x } = selectfieldPadding[props.size || 'md'];
		return `0 ${x}px`;
	}};
`;
