import { styled } from '../../../theme';
import { Center } from '../../Center';
import { TextButton } from '../../TextButton';

export const Wrapper = styled(Center)`
	margin-top: ${({ theme }) => theme.space.md}px;
	flex-direction: column;
`;

export const VendorButton = styled(TextButton).attrs((props) => ({
	...props,
}))``;
