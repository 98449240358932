import { LogoSwishBase, LogoTrustlyBadgeGreen } from '@mybonus/ui';

import { Wrapper, Copy, Logos, Logo, BankIdLogo, StripeLogo } from './PaymentSection.parts';

export function PaymentSection() {
	return (
		<Wrapper>
			<Copy>Säkra överföringar med</Copy>
			<Logos>
				<Logo uri={LogoTrustlyBadgeGreen} alt="Trustly onlinebanköverföringar" />
				<BankIdLogo alt="BankID" />
				<StripeLogo alt="Stripe betalning" />
				<Logo uri={LogoSwishBase} alt="Swish betalningar" />
			</Logos>
		</Wrapper>
	);
}
