import { Textfield } from '../../primitives';
import { styled } from '../../theme';

export const PasswordTextfield = styled(Textfield).attrs(() => ({
	type: 'password',
	placeholder: 'Lösenord (minst 6 tecken)',
}))`
	width: 100%;
	text-align: center;
`;
