import { z } from 'zod';

export const CashbackLog = z.object({
	id: z.number(),
	date: z.date(),
	status: z.enum(['PENDING', 'APPROVED', 'ACKNOWLEDGED', 'PAID', 'DENIED', 'COLLECTED']),
	sourceType: z.enum([
		'NETWORK',
		'SPECIAL',
		'RECRUIT',
		'BONUS',
		'AFFILIATE_WELCOME',
		'MANUAL',
		'FOLLOWER',
		'CLAIM_MANUAL',
		'CLAIM_NETWORK',
		'GIFTCARD',
	]),
	isCollectible: z.boolean(),
	message: z.string().nullable(),
	hasWithdrawalLock: z.boolean(),
	store: z
		.object({
			name: z.string(),
		})
		.nullable(),
	cashbackValue: z.string(),
	purchaseValue: z.string(),
	collectibleEstimate: z.object({
		minDays: z.number(),
		maxDays: z.number(),
		progressDays: z.number(),
	}),
});

export type CashbackLog = z.infer<typeof CashbackLog>;

export const CashbackState = z.enum([
	// Incoming cashbacks are pending, approved, denied. Denied recruit cashbacks be excluded when
	// they are older than 8 weeks.
	'INCOMING',

	// Cashbacks that are ready are ready for withdrawal. Note that these cashbacks can be "locked"
	// during ongoing withdrawals.
	'READY',

	// Cashbacks that are ready for withdrawal right now, excludes cashbacks with withdrawal lock.
	'COLLECTIBLE',

	// Cashbacks that has been withdrawn.
	'COLLECTED',
]);
export type CashbackState = z.infer<typeof CashbackState>;
