import { RemoteImage, Box } from '../../../primitives';
import { styled } from '../../../theme';

export const ItemWrapper = styled(Box)`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: ${({ theme }) => theme.space.md}px;
`;

export const StoreImage = styled(RemoteImage)`
	max-height: 32px;
`;
