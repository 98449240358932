import { Box } from '../../primitives';
import { styled } from '../../theme';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';
import { Waiter } from '../Waiter';

export const Wrapper = styled(Waiter)`
	display: flex;
	flex-flow: row wrap;
	padding: ${({ theme }) => theme.space.sm}px;

	@media ${({ theme }) => theme.device.laptop} {
		padding: ${({ theme }) => theme.space.lg}px;
	}
`;

export const Group = styled(Box)`
	padding: ${({ theme }) => theme.space.md}px;

	@media ${({ theme }) => theme.device.laptop} {
		flex: 1 0 50%;
		padding: ${({ theme }) => theme.space.md}px
			${({ theme }) => theme.space.lg}px;
	}
`;

export const GroupHeading = styled(Heading).attrs((props) => ({
	...props,
	level: 4,
	color: props.theme.color.green.base,
}))``;

export const ItemHeader = styled(Paragraph)<{ open: boolean }>`
	font-family: ${({ theme, open }) =>
		open ? theme.font.bold : theme.font.base};
`;
