import { Anchor } from '../../primitives';
import { styled } from '../../theme';
import { FavoriteList } from '../FavoriteList';
import { Paragraph } from '../Paragraph';
import { Popdown as _Popdown } from '../Popdown';

export const Popdown = styled(_Popdown)`
	min-width: 440px;
`;

export const $FavoriteList = styled(FavoriteList)`
	max-height: 500px;
	overflow-y: auto;
	overflow-x: hidden;
`;

export const ShowAllText = styled(Paragraph)`
	text-align: center;
`;

export const $Anchor = styled(Anchor).attrs((props) => ({
	...props,
}))``;
