import { isBrowser } from '@mybonus/public';

export function useClientStorage<T>(opts: {
	key: string;
	type: 'session' | 'persistent';
}) {
	const { key, type = 'persistent' } = opts;

	const fn = (
		{
			persistent: 'localStorage',
			session: 'sessionStorage',
		} as const
	)[type];

	function setItem(data: T) {
		if (isBrowser()) {
			window[fn].setItem(key, JSON.stringify(data));
		}
	}

	function getItem(): T | null {
		if (isBrowser()) {
			const v = window[fn].getItem(key);

			return v ? JSON.parse(v) : null;
		}

		return null;
	}

	return {
		setItem,
		getItem,
	};
}
