import { styled, Center, CoverSection, StoreSearch } from '@mybonus/ui';

export const $CoverSection = styled(CoverSection)`
	height: 300px;
`;

export const $Center = styled(Center)`
	padding: ${({ theme }) => theme.space.md}px;
`;

export const $StoreSearch = styled(StoreSearch)`
	max-width: 600px;
`;
