import { config } from '@mybonus/public';
import { APIInput, APIOutput, useAPI, useClientStorage, useMutation } from '@mybonus/ui';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export function useRegisterRecruitmentView() {
	const params = useParams();
	const { api } = useAPI();
	const navigate = useNavigate();
	const recruitmentIdStorage = useClientStorage<string>({
		key: config.storage.keys.recruitmentId,
		type: 'session',
	});
	const mutation = useMutation<
		APIInput['guest']['recruitment']['registerInitialRecruitment'],
		APIOutput['guest']['recruitment']['registerInitialRecruitment']
	>((input) => api.guest.recruitment.registerInitialRecruitment.mutate(input));

	const code: string | null = typeof params.code === 'string' ? params.code : null;

	useEffect(() => {
		if (code) {
			mutation.mutate({ code });
		} else {
			// No code supplied, should never get here in the first place since the route requires code to
			// be set?
			navigate('/');
		}
	}, [code]);

	useEffect(() => {
		if (mutation.data === null) {
			navigate('/');
		} else if (mutation.data) {
			recruitmentIdStorage.setItem(`${mutation.data.recruitmentId}`);

			if (mutation.data.meta.landingPageUrl) {
				navigate(mutation.data.meta.landingPageUrl);
			} else {
				navigate('/');
			}
		}
	}, [mutation.data]);

	useEffect(() => {
		if (mutation.error) {
			// Don't mess with the user UX, fall back to home page on error
			navigate('/');
		}
	}, [mutation.error]);

	return {
		error: mutation.error,
		isLoading: mutation.isLoading,
	};
}
