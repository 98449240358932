import { styled, Box, Paragraph, LocalImage, LogoBankIdBase, LogoStripeBase } from '@mybonus/ui';

export const Wrapper = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: ${({ theme }) => theme.space.md}px 0;

	@media ${({ theme }) => theme.device.tablet} {
		align-items: flex-end;
	}
`;

export const Copy = styled(Paragraph).attrs((props) => ({
	...props,
	color: props.theme.color.gray.base,
	size: 'sm',
}))`
	text-align: center;

	@media ${({ theme }) => theme.device.tablet} {
		text-align: left;
	}
`;

export const Logos = styled(Box)`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: ${({ theme }) => theme.space.lg}px;
	justify-items: center;
	align-items: center;
	margin: ${({ theme }) => theme.space.md}px ${({ theme }) => theme.space.lg}px
		${({ theme }) => theme.space.lg}px;

	@media ${({ theme }) => theme.device.tablet} {
		grid-template-columns: repeat(4, 1fr);
		margin-top: 0;
		margin-right: 0;
	}
`;

export const Logo = styled(LocalImage)`
	max-width: 100px;
`;

export const BankIdLogo = styled(LocalImage).attrs((props) => ({
	...props,
	uri: LogoBankIdBase,
}))`
	height: 36px;
`;

export const StripeLogo = styled(LocalImage).attrs((props) => ({
	...props,
	uri: LogoStripeBase,
}))`
	height: 32px;
`;
