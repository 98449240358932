import { useState } from 'react';

import { useAPI, useQuery } from '../../api';

export function useCategoryCloud() {
	const { api } = useAPI();
	const [selectedCategoryId, setSelectedCategoryId] = useState<number>();

	const query = useQuery(
		'guest.category.list',
		(args) => api.guest.category.list.query(args),
		undefined,
	);

	const categories = [{ id: undefined, name: 'Alla' }, ...(query.data || [])];

	return {
		query,
		categories,
		selectedCategoryId,
		setSelectedCategoryId,
	};
}
