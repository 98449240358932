import { Anchor, Text } from '../../primitives';
import { styled } from '../../theme';
import { Avatar } from '../Avatar';
import { Center } from '../Center';
import { Column } from '../Column';
import { GreenGradient } from '../GreenGradient';
import { TextButton } from '../TextButton';

export const $GreenGradient = styled(GreenGradient).attrs((props) => ({
	...props,
	angle: 45,
}))`
	border-top-left-radius: ${({ theme }) => theme.rounding.md}px;
	border-top-right-radius: ${({ theme }) => theme.rounding.md}px;
	border-bottom-left-radius: ${({ theme }) => theme.rounding.md + 3}px;
	border-bottom-right-radius: ${({ theme }) => theme.rounding.md + 3}px;
	width: 100%;
	padding-top: ${({ theme }) => theme.space.lg}px;
`;

export const PersonBox = styled(Center)`
	flex-direction: column;

	> * {
		margin-bottom: ${({ theme }) => theme.space.xs}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const $Avatar = styled(Avatar).attrs((props) => ({
	...props,
	size: 120,
}))`
	margin-bottom: ${({ theme }) => theme.space.md}px;
`;

export const Fullname = styled(Text).attrs((props) => ({
	...props,
	color: props.theme.color.white.base,
	size: 'xl',
}))`
	text-align: center;
`;

const SettingsText = styled(Text).attrs((props) => ({
	...props,
	size: 'sm',
	children: 'Ändra uppgifter',
	color: props.theme.color.white.base,
}))``;

export const SettingsAnchor = styled(Anchor).attrs((props) => ({
	...props,
	color: props.theme.color.white.base,
	to: { local: '/account/settings' },
	children: <SettingsText />,
}))``;

export const WithdrawBox = styled(Center)`
	flex-direction: column;
	border-top-left-radius: ${({ theme }) => theme.rounding.md}px;
	border-top-right-radius: ${({ theme }) => theme.rounding.md}px;
	background-color: ${({ theme }) => theme.color.lighterGray.base};
	padding: ${({ theme }) => theme.space.lg}px ${({ theme }) => theme.space.lg}px;
	margin-top: ${({ theme }) => theme.space.lg}px;

	> * {
		margin-bottom: ${({ theme }) => theme.space.md}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const ReadyLabel = styled(Text).attrs((props) => ({
	...props,
	size: 'sm',
}))``;

export const StateDescriptionText = styled(Text).attrs((props) => ({
	...props,
	size: 'sm',
	color: props.theme.color.midGray.base,
}))`
	text-align: center;
`;

export const ReadyValue = styled(Text).attrs((props) => ({
	...props,
	size: 'xxl',
	style: 'bold',
	color: props.theme.color.green.base,
}))``;

export const WithdrawButton = styled(TextButton).attrs((props) => ({
	...props,
	variant: 'primary',
	size: 'lg',
}))`
	margin-top: ${({ theme }) => theme.space.sm}px;
	width: 100%;
`;

export const SummariesBox = styled(Column)`
	padding: ${({ theme }) => theme.space.md}px;
	background-color: ${({ theme }) => theme.color.white.base};
	border-bottom-left-radius: ${({ theme }) => theme.rounding.md}px;
	border-bottom-right-radius: ${({ theme }) => theme.rounding.md}px;
`;
