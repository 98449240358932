import { styled } from '../../theme';
import { iconSize } from '../Icon';
import type { HamburgerButtonProps } from './HamburgerButton';

export const Wrapper = styled.div<HamburgerButtonProps>`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: ${({ size }) => iconSize[size]}px;
	height: ${({ size }) => iconSize[size]}px;
	cursor: pointer;
	padding: 0;

	div {
		width: ${({ size }) => iconSize[size]}px;
		height: ${({ size }) => iconSize[size] / 6}px;
		background: ${({ open, theme }) =>
			open ? theme.color.darkGray.base : theme.color.white.base};
		border-radius: 10px;
		transition: all 0.3s linear;
		position: relative;
		transform-origin: 0.5px;

		:first-child {
			transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
		}

		:nth-child(2) {
			opacity: ${({ open }) => (open ? '0' : '1')};
			transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
		}

		:nth-child(3) {
			transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
		}
	}
`;
