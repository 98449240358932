import { z } from 'zod';

import { slugify } from '../utils';

export const Slug = z.string().transform(slugify);
export type Slug = z.infer<typeof Slug>;

export const ListTag = z.object({
	id: z.number(),
	name: z.string(),
	slug: Slug,
});
export type ListTag = z.infer<typeof ListTag>;

export const SingleTag = ListTag;
export type SingleTag = z.infer<typeof SingleTag>;
