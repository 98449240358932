import { LogoMyBonusWhite, LogoMyBonusGreen } from '../../assets';
import { LocalImage } from '../../primitives';

export type LogoProps = {
	variant?: 'white' | 'colored';
	className?: string;
};

export function Logo(props: LogoProps) {
	switch (props.variant) {
		case 'colored':
			return <LocalImage uri={LogoMyBonusGreen} className={props.className} />;
		case 'white':
		default:
			return <LocalImage uri={LogoMyBonusWhite} className={props.className} />;
	}
}
