import type { ReactNode } from 'react';

import { Wrapper } from './AccountMenu.parts';

export type AccountMenuProps = {
	children: ReactNode;
};

export function AccountMenu(props: AccountMenuProps) {
	return <Wrapper>{props.children}</Wrapper>;
}
