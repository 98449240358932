import { z } from 'zod';

import { stringToDate } from './zod-primitives';

export const Duration = z.object({
	unit: z.enum(['HOURS', 'DAYS', 'WEEKS']),
	value: z.number().min(0),
});

export type Duration = z.infer<typeof Duration>;

export const DateRange = z.object({
	from: stringToDate.default(() => {
		const now = new Date();
		now.setDate(now.getDate() - 7);
		return now;
	}),
	to: stringToDate.default(() => new Date()),
});
export type DateRange = z.infer<typeof DateRange>;
