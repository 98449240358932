import type { TextProps } from '../../primitives';
import { H1, H2, H4, H6 } from './Heading.parts';

export { createHeadingCSS } from './Heading.parts';

export type HeadingProps = TextProps & {
	level: 1 | 2 | 4 | 6;
};

const map = {
	1: H1,
	2: H2,
	4: H4,
	6: H6,
};

export function Heading(
	props: HeadingProps & { className?: string },
): JSX.Element {
	const { level, ...rest } = props;
	const Comp = map[level];

	return (
		<Comp className={props.className} {...rest}>
			{props.children}
		</Comp>
	);
}
