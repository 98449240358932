import {
	RecruitmentFloatBox,
	RecruitmentGuideFloatBox,
	PageContainer,
	useHead,
} from '@mybonus/ui';

import { env } from '../../shared/env';
import { FloatBoxWrapper } from './RecruitmentView.parts';

export function RecruitmentView() {
	useHead({ subtitle: 'Bjud in vänner' });

	return (
		<PageContainer>
			<FloatBoxWrapper>
				<RecruitmentFloatBox
					fbAppId={env.FB_APP_ID}
					webBaseUrl={env.WEB_BASE_URL}
				/>
				<RecruitmentGuideFloatBox />
			</FloatBoxWrapper>
		</PageContainer>
	);
}
