import { ImagePhoneCover } from '@mybonus/ui';

import { $Center, $CoverSection, $StoreSearch } from './SearchCover.parts';

export function SearchCover() {
	return (
		<$CoverSection backgroundImageUrl={ImagePhoneCover} withOverlayColor>
			<$Center vertical>
				<$StoreSearch />
			</$Center>
		</$CoverSection>
	);
}
