import { Anchor, AnchorProps } from '../../primitives';
import { LinkText } from './ActivePathAnchor.parts';

export type ActivePathAnchorProps = AnchorProps & {
	active: boolean;
};

export function ActivePathAnchor(
	props: ActivePathAnchorProps & { className?: string },
) {
	const { children, className, ...rest } = props;
	return (
		<Anchor {...rest} underlined={false}>
			<LinkText active={props.active} className={className}>
				{children}
			</LinkText>
		</Anchor>
	);
}
