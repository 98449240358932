import { useState } from 'react';

import { MainForm } from './MainForm';
import { TrackEmailForm } from './TrackEmailForm';

export function ClaimsForm() {
	const [trackEmail, setTrackEmail] = useState<string>();

	return trackEmail ? (
		<TrackEmailForm trackEmail={trackEmail} />
	) : (
		<MainForm onCreate={(v) => setTrackEmail(v)} />
	);
}
