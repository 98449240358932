import { styled, FloatBox, Heading, TextButton } from '@mybonus/ui';

export const $FloatBox = styled(FloatBox)`
	margin-top: 16px;
	width: 100%;
	max-width: 400px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const Title = styled(Heading).attrs((props) => ({
	...props,
	level: 1,
}))``;

export const ConfirmButton = styled(TextButton).attrs((props) => ({
	...props,
	text: 'Ja, avregistrera mig',
	variant: 'alternative',
}))``;
