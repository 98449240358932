import { z } from 'zod';

export const stringToNumber = z.preprocess(
	(a) => parseInt(String(z.union([z.string(), z.number()]).parse(a)), 10),
	z.number(),
);

export const stringToBool = z.union([z.string(), z.undefined()]).transform((v) => {
	if (v === '1') {
		return true;
	} else if (v === '0') {
		return false;
	} else {
		return false;
	}
});

export const stringToDate = z.preprocess((arg) => {
	if (typeof arg == 'string' || arg instanceof Date) return new Date(arg);
}, z.date());

export const stringToTime = z.custom<string>((val) =>
	/^(?:0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(String(val)),
);
