import {
	PageTitle,
	PageContainer,
	Heading,
	CmsContent,
	useHead,
} from '@mybonus/ui';

import {
	$FloatBox,
	$DownloadAppRow,
	Content,
	AppImage,
	Preview,
} from './AppView.parts';

export function AppView() {
	useHead({ subtitle: 'App' });

	return (
		<PageContainer noTopSpace>
			<PageTitle>
				<Heading level={1}>MyBonus App</Heading>
			</PageTitle>

			<$FloatBox>
				<Content>
					<CmsContent code="app_text" />
					<$DownloadAppRow />
				</Content>
				<Preview>
					<AppImage />
				</Preview>
			</$FloatBox>
		</PageContainer>
	);
}
