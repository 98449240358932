import {
	useAccountContext,
	AccountPill,
	WhiteBrightenedLoginButton,
	WhiteRegisterButton,
	Waiter,
} from '@mybonus/ui';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
	Gradient,
	Spacer,
	$Container,
	LogoLink,
	AccountWrapper,
	HamburgerIcon,
	$NotificationPill,
	$FavoritePill,
	$SearchPill,
	Left,
	Position,
	LoginButtonWrapper,
} from './Header.parts';
import { MenuBar } from './MenuBar';
import { MenuDrawer } from './MenuDrawer';

export function Header() {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const account = useAccountContext();
	const location = useLocation();

	useEffect(() => {
		setDrawerOpen(false);
	}, [location]);

	return (
		<>
			<MenuDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
			<Position>
				<Gradient>
					<$Container>
						<Left>
							<LogoLink />
						</Left>
						<Waiter isLoading={account.isLoading}>
							{account.overview.data ? (
								<Waiter isLoading={account.overview.isLoading}>
									<AccountWrapper>
										<AccountPill />
										<$NotificationPill />
										<$FavoritePill />
										<$SearchPill />
									</AccountWrapper>
								</Waiter>
							) : (
								<AccountWrapper>
									<LoginButtonWrapper>
										<WhiteBrightenedLoginButton />
										<WhiteRegisterButton />
									</LoginButtonWrapper>
								</AccountWrapper>
							)}
						</Waiter>
						<HamburgerIcon size="xl" open={drawerOpen} onClick={() => setDrawerOpen(!drawerOpen)} />
					</$Container>
				</Gradient>
				<MenuBar />
			</Position>
			<Spacer />
		</>
	);
}
