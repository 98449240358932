import { config } from '../config';
import { ClaimTimeExpiredError, ClaimTimeNotActiveError } from '../err';
import { createPastDate } from './time';

export function getValidClaimTimespan(): { from: Date; to: Date } {
	return {
		from: createPastDate(new Date(), config.claim.maxAge),
		to: createPastDate(new Date(), config.claim.minAge),
	};
}

export function validateClaimTime(purchaseDate: Date): void {
	const { from, to } = getValidClaimTimespan();

	if (purchaseDate.getTime() < from.getTime()) {
		throw new ClaimTimeExpiredError(`Cannot claim purchases older than ${config.claim.maxAge}`);
	}

	if (purchaseDate.getTime() > to.getTime()) {
		throw new ClaimTimeNotActiveError(`Cannot claim purchases newer than ${config.claim.minAge}`);
	}
}
