import { Box, Textfield } from '../../../../primitives';
import { styled } from '../../../../theme';
import { TextButton } from '../../../TextButton';

export const Wrapper = styled(Box)`
	position: relative;
	width: 100%;
`;

export const $Textfield = styled(Textfield).attrs((props) => ({
	...props,
	dashed: true,
	size: 'sm',
	disabled: true,
}))`
	color: ${({ theme }) => theme.color.green.base};
	-webkit-text-fill-color: ${({ theme }) => theme.color.green.base};
	width: 100%;
`;

export const CopyButton = styled(TextButton).attrs((props) => ({
	...props,
	variant: 'primaryHollow',
	size: 'sm',
}))`
	position: absolute;
	right: 0;
	top: 0;
`;
