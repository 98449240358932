import { styled } from '../../../theme';
import { Icon } from '../../Icon';
import { Paragraph } from '../../Paragraph';
import { Row } from '../../Row';

export const Checkbox = styled(Icon).attrs((props) => ({
	...props,
	name: 'IconCheckboxGreen',
	size: 'sm',
}))`
	margin: 14px ${({ theme }) => theme.space.md}px 0 0;
`;

export const Wrapper = styled(Row)``;

export const Content = styled(Paragraph)``;
