import type { ReactNode } from 'react';

import { Circle, CircleText, Wrapper, Content } from './Step.parts';

export type StepProps = {
	number: number;
	children: ReactNode;
};

export function Step(props: StepProps) {
	return (
		<Wrapper>
			<Circle>
				<CircleText>{props.number}</CircleText>
			</Circle>
			<Content>{props.children}</Content>
		</Wrapper>
	);
}
