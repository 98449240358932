import { styled, FloatBox, Center, RemoteImage, AnchorButton, Box, Waiter } from '@mybonus/ui';

export const $FloatBox = styled(FloatBox)`
	position: relative;
	padding-top: ${({ theme }) => theme.space.lg}px;
	padding-bottom: ${({ theme }) => theme.space.lg}px;
`;

export const FavoriteWrapper = styled(Box)`
	position: absolute;
	top: -${({ theme }) => theme.space.lg - 4}px;
	left: 50%;
	transform: translateX(-50%);
`;

export const Logo = styled(RemoteImage)`
	margin: ${({ theme }) => theme.space.md}px 0 ${({ theme }) => theme.space.sm}px;
	height: 60px;
	object-fit: contain;
	max-width: 220px;
`;

export const $Center = styled(Center)`
	flex-direction: column;

	> * {
		margin-bottom: ${({ theme }) => theme.space.md}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const RedirectButton = styled(AnchorButton).attrs((props) => ({
	...props,
	variant: 'alternative',
	text: 'Till butik',
}))`
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
`;

export const $Waiter = styled(Waiter)`
	min-height: 190px;
`;
