import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { getIsomorphicSentryConfig } from '../shared';

export function initializeMonitoring() {
	Sentry.init({
		...getIsomorphicSentryConfig(),
		integrations: [new BrowserTracing()],
	} as any);
}
