import { memo } from 'react';

import type { APIOutput } from '../../api';
import { Wrapper, $RemoteImage, DefaultImage } from './Avatar.parts';

export type AvatarProps = {
	avatar?: APIOutput['user']['account']['overview']['user']['avatar'];
	size: number;
};

function _Avatar({
	avatar,
	size,
	className,
}: AvatarProps & { className?: string }) {
	return (
		<Wrapper className={className} size={size}>
			{avatar && (
				<>
					{avatar.isDefault ? (
						<DefaultImage />
					) : (
						<$RemoteImage url={avatar.url} />
					)}
				</>
			)}
		</Wrapper>
	);
}

export const Avatar = memo(_Avatar);
