import { Box } from '../../primitives';
import { styled } from '../../theme';
import { Icon } from '../Icon';

export const IconWrapper = styled(Box)`
	display: inline-flex;
	align-items: center;
`;

export const $Icon = styled(Icon)`
	margin-right: ${({ theme }) => theme.space.sm}px;
`;
