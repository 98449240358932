import { PageContainer } from '@mybonus/ui';

export function TrustlyTemplateView() {
	const template = `<!-- TRUSTLY-PAYMENT-PAGE-GOES-HERE -->`;

	return (
		<PageContainer>
			<div dangerouslySetInnerHTML={{ __html: template }}></div>
		</PageContainer>
	);
}
