import { styled } from '../../theme';
import { AnchorButton } from '../AnchorButton';
import { Center } from '../Center';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';

export const Wrapper = styled(Center)`
	flex-direction: column;
	padding: ${({ theme }) => theme.space.lg}px ${({ theme }) => theme.space.md}px;
`;

export const Title = styled(Heading).attrs((props) => ({
	...props,
	level: 2,
}))`
	text-align: center;
`;

export const Description = styled(Paragraph).attrs((props) => ({
	...props,
	size: 'lg',
}))`
	max-width: 500px;
	text-align: center;
`;

export const ButtonWrapper = styled(Center)`
	margin-top: ${({ theme }) => theme.space.sm}px;

	> * {
		margin-right: ${({ theme }) => theme.space.sm}px;

		&:last-child {
			margin-right: 0;
		}
	}
`;

export const LoginButton = styled(AnchorButton).attrs((props) => ({
	...props,
	variant: 'primary',
	text: 'Logga in',
}))``;

export const RegisterButton = styled(AnchorButton).attrs((props) => ({
	...props,
	variant: 'primaryHollow',
	to: { local: '/register' },
	text: 'Gå med',
}))``;
