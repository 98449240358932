import { ImageBonusBuddies } from '../../assets';
import { useAuthContext } from '../../context';
import { useUrl } from '../../hooks';
import { JoinPrompt } from '../JoinPrompt';
import { Jumbotron } from '../Jumbotron';
import { ListSection } from './ListSection';
import { Wrapper, MemberWrapper } from './RecruitmentFloatBox.parts';
import { ShareSection } from './ShareSection';

export type RecruitmentFloatBoxProps = {
	webBaseUrl: string;
	fbAppId: string;
};

export function RecruitmentFloatBox(props: RecruitmentFloatBoxProps) {
	const auth = useAuthContext();
	const url = useUrl();

	return (
		<Jumbotron
			title="Tipsa dina vänner om MyBonus!"
			subtitle="Din vän får 50 kr, och vi ger dig 10% av allt din vän tjänar in under ett helt år!"
			imageUri={ImageBonusBuddies}
		>
			{!auth.isLoading && (
				<Wrapper>
					{auth.account ? (
						<MemberWrapper>
							<ShareSection {...props} />
							<ListSection />
						</MemberWrapper>
					) : (
						<JoinPrompt currentUrlPath={url.path} />
					)}
				</Wrapper>
			)}
		</Jumbotron>
	);
}
