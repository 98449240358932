import {
	PageContainer,
	PageTitle,
	AccountSettingsForm,
	AccountMenu,
	AccountMenuItem,
	useAuthContext,
	useHead,
} from '@mybonus/ui';

import {
	$FloatBox,
	$MenuFloatBox,
	$BackAnchor,
	Title,
	FloatWrapper,
} from './AccountSettingsView.parts';

export function AccountSettingsView() {
	const auth = useAuthContext();
	useHead({ subtitle: 'Inställningar' });

	return (
		<PageContainer>
			<PageTitle>
				<Title>Inställningar</Title>
			</PageTitle>

			<FloatWrapper>
				<$BackAnchor />
				<$FloatBox>
					<AccountSettingsForm />
				</$FloatBox>
				<$MenuFloatBox>
					<AccountMenu>
						<AccountMenuItem
							title="Ändra lösenord"
							subtitle="Återställ eller byt lösenord till ditt konto"
							to={{ local: '/account/settings/password' }}
							isFirst
						/>
						<AccountMenuItem
							title="Notiser"
							subtitle="Hantera inställningar för notiser, e-post och SMS"
							to={{ local: '/account/settings/communication' }}
						/>
						<AccountMenuItem
							title="Företagskonto"
							subtitle="Har du ett företag? Registrera ditt företag här"
							to={{ local: '/account/settings/business' }}
						/>
						<AccountMenuItem
							title="Avsluta medlemskap"
							subtitle="Här kan du avsluta ditt MyBonus konto"
							to={{ local: '/account-removal/init' }}
						/>
						<AccountMenuItem
							title="Logga ut"
							subtitle="Byt användare"
							to={{ onClick: () => auth.logout() }}
							isDanger
						/>
					</AccountMenu>
				</$MenuFloatBox>
			</FloatWrapper>
		</PageContainer>
	);
}
