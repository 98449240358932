import { Box } from '../../primitives';
import { styled } from '../../theme';

export const Overlay = styled(Box)`
	position: fixed;
	z-index: 3;
	pointer-events: none;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	padding: ${({ theme }) => theme.space.md}px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
`;
