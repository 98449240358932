import { Wrapper, $Container } from './MenuBar.parts';
import { MenuLinks } from './MenuLinks';

export function MenuBar() {
	return (
		<Wrapper>
			<$Container>
				<MenuLinks />
			</$Container>
		</Wrapper>
	);
}
