import styled from 'styled-components';

import type { RemoteImageProps } from './RemoteImage.types';

function Base(props: RemoteImageProps & { className?: string }) {
	return <img src={props.url} alt={props.alt} className={props.className} />;
}

export const RemoteImage = styled(Base)`
	display: block;
`;
