import { useRecruitmentSummary, useSharing } from './ShareSection.hooks';
import {
	Wrapper,
	FacebookMessengerButton,
	FacebookShareButton,
	ShareAlternatives,
	$CodeCopy,
	SummaryRow,
	SummaryItem,
	SummaryTitle,
	SummarySub,
	ShareText,
	$Waiter,
} from './ShareSection.parts';

export type ShareSectionProps = {
	webBaseUrl: string;
	fbAppId: string;
};

export function ShareSection(props: ShareSectionProps) {
	const { code, handleMessengerShareClick, handleFacebookShareClick } = useSharing(props);
	const { summary } = useRecruitmentSummary();

	return (
		<Wrapper>
			<$Waiter isLoading={summary.isLoading}>
				{summary.data && (
					<SummaryRow>
						<SummaryItem>
							<SummaryTitle>{summary.data?.activeRecruitmentsCount} st</SummaryTitle>
							<SummarySub>Bonus Buddies</SummarySub>
						</SummaryItem>
						<SummaryItem>
							<SummaryTitle>{summary.data?.totalRecruitCashback}</SummaryTitle>
							<SummarySub>Från dina Buddies</SummarySub>
						</SummaryItem>
					</SummaryRow>
				)}
			</$Waiter>

			<ShareText>Bjud in med din personliga kod eller länk</ShareText>

			<ShareAlternatives>
				<$CodeCopy code={code} />
				<$CodeCopy code={`mybonus.com/r/${code}`} />
				<FacebookMessengerButton onClick={handleMessengerShareClick} />
				<FacebookShareButton onClick={handleFacebookShareClick} />
			</ShareAlternatives>
		</Wrapper>
	);
}
