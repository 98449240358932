import { Box } from '../../../primitives';
import { styled, css } from '../../../theme';
import type { AccordionItemProps } from './AccordionItem';

export const Header = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
`;

export const Title = styled(Box)`
	padding-right: 16px;
`;

export const Wrapper = styled(Box)<{ size?: AccordionItemProps['size'] }>`
	border-bottom: 1px solid ${({ theme }) => theme.color.lightGray.base};

	${({ size }) => {
		switch (size) {
			case 'sm':
				return css`
					padding: ${({ theme }) => theme.space.xs}px 0;
				`;
			case 'lg':
				return css`
					padding: ${({ theme }) => theme.space.md}px 0;
				`;
			case 'md':
			default:
				return css`
					padding: ${({ theme }) => theme.space.sm}px 0;
				`;
		}
	}}
`;
