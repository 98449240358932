import { styled } from '../../theme';

export const $Form = styled.form`
	display: flex;
	flex-direction: column;

	> * {
		margin-bottom: ${({ theme }) => theme.space.sm}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;
