import { isBrowser, UniversalIntent } from '@mybonus/public';
import { Spinner, useUniversalIntentNavigation } from '@mybonus/ui';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Wrapper } from './UniversalIntentView.parts';

export function UniversalIntentView() {
	if (!isBrowser()) {
		return null;
	}

	const intent = useUniversalIntentNavigation();
	const params = useParams();
	const navigate = useNavigate();
	const { target, id } = params;

	useEffect(() => {
		const result = UniversalIntent.safeParse({
			target: target?.toUpperCase().replace('-', '_'),
			id,
		});

		if (result.success) {
			intent.auto(result.data);
		} else {
			navigate('/404');
		}
	}, []);

	return (
		<Wrapper>
			<Spinner size="lg" />
		</Wrapper>
	);
}
