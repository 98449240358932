import { styled } from '../../../theme';
import { Center } from '../../Center';
import { Heading } from '../../Heading';
import { Paragraph } from '../../Paragraph';
import { TextButton } from '../../TextButton';

export const Wrapper = styled(Center)`
	flex-direction: column;
`;

export const Title = styled(Heading).attrs((props) => ({
	...props,
	level: 4,
	children: 'Presentkort på väg!',
	color: props.theme.color.green.base,
}))``;

export const Description = styled(Paragraph).attrs((props) => ({
	...props,
}))`
	text-align: center;
`;

export const OrderMoreButton = styled(TextButton).attrs((props) => ({
	...props,
	variant: 'transparent',
	text: 'Tillbaka',
}))``;
