import constate from 'constate';
import { useEffect } from 'react';

import { APIInput, APIOutput, useAPI, useQuery } from '../api';
import { useAuthContext } from './auth';

function useAccount() {
	const { api } = useAPI();
	const auth = useAuthContext();

	const overview = useQuery<
		APIOutput['user']['account']['overview'],
		APIInput['user']['account']['overview']
	>(
		'user.account.overview',
		() => api.user.account.overview.query(),
		undefined,
		{ preventAutoload: !auth.account },
	);

	useEffect(() => {
		if (!auth.account) {
			overview.reset();
		}
	}, [auth.account]);

	return {
		overview,
		isLoading: auth.isLoading || overview.isLoading,
		isIdentified: !!overview.data?.user.identification,
		invalidate: () => overview.load({ bypassCache: true, refetch: true }),
	};
}

export const [AccountProvider, useAccountContext] = constate(useAccount);
