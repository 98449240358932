import { PageTitle, PageContainer, FloatBox, Heading, CmsContent, useHead } from '@mybonus/ui';

const title = 'Cookiepolicy';

export function CookiePolicyView() {
	useHead({ subtitle: title });

	return (
		<PageContainer width="narrow" noTopSpace>
			<PageTitle>
				<Heading level={1}>{title}</Heading>
			</PageTitle>

			<FloatBox>
				<CmsContent code="cookie_text" />
			</FloatBox>
		</PageContainer>
	);
}
