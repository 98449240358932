import { useTheme } from 'styled-components';

import type { AnchorProps } from '../../primitives';
import type { Theme } from '../../theme';
import { Icon } from '../Icon';
import {
	Wrapper,
	ContentColumn,
	ActionColumn,
	Subtitle,
	Title,
} from './AccountMenuItem.parts';

export type AccountSettingProps = {
	title: string;
	subtitle: string;
	to: AnchorProps['to'];
	isFirst?: boolean;
	isDanger?: boolean;
};

export function AccountMenuItem(props: AccountSettingProps) {
	const theme = useTheme() as Theme;
	const textColor = props.isDanger
		? theme.color.orange.base
		: theme.color.darkGray.base;
	return (
		<Wrapper to={props.to} isFirst={!!props.isFirst}>
			<ContentColumn>
				<Title color={textColor}>{props.title}</Title>
				<Subtitle color={textColor}>{props.subtitle}</Subtitle>
			</ContentColumn>
			<ActionColumn>
				<Icon name="IconArrowRightGray" size="lg" />
			</ActionColumn>
		</Wrapper>
	);
}
