import { z } from 'zod';

export const Pagination = z.object({
	offset: z.number().min(0),
	limit: z.number().min(1).max(200),
});

export type Pagination = z.infer<typeof Pagination>;

export type PaginationInfo = {
	totalRecords: number;
	pages: number;
	page: number;
};

export type Paginated<T> = {
	items: T[];
	pagination: PaginationInfo;
};
