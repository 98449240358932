import { Box, LocalImage } from '../../primitives';
import { styled } from '../../theme';

export const Wrapper = styled(Box)`
	display: flex;
`;

export const VendorLogo = styled(LocalImage)`
	height: 48px;
	margin-left: ${({ theme }) => theme.space.sm}px;
`;
