import { createFutureDate } from './time';

interface DataStoreEntry<DataT> {
	expiresAt: number | false;
	value: Promise<DataT>;
}

let datastore: Record<string, DataStoreEntry<any>> = {};

export function clearReadthrough() {
	datastore = {};
}

export function clearReadthroughKey(key: string): void {
	delete datastore[key];
}

export async function readthrough<DataT>(
	key: string,
	ttl: string | false,
	cb: () => Promise<DataT>,
	opts?: { bypassCache?: boolean },
): Promise<DataT> {
	if (key in datastore && opts?.bypassCache !== true) {
		const entry = datastore[key as keyof typeof datastore];

		if (entry) {
			const isValid = entry.expiresAt && entry.expiresAt > Date.now();

			if (isValid) {
				return entry.value;
			}
		}
	}

	const promise = cb();
	datastore[key] = {
		expiresAt: ttl ? createFutureDate(new Date(), ttl).getTime() : false,
		value: promise,
	};

	return promise;
}
