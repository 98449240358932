import { Box } from '../../primitives';
import { styled } from '../../theme';
import { Center } from '../Center';

export const Wrapper = styled(Center)``;

export const MemberWrapper = styled(Box)`
	width: 100%;
	max-width: 800px;
	display: flex;
	flex-direction: column;

	@media ${({ theme }) => theme.device.tablet} {
		flex-direction: row;
	}
`;
