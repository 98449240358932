import {
	Drawer,
	Separator,
	type DrawerProps,
	useAuthContext,
	ActivePathAnchor,
	useNotificationContext,
	StoreSearch,
	Waiter,
} from '@mybonus/ui';

import { useMenuLinks, MenuItem } from '../../../hooks';
import { AccountOverview } from './AccountOverview';
import { LoginButtons } from './LoginButtons';
import {
	Menu,
	IconMenu,
	NotificationMenuItem,
	FavoritesMenuItem,
	AccountMenuItem,
	SettingsMenuItem,
	DrawerContent,
} from './MenuDrawer.parts';

const guestLinks: MenuItem[] = [
	{ type: 'static', path: '/app', text: 'Ladda ner Appen' },
	{ type: 'static', path: '/plugin', text: 'Installera Autobäring' },
];

export type MenuDrawerProps = DrawerProps;

export function MenuDrawer(props: MenuDrawerProps) {
	const auth = useAuthContext();
	const notification = useNotificationContext();
	const { location, isLoading, items } = useMenuLinks();

	function renderMenuList(overrideItems?: MenuItem[]) {
		return (overrideItems || items).map((item) => (
			<ActivePathAnchor
				key={`${item.type}-${item.path}`}
				to={{ local: item.path }}
				active={location.pathname === item.path}
			>
				{item.text}
			</ActivePathAnchor>
		));
	}

	return (
		<Drawer {...props}>
			<DrawerContent>
				<Waiter isLoading={auth.isLoading || isLoading}>
					{auth.account ? <AccountOverview /> : <LoginButtons />}

					<StoreSearch />
					<Separator />
					<Menu>{renderMenuList()}</Menu>
					<Separator />

					{auth.account ? (
						<IconMenu>
							<AccountMenuItem />
							<NotificationMenuItem count={notification.unreadCount} />
							<FavoritesMenuItem />
							<SettingsMenuItem />
						</IconMenu>
					) : (
						<Menu>{renderMenuList(guestLinks)}</Menu>
					)}
					<Separator />
				</Waiter>
			</DrawerContent>
		</Drawer>
	);
}
