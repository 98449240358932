import { Box } from '../../../primitives';
import { styled } from '../../../theme';
import { Heading } from '../../Heading';
import { Waiter } from '../../Waiter';

export const Wrapper = styled(Box)`
	width: 100%;
	padding: ${({ theme }) => theme.space.lg}px;
`;

export const Title = styled(Heading).attrs((props) => ({
	...props,
	level: 4,
	children: 'Mina Bonus Buddies',
}))`
	margin-top: 0;
`;

export const List = styled(Box)`
	> * {
		margin-bottom: ${({ theme }) => theme.space.md}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const $Waiter = styled(Waiter)`
	min-height: 100px;
`;
