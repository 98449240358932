import {
	styled,
	FloatBox,
	LocalImage,
	DownloadAppRow,
	Box,
	ImageAppMockup,
} from '@mybonus/ui';

export const $FloatBox = styled(FloatBox)`
	display: flex;
	flex-direction: column;

	@media ${({ theme }) => theme.device.tablet} {
		flex-direction: row;
	}
`;
export const Content = styled(Box)`
	width: 100%;

	@media ${({ theme }) => theme.device.tablet} {
		padding: ${({ theme }) => theme.space.lg}px;
	}
`;
export const Preview = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const AppImage = styled(LocalImage).attrs((props) => ({
	...props,
	uri: ImageAppMockup,
}))`
	width: 100%;
	max-width: 500px;
	padding: ${({ theme }) => theme.space.md}px;
`;

export const $DownloadAppRow = styled(DownloadAppRow)`
	width: 100%;
	justify-content: center;
	margin-top: ${({ theme }) => theme.space.lg}px;
	margin-bottom: ${({ theme }) => theme.space.lg}px;

	@media ${({ theme }) => theme.device.tablet} {
		justify-content: flex-start;
	}
`;
