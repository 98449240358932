import { createGlobalStyle } from 'styled-components';

export const GlobalReset = createGlobalStyle`
	html, body, #app {
		height: 100%;
	}

	body {
		margin: 0;
	}
`;
