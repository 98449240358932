import { useState } from 'react';

import { useClipboard } from '../../../hooks';
import { Text, Box, Anchor } from '../../../primitives';
import { Checkbox } from '../../Checkbox';
import { FormRow } from '../../FormRow';
import { Heading } from '../../Heading';
import { Paragraph } from '../../Paragraph';
import {
	FinishedButton,
	EmailField,
	FormElements,
	CopyFormRow,
} from './TrackEmailForm.parts';

export type TrackEmailFormProps = {
	trackEmail: string;
};

export function TrackEmailForm(props: TrackEmailFormProps) {
	const [hasSent, setHasSent] = useState(false);
	const clipboard = useClipboard(props.trackEmail);

	return (
		<Box>
			<Heading level={4}>Snart klar...</Heading>

			<Paragraph>
				Du behöver skicka in din order-/bokningsbekräftelse till oss. Vanligtvis
				skickad på mail från butiken när du genomförde ditt köp. Om du inte har
				fått en orderbekräftelse ber vi dig attt skicka in ett välkomstmail
				eller liknande. Vidarebefordra till mail-adressen nedan.
			</Paragraph>

			<FormElements>
				<CopyFormRow>
					<EmailField value={props.trackEmail} />
					<Paragraph size="sm">
						<Anchor to={{ onClick: clipboard.handleCopy }}>
							{clipboard.copied ? 'Kopierat!' : 'Kopiera'}
						</Anchor>
					</Paragraph>
				</CopyFormRow>

				<FormRow>
					<Checkbox value={hasSent} onChange={() => setHasSent(!hasSent)} />
					<Text size="sm">Jag har skickat in orderbekräftelse</Text>
				</FormRow>

				<FinishedButton disabled={!hasSent} />
			</FormElements>
		</Box>
	);
}
