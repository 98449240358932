import {
	Heading,
	PageContainer,
	PageTitle,
	Paragraph,
	AnchorProps,
} from '@mybonus/ui';

import { BackButton } from './ErrorView.parts';

export type ErrorViewProps = {
	title: number;
	text: string;
	to: AnchorProps['to'];
};

export function ErrorView(props: ErrorViewProps) {
	return (
		<PageContainer>
			<PageTitle>
				<Heading level={1}>{props.title} :(</Heading>
				<Paragraph size="lg">{props.text}</Paragraph>
				<BackButton />
			</PageTitle>
		</PageContainer>
	);
}
