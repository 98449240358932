import { styled, Heading, FloatBox, Column } from '@mybonus/ui';

export const FloatWrapper = styled(Column)`
	align-items: center;
	width: 100%;
	max-width: 500px;
`;

export const Title = styled(Heading).attrs((props) => ({
	...props,
	level: 1,
}))`
	margin-top: 0;
`;

export const $FloatBox = styled(FloatBox)`
	width: 100%;
`;
