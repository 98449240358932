import {
	styled,
	FloatBox,
	Heading,
	BackAnchor,
	Column,
	Row,
	Text,
} from '@mybonus/ui';

export const FloatWrapper = styled(Column)`
	> * {
		margin-bottom: ${({ theme }) => theme.space.md}px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	align-items: center;
	width: 100%;
	max-width: 500px;
`;

export const Title = styled(Heading).attrs((props) => ({
	...props,
	level: 1,
}))`
	margin-top: 0;
`;

export const $FloatBox = styled(FloatBox)`
	width: 100%;
`;

export const $BackAnchor = styled(BackAnchor).attrs((props) => ({
	...props,
	to: { local: '/account/settings' },
}))`
	width: 100%;
	margin-left: ${({ theme }) => theme.space.md}px;
`;

export const FloatBoxTitle = styled(Heading).attrs((props) => ({
	...props,
	level: 4,
}))`
	margin-top: 0;
`;

export const SettingWrapper = styled(Column)`
	> * {
		margin-bottom: ${({ theme }) => theme.space.md}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const SettingRow = styled(Row)`
	justify-content: space-between;
	align-items: center;
`;

export const SettingLabel = styled(Text).attrs((props) => ({
	...props,
}))``;
