import { PageTitle, PageContainer, AnchorButton } from '@mybonus/ui';

import {
	Description,
	$FloatBox,
	$Center,
	Title,
	CustomerServiceAnchor,
} from './WithdrawalView.parts';

export type WithdrawalViewProps = {
	result: 'success' | 'error';
};

export function WithdrawalView(props: WithdrawalViewProps) {
	function getResultMsg() {
		switch (props.result) {
			case 'success':
				return 'lyckades';
			case 'error':
				return 'avbröts';
		}
	}

	return (
		<PageContainer>
			<PageTitle>
				<Title>Utbetalningen {getResultMsg()}</Title>
			</PageTitle>

			<$FloatBox>
				{props.result === 'error' && (
					<Description>
						Tyvärr kunde inte din utbetalning genomföras. Försök igen lite
						senare eller kontakta oss på <CustomerServiceAnchor /> så hjälper vi
						dig.
					</Description>
				)}
				{props.result === 'success' && (
					<$Center>
						<Description>
							Din utbetalning har skickats till din bank för behandling.
							Pengarna kommer synas på ditt bankkonto inom kort. Notera dock att
							större summor kan komma att granskas varför det kan ta någon
							arbetsdag eller två innan de syns på ditt bankkonto.
						</Description>

						<Description>
							Glöm inte att tipsa vänner och bekanta om MyBonus så får du pengar
							även på deras köp.
						</Description>

						<Description>Tack för att du använder MyBonus!</Description>

						<AnchorButton
							variant="primary"
							to={{ local: '/account' }}
							text="Till mitt konto"
						/>
					</$Center>
				)}
			</$FloatBox>
		</PageContainer>
	);
}
