import { v4 as uuidv4 } from 'uuid';

export const ALPHABET_WITHOUT_VOWELS = 'bcdfghjklmnpqrstvwxyz';

export function getUUID(opts = { uppercased: false, dashes: true }): string {
	let uuid = uuidv4();

	if (opts.uppercased) {
		uuid = uuid.toUpperCase();
	}

	if (!opts.dashes) {
		uuid = uuid.replace(/-/g, '');
	}

	return uuid;
}

export function getRandomStringFromCharset(
	length: number,
	charset = 'abcdefghijklmnopqrstuvwxyz0123456789',
): string {
	let result = '';

	for (let i = 0; i < length; i++) {
		const randomPosition = Math.floor(Math.random() * charset.length);
		result += charset.substring(randomPosition, randomPosition + 1);
	}

	return result;
}
