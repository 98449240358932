import { type ListStore as ListStoreType } from '@mybonus/public';

import { FavoriteButton } from '../../FavoriteButton';
import { ListStore } from '../../ListStore';
import { Wrapper } from './FavoriteListItem.parts';

export type FavoriteListItemProps = {
	store: ListStoreType;
	onClick: () => void;
	onFavoriteClick: () => void;
	isFavorite: boolean;
};

export function FavoriteListItem(props: FavoriteListItemProps) {
	return (
		<Wrapper isFavorite={props.isFavorite}>
			<ListStore {...props} />
			<FavoriteButton onClick={props.onFavoriteClick} isFavorite={props.isFavorite} />
		</Wrapper>
	);
}
