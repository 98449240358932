import { APIInput, useAPI, useHead, useMutation } from '@mybonus/ui';
import { useParams } from 'react-router-dom';

export function useSmsUnsubscribeView() {
	const params = useParams();
	const { api } = useAPI();
	useHead({ subtitle: 'Avregistrera från SMS' });

	const mutation = useMutation<APIInput['guest']['sms']['unsubscribe']>(
		(args) => api.guest.sms.unsubscribe.mutate(args),
	);

	function doUnsubscribe() {
		mutation.reset();

		if (params.code) {
			mutation.mutate({ code: params.code });
		}
	}

	return { mutation, doUnsubscribe };
}
