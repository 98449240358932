import { Box } from '../../primitives';
import { styled } from '../../theme';
import { TextButton } from '../TextButton';
import { Waiter } from '../Waiter';

export const Item = styled(TextButton).attrs((props) => ({
	...props,
}))``;

export const Wrapper = styled(Box)`
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	margin-bottom: ${({ theme }) => -theme.space.sm}px;

	${Item} {
		margin-right: ${({ theme }) => theme.space.sm}px;
		margin-bottom: ${({ theme }) => theme.space.sm}px;

		&:last-child {
			margin-right: 0;
		}
	}
`;

export const $Waiter = styled(Waiter)`
	width: 100%;
`;
