import type { SingleStore } from '@mybonus/public';
import { BestOfferText } from '@mybonus/ui';

import { Text } from './GiftcardDescription.parts';

export type GiftcardDescriptionProps = {
	store: SingleStore;
};

export function GiftcardDescription(props: GiftcardDescriptionProps) {
	const { store } = props;
	return (
		<Text>
			Du får {store.bestOffer && <BestOfferText lower bestOffer={store.bestOffer} />} återbäring vid
			köp av presentkort hos {store.name} (i samarbete med Zupergift).
		</Text>
	);
}
