import { RemoteImage, Box } from '../../../primitives';
import { styled } from '../../../theme';
import { Row } from '../../Row';

export const Wrapper = styled(Row)`
	padding: ${({ theme }) => theme.space.md}px;
	justify-content: space-between;
	align-items: center;
`;

export const Logo = styled(RemoteImage)`
	max-height: 28px;
`;
export const Details = styled(Box)``;
