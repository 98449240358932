import { BaseError } from './base';

export { BaseError } from './base';
export class ExposedError extends BaseError {}

export class AccountNotFoundError extends ExposedError {}
export class AffiliateCampaignInactiveError extends ExposedError {}
export class AffiliateCampaignNotFoundError extends ExposedError {}
export class AlreadyAnonymizedError extends ExposedError {}
export class AudienceNameExistsError extends ExposedError {}
export class BankIDOngoingOrderError extends ExposedError {}
export class BankIDPollError extends ExposedError {}
export class ClaimTimeExpiredError extends ExposedError {}
export class ClaimTimeNotActiveError extends ExposedError {}
export class GrantAccessLockedError extends ExposedError {}
export class IdentificationNotFoundError extends ExposedError {}
export class IdentificationSSNMismatchError extends ExposedError {}
export class InsufficientCashbackSumForWithdrawalError extends ExposedError {}
export class InvalidCredentialsError extends ExposedError {}
export class InvalidMFATokenError extends ExposedError {}
export class InvalidOrgNrError extends ExposedError {}
export class InvalidPhoneNumberError extends ExposedError {}
export class InvalidPushTokenError extends ExposedError {}
export class InvalidRefreshTokenError extends ExposedError {}
export class InvalidResetPasswordToken extends ExposedError {}
export class InvalidSSNError extends ExposedError {}
export class InvalidStoreGiftcardVendorOfferError extends ExposedError {}
export class InvalidURLError extends ExposedError {}
export class OutdatedClientError extends ExposedError {}
export class RecruiterNotFoundError extends ExposedError {}
export class RefreshTokenNotFoundError extends ExposedError {}
export class SSNNotUniqueError extends ExposedError {}
export class StoreClickNotFoundError extends ExposedError {}
export class StoreGiftcardVendorOfferLockedError extends ExposedError {}
export class TimeoutError extends ExposedError {}
export class TrackTicketExistsError extends ExposedError {}
export class UnauthorizedError extends ExposedError {}
export class UnauthenticatedError extends ExposedError {}
export class UserAccountNotCompleteError extends ExposedError {}
export class UserAlreadyExistsError extends ExposedError {}
export class UserNotFoundError extends ExposedError {}
export class UserTemporaryEmailNotFoundError extends ExposedError {}
export class TempIdentityNotFoundError extends ExposedError {}
export class VendorAuthenticationError extends ExposedError {}
export class VendorProfileIncompleteError extends ExposedError {}
export class ShortCodeNotFoundError extends ExposedError {}
export class NetworkError extends ExposedError {}
