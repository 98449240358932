import type { ReactNode } from 'react';

import { Wrapper, Checkbox, Content } from './CashbackGuideListItem.parts';

export type CashbackGuideListItemProps = {
	children: ReactNode;
};

export function CashbackGuideListItem(props: CashbackGuideListItemProps) {
	return (
		<Wrapper>
			<Checkbox />
			<Content>{props.children}</Content>
		</Wrapper>
	);
}
