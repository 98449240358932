import { RemoteImage } from '../../../primitives';
import { styled } from '../../../theme';
import { Center } from '../../Center';
import { NumberedSteps } from '../../NumberedSteps';

export const Wrapper = styled(Center)`
	flex-direction: column;
	width: 100%;
`;

export const Steps = styled(NumberedSteps)`
	margin-top: ${({ theme }) => theme.space.sm}px;
`;

export const QRImage = styled(RemoteImage)``;
