import { styled, Column, SortLabel, Container } from '@mybonus/ui';

export const Filters = styled(Column)`
	margin-bottom: ${({ theme }) => theme.space.lg}px;
`;

export const $SortLabel = styled(SortLabel)`
	margin-top: ${({ theme }) => theme.space.lg}px;
`;

export const LetterGroups = styled(Column)`
	width: 100%;
	align-items: stretch;

	> * {
		margin-bottom: ${({ theme }) => theme.space.lg}px;
	}
`;

export const GroupContainer = styled(Container)`
	padding-bottom: ${({ theme }) => theme.space.lg}px;
	z-index: 0;
`;
