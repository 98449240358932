import { type PaymentVendorType } from '@mybonus/public';
import { ReactNode } from 'react';

import { usePayment } from './Payment.hooks';
import {
	Wrapper,
	Paragraph,
	Spinner,
	TryAgainAnchor,
	SwishQRImage,
	AbortAnchor,
} from './Payment.parts';

export type PaymentProps = {
	vendor: PaymentVendorType;
	paymentRequestId: number;
	data:
		| {
				paymentRequestToken: string;
				callbackUrl: string;
				qrSvg: string;
		  }
		| {
				url: string;
		  };
	success: ReactNode;
	onTryAgain: () => void;
};

export function Payment(props: PaymentProps) {
	const payment = usePayment(props);

	function onTryAgain() {
		payment.reset();
		props.onTryAgain();
	}

	function renderPending() {
		if (payment.isTimeout) {
			return (
				<>
					<Paragraph>Det tog lite för lång tid</Paragraph>
					<Paragraph>
						<TryAgainAnchor to={{ onClick: () => onTryAgain() }}>Försök igen</TryAgainAnchor>
					</Paragraph>
				</>
			);
		}

		if (props.vendor === 'SWISH') {
			return (
				<>
					{'qrSvg' in props.data && (
						<>
							<Paragraph>
								Försöker öppna Swish på denna enhet. Skanna QR koden för att betala på annan enhet.
							</Paragraph>
							<SwishQRImage
								key={Date.now()} // Hack to force rerender of image
								uri={`data:image/svg+xml;base64,${btoa(
									unescape(encodeURIComponent(props.data.qrSvg)),
								)}`}
							/>
							<Paragraph>
								<AbortAnchor to={{ onClick: () => onTryAgain() }}>Avbryt</AbortAnchor>
							</Paragraph>
						</>
					)}
				</>
			);
		}

		return (
			<>
				<Paragraph>Startar betalning...</Paragraph>
				<Spinner />
			</>
		);
	}

	function renderAborted() {
		return (
			<>
				<Paragraph>Betalningen avbröts</Paragraph>
				<Paragraph>
					<TryAgainAnchor to={{ onClick: () => onTryAgain() }}>Tillbaka</TryAgainAnchor>
				</Paragraph>
			</>
		);
	}

	function renderFailed() {
		return (
			<>
				<Paragraph>Betalningen misslyckades</Paragraph>
				<Paragraph>
					<TryAgainAnchor to={{ onClick: () => onTryAgain() }}>Försök igen</TryAgainAnchor>
				</Paragraph>
			</>
		);
	}

	function render() {
		switch (payment.paymentStatus) {
			case 'PENDING':
				return renderPending();
			case 'ABORTED':
				return renderAborted();
			case 'FAILED':
				return renderFailed();
			case 'SUCCESS':
				return props.success;
		}
	}

	return <Wrapper>{render()}</Wrapper>;
}
