import { PageContainer, PageTitle, Form, Textfield, TextButton, ErrorMessage } from '@mybonus/ui';

import { useBusinessSettings } from './BusinessSettingsView.hooks';
import {
	$FloatBox,
	$BackAnchor,
	RemoveAnchor,
	SuccessMessage,
	Title,
	FloatWrapper,
	RemoveAnchorText,
} from './BusinessSettingsView.parts';

export function BusinessSettingsView() {
	const { form, mutation, error, isLoading, onClear, hasCompany } = useBusinessSettings();
	return (
		<PageContainer>
			<PageTitle>
				<Title>Företagsinställningar</Title>
			</PageTitle>

			<FloatWrapper>
				<$BackAnchor />
				<$FloatBox>
					<Form onSubmit={form.handleSubmit}>
						<Textfield
							type="text"
							placeholder="Företagsnamn"
							value={form.data.name || ''}
							variant={form.errors.name ? 'error' : undefined}
							onChange={form.handleChange('name')}
						/>
						<Textfield
							type="text"
							placeholder="Orgnr. (xxxxxx-xxxx)"
							value={form.data.orgNr || ''}
							variant={form.errors.orgNr ? 'error' : undefined}
							onChange={form.handleChange('orgNr')}
						/>

						<ErrorMessage error={error} />
						<TextButton
							variant="primary"
							text="Spara"
							onClick={form.handleSubmit}
							disabled={isLoading || !form.isChanged}
						/>
						{mutation.isSuccess && <SuccessMessage>Inställningarna har sparats</SuccessMessage>}
						{hasCompany && !mutation.isSuccess && (
							<RemoveAnchor to={{ onClick: onClear }}>
								<RemoveAnchorText />
							</RemoveAnchor>
						)}
					</Form>
				</$FloatBox>
			</FloatWrapper>
		</PageContainer>
	);
}
