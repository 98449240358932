import { useState } from 'react';
import { useTheme } from 'styled-components';

import type { Theme } from '../theme';
import { useEventListener } from './event-listener';
import { useIsomorphicLayoutEffect } from './isomorphic-layout-effect';

export function useDevice() {
	const [width, setWidth] = useState(0);
	const theme = useTheme() as Theme;

	function handleSize() {
		setWidth(window.innerWidth);
	}

	useEventListener('resize', handleSize);

	useIsomorphicLayoutEffect(() => {
		handleSize();
	}, []);

	function min(device: keyof typeof theme.deviceSize) {
		return width === 0 ? undefined : !(theme.deviceSize[device] >= width);
	}

	return {
		min,
	};
}
