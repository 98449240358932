import { Box } from '../../primitives';
import { styled, css } from '../../theme';
import type { SwitchButtonProps } from './SwitchButton';

export const switchSize = {
	sm: {
		slider: 18,
		dot: 20,
	},
	md: {
		slider: 20,
		dot: 22,
	},
	lg: {
		slider: 24,
		dot: 26,
	},
};

export type SwitchSize = keyof typeof switchSize;

type SwitchButtonPartProps = Omit<SwitchButtonProps, 'onChange'>;

export const Wrapper = styled(Box)<SwitchButtonPartProps>`
	padding: ${({ size }) =>
		(switchSize[size || 'md'].dot - switchSize[size || 'md'].slider) / 2}px;
	${({ disabled }) =>
		!disabled &&
		css`
			cursor: pointer;
		`}
`;

export const Slider = styled(Box)<SwitchButtonPartProps>`
	background-color: ${({ value, theme }) =>
		value ? theme.color.green.base : theme.color.lightGray.base};
	transition: background-color 0.2s ease-in-out;
	border-radius: 500px;
	height: ${({ size }) => switchSize[size || 'md'].slider}px;
	width: ${({ size }) => switchSize[size || 'md'].slider * 2}px;
	position: relative;
`;

export const Dot = styled(Box)<SwitchButtonPartProps>`
	left: ${({ size }) =>
		(switchSize[size || 'md'].slider - switchSize[size || 'md'].dot) / 2}px;
	position: absolute;
	transition: transform 0.2s ease-in-out;
	top: 50%;
	transform: translateY(-50%)
		translateX(
			${({ value, size }) =>
				value
					? css`calc(100% - ${
							switchSize[size || 'md'].dot - switchSize[size || 'md'].slider
					  }px)`
					: css`0%`}
		);
	background-color: ${({ theme }) => theme.color.lightGray.active};
	border-radius: 500px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	height: ${({ size }) => switchSize[size || 'md'].dot}px;
	width: ${({ size }) => switchSize[size || 'md'].dot}px;
`;
