import { CheckboxSize, Dot, Wrapper } from './Checkbox.parts';

export type CheckboxProps = {
	value: boolean;
	onChange?: (value: boolean) => void;
	disabled?: boolean;
	size?: CheckboxSize;
};

export function Checkbox(props: CheckboxProps) {
	const { onChange, ...rest } = props;

	return (
		<Wrapper onClick={() => onChange?.(!props.value)} {...rest}>
			<Dot {...rest} />
		</Wrapper>
	);
}
