import { styled, keyframes } from '../../theme';
import { Row } from '../Row';

const createKeyframes = (numVisible: number, numSlides: number) => keyframes`
  100% {
    transform: translateX(-${(100 / numVisible) * numSlides}%);
  }
`;

export const Wrapper = styled.div`
	position: relative;
	overflow: hidden;
	transform: translate3d(0, 0, 0);
	width: 100%;
`;

export const Track = styled(Row)<{
	numVisible: number;
	numSlides: number;
	timePerSlide: number;
}>`
	position: absolute;
	top: 0;
	bottom: 0;
	transform: translate3d(0, 0, 0);
	animation: ${(props) => createKeyframes(props.numVisible, props.numSlides)}
		${(props) => props.numSlides * (props.timePerSlide / 1000)}s linear infinite;
`;

export const Child = styled.div<{ i: number; numVisible: number }>`
	flex: 0 0 ${(props) => 100 / props.numVisible}%;
	width: ${(props) => 100 / props.numVisible}%;
	height: 100%;
`;
