import { Box } from '../../primitives';
import { styled } from '../../theme';
import { AnchorButton } from '../AnchorButton';
import { CoverSection } from '../CoverSection';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';

export const $CoverSection = styled(CoverSection)`
	max-width: 1600px;

	max-height: 40vh;

	@media ${({ theme }) => theme.device.laptop} {
		max-height: unset;
	}
`;

export const Wrapper = styled(Box)`
	height: 100%;
	justify-content: center;
	display: flex;
`;

export const Content = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	width: 100%;
	max-width: 1200px;
	padding: ${({ theme }) => theme.space.md}px;

	@media ${({ theme }) => theme.device.laptop} {
		padding: ${({ theme }) => theme.space.lg}px;
	}
`;

export const Headline = styled(Heading).attrs((props) => ({
	...props,
	level: 1,
}))`
	font-family: ${({ theme }) => theme.font.black};
	font-size: 38px;

	@media ${({ theme }) => theme.device.laptop} {
		font-size: 48px;
	}
`;

export const Description = styled(Paragraph).attrs((props) => ({
	...props,
	size: 'lg',
}))`
	@media ${({ theme }) => theme.device.laptop} {
		max-width: 50%;
	}
`;

export const Button = styled(AnchorButton).attrs((props) => ({
	...props,
	variant: 'alternative',
	size: 'lg',
}))`
	margin-top: ${({ theme }) => theme.space.md}px;
`;
