export abstract class ExtendableError extends Error {
	constructor(msg?: string) {
		super(msg);
		this.name = new.target.name;
	}
}

export class BaseError extends ExtendableError {
	constructor(msg?: string) {
		super(msg);
	}

	toString(): string {
		return `${this.name}: ${this.message || '<no message>'}`;
	}
}
