import { APIInput, APIOutput, useQuery, useAPI } from '@mybonus/ui';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export type MenuItem = {
	type: 'static' | 'page';
	path: string;
	text: string;
};

const DEFAULT_ITEMS: MenuItem[] = [
	{ type: 'static', path: '/browse', text: 'Butiker' },
	{ type: 'static', path: '/campaigns', text: 'Kampanjer' },
	{ type: 'static', path: '/recruitment', text: 'Bonus Buddies' },
	{ type: 'static', path: '/support', text: 'Kundservice' },
];

export function useMenuLinks() {
	const { api } = useAPI();
	const location = useLocation();

	const input = useMemo(
		() => ({
			showInMenu: true,
		}),
		[],
	);

	const list = useQuery<
		APIOutput['guest']['cms']['page']['list'],
		APIInput['guest']['cms']['page']['list']
	>('guest.page.list', (args) => api.guest.cms.page.list.query(args), input);

	const items = useMemo(() => {
		const result: MenuItem[] = [];
		const pagesList = [...(list.data || [])];

		function pushPage(p: APIOutput['guest']['cms']['page']['list'][number]) {
			result.push({ type: 'page', path: `/page/${p.slug}`, text: p.title });
		}

		DEFAULT_ITEMS.forEach((item, i) => {
			const pageToInjectIndex = pagesList.findIndex((p) => p.order === i + 1);

			result.push(item);

			if (pageToInjectIndex !== -1) {
				const pageToInject = pagesList.splice(pageToInjectIndex, 1);
				pageToInject[0] && pushPage(pageToInject[0]);
			}
		});

		// Append the rest of pages to end if any left
		pagesList.forEach((p) => pushPage(p));

		return result;
	}, [list.data]);

	return {
		location,
		isLoading: list.isLoading,
		items,
	};
}
