import { ListStore, imageUrlWithSize, config } from '@mybonus/public';

import { Anchor } from '../../../primitives';
import { BestOfferLabel } from '../../BestOfferLabel';
import { Wrapper, Logo, Details } from './StoreBar.parts';

export type StoreBarProps = {
	store: ListStore;
};

export function StoreBar(props: StoreBarProps) {
	const { store } = props;
	return (
		<Wrapper>
			<Anchor
				to={{
					local: config.urls.store({
						storeId: store.id,
						storeName: store.name,
					}),
				}}
			>
				<Logo
					url={imageUrlWithSize(store.logoUrl || '', {
						h: '50',
						format: 'png',
					})}
					alt={store.name}
				/>
			</Anchor>
			<Details>
				{!!store.bestOffer && <BestOfferLabel bestOffer={store.bestOffer} />}
			</Details>
		</Wrapper>
	);
}
