import { useState, useEffect } from 'react';

export function useClipboard(content: string, timeout = 2000) {
	const [copied, setCopied] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (copied || error) {
			const timer = setTimeout(() => {
				setCopied(false);
				setError(false);
			}, timeout);
			return () => clearTimeout(timer);
		}
	}, [copied, error]);

	function handleCopy() {
		try {
			navigator.clipboard.writeText(content);
			setCopied(true);
		} catch (err) {
			setError(true);
		}
	}

	return {
		error,
		copied,
		handleCopy,
	};
}
