import { imageUrlWithSize, config } from '@mybonus/public';

import type { APIOutput } from '../../../api';
import { Anchor } from '../../../primitives';
import { ItemWrapper, StoreImage } from './StoreSlideBannerItem.parts';

export type StoreSlideBannerItemProps = {
	store: APIOutput['guest']['store']['list']['items'][number];
};

export function StoreSlideBannerItem(props: StoreSlideBannerItemProps) {
	return (
		<ItemWrapper>
			{props.store.logoUrl && (
				<Anchor
					to={{
						local: config.urls.store({
							storeId: props.store.id,
							storeName: props.store.name,
						}),
					}}
				>
					<StoreImage
						url={imageUrlWithSize(props.store.logoUrl, {
							h: '100',
							format: 'png',
						})}
						alt={props.store.name}
					/>
				</Anchor>
			)}
		</ItemWrapper>
	);
}
