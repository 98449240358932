import type { ReactNode } from 'react';

import type { ContainerProps } from '../Container';
import { Background, $Container } from './PageContainer.parts';

export type PageContainerProps = {
	children?: ReactNode;
	noTopSpace?: boolean;
};

export function PageContainer(props: PageContainerProps & ContainerProps & { className?: string }) {
	return (
		<Background className={props.className} noTopSpace={props.noTopSpace}>
			<$Container width={props.width} padding={props.padding}>
				{props.children}
			</$Container>
		</Background>
	);
}
