import { Box } from '../../primitives';
import { styled } from '../../theme';
import { Spinner } from '../Spinner';

export const $Spinner = styled(Spinner)`
	width: 30px;
	height: 30px;
	margin: 0;
`;

export const SpinnerContainer = styled(Box)<{ show: boolean }>`
	transition: opacity 0.2s ease-in-out;
	pointer-events: none;
	opacity: ${({ show }) => (show ? '1' : '0')};
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ContentContainer = styled(Box)<{ isLoading: boolean }>`
	transition: opacity 0.2s ease-in-out;
	opacity: ${({ isLoading }) => (isLoading ? '0' : '1')};
	width: 100%;
	height: 100%;
`;

export const Base = styled(Box)`
	position: relative;
	z-index: 1;
	display: flex;
`;
