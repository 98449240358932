import {
	PageTitle,
	PageContainer,
	FloatBox,
	Heading,
	ImageDudeHammock,
	ImageBullishTrumpet,
	ImageChasingPig,
	ImageGroupOfFriends,
	useHead,
} from '@mybonus/ui';

import {
	Content,
	Illustration,
	ContentSection,
	$CmsContent,
	TopContent,
} from './HowItWorksView.parts';

const title = 'Så funkar det';

export function HowItWorksView() {
	useHead({ subtitle: title });

	return (
		<PageContainer noTopSpace>
			<PageTitle>
				<Heading level={1}>{title}</Heading>
			</PageTitle>

			<FloatBox>
				<Content>
					<TopContent />

					<ContentSection>
						<Illustration uri={ImageDudeHammock} />
						<$CmsContent code="howitworks_1" />
					</ContentSection>

					<ContentSection flipped>
						<$CmsContent code="howitworks_2" />
						<Illustration uri={ImageBullishTrumpet} />
					</ContentSection>

					<ContentSection>
						<Illustration uri={ImageChasingPig} />
						<$CmsContent code="howitworks_3" />
					</ContentSection>

					<ContentSection flipped>
						<$CmsContent code="howitworks_4" />
						<Illustration uri={ImageGroupOfFriends} />
					</ContentSection>
				</Content>
			</FloatBox>
		</PageContainer>
	);
}
