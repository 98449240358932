import { AccountRoute } from '@mybonus/ui';
import type { RouteObject } from 'react-router-dom';

import { App } from './App';
import {
	AboutView,
	AccountFavoritesView,
	AccountNotificationsView,
	AccountRemovalFulfillView,
	AccountRemovalInitView,
	AccountSettingsView,
	RecoverView,
	AccountView,
	AppView,
	BrowseView,
	BusinessSettingsView,
	CampaignsView,
	ClaimView,
	CommunicationSettingsView,
	CookiePolicyView,
	ErrorView,
	FulfillEmailChangeView,
	FulfillRecoverView,
	HomeView,
	HowItWorksView,
	PageView,
	LoginView,
	PasswordSettingsView,
	PaymentView,
	PluginView,
	PrivacyPolicyView,
	RecruitmentView,
	RedirectView,
	RegisterRecruitmentView,
	RegisterView,
	SmsUnsubscribeView,
	StoreView,
	SupportView,
	TrustlyTemplateView,
	UniversalIntentView,
	UserTermsView,
	WithdrawalView,
} from './components';

export const routes: RouteObject[] = [
	{
		path: '/',
		element: <App />,
		errorElement: (
			<ErrorView
				title={500}
				text="Ett oväntat fel uppstod, försök igen lite senare eller kontakta kundtjänst om felet kvarstår."
				to={{ url: '/' }}
			/>
		),
		children: [
			{
				path: '*',
				element: (
					<ErrorView title={404} text="Sidan du sökte kunde inte hittas." to={{ local: '/' }} />
				),
			},
			{
				path: '/',
				element: <HomeView />,
			},
			{
				path: '/browse',
				element: <BrowseView />,
			},
			{
				path: '/campaigns',
				element: <CampaignsView />,
			},
			{
				path: '/recruitment',
				element: <RecruitmentView />,
			},
			{
				path: '/support',
				element: <SupportView />,
			},
			{
				path: '/plugin',
				element: <PluginView />,
			},
			{
				path: '/app',
				element: <AppView />,
			},
			{
				path: '/how-it-works',
				element: <HowItWorksView />,
			},
			{
				path: '/store/:id/:name?',
				element: <StoreView />,
			},
			{
				path: '/out/:id/:link?',
				element: <RedirectView />,
			},
			{
				path: '/404',
				element: (
					<ErrorView title={404} text="Sidan du sökte kunde inte hittas" to={{ url: '/' }} />
				),
			},
			{
				path: '/register',
				element: <RegisterView />,
			},
			{
				path: '/login',
				element: <LoginView />,
			},
			{
				path: '/recover',
				element: <RecoverView />,
			},
			// TODO: This is legacy
			{
				path: '/l/:token',
				element: <FulfillRecoverView />,
			},
			{
				path: '/recover/fulfill/:token',
				element: <FulfillRecoverView />,
			},
			// TODO: This is legacy
			{
				path: '/n/:code',
				element: <FulfillEmailChangeView />,
			},
			{
				path: '/email-change/fulfill/:code',
				element: <FulfillEmailChangeView />,
			},
			{
				path: '/r/:code',
				element: <RegisterRecruitmentView />,
			},
			{
				path: '/s/:target/:id?',
				element: <UniversalIntentView />,
			},
			{
				path: '/u/:code',
				element: <SmsUnsubscribeView />,
			},
			{
				path: '/page/:slug',
				element: <PageView />,
			},
			{
				path: '/cookies',
				element: <CookiePolicyView />,
			},
			{
				path: '/terms',
				element: <UserTermsView />,
			},
			{
				path: '/privacy',
				element: <PrivacyPolicyView />,
			},
			{
				path: '/about',
				element: <AboutView />,
			},
			{
				path: '/account-removal/init',
				element: <AccountRemovalInitView />,
			},
			{
				path: '/account-removal/:code',
				element: <AccountRemovalFulfillView />,
			},
			{
				path: '/account',
				element: <AccountRoute />,
				children: [
					{
						path: '',
						element: <AccountView />,
					},
					{
						path: 'notifications',
						element: <AccountNotificationsView />,
					},
					{
						path: 'favorites',
						element: <AccountFavoritesView />,
					},
					{
						path: 'claim',
						element: <ClaimView />,
					},
					{
						path: 'settings',
						children: [
							{
								path: '',
								element: <AccountSettingsView />,
							},
							{
								path: 'password',
								element: <PasswordSettingsView />,
							},
							{
								path: 'communication',
								element: <CommunicationSettingsView />,
							},
							{
								path: 'business',
								element: <BusinessSettingsView />,
							},
						],
					},
				],
			},
			{
				path: '/trustly',
				children: [
					{
						path: 'template',
						element: <TrustlyTemplateView />,
					},
				],
			},
			{
				path: '/payment',
				children: [
					{
						path: 'success',
						element: <PaymentView result="success" />,
					},
					{
						path: 'cancel',
						element: <PaymentView result="cancel" />,
					},
				],
			},
			{
				path: '/withdrawal',
				children: [
					{
						path: 'success',
						element: <WithdrawalView result="success" />,
					},
					{
						path: 'error',
						element: <WithdrawalView result="error" />,
					},
				],
			},
		],
	},
];
