import { ErrorMessage } from '../ErrorMessage';
import { Paragraph } from '../Paragraph';
import { TextButton } from '../TextButton';
import { useChangePasswordForm } from './ChangePasswordForm.hooks';
import { Wrapper } from './ChangePasswordForm.parts';

export function ChangePasswordForm() {
	const { mutation, onRequestPasswordReset, email } = useChangePasswordForm();
	return (
		<Wrapper>
			<Paragraph>
				Skapa ett nytt lösenord genom att begära återställning av ditt lösenord
				nedan.
			</Paragraph>
			<Paragraph>
				Efter att du begärt ett nytt lösenord kommer du få ett mejl med
				instruktioner om hur du sätter ett nytt lösenord till ditt konto.
			</Paragraph>
			<ErrorMessage error={mutation.error} />
			{mutation.isSuccess && (
				<>
					<Paragraph variant="info">Mejl på väg!</Paragraph>
					<Paragraph>
						Ett mejl har skickats till {email} med vidare instruktioner om hur
						du sätter ett nytt lösenord. Om du inte fått något mejl, kolla
						skräpposten eller kontakta kundservice.
					</Paragraph>
				</>
			)}
			<TextButton
				variant="primary"
				disabled={mutation.isLoading || !email || !!mutation.isSuccess}
				onClick={onRequestPasswordReset}
				text="Begär nytt lösenord"
			/>
		</Wrapper>
	);
}
