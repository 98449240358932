import { useClipboard } from '../../../../hooks';
import { Wrapper, $Textfield, CopyButton } from './CodeCopy.parts';

export type CodeCopyProps = {
	code: string;
};

export function CodeCopy(props: CodeCopyProps & { className?: string }) {
	const clipboard = useClipboard(props.code);

	return (
		<Wrapper className={props.className}>
			<$Textfield value={props.code} />
			<CopyButton
				text={clipboard.copied ? 'Kopierad' : 'Kopiera'}
				onClick={clipboard.handleCopy}
			/>
		</Wrapper>
	);
}
