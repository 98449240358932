import {
	Name,
	Email,
	Password,
	NameInput,
	PhoneInput,
	SSNInput,
	isValidOrgNr,
	stringToDate,
	stringToTime,
	stringToNumber,
} from '@mybonus/public';

export const emailValidation = (msg?: string) => ({
	required: { message: 'Du måste fylla i email' },
	custom: {
		message: msg || 'Fyll i en giltig e-post',
		isValid: (value: unknown) => Email.safeParse(value).success,
	},
});

export const passwordValidation = (msg?: string) => ({
	required: { message: 'Du måste fylla i lösenord' },
	custom: {
		message: msg || 'Fyll i ett giltigt lösenord',
		isValid: (value: unknown) => Password.safeParse(value).success,
	},
});

export const nameValidation = (msg?: string) => ({
	custom: {
		message: msg || 'Fyll i ett giltigt namn',
		isValid: (value: unknown) => NameInput.safeParse(value).success,
	},
});

export const amountValidation = (msg?: string) => ({
	required: {
		message: 'Du måste ange ett värde',
	},
	custom: {
		message: msg || 'Fyll i ett giltigt värde',
		isValid: (value: unknown) => stringToNumber.safeParse(value).success,
	},
});

export const dateValidation = (msg?: string) => ({
	custom: {
		message: msg || 'Fyll i ett giltigt datum (yyyy-mm-dd)',
		isValid: (value: unknown) => stringToDate.safeParse(value).success,
	},
});

export const timeValidation = (msg?: string) => ({
	custom: {
		message: msg || 'Fyll i en giltig tid (hh:mm)',
		isValid: (value: unknown) => stringToTime.safeParse(value).success,
	},
});

export const phoneValidation = (msg?: string) => ({
	custom: {
		message: msg || 'Fyll i ett giltigt telefonnummer',
		isValid: (value: unknown) => PhoneInput.safeParse(value).success,
	},
});

export const ssnValidation = (msg?: string) => ({
	custom: {
		isValid: (value: unknown) => SSNInput.safeParse(value).success,
		message: msg || 'Fyll i ett giltigt personnummer',
	},
});

export const companyNameValidation = (msg?: string) => ({
	custom: {
		message: msg || 'Fyll i ett giltigt namn',
		isValid: (value: unknown) => Name.safeParse(value).success,
	},
});

export const orgNrValidation = (msg?: string) => ({
	custom: {
		isValid: (value: unknown) => isValidOrgNr(String(value)),
		message: msg || 'Fyll i ett giltigt organisationsnummer (xxxxxx-xxxx)',
	},
});
