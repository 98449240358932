import { utcToZonedTime, zonedTimeToUtc, format } from 'date-fns-tz';
import parse from 'date-fns/parse';
import ms from 'ms';

import { TimeoutError } from '../../err';

export function wait(ms: number): Promise<void> {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export function debounce<A extends any[], R = void>(
	fn: (...args: A) => R,
	delay: string,
): [(...args: A) => Promise<R>, () => void] {
	let timer: any;

	const debouncedFunc = (...args: A): Promise<R> =>
		new Promise((resolve) => {
			if (timer) {
				clearTimeout(timer);
			}

			timer = setTimeout(() => {
				resolve(fn(...args));
			}, ms(delay));
		});

	const teardown = () => clearTimeout(timer);

	return [debouncedFunc, teardown];
}

export function startTimer(): { stop: () => number } {
	const startTime = process.hrtime();

	return {
		stop: function stop(): number {
			const [sec, nsec] = process.hrtime(startTime);

			return Math.round(sec * 1000 + nsec / 1000000);
		},
	};
}

export function race<T>(promise: Promise<T>, ms: number): Promise<T> {
	return Promise.race([
		promise,
		wait(ms).then(() => {
			throw new TimeoutError(`Operation timed out after ${ms} ms`);
		}),
	]);
}

export function createFutureDate(from: Date, timeString: string): Date {
	return createOffsetDate(from, timeString, 'future');
}

export function createPastDate(from: Date, timeString: string): Date {
	return createOffsetDate(from, timeString, 'past');
}

export function createOffsetDate(from: Date, timeString: string, dir: 'past' | 'future'): Date {
	const msOffset = ms(timeString);
	const secOffset = msOffset / 1000;

	if (typeof msOffset === 'undefined') {
		throw new Error(`Invalid timestring "${timeString}"`);
	}

	const d = new Date(from.getTime());
	d.setSeconds(d.getSeconds() + (dir === 'past' ? -secOffset : secOffset));
	return d;
}

export function isAfter(reference: Date, test: Date): boolean {
	return test.getTime() > reference.getTime();
}

export function durationToHours(duration: {
	unit: 'HOURS' | 'DAYS' | 'WEEKS';
	value: number;
}): number {
	switch (duration.unit) {
		case 'HOURS':
			return duration.value;
		case 'DAYS':
			return duration.value * 24;
		case 'WEEKS':
			return duration.value * 24 * 7;
	}
}

export function hoursToDurationString(hours: number): string {
	let restHours = hours;
	let weeks;
	let days;
	let str = '';

	if (restHours > 24 * 7) {
		weeks = Math.floor(restHours / 24 / 7);
		restHours -= weeks * 7 * 24;
		str += `${weeks} week${weeks > 1 ? 's' : ''}`;
	}

	if (restHours > 24) {
		days = Math.floor(restHours / 24);
		restHours -= days * 24;
		str += `${days} day${days > 1 ? 's' : ''}`;
	}

	if (restHours > 0) {
		str += `${restHours} hour${restHours > 1 ? 's' : ''}`;
	}

	return str;
}

export function UTCDateToSwedishTimestamp(utcDate: Date): string {
	const tz = 'Europe/Stockholm';
	const zonedDate = utcToZonedTime(utcDate, tz);

	const pattern = 'yyyy-MM-dd HH:mm:ss';
	return format(zonedDate, pattern, { timeZone: tz });
}

export function swedishTimestampToUTC(dateString: string): Date {
	const tz = 'Europe/Stockholm';
	return zonedTimeToUtc(dateString, tz);
}

export function dateToUserFormat(d: Date): string {
	return format(d, 'yyyy-MM-dd | HH:mm');
}

export function diffDays(d1: Date, d2: Date): number {
	return Math.floor((d2.getTime() - d1.getTime()) / (1000 * 60 * 60 * 24));
}

export function parseUserDateTimeInput(input: string): Date {
	return parse(input, 'yyyy-MM-dd HH:mm', new Date());
}

export function isBlackWeek(opts?: { ref?: Date; untilCyberMonday: boolean }): boolean {
	const ref = opts?.ref || new Date();

	// Calculate Black Friday (4th Friday of November)
	// 0-based index for months in JS Date, so 10 is November
	const tmp = new Date(ref.getFullYear(), 10, 1);

	tmp.setDate(tmp.getDate() + ((5 - tmp.getDay()) % 7)); // first friday
	tmp.setDate(tmp.getDate() + 28); // add 4 weeks

	const blackFriday = tmp;
	const blackWeekStart = new Date(blackFriday);
	blackWeekStart.setDate(blackFriday.getDate() - 5);
	const blackWeekEnd = new Date(blackFriday);
	blackWeekEnd.setHours(23);
	blackWeekEnd.setMinutes(59);
	blackWeekEnd.setSeconds(59);
	blackWeekEnd.setMilliseconds(999);

	if (opts?.untilCyberMonday) {
		blackWeekEnd.setDate(blackWeekEnd.getDate() + 3); // Include sat, sun, mon
	}

	return ref >= blackWeekStart && ref <= blackWeekEnd;
}

export function isChristmasTimes(opts?: { ref?: Date }): boolean {
	const ref = opts?.ref || new Date();

	// zero-index, december
	return ref.getMonth() == 11;
}
