import { useEffect } from 'react';

import { useRedirect } from '../../hooks';
import { $Paragraph } from './Withdrawal.parts';

export type WithdrawalProps = {
	data?: { url: string };
};

export function Withdrawal(props: WithdrawalProps) {
	const redirect = useRedirect();

	useEffect(() => {
		if (props.data?.url) {
			redirect.toURL(props.data.url);
		}
	}, [props.data]);

	return <$Paragraph>Utbetalning påbörjas...</$Paragraph>;
}
