import { config } from '@mybonus/public';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function useFavoriteWebNavigation() {
	const navigate = useNavigate();

	const handleSelect = useCallback((store: { id: number; name: string }) => {
		navigate(config.urls.store({ storeId: store.id, storeName: store.name }));
	}, []);

	const handleNoContentClick = useCallback(() => {
		navigate(`/browse`);
	}, []);

	const handleShowAllClick = useCallback(() => {
		navigate(`/account/favorites`);
	}, []);

	return {
		handleSelect,
		handleNoContentClick,
		handleShowAllClick,
	};
}
