import { config } from '@mybonus/public';
import { styled, Column, AnchorButton, Center, Paragraph, FloatBox } from '@mybonus/ui';

export const FloatBoxWrapper = styled(Column)`
	gap: ${({ theme }) => theme.space.lg}px;
`;

export const HelpCenterBox = styled(Center)`
	border-bottom-left-radius: ${({ theme }) => theme.rounding.md}px;
	border-bottom-right-radius: ${({ theme }) => theme.rounding.md}px;
	// background-color: ${({ theme }) => theme.color.lightGray.base};
	padding: ${({ theme }) => theme.space.xl}px ${({ theme }) => theme.space.md}px;
	flex-direction: column;
	text-align: center;
`;

export const HelpCenterText = styled(Paragraph).attrs((props) => ({
	...props,
	children: `Allt du behöver, samlat på ett ställe.`,
	size: 'sm',
}))`
	color: ${({ theme }) => theme.color.gray.base};
	margin-top: 0;
`;

export const HelpCenterButton = styled(AnchorButton).attrs((props) => ({
	...props,
	text: 'Gå till hjälpcenter',
	size: 'lg',
	variant: 'primary',
	to: { url: config.urls.helpCenter() },
}))`
	margin-bottom: ${({ theme }) => theme.space.md}px;
`;

export const ClaimFloatBox = styled(FloatBox)``;
