import type { BestOffer } from '@mybonus/public/types';

import { BestOfferText } from '../BestOfferText';
import { Wrapper } from './BestOfferLabel.parts';

export type OfferLabelProps = {
	bestOffer: BestOffer;
	size?: 'sm' | 'md' | 'lg';
};

export function BestOfferLabel(props: OfferLabelProps) {
	return (
		<Wrapper {...props}>
			<BestOfferText bestOffer={props.bestOffer} size={props.size} />
		</Wrapper>
	);
}
