import { CampaignGrid } from '@mybonus/ui';

import { useCampaignSection } from './CampaignSection.hooks';
import {
	Background,
	Title,
	ToCampaignsButton,
	$Container,
} from './CampaignSection.parts';

export function CampaignSection() {
	const { campaigns } = useCampaignSection();

	return (
		<Background>
			<$Container>
				<Title>Veckans bästa deals</Title>
				<CampaignGrid campaigns={campaigns} />
				<ToCampaignsButton />
			</$Container>
		</Background>
	);
}
