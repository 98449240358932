import { Textfield } from '../../primitives';
import { styled } from '../../theme';
import { EmailTextfield } from '../EmailTextfield';
import { Paragraph } from '../Paragraph';
import { TextButton } from '../TextButton';

export const SaveButton = styled(TextButton).attrs(() => ({
	variant: 'primary',
	text: 'Spara',
}))`
	width: 100%;
`;

export const SuccessMessage = styled(Paragraph).attrs((props) => ({
	...props,
	variant: 'info',
}))`
	text-align: center;
`;

export const FirstnameTextfield = styled(Textfield).attrs(() => ({
	placeholder: 'Förnamn',
	type: 'text',
}))`
	width: 100%;
`;

export const LastnameTextfield = styled(Textfield).attrs(() => ({
	type: 'text',
	placeholder: 'Efternamn',
}))`
	width: 100%;
`;

export const PhoneTextfield = styled(Textfield).attrs(() => ({
	type: 'text',
	placeholder: 'Mobilnummer',
}))`
	width: 100%;
`;

export const SSNTextfield = styled(Textfield).attrs(() => ({
	type: 'text',
	placeholder: 'Personnummer (yyyymmddxxxx)',
}))`
	width: 100%;
`;

export const $EmailTextfield = styled(EmailTextfield).attrs((props) => ({
	...props,
}))``;

export const TempEmailWarning = styled(Paragraph).attrs((props) => ({
	...props,
	variant: 'warning',
}))`
	margin-left: ${({ theme }) => theme.space.sm * 2}px;
	margin-top: 0;
	margin-bottom: ${({ theme }) => theme.space.sm}px;
`;
