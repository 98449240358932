import { Box } from '../../primitives';
import { styled } from '../../theme';
import { Waiter } from '../Waiter';

export const Wrapper = styled(Waiter)`
	min-height: 70px;
`;

export const Items = styled(Box)<{ grid?: boolean }>`
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: ${({ theme }) => theme.space.lg}px;

	@media ${({ theme }) => theme.device.laptop} {
		grid-template-columns: ${({ grid }) => (grid ? '1fr 1fr' : '1fr')};
	}
`;

export const Footer = styled(Box)`
	width: 100%;
`;
