import type { ReactNode } from 'react';

import { JumboTitle } from '../JumboTitle';
import {
	$FloatBox,
	$GreenGradient,
	Typography,
	Subtitle,
	Image,
} from './Jumbotron.parts';

export type JumbotronProps = {
	title: string;
	subtitle: string;
	imageUri: any;
	children?: ReactNode;
};

export function Jumbotron(props: JumbotronProps) {
	return (
		<$FloatBox>
			<$GreenGradient>
				<Typography>
					<JumboTitle>{props.title}</JumboTitle>
					<Subtitle>{props.subtitle}</Subtitle>
				</Typography>
				<Image uri={props.imageUri} />
			</$GreenGradient>
			{props.children}
		</$FloatBox>
	);
}
