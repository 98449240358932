import { styled } from '../../theme';
import { PasswordTextfield } from '../PasswordTextfield';
import { TextButton } from '../TextButton';
import { Waiter } from '../Waiter';

export const $PasswordTextfield = styled(PasswordTextfield).attrs((props) => ({
	...props,
	autoCompleteNewPassword: true,
}))`
	text-align: center;
`;

export const SaveButton = styled(TextButton).attrs(() => ({
	variant: 'primary',
	text: 'Spara lösenord',
}))`
	width: 100%;
	margin: ${({ theme }) => theme.space.sm}px 0;
`;

export const $Waiter = styled(Waiter)`
	text-align: center;
`;

export const LoginButton = styled(TextButton).attrs((props) => ({
	...props,
	text: 'Till login',
	variant: 'primary',
}))``;
