import { useAPI, useQuery, type APIOutput, type APIInput } from '../../api';
import { usePagination } from '../../hooks';
import { LoadMoreArrow } from '../LoadMoreArrow';
import { ListItem } from './ListItem';
import { Wrapper, $Waiter } from './WithdrawalList.parts';

export function WithdrawalList() {
	const { pagination: input, page, setPage } = usePagination();
	const { api } = useAPI();

	const listing = useQuery<
		APIOutput['user']['withdrawal']['list'],
		APIInput['user']['withdrawal']['list']
	>('user.withdrawal.list', (args) => api.user.withdrawal.list.query(args), input, {
		mergeData: (data, newData) =>
			data ? { ...data, ...newData, items: data.items.concat(newData.items) } : newData,
		ttl: false,
	});

	function loadMore() {
		setPage(page + 1);
	}

	return (
		<$Waiter isLoading={listing.isLoading}>
			<Wrapper>
				{(listing.data?.items || []).map((item) => (
					<ListItem key={item.id} item={item} />
				))}

				{(listing.data?.pagination.pages || 0) > page && (
					<LoadMoreArrow isLoading={listing.isLoading} onClick={loadMore} />
				)}
			</Wrapper>
		</$Waiter>
	);
}
