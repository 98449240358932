import { Link } from 'react-router-dom';

import { styled, css } from '../../theme';
import type { AnchorProps } from './Anchor.types';

function Base(props: AnchorProps) {
	if ('local' in props.to) {
		return (
			<Link to={props.to.local} state={props.to.state} className={props.className}>
				{props.children}
			</Link>
		);
	} else if ('url' in props.to) {
		return (
			<a
				href={props.to.url}
				className={props.className}
				{...(props.to.newTab ? { target: '_blank' } : {})}
			>
				{props.children}
			</a>
		);
	} else if ('onClick' in props.to) {
		return (
			<a onClick={props.to.onClick} className={props.className}>
				{props.children}
			</a>
		);
	} else {
		throw new Error(`Unsupported props to <Anchor/>: ${JSON.stringify(props)}`);
	}
}

export const Anchor = styled(Base)`
	cursor: pointer;
	text-decoration: underline;
	color: ${(props) => props.color || props.theme.color.green.base};

	${({ underlined }) =>
		underlined === false
			? css`
					text-decoration: none;
			  `
			: ''}
`;
