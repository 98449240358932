import constate from 'constate';

import { useAPI, useQuery } from '../api';
import { useAuthContext } from './auth';

function useNotification() {
	const { api } = useAPI();
	const auth = useAuthContext();

	const unreadCountQuery = useQuery(
		'user.notification.unreadCount',
		() => api.user.notification.unreadCount.query(),
		undefined,
		{ preventAutoload: !auth.account },
	);

	return {
		unreadCount: unreadCountQuery.data,
		isLoading: auth.isLoading || unreadCountQuery.isLoading,
		invalidate: () =>
			unreadCountQuery.load({ bypassCache: true, refetch: true }),
	};
}

export const [NotificationProvider, useNotificationContext] =
	constate(useNotification);
