import { Anchor, buttonPadding, Box } from '../../primitives';
import { styled } from '../../theme';
import { Center } from '../Center';
import { ErrorMessage as _ErrorMessage } from '../ErrorMessage';
import { Icon } from '../Icon';
import { Paragraph } from '../Paragraph';
import { TextButton } from '../TextButton';

export const Wrapper = styled(Box)`
	width: 100%;
`;

export const InitButton = styled(TextButton).attrs((props) => ({
	...props,
	iconRight: <Icon size="xxl" name="IconBankIdNegative" />,
	text: 'Identifiera med BankID',
	variant: 'bankid',
}))`
	width: 100%;
	padding: 8px ${buttonPadding.md.x}px;
`;

export const Description = styled(Paragraph)`
	text-align: center;
`;

export const ErrorMessage = styled(_ErrorMessage)`
	text-align: center;
`;

export const FlowAnchor = styled(Anchor).attrs((props) => ({
	...props,
}))``;

export const AbortAnchor = styled(Anchor).attrs((props) => ({
	...props,
	children: <Paragraph variant="error">Avbryt</Paragraph>,
	color: props.theme.color.red.base,
}))`
	text-align: center;
`;

export const CompleteCheck = styled(Center).attrs((props) => ({
	...props,
	children: <Icon name="IconCheckboxGreen" size="xxxl" />,
	vertical: true,
}))`
	height: 150px;
`;
