import { Icon } from '../Icon';
import { TextButton, TextButtonProps } from '../TextButton';

export type FacebookButtonProps = TextButtonProps;

export function FacebookButton(props: FacebookButtonProps) {
	return (
		<TextButton
			variant="facebook"
			iconLeft={<Icon name="IconFacebookWhite" />}
			{...props}
		/>
	);
}
