import { Text as _Text } from '../../primitives';
import { styled } from '../../theme';

export const Text = styled(_Text).attrs((props) => ({
	...props,
	color: props.theme.color.orange.base,
	element: 'span',
}))`
	white-space: nowrap;
`;

export const Striked = styled(Text).attrs((props) => ({
	...props,
	decoration: 'strike',
}))``;

export const OverrideOfferText = styled(Text).attrs((props) => ({
	...props,
	style: 'bold',
}))`
	margin-left: ${({ theme }) => theme.space.xs}px;
`;

export const PrefixText = styled(Text)`
	margin-right: ${({ theme }) => theme.space.xs}px;
`;
