import { styled } from '../../theme';
import { Heading } from '../Heading';

export const Title = styled(Heading).attrs((props) => ({
	...props,
	level: 4,
	color: props.theme.color.green.base,
}))`
	margin-top: 0;
`;
