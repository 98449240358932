import { AnchorButton } from '@mybonus/ui';

import { Wrapper, SubText } from './SignupButton.parts';

export function SignupButton() {
	return (
		<Wrapper>
			<AnchorButton
				to={{ local: '/register' }}
				text="Bli medlem nu"
				size="lg"
				variant="primary"
			/>
			<SubText>MyBonus är helt kostnadsfritt.</SubText>
		</Wrapper>
	);
}
