import { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function useUrl() {
	const navigate = useNavigate();

	const url = new URL(typeof window !== 'undefined' ? window.location.href : 'http://localhost');

	const path = useMemo(() => `${url.pathname}${url.search}${url.hash}`, [url]);

	const queryObject = useMemo(() => {
		const params: Record<string, string> = {};

		for (const [key, value] of url.searchParams.entries()) {
			params[key] = value;
		}

		return params;
	}, [url]);

	const updateQueryObject = useCallback(
		(data: Record<string, string | undefined>) => {
			for (const key in data) {
				const value = data[key as keyof typeof data];

				if (value === undefined) {
					url.searchParams.delete(key);
				} else {
					url.searchParams.set(key, value);
				}
			}

			navigate({ pathname: url.pathname, search: url.search }, { replace: true });
		},
		[url, queryObject, navigate],
	);

	return {
		url,
		path,
		queryObject,
		updateQueryObject,
	};
}
