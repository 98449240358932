export const font = {
	base: 'lineto-circular-book',
	italic: 'lineto-circular-book-italic',
	bold: 'lineto-circular-bold',
	black: 'lineto-circular-black',
};

export const fontSize = {
	xs: 12,
	sm: 14,
	md: 16,
	lg: 18,
	xl: 24,
	xxl: 32,
};
