import { useState, useMemo } from 'react';

export function usePagination(initialPage = 1, pageSize = 50) {
	const [page, setPage] = useState(initialPage);
	const pagination = useMemo(
		() => ({
			pagination: {
				limit: pageSize,
				offset: (page - 1) * pageSize,
			},
		}),
		[page],
	);

	return {
		pagination,
		page,
		setPage,
	};
}
