import type { ReactNode } from 'react';
import { useTheme } from 'styled-components';

import { Button, ButtonProps, Text } from '../../primitives';
import type { Theme } from '../../theme';
import { IconLeftWrapper, IconRightWrapper } from './TextButton.parts';

export type TextButtonProps = Omit<ButtonProps, 'children'> & {
	text: string;
	iconLeft?: ReactNode;
	iconRight?: ReactNode;
};

export function TextButton(props: TextButtonProps & { className?: string }) {
	const theme = useTheme() as Theme;
	return (
		<Button {...props}>
			{props.iconLeft ? (
				<IconLeftWrapper>{props.iconLeft}</IconLeftWrapper>
			) : (
				<span></span>
			)}
			<Text
				style="bold"
				noBreak
				size={props.size}
				color={
					props.disabled
						? theme.color.midGray.base
						: !props.variant || props.variant === 'default'
						? theme.color.white.base
						: theme.button[props.variant].fg.base
				}
			>
				{props.text}
			</Text>
			{props.iconRight ? (
				<IconRightWrapper>{props.iconRight}</IconRightWrapper>
			) : (
				<span></span>
			)}
		</Button>
	);
}
