import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import type { AnchorProps } from '../../primitives';
import { TextButton, type TextButtonProps } from '../TextButton';

export type AnchorButtonProps = Pick<AnchorProps, 'to'> &
	Omit<TextButtonProps, 'onClick'>;

export function AnchorButton(props: AnchorButtonProps) {
	const navigate = useNavigate();

	const handleClick = useCallback(() => {
		if ('local' in props.to) {
			navigate(props.to.local, { state: props.to.state });
		} else if ('url' in props.to) {
			if (props.to.newTab) {
				window.open(props.to.url, '_blank')?.focus();
			} else {
				window.location.href = props.to.url;
			}
		} else if ('onClick' in props.to) {
			props.to.onClick();
		} else {
			throw new Error(
				`Unsupported props to <AnchorButton/>: ${JSON.stringify(props)}`,
			);
		}
	}, [props.to]);

	return <TextButton {...props} onClick={handleClick} />;
}
