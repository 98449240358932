import { z } from 'zod';

import { Duration } from '../../time';
import { stringToDate } from '../../zod-primitives';

export const AudienceSyncJobActiveHours = z.object({
	fromHour: z.number().min(0).max(23),
	activeHours: z.number().min(0).max(24),
});
export type AudienceSyncJobActiveHours = z.infer<typeof AudienceSyncJobActiveHours>;

export const AudienceSyncJobRejoinRules = z.object({
	rejoinAfter: Duration,
	rejoinLimit: z.number(),
});
export type AudienceSyncJobRejoinRules = z.infer<typeof AudienceSyncJobRejoinRules>;

export const AudienceSyncJobScheduleInput = z.union([
	z.object({
		recurring: z.literal(0),
		activeFrom: stringToDate,
	}),
	z.object({
		recurring: z.number(),
		activeFrom: stringToDate,
		activeTo: stringToDate,
		activeHours: AudienceSyncJobActiveHours.optional(),
		rejoinRules: AudienceSyncJobRejoinRules.optional(),
	}),
]);
export type AudienceSyncJobScheduleInput = z.infer<typeof AudienceSyncJobScheduleInput>;

export const AudienceSyncDestination = z.enum(['MAILCHIMP', 'NOTIFICATION', 'CASHBACK_SHARE']);
export type AudienceSyncDestination = z.infer<typeof AudienceSyncDestination>;
