import { config } from '@mybonus/public';

import { useAuthContext } from '../../context';
import { ErrorMessage } from '../ErrorMessage';
import { Form } from '../Form';
import { Paragraph } from '../Paragraph';
import { useFulfillRecoverForm } from './FulfillRecoverForm.hooks';
import { SaveButton, $PasswordTextfield, $Waiter, LoginButton } from './FulfillRecoverForm.parts';

export type FulfillRecoverFormProps = {
	token: string;
	onLoginClick: () => void;
};

export function FulfillRecoverForm(props: FulfillRecoverFormProps) {
	const { form, mutation, validation } = useFulfillRecoverForm(props.token);
	const auth = useAuthContext();

	return (
		<$Waiter isLoading={typeof validation.data !== 'boolean'}>
			{validation.data ? (
				<Form onSubmit={form.handleSubmit}>
					{!form.hasErrors && mutation.isSuccess ? (
						<>
							<Paragraph variant="info">Ditt lösenord har bytts!</Paragraph>
							{!auth.account && <LoginButton onClick={props.onLoginClick} />}
						</>
					) : (
						<>
							<$PasswordTextfield
								value={form.data.password || ''}
								variant={form.errors.password ? 'error' : undefined}
								onChange={form.handleChange('password')}
							/>

							<SaveButton
								onClick={form.handleSubmit}
								isLoading={mutation.isLoading}
								disabled={!!mutation.isSuccess}
							/>

							<ErrorMessage
								error={
									(form.hasErrors && 'Se över informationen ovan och försök igen') || mutation.error
								}
							/>
						</>
					)}
				</Form>
			) : (
				<ErrorMessage
					error={`Länken har löpt ut, har redan använts eller är felaktig. Du kan försöka igen genom att göra en ny återställning. Tänk på att länken i mailet enbart är giltig i ${config.passwordResetCodeValidPeriod}.`}
				/>
			)}
		</$Waiter>
	);
}
