import type { Offer } from '@mybonus/public';

import {
	Striked,
	OverrideOfferText,
	Text,
	PrefixText,
} from './OfferText.parts';

export type OfferTextProps = {
	offer: Offer;
	prefix?: string;
	size?: 'sm' | 'md' | 'lg';
};

export function OfferText(props: OfferTextProps) {
	const { offer, prefix, size } = props;
	return (
		<>
			{prefix && <PrefixText size={size}>{prefix}</PrefixText>}
			{offer.override ? (
				<>
					<Striked size={size}>{offer.spec.text}</Striked>
					<OverrideOfferText size={size}>
						{offer.override.spec.text}
					</OverrideOfferText>
				</>
			) : (
				<Text size={size}>{offer.spec.text}</Text>
			)}
		</>
	);
}
