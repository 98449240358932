import { AnchorButton, styled } from '@mybonus/ui';

export const BackButton = styled(AnchorButton).attrs((props) => ({
	...props,
	variant: 'primaryHollow',
	to: { local: '/' },
	text: 'Till startsidan',
}))`
	margin-top: ${({ theme }) => theme.space.md}px;
`;
