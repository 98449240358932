import { config } from '@mybonus/public';
import { useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAuthContext, useFacebookAuthContext } from '../../context';
import { useClientStorage } from '../../hooks';
import { Anchor } from '../../primitives';
import { ErrorMessage } from '../ErrorMessage';
import { PageContainer } from '../PageContainer';
import { PageTitle } from '../PageTitle';
import { Paragraph } from '../Paragraph';
import { PasswordTextfield } from '../PasswordTextfield';
import { FacebookConnectButton } from './FacebookConnectButton';
import { useRegisterForm, useVendorConnect } from './RegisterView.hooks';
import {
	RegisterButton,
	$FloatBox,
	$EmailTextfield,
	Title,
	AlreadyMemberText,
	$Form,
	RecruitmentCodeTextfield,
	OrText,
} from './RegisterView.parts';

export function RegisterView() {
	// TODO: We should not use web-specific things in this view, since the app may use this view as well.
	const location = useLocation();
	const recruitmentIdStorage = useClientStorage<string>({
		key: config.storage.keys.recruitmentId,
		type: 'session',
	});

	const recruitmentId = useMemo(() => {
		const value = recruitmentIdStorage.getItem();
		return value ? Number(value) : null;
	}, []);

	const { form, error, mutation } = useRegisterForm({
		initialData: {
			email: location.state?.email,
			recruitmentId: recruitmentId || undefined,
		},
	});
	const auth = useAuthContext();
	const facebookAuth = useFacebookAuthContext();
	const navigate = useNavigate();
	const vendorConnect = useVendorConnect();

	useEffect(() => {
		if (auth.account) {
			navigate('/account');
		}
	}, [auth.account]);

	const handleFacebookConnect = useCallback(async () => {
		const res = await facebookAuth.login();

		if (res) {
			vendorConnect.connect({
				vendorType: 'FACEBOOK',
				vendorAccessToken: res.authResponse.accessToken,
				recruitmentCode: form.data.recruitmentCode,
				recruitmentId: form.data.recruitmentId,
			});
		}
	}, [form.data.recruitmentCode, form.data.recruitmentId]);

	return (
		<PageContainer>
			<PageTitle>
				<Title />
				<AlreadyMemberText />
			</PageTitle>

			<$FloatBox>
				<$Form onSubmit={form.handleSubmit}>
					<RecruitmentCodeTextfield
						value={form.data.recruitmentCode || ''}
						variant={form.errors.recruitmentCode ? 'error' : undefined}
						onChange={form.handleChange('recruitmentCode')}
					/>

					<FacebookConnectButton
						isLoading={facebookAuth.isLoading}
						onClick={handleFacebookConnect}
					/>
					<ErrorMessage error={facebookAuth.error || vendorConnect.error} />
					<OrText />

					<$EmailTextfield
						value={form.data.email || ''}
						variant={form.errors.email ? 'error' : undefined}
						onChange={form.handleChange('email')}
					/>

					<PasswordTextfield
						value={form.data.password || ''}
						variant={form.errors.password ? 'error' : undefined}
						onChange={form.handleChange('password')}
					/>

					<ErrorMessage
						error={(form.hasErrors && 'Se över informationen ovan och försök igen') || error}
					/>

					<Paragraph size="sm">
						När du klickar på Gå med godkänner du MyBonus{' '}
						<Anchor to={{ newTab: true, url: config.urls.terms() }}>Användarvillkor</Anchor> och{' '}
						<Anchor to={{ newTab: true, url: config.urls.privacyPolicy() }}>
							Integritetspolicy
						</Anchor>
						.
					</Paragraph>

					<RegisterButton isLoading={mutation.isLoading} />
				</$Form>
			</$FloatBox>
		</PageContainer>
	);
}
