import { useState } from 'react';

import { Reference, $IconButton, $StoreSearch } from './SearchPill.parts';

export type SearchPillProps = {
	className?: string;
};

export function SearchPill(props: SearchPillProps) {
	const [showStoreSearch, setShowStoreSearch] = useState(false);

	return (
		<Reference className={props.className}>
			<$IconButton onClick={() => setShowStoreSearch(true)} />

			<$StoreSearch
				onClose={() => setShowStoreSearch(false)}
				visible={showStoreSearch}
				focus={showStoreSearch}
			/>
		</Reference>
	);
}
