import { Box, LocalImage } from '../../primitives';
import { styled } from '../../theme';
import { FloatBox } from '../FloatBox';
import { GreenGradient } from '../GreenGradient';
import { Paragraph } from '../Paragraph';

export const $FloatBox = styled(FloatBox)`
	width: 100%;
	padding: 0;
`;

export const $GreenGradient = styled(GreenGradient)`
	border-top-left-radius: ${({ theme }) => theme.rounding.md}px;
	border-top-right-radius: ${({ theme }) => theme.rounding.md}px;
	display: flex;
	padding: ${({ theme }) => theme.space.md}px ${({ theme }) => theme.space.md}px
		0 ${({ theme }) => theme.space.md}px;
	flex-direction: column;
	align-items: center;

	@media ${({ theme }) => theme.device.laptop} {
		flex-direction: row;
		align-items: flex-end;
		padding: ${({ theme }) => theme.space.xxl}px
			${({ theme }) => theme.space.xl}px 0 ${({ theme }) => theme.space.xl}px;
	}
`;

export const Typography = styled(Box)`
	@media ${({ theme }) => theme.device.laptop} {
		height: 100%;
		flex: 0 1 55%;
		padding-bottom: ${({ theme }) => theme.space.xl}px;
	}
`;

export const Subtitle = styled(Paragraph).attrs((props) => ({
	...props,
	color: props.theme.color.white.base,
	size: 'lg',
}))``;

export const Image = styled(LocalImage).attrs((props) => ({
	...props,
}))`
	width: 100%;
	max-width: 400px;

	@media ${({ theme }) => theme.device.laptop} {
		flex: 0 1 45%;
		padding-left: ${({ theme }) => theme.space.lg}px;
	}
`;
