import { Textfield } from '../../../primitives';
import { styled } from '../../../theme';
import { ErrorMessage } from '../../ErrorMessage';
import { Paragraph } from '../../Paragraph';
import { Row } from '../../Row';
import { TextButton } from '../../TextButton';

export const Label = styled(Paragraph).attrs((props) => ({
	...props,
}))`
	margin-left: ${({ theme }) => theme.space.sm}px;
	margin-top: ${({ theme }) => theme.space.sm}px;
`;

export const SaveButton = styled(TextButton).attrs(() => ({
	variant: 'primary',
	text: 'Rapportera ditt köp',
}))`
	width: 100%;
	margin-top: ${({ theme }) => theme.space.md}px;
`;

export const $ErrorMessage = styled(ErrorMessage)`
	text-align: center;
`;

export const DateTimeRow = styled(Row)`
	> * {
		margin-right: ${({ theme }) => theme.space.sm}px;

		&:last-child {
			margin-right: 0;
		}
	}
`;

export const Datefield = styled(Textfield).attrs((props) => ({
	...props,
	type: 'date',
}))`
	flex: 1;
	width: 100%;
`;

export const Timefield = styled(Textfield).attrs((props) => ({
	...props,
	type: 'time',
}))`
	flex: 1;
	width: 100%;
`;
