import { styled, LocalImage, Box, CmsContent } from '@mybonus/ui';

export const TopContent = styled(CmsContent).attrs((props) => ({
	...props,
	code: 'howitworks_how',
}))`
	@media ${({ theme }) => theme.device.tablet} {
		max-width: 50%;
	}
`;

export const Content = styled(Box)`
	width: 100%;

	@media ${({ theme }) => theme.device.tablet} {
		padding: ${({ theme }) => theme.space.lg}px;
	}
`;

export const ContentSection = styled(Box)<{ flipped?: boolean }>`
	display: flex;
	align-items: center;
	padding: ${({ theme }) => theme.space.lg}px 0;
	flex-direction: ${({ flipped }) => (flipped ? 'column-reverse' : 'column')};

	@media ${({ theme }) => theme.device.tablet} {
		flex-direction: row;
	}
`;

export const $CmsContent = styled(CmsContent)`
	width: 100%;
`;

export const Illustration = styled(LocalImage)`
	width: 100%;
	max-height: 256px;
	padding: ${({ theme }) => theme.space.lg}px;

	@media ${({ theme }) => theme.device.tablet} {
		padding: ${({ theme }) => theme.space.md}px;
	}
`;
