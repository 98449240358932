import { Text } from '../../primitives';
import { styled, css, mainTheme } from '../../theme';

export const ParagraphCSS = css<{
	color?: string;
	size?: keyof typeof mainTheme.fontSize;
}>`
	font-family: ${({ theme }) => theme.font.base};
	font-size: ${({ theme, size }) =>
		size ? theme.fontSize[size] : theme.fontSize.md}px;
	color: ${(props) => props.color || props.theme.color.darkGray.base};
	line-height: 1.4;
	margin-top: 8px;
	margin-bottom: 12px;
`;

export const Base = styled(Text).attrs((props) => ({
	...props,
	element: props.element || 'p',
}))`
	${ParagraphCSS}
`;
