import { wait } from '@mybonus/public';
import { useRef, useEffect, useState } from 'react';

export function usePoll<CallbackT extends () => Promise<void>>(
	_callback: CallbackT,
	opts: {
		intervalMs: number;
		timeoutMs?: number;
	},
) {
	const callback = useRef<CallbackT>();
	const lastPoll = useRef<number>();
	const firstPoll = useRef<number>();
	const isPolling = useRef<boolean>();
	const [isTimeout, setIsTimeout] = useState(false);
	const timeoutMs = useRef<number | undefined>();

	useEffect(() => {
		callback.current = _callback;
	}, [_callback]);

	useEffect(() => {
		timeoutMs.current = opts.timeoutMs;
	}, [opts.timeoutMs]);

	useEffect(() => {
		return () => {
			stop();
		};
	}, []);

	const run = useRef(async () => {
		const now = Date.now();

		if (!firstPoll.current) {
			firstPoll.current = now;
		}

		const delay = !lastPoll.current ? 0 : Math.max(0, lastPoll.current - now + opts.intervalMs);

		if (
			timeoutMs.current &&
			firstPoll.current &&
			Date.now() + delay > firstPoll.current + timeoutMs.current
		) {
			setIsTimeout(true);
			stop();
			return;
		}

		await wait(delay);

		if (!isPolling.current) {
			return;
		}

		lastPoll.current = Date.now();
		await callback.current?.();

		if (isPolling.current) {
			run.current();
		}
	});

	function start() {
		setIsTimeout(false);

		const shouldRun = !isPolling.current;
		isPolling.current = true;

		if (shouldRun) {
			run.current();
		}
	}

	function stop() {
		isPolling.current = false;
		firstPoll.current = undefined;
	}

	function reset() {
		stop();
		firstPoll.current = undefined;
		setIsTimeout(false);
	}

	return {
		start,
		stop,
		reset,
		isTimeout,
	};
}
