import { styled, CmsContent, FloatBox } from '@mybonus/ui';

export const $FloatBox = styled(FloatBox)`
	margin-top: 16px;
	width: 100%;
	max-width: 400px;
`;

export const $CmsContent = styled(CmsContent)`
	text-align: center;
	max-width: 400px;
`;
