import {
	Container,
	Row,
	Paragraph,
	ThemeProvider,
	CmsContent,
	invertTheme,
	useStoreCountContext,
} from '@mybonus/ui';

import {
	AboutColumn,
	$Anchor,
	Copyright,
	FacebookAnchorIcon,
	InfoRow,
	InstagramAnchorIcon,
	LinksColumn,
	$DownloadAppRow,
	LogoLink,
	$Separator,
	SocialRow,
	Wrapper,
	LinkList,
} from './Footer.parts';

export function Footer() {
	const storeCount = useStoreCountContext();

	return (
		<Wrapper>
			<ThemeProvider theme={invertTheme}>
				<Container>
					<Row>
						<LogoLink />
					</Row>
					<InfoRow>
						<AboutColumn>
							<CmsContent
								code="footer_text"
								replacements={{ storeCount: storeCount.text }}
							/>
							<SocialRow>
								<InstagramAnchorIcon />
								<FacebookAnchorIcon />
							</SocialRow>
						</AboutColumn>
						<LinksColumn>
							<Row>
								<LinkList>
									<Paragraph>
										<$Anchor to={{ local: '/support' }}>Kontakta oss</$Anchor>
										<$Anchor to={{ local: '/how-it-works' }}>
											Så funkar det
										</$Anchor>
										<$Anchor to={{ local: '/plugin' }}>
											Smarta webbläsartillägget
										</$Anchor>
										<$Anchor to={{ local: '/app' }}>Ladda ner vår app</$Anchor>
									</Paragraph>
								</LinkList>
								<LinkList>
									<Paragraph>
										<$Anchor to={{ local: '/about' }}>Om MyBonus</$Anchor>
										<$Anchor to={{ local: '/terms' }}>Användarvillkor</$Anchor>
										<$Anchor to={{ local: '/privacy' }}>
											Integritetspolicy
										</$Anchor>
										<$Anchor to={{ local: '/cookies' }}>Cookiepolicy</$Anchor>
									</Paragraph>
								</LinkList>
							</Row>
							<$DownloadAppRow />
						</LinksColumn>
					</InfoRow>
					<$Separator />

					<Copyright>
						Copyright © {new Date().getFullYear()} MyBonus AB org.nr
						556994-6832, en del av Say Group.
					</Copyright>
				</Container>
			</ThemeProvider>
		</Wrapper>
	);
}
