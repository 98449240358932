import { styled, FloatBox, Column } from '@mybonus/ui';

export const FloatWrapper = styled(Column)`
	> * {
		margin-bottom: ${({ theme }) => theme.space.md}px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	align-items: center;
	width: 100%;
	max-width: 500px;
`;

export const $FloatBox = styled(FloatBox)`
	width: 100%;
	padding: 0;
`;
