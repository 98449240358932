import { Text } from '../../../primitives';
import { styled } from '../../../theme';
import { Center } from '../../Center';
import { Paragraph } from '../../Paragraph';

export const Wrapper = styled(Center)`
	flex-direction: column;

	> * {
		margin-bottom: ${({ theme }) => theme.space.lg}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const Description = styled(Paragraph)`
	text-align: center;
`;

export const SummaryBox = styled(Center)`
	border-radius: ${({ theme }) => theme.rounding.md}px;
	padding: ${({ theme }) => theme.space.lg}px;
	background-color: ${({ theme }) => theme.color.lighterGray.base};
`;

export const WithdrawnValue = styled(Text).attrs((props) => ({
	...props,
	size: 'xxl',
	style: 'bold',
	color: props.theme.color.green.base,
}))``;
