import type { ReactNode } from 'react';

import { Wrapper, Track, Child } from './SlideBanner.parts';

export type SlideBannerProps = {
	children: ReactNode[];
	numVisible: number;
	timePerSlide?: number;
};

export function SlideBanner(props: SlideBannerProps & { className?: string }) {
	return (
		<Wrapper className={props.className}>
			<Track
				numVisible={props.numVisible}
				numSlides={props.children.length}
				timePerSlide={props.timePerSlide || 1000}
			>
				{Array.from({ length: 2 }).map((group) =>
					props.children.map((child, i) => (
						<Child key={`${group}-${i}`} i={i} numVisible={props.numVisible}>
							{child}
						</Child>
					)),
				)}
			</Track>
		</Wrapper>
	);
}
