import {
	DownloadAppRow,
	useStoreCountContext,
	ImageAppMockup,
	DownloadPluginButton,
	ImagePluginMacbook,
} from '@mybonus/ui';

import { Wrapper, Image, Content, $CmsContent } from './PlatformSection.parts';

export type PlatformSectionProps = {
	platform: 'APP' | 'EXT' | 'WEB';
};

const platforms = {
	APP: {
		code: 'home_app_intro',
		download: <DownloadAppRow />,
		image: ImageAppMockup,
	},
	EXT: {
		code: 'home_plugin_intro',
		download: <DownloadPluginButton vendor="chrome" />,
		image: ImagePluginMacbook,
	},
	WEB: {
		code: 'home_web_intro',
		download: null,
		image: ImagePluginMacbook,
	},
};

export function PlatformSection(props: PlatformSectionProps) {
	const storeCount = useStoreCountContext();
	const platform = platforms[props.platform];

	return (
		<Wrapper>
			<Content>
				<$CmsContent code={platform.code} replacements={{ storeCount: storeCount.text }} />
				{platform.download}
			</Content>
			<Image uri={platform.image} />
		</Wrapper>
	);
}
