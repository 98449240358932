import { isBrowser, config, type MetaTag } from '@mybonus/public';
import { useSSR, ISSRContext } from '@mybonus/ssr';
import { useEffect } from 'react';

export type HeadInitialOpts = (
	| {
			title?: string;
	  }
	| {
			subtitle?: string;
	  }
) & {
	metaTags?: ISSRContext<unknown>['metaTags'];
};

export function useHead(opts?: HeadInitialOpts) {
	const ssr = useSSR();

	function setTitle(title: string) {
		ssr.title = title;

		if (isBrowser()) {
			document.title = title;
		}
	}

	function setSubtitle(subtitle: string) {
		setTitle(`${config.web.defaultTitle} - ${subtitle}`);
	}

	function handleOptsUpdate() {
		if (opts && 'title' in opts && typeof opts.title === 'string') {
			setTitle(opts.title);
		}

		if (opts && 'subtitle' in opts && typeof opts.subtitle === 'string') {
			setSubtitle(opts.subtitle);
		}
	}

	// Update if changed by the browser (not SSR)
	useEffect(() => {
		handleOptsUpdate();
	}, [opts]);

	// Handle collection of values for SSR
	if (!isBrowser()) {
		handleOptsUpdate();

		opts?.metaTags?.forEach((tag) => {
			ssr.metaTags = ssr.metaTags
				.filter((t) => !(t.type === tag.type && t.key === tag.key)) // Remove matching
				.concat([tag]); // Add it again
		});
	}

	return { setTitle, setSubtitle };
}
