import { Box } from '../../primitives';
import { styled, css } from '../../theme';

export type ContainerProps = {
	width?: 'contained' | 'full' | 'narrow';
	padding?: boolean;
};

export const Container = styled(Box)<ContainerProps>`
	width: 100%;

	${({ width }) => {
		switch (width) {
			case 'full':
				return '';
			case 'narrow':
				return css`
					max-width: 800px;
				`;
			case 'contained':
			default:
				return css`
					max-width: 1200px;
				`;
		}
	}}
	${({ width, padding }) =>
		width !== 'full' && padding !== false
			? css`
					padding: 0 ${({ theme }) => theme.space.md}px;

					@media ${({ theme }) => theme.device.laptop} {
						padding: 0 ${({ theme }) => theme.space.lg}px;
					}
			  `
			: css``}
`;
