import { type ReactNode, useMemo } from 'react';

import { useClickOutside, UseClickOutsideOpts } from '../../hooks';
import { Wrapper, Triangle } from './Popdown.parts';
export { PopdownMountingReference } from './Popdown.parts';

export type PopdownPosition = 'left' | 'right';

export type PopdownProps = {
	open: boolean;
	position?: PopdownPosition;
	positionOffset?: number;
	arrow?: {
		position?: PopdownPosition;
		color?: string;
		px?: number;
	};
	borderRadius?: number;
	onClose: () => void;
	children?: ReactNode;
	exceptRefs?: UseClickOutsideOpts['exceptRefs'];
};

export function Popdown(props: PopdownProps & { className?: string }) {
	const useClickOutsideOpts = useMemo(
		() => ({
			onClose: props.onClose,
			exceptRefs: props.exceptRefs,
		}),
		[props.onClose, props.exceptRefs],
	);
	const ref = useClickOutside(useClickOutsideOpts);

	const { children, className, ...rest } = props;

	return (
		<Wrapper className={className} ref={ref} {...rest}>
			{props.arrow && <Triangle {...rest} />}
			{children}
		</Wrapper>
	);
}
