import { useState, useMemo, useCallback } from 'react';

export type FormValueType = string | number | boolean | undefined;

export type Validation = {
	required?: {
		message: string;
	};
	pattern?: {
		message: string;
		value: string;
	};
	custom?: {
		message: string;
		isValid: (value: unknown) => boolean;
	};
};

type ValidationMap<T> = Partial<Record<keyof T, Validation>>;
type ErrorMap<T> = Partial<Record<keyof T, string>>;

export type Opts<T extends Record<keyof T, unknown>> = {
	initialValues?: Partial<T>;
	validations?: ValidationMap<T>;
	onSubmit?: () => void;
	onChange?: (newData: T) => void;
};

export function useForm<T extends Record<keyof T, unknown>>(opts?: Opts<T>) {
	const [data, setData] = useState<T>((opts?.initialValues || {}) as T);
	const [errors, setErrors] = useState<ErrorMap<T>>({});
	const [isChanged, setIsChanged] = useState(false);

	const hasErrors = useMemo(() => Object.values(errors).filter((e) => e).length > 0, [errors]);

	const handleChange = useCallback(
		(key: keyof T, sanitizeFn?: (value: unknown) => unknown) => (input: unknown) => {
			const value = sanitizeFn ? sanitizeFn(input) : input;

			if (hasErrors) {
				setErrors({
					...errors,
					[key]: undefined,
				});
			}

			const newData = {
				...data,
				[key]: value,
			};

			setData(newData);

			opts?.onChange?.(newData);
			setIsChanged(true);
		},
		[data, errors],
	);

	const validate = useCallback(() => {
		const validations = opts?.validations;

		if (validations) {
			let isValid = true;
			const newErrors: ErrorMap<T> = {};

			for (const key in validations) {
				const value = data[key];
				const validation = validations[key];

				if (
					validation?.required &&
					(value === null ||
						typeof value === 'undefined' ||
						(typeof value === 'string' && value.length === 0))
				) {
					isValid = false;
					newErrors[key] = validation.required.message;
					continue;
				}

				if (validation?.pattern && !RegExp(validation.pattern.value).test(String(value))) {
					isValid = false;
					newErrors[key] = validation.pattern.message;
				}

				if (validation?.custom && !validation.custom.isValid(value)) {
					isValid = false;
					newErrors[key] = validation.custom.message;
				}
			}

			if (!isValid) {
				setErrors(newErrors);
				return false;
			}
		}

		setErrors({});

		return true;
	}, [data]);

	const handleSubmit = useCallback(() => {
		const result = validate();

		if (!result) {
			return;
		}

		if (opts?.onSubmit) {
			opts.onSubmit();
			setIsChanged(false);
		}
	}, [opts?.onSubmit]);

	const reset = useCallback(() => {
		setErrors({});
		setData((opts?.initialValues || {}) as T);
	}, []);

	return {
		data,
		setData,
		validate,
		handleChange,
		handleSubmit,
		errors,
		hasErrors,
		reset,
		isChanged,
	};
}
