import { useStoreCountContext } from '@mybonus/ui';

import { Background, ImageWrapper, Image, Wrapper, $CmsContent } from './HomeTopSection.parts';

export function HomeTopSection() {
	const storeCount = useStoreCountContext();

	return (
		<Background>
			<Wrapper>
				<$CmsContent replacements={{ storeCount: storeCount.text }} />
				<ImageWrapper>
					<Image />
				</ImageWrapper>
			</Wrapper>
		</Background>
	);
}
