import {
	Wrapper,
	Title,
	Description,
	OrderMoreButton,
} from './GiftcardOrderSuccess.parts';

export type GiftcardOrderSuccessProps = {
	onOrderAnother: () => void;
};

export function GiftcardOrderSuccess(props: GiftcardOrderSuccessProps) {
	return (
		<Wrapper>
			<Title />
			<Description>
				Betalningen lyckades. Vi kommer inom kort skicka värdehandlingen till
				dig efter att betalningen har verifierats. Håll utskik i din mailkorg!
			</Description>
			<Description>
				Om du inte fått en bekräftelse efter 4 timmar vänligen kontrollera att
				din e-postadress är korrekt ifylld på ditt konto och kontakta
				kundtjänst.
			</Description>
			<OrderMoreButton onClick={props.onOrderAnother} />
		</Wrapper>
	);
}
