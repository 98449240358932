import { styled, Box, Heading } from '@mybonus/ui';

export const Wrapper = styled(Box)<{ noIndent?: boolean }>`
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-areas:
		'bullet heading'
		'${({ noIndent }) => (noIndent ? 'content' : 'blank')} content';
`;

export const Bullet = styled(Box)`
	grid-area: bullet;

	border-radius: 50px;
	width: 16px;
	height: 16px;
	background-color: ${({ theme }) => theme.color.green.base};
	margin: 18px ${({ theme }) => theme.space.md}px 0 0;
`;

export const HeadingText = styled(Heading).attrs((props) => ({
	...props,
	level: 4,
}))`
	grid-area: heading;
`;

export const Content = styled(Box)`
	grid-area: content;
`;
