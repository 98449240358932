import { styled, FloatBox, Box } from '@mybonus/ui';

export const Layout = styled(Box)`
	display: grid;
	grid-row-gap: ${({ theme }) => theme.space.md}px;
	align-items: start;
	width: 100%;

	@media ${({ theme }) => theme.device.laptop} {
		grid-template-columns: 1fr 2fr;
		grid-gap: ${({ theme }) => theme.space.lg}px;
		grid-template-rows: repeat(8, auto);
	}
`;

export const CashbackListBox = styled(FloatBox)`
	grid-row: 2 / 3;

	@media ${({ theme }) => theme.device.laptop} {
		grid-column: 2 / -1;
		grid-row: 1 / -1;
	}
`;

export const AccountSummaryBox = styled(FloatBox)`
	grid-column: 1 / 1;
	padding: 0;
`;

export const HelpBox = styled(FloatBox)`
	grid-column: 1 / 1;
`;
