import { Box } from '../../primitives';
import { styled, css } from '../../theme';

export type CenterProps = {
	vertical?: boolean;
};

export const Center = styled(Box)<CenterProps>`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	${({ vertical }) =>
		vertical
			? css`
					height: 100%;
			  `
			: ''}
`;
