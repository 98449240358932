import { useState, useEffect, type ReactNode, useRef } from 'react';

import { Base, SpinnerContainer, ContentContainer, $Spinner } from './Waiter.parts';

export type WaiterProps = {
	isLoading: boolean;
	className?: string;
	children?: ReactNode;
	hideSpinner?: boolean;
};

const LONG_WAIT_THRESHOLD = 750; // When to show spinner

export function Waiter(props: WaiterProps) {
	const [isTakingLong, setIsTakingLong] = useState(false);
	const timeoutRef = useRef<any | null>(null);

	useEffect(() => {
		if (props.isLoading && !props.hideSpinner) {
			timeoutRef.current = setTimeout(() => {
				setIsTakingLong(true);
			}, LONG_WAIT_THRESHOLD);

			return () => {
				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current);
				}
			};
		} else {
			if (timeoutRef.current) {
				setIsTakingLong(false);
				clearTimeout(timeoutRef.current);
			}
		}
	}, [props.isLoading]);

	return (
		<Base className={props.className}>
			<ContentContainer isLoading={props.isLoading}>{props.children}</ContentContainer>
			{!props.hideSpinner && (
				<SpinnerContainer show={isTakingLong}>
					<$Spinner />
				</SpinnerContainer>
			)}
		</Base>
	);
}
