import { useEffect } from 'react';

import { useAPI, useMutation, type APIInput, type APIOutput } from '../../api';
import { useAuthContext } from '../../context';
import { useForm, emailValidation, passwordValidation } from '../../hooks';

export function useRegisterForm(opts?: {
	initialData?: { email?: string; recruitmentId?: number };
}) {
	const { api } = useAPI();
	const auth = useAuthContext();

	const mutation = useMutation<
		APIInput['guest']['auth']['credentials']['register'],
		APIOutput['guest']['auth']['credentials']['register']
	>((input) => api.guest.auth.credentials.register.mutate(input));

	const form = useForm<{
		recruitmentCode: string | undefined;
		recruitmentId: number | undefined;
		email: string;
		password: string;
	}>({
		validations: {
			recruitmentCode: {},
			recruitmentId: {},
			email: emailValidation(),
			password: passwordValidation('Lösenordet uppfyllde inte kraven'),
		},
		onSubmit: () => {
			mutation.mutate(form.data);
		},
		initialValues: {
			email: opts?.initialData?.email,
			recruitmentId: opts?.initialData?.recruitmentId,
			password: undefined,
		},
	});

	useEffect(() => {
		if (mutation.data) {
			form.reset();
			auth.login(mutation.data);
		}
	}, [mutation.data]);

	const error = mutation.error || auth.error;

	return { form, mutation, error };
}

export function useVendorConnect() {
	const { api } = useAPI();
	const auth = useAuthContext();
	const mutation = useMutation<
		APIInput['guest']['auth']['vendor']['connect'],
		APIOutput['guest']['auth']['vendor']['connect']
	>((input) => api.guest.auth.vendor.connect.mutate(input));

	function connect(data: APIInput['guest']['auth']['vendor']['connect']) {
		mutation.mutate(data);
	}

	useEffect(() => {
		if (mutation.data) {
			auth.login(mutation.data);
		}
	}, [mutation.data]);

	const error = mutation.error || auth.error;

	return { mutation, error, connect };
}
