import { Text } from '../../../primitives';
import { styled, css } from '../../../theme';
import { Column } from '../../Column';
import { Row } from '../../Row';

export const Wrapper = styled(Column)<{ isLast?: boolean }>`
	> * {
		margin-bottom: ${({ theme }) => theme.space.xs}px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	padding: ${({ theme }) => theme.space.md}px 0
		${({ theme }) => theme.space.md}px;
	${({ isLast }) =>
		!isLast
			? css`
					border-bottom: 1px solid ${({ theme }) => theme.color.lightGray.base};
			  `
			: ''}
`;

export const TitleRow = styled(Row)``;
export const Title = styled(Text).attrs((props) => ({
	...props,
	size: 'lg',
}))``;

export const SubRow = styled(Row)`
	justify-content: space-between;
	align-items: flex-end;
`;

export const Sub = styled(Text).attrs((props) => ({
	...props,
	color: props.theme.color.midGray.base,
	size: 'sm',
}))``;

export const Value = styled(Text).attrs((props) => ({
	...props,
	color: props.theme.color.green.base,
	size: 'lg',
}))``;
