import { Box } from '../../primitives';
import { styled } from '../../theme';

export const ToastList = styled(Box)`
	width: 100%;
	max-width: 500px;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.space.md}px;
`;
