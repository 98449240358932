import { Box, Text } from '../../../primitives';
import { styled } from '../../../theme';
import { Row } from '../../Row';

export const Wrapper = styled(Row)`
	padding: ${({ theme }) => theme.space.sm}px 0;
	align-items: center;
`;

export const Circle = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 500px;
	height: 40px;
	width: 40px;
	background-color: ${({ theme }) => theme.color.lightGreen.base};
	margin-right: ${({ theme }) => theme.space.sm}px;
	flex: 0 0 auto;
`;

export const CircleText = styled(Text).attrs((props) => ({
	...props,
	size: 'lg',
	style: 'bold',
	color: props.theme.color.green.base,
}))``;

export const Content = styled(Box)``;
