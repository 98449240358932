import {
	useAPI,
	useQuery,
	useMutation,
	type APIInput,
	type APIOutput,
	useHead,
} from '@mybonus/ui';

export function useCommunicationSettings() {
	const { api } = useAPI();
	useHead({ subtitle: 'Inställningar' });

	const sms = useQuery<
		APIOutput['user']['sms']['setting']['map'],
		APIInput['user']['sms']['setting']['map']
	>(
		'user.sms.setting.map',
		(args) => api.user.sms.setting.map.query(args),
		undefined,
	);

	const updateSms = useMutation<
		APIInput['user']['sms']['setting']['update'],
		APIOutput['user']['sms']['setting']['update']
	>((args) => api.user.sms.setting.update.mutate(args), {
		onSuccess: () => {
			sms.load({ bypassCache: true, refetch: true });
		},
	});

	function smsOnChange(
		type: APIInput['user']['sms']['setting']['update']['type'],
	) {
		updateSms.mutate({
			type,
			value: sms.data?.[type] === 'ON' ? 'OFF' : 'ON',
		});
	}

	const email = useQuery<
		APIOutput['user']['email']['setting']['map'],
		APIInput['user']['email']['setting']['map']
	>(
		'user.email.setting.map',
		(args) => api.user.email.setting.map.query(args),
		undefined,
	);

	const updateEmail = useMutation<
		APIInput['user']['email']['setting']['update'],
		APIOutput['user']['email']['setting']['update']
	>((args) => api.user.email.setting.update.mutate(args), {
		onSuccess: () => {
			email.load({ bypassCache: true, refetch: true });
		},
	});

	function emailOnChange(
		type: APIInput['user']['email']['setting']['update']['type'],
	) {
		updateEmail.mutate({
			type,
			value: email.data?.[type] === 'ON' ? 'OFF' : 'ON',
		});
	}

	const notification = useQuery<
		APIOutput['user']['notification']['setting']['map'],
		APIInput['user']['notification']['setting']['map']
	>(
		'user.notification.setting.map',
		(args) => api.user.notification.setting.map.query(args),
		undefined,
	);

	const updateNotification = useMutation<
		APIInput['user']['notification']['setting']['update'],
		APIOutput['user']['notification']['setting']['update']
	>((args) => api.user.notification.setting.update.mutate(args), {
		onSuccess: () => {
			notification.load({ bypassCache: true, refetch: true });
		},
	});

	function notificationOnChange(
		type: APIInput['user']['notification']['setting']['update']['type'],
	) {
		updateNotification.mutate({
			type,
			value: notification.data?.[type] === 'ON' ? 'OFF' : 'ON',
		});
	}

	return {
		sms: {
			state: sms.data,
			isLoading: sms.isLoading || updateSms.isLoading,
			onChange: smsOnChange,
		},
		email: {
			state: email.data,
			isLoading: email.isLoading || updateEmail.isLoading,
			onChange: emailOnChange,
		},
		notification: {
			state: notification.data,
			isLoading: notification.isLoading || updateNotification.isLoading,
			onChange: notificationOnChange,
		},
	};
}
