import type { ReactNode } from 'react';

import { Anchor, AnchorProps } from '../../primitives';
import { Paragraph } from '../Paragraph';
import { IconWrapper, $Icon } from './BackAnchor.parts';

export type BackAnchorProps = {
	to: AnchorProps['to'];
	children?: ReactNode;
};

export function BackAnchor(props: BackAnchorProps & { className?: string }) {
	return (
		<Paragraph className={props.className}>
			<Anchor underlined={false} to={props.to}>
				{props.children || (
					<IconWrapper>
						<$Icon name="IconArrowLeftGreen" size="sm" />
						Tillbaka
					</IconWrapper>
				)}
			</Anchor>
		</Paragraph>
	);
}
