import { styled, Box, Waiter } from '@mybonus/ui';

import { IconMenuItem } from './IconMenuItem';

export const Menu = styled(Box)`
	display: flex;
	flex-direction: column;
	padding: ${({ theme }) => theme.space.md + theme.space.sm}px ${({ theme }) => theme.space.md}px;

	> * {
		margin-bottom: ${({ theme }) => theme.space.md}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const IconMenu = styled(Menu)`
	> * {
		margin-right: ${({ theme }) => theme.space.md + theme.space.sm}px;

		&:last-child {
			margin-right: 0;
		}
	}
`;

export const DrawerContent = styled(Box)`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export const NotificationMenuItem = styled(IconMenuItem).attrs((props) => ({
	...props,
	icon: 'IconBellGray',
	text: 'Notiser',
	path: '/account/notifications',
}))``;

export const FavoritesMenuItem = styled(IconMenuItem).attrs((props) => ({
	...props,
	icon: 'IconHeartGray',
	text: 'Mina favoriter',
	path: '/account/favorites',
}))``;

export const SettingsMenuItem = styled(IconMenuItem).attrs((props) => ({
	...props,
	icon: 'IconCogGray',
	text: 'Inställningar',
	path: '/account/settings',
}))``;

export const AccountMenuItem = styled(IconMenuItem).attrs((props) => ({
	...props,
	icon: 'IconUserGray',
	text: 'Mitt konto',
	path: '/account',
}))``;
