import { Text } from '../../primitives';
import { styled, css } from '../../theme';

export const LinkText = styled(Text)<{ active?: boolean }>`
	color: ${({ theme }) => theme.color.darkGray.base};
	transition: color 0.2s ease-in-out;

	&:hover {
		color: ${({ theme }) => theme.color.green.base};
	}

	${({ active }) =>
		active &&
		css`
			color: ${({ theme }) => theme.color.green.base};
		`}
`;
