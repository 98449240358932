import { Box } from '../../../primitives';
import { styled } from '../../../theme';
import { FacebookButton } from '../../FacebookButton';
import { Heading } from '../../Heading';
import { Icon } from '../../Icon';
import { Paragraph } from '../../Paragraph';
import { TextButton } from '../../TextButton';
import { Waiter } from '../../Waiter';
import { CodeCopy } from './CodeCopy';

export const Wrapper = styled(Box)`
	width: 100%;
	padding: ${({ theme }) => theme.space.lg}px;
`;

export const FacebookMessengerButton = styled(TextButton).attrs((props) => ({
	...props,
	variant: 'facebookMessenger',
	iconLeft: <Icon name="IconFacebookMessengerWhite" />,
	text: 'Dela på Messenger',
	size: 'sm',
}))``;

export const FacebookShareButton = styled(FacebookButton).attrs((props) => ({
	...props,
	text: 'Dela på Facebook',
	size: 'sm',
}))``;

export const ShareAlternatives = styled(Box)`
	display: flex;
	flex-direction: column;

	> * {
		margin-bottom: ${({ theme }) => theme.space.md}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const $CodeCopy = styled(CodeCopy)`
	flex: 1 0 33%;
`;

export const SummaryRow = styled(Box)`
	display: flex;
	justify-content: space-between;
	margin-bottom: ${({ theme }) => theme.space.lg}px;

	@media ${({ theme }) => theme.device.mobileM} {
		justify-content: space-around;
	}

	@media ${({ theme }) => theme.device.tablet} {
		flex-direction: row;
		justify-content: space-between;
	}
`;

export const SummaryItem = styled(Box)``;

export const SummaryTitle = styled(Heading).attrs((props) => ({
	...props,
	level: 2,
	color: props.theme.color.green.base,
}))`
	font-size: 42px;
	line-height: 1;
	margin-top: 0;
`;

export const SummarySub = styled(Paragraph)`
	margin-top: 0;
`;

export const ShareText = styled(Paragraph)`
	text-align: center;
`;

export const $Waiter = styled(Waiter)`
	min-height: 100px;
`;
