import {
	styled,
	FloatBox,
	ImagePluginMacbook,
	LocalImage,
	DownloadPluginButton,
} from '@mybonus/ui';
import { Box } from '@mybonus/ui';

export const $FloatBox = styled(FloatBox)`
	display: flex;
	flex-direction: column-reverse;

	@media ${({ theme }) => theme.device.tablet} {
		flex-direction: row;
	}
`;
export const Content = styled(Box)`
	width: 100%;

	@media ${({ theme }) => theme.device.tablet} {
		padding: ${({ theme }) => theme.space.lg}px;
	}
`;
export const Preview = styled(Box)`
	width: 100%;
`;

export const PluginImage = styled(LocalImage).attrs((props) => ({
	...props,
	uri: ImagePluginMacbook,
}))`
	max-width: 100%;
`;

export const $DownloadPluginButton = styled(DownloadPluginButton)`
	margin-top: ${({ theme }) => theme.space.lg}px;
`;
