import {
	FloatBox,
	Heading,
	BackAnchor,
	Column,
	Text,
	Paragraph,
	Anchor,
	styled,
} from '@mybonus/ui';

export const FloatWrapper = styled(Column)`
	> * {
		margin-bottom: ${({ theme }) => theme.space.md}px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	align-items: center;
	width: 100%;
	max-width: 500px;
`;

export const Title = styled(Heading).attrs((props) => ({
	...props,
	level: 1,
}))`
	margin-top: 0;
`;

export const $FloatBox = styled(FloatBox)`
	width: 100%;
`;

export const $BackAnchor = styled(BackAnchor).attrs((props) => ({
	...props,
	to: { local: '/account/settings' },
}))`
	width: 100%;
	margin-left: ${({ theme }) => theme.space.md}px;
`;

export const RemoveAnchor = styled(Anchor).attrs((props) => ({
	...props,
	underlined: false,
}))`
	text-align: center;
	margin-top: ${({ theme }) => theme.space.sm}px;
`;

export const RemoveAnchorText = styled(Text).attrs((props) => ({
	...props,
	color: props.theme.color.red.base,
	decoration: 'underline',
	children: 'Avregistrera företag',
}))``;

export const SuccessMessage = styled(Paragraph).attrs((props) => ({
	...props,
	variant: 'info',
}))`
	text-align: center;
`;
