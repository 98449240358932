import {
	styled,
	FloatBox,
	LogoMyBonusGreen,
	LocalImage,
	Center,
} from '@mybonus/ui';

export const $FloatBox = styled(FloatBox)`
	padding: 0;
	width: 100%;
	max-width: 600px;
`;

const Logo = styled(LocalImage).attrs((props) => ({
	...props,
	uri: LogoMyBonusGreen,
}))`
	height: 20px;
`;

export const Header = styled(Center).attrs((props) => ({
	...props,
	children: <Logo />,
}))`
	padding: ${({ theme }) => theme.space.md}px;
	border-bottom: 1px solid ${({ theme }) => theme.color.lightGray.base};
`;
