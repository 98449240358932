import {
	PageContainer,
	FavoriteList,
	useFavoriteWebNavigation,
	useHead,
	Heading,
	PageTitle,
} from '@mybonus/ui';

import { $FloatBox, FloatWrapper } from './AccountFavoritesView.parts';

export function AccountFavoritesView() {
	const nav = useFavoriteWebNavigation();
	useHead({ subtitle: 'Mina favoriter' });

	return (
		<PageContainer noTopSpace>
			<PageTitle>
				<Heading level={1}>Mina favoriter</Heading>
			</PageTitle>

			<FloatWrapper>
				<$FloatBox>
					<FavoriteList
						onSelect={nav.handleSelect}
						onNoContentClick={nav.handleNoContentClick}
						isVisible={true}
						limit={50}
						grid
					/>
				</$FloatBox>
			</FloatWrapper>
		</PageContainer>
	);
}
