import { RefObject } from 'react';

import { styled, css } from '../../theme';
import type { TextfieldProps } from './Textfield.types';

function Base(
	props: TextfieldProps & {
		className?: string;
		_ref?: RefObject<any>;
	},
) {
	const p = {
		type: props.type || 'text',
		step: props.type === 'number' ? 'any' : undefined,
		onFocus: props.onFocus,
		placeholder: props.placeholder,
		className: props.className,
		ref: props._ref,
		autoComplete: props.autoCompleteNewPassword ? 'new-password' : undefined,
		value: props.value,
		onChange: (e: any) => props.onChange?.(e.target.value),
		disabled: props.disabled,
	};

	if (props.multiline) {
		const { type, step, ...rest } = p;
		return <textarea {...rest} rows={props.multiline.rows}></textarea>;
	}

	return <input {...p} />;
}

export const textfieldPadding = {
	xs: { x: 14, y: 10 },
	sm: { x: 18, y: 12 },
	md: { x: 24, y: 16 },
	lg: { x: 32, y: 20 },
};

export const Textfield = styled(Base)`
	border-radius: ${(props) => {
		const { y } = textfieldPadding[props.size || 'md'];
		return `${y * 2}px`;
	}};
	border: 1px ${(props) => (props.dashed ? 'dashed' : 'solid')}
		${({ theme }) => theme.color.midGray.base};
	transition: border-color 0.5s ease-in-out;
	outline: none;
	margin: 0;

	font-family: ${({ theme }) => theme.font.base};
	font-size: ${(props) =>
		({ theme }) =>
			theme.fontSize[props.size || 'md']}px;
	box-sizing: border-box;

	&:focus::-webkit-input-placeholder {
		color: transparent !important;
	}
	&:focus::-moz-placeholder {
		color: transparent !important;
	}
	&:focus:-moz-placeholder {
		color: transparent !important;
	}

	${(props) => {
		switch (props.variant) {
			case 'primary':
				return css`
					border-color: ${({ theme }) => theme.color.green.base};
				`;
			case 'warning':
				return css`
					border-color: ${({ theme }) => theme.color.yellow.base};
				`;
			case 'error':
				return css`
					border-color: ${({ theme }) => theme.color.red.base};
				`;
			case 'transparent':
				return css`
					border: 0;
				`;
			case 'default':
			default:
		}
	}}

	padding: ${(props) => {
		const { x, y } = textfieldPadding[props.size || 'md'];
		return `${y}px ${x}px`;
	}};
`;
