import type { ReactNode } from 'react';

import { Wrapper, Title, Message } from './NoContent.parts';

export type NoContentProps = {
	title?: string;
	message: string;
	content?: ReactNode;
};

export function NoContent(props: NoContentProps) {
	return (
		<Wrapper>
			<Title>{props.title || 'Här var det tomt!'}</Title>
			<Message>{props.message}</Message>
			{props.content}
		</Wrapper>
	);
}
