import { Box } from '../../primitives';
import { Title } from './CashbackGuideList.parts';
import { CashbackGuideListItem } from './CashbackGuideListItem';

const items = [
	'Pausa eventuella AdBlockers',
	'Tillåt alltid cookies',
	'Använd bara rabattkoder från oss eller som ligger live på butikens hemsida',
	'Besök inte andra sidor efter klick till butik, såsom jämförelse-/rabattkodssidor',
	'Ett helt köp med presentkort ger tyvärr ingen återbäring',
	'Återköp/returer gör att din återbäring kan utebli eller minskas',
	'Var uppmärksam på eventuella särskilda villkor',
];

export function CashbackGuideList() {
	return (
		<Box>
			<Title>För godkänd återbäring</Title>
			{items.map((item, i) => (
				<CashbackGuideListItem key={i}>{item}</CashbackGuideListItem>
			))}
		</Box>
	);
}
