import type { ReactNode, RefObject } from 'react';

import { $Form } from './Form.parts';

export type FormProps = {
	onSubmit: () => void;
	children?: ReactNode;
	_ref?: RefObject<HTMLFormElement>;
};

export function Form(props: FormProps & { className?: string }) {
	return (
		<$Form
			className={props.className}
			onSubmit={(e) => {
				e.preventDefault();
				props.onSubmit();
			}}
			ref={props._ref}
		>
			{props.children}
		</$Form>
	);
}
