import { isBrowser } from '@mybonus/public';
import { useCallback } from 'react';

export function useDataLayer() {
	const push = useCallback((...args: any[]) => {
		if (isBrowser()) {
			const w = window as any;
			w.dataLayer = w.dataLayer || [];
			w.dataLayer.push(...args);
		}
	}, []);

	return {
		push,
	};
}
