import { useAPI, useQuery, APIInput } from '@mybonus/ui';
import { useMemo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// TODO: We may want a <title> here. It may be possible to take the first element from the markdown content (probably h1)
export function usePageView() {
	const params = useParams();
	const navigate = useNavigate();
	const { api } = useAPI();

	const pageInput = useMemo(() => ({ slug: String(params.slug) }), [params.slug]);
	const page = useQuery(
		'guest.cms.page.single',
		(args) => api.guest.cms.page.single.query(args),
		pageInput,
	);

	const tagId = useMemo(() => page.data?.tagId, [page.data]);

	const campaignsInput = useMemo<APIInput['guest']['campaign']['list']>(
		() => ({
			filter: { tagId },
			order: { by: 'PRIORITY', dir: 'ASC' },
		}),
		[tagId],
	);

	const campaigns = useQuery(
		'guest.campaigns.list',
		(args) => api.guest.campaign.list.query(args),
		campaignsInput,
		{ preventAutoload: !tagId },
	);

	useEffect(() => {
		// TODO: We may want the server to give 404 here, so that 3rd party tools
		// can catch 404 and show where the missing link was.
		if (page.data === null) navigate('/404');
	}, [page.data]);

	const hero = page?.data?.hero;

	return { page, hero, campaigns };
}
