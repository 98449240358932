import { z } from 'zod';

import { PlatformAlias } from '../../platform';
import { Duration } from '../../time';
import { stringToDate } from '../../zod-primitives';

export const FieldState = z.discriminatedUnion('id', [
	z.object({
		id: z.literal('emailNewsletter'),
		type: z.literal('BOOL'),
		state: z.object({
			op: z.enum(['TRUTHY', 'FALSY']).optional(),
			value: z.never().optional(),
		}),
	}),
	z.object({
		id: z.literal('visitDate'),
		type: z.literal('DATE'),
		state: z.object({
			op: z.enum(['GT', 'LT']).optional(),
			value: stringToDate.optional(),
		}),
	}),
	z.object({
		id: z.literal('birthDay'),
		type: z.literal('BOOL'),
		state: z.object({
			op: z.enum(['TRUTHY', 'FALSY']).optional(),
			value: z.never().optional(),
		}),
	}),
	z.object({
		id: z.literal('giftcardPurchaser'),
		type: z.literal('BOOL'),
		state: z.object({
			op: z.enum(['TRUTHY']).optional(),
			value: z.never().optional(),
		}),
	}),
	z.object({
		id: z.literal('gender'),
		type: z.literal('SELECT'),
		state: z.object({
			op: z.enum(['INCLUDES', 'EXCLUDES']).optional(),
			value: z.string().array().optional(),
		}),
	}),
	z.object({
		id: z.literal('registeredAt'),
		type: z.literal('DATE'),
		state: z.union([
			z.object({
				op: z.enum(['IN_THE_LAST', 'MORE_THAN']).optional(),
				value: Duration.optional(),
			}),
			z.object({
				op: z.enum(['GT', 'LT']).optional(),
				value: stringToDate.optional(),
			}),
		]),
	}),
	z.object({
		id: z.literal('platforms'),
		type: z.literal('SELECT'),
		state: z.object({
			op: z.literal('INCLUDES').optional(),
			value: PlatformAlias.array().optional(),
		}),
	}),
	z.object({
		id: z.literal('age'),
		type: z.literal('NUMBER'),
		state: z.object({
			op: z.enum(['LT', 'GT']).optional(),
			value: z.number().optional(),
		}),
	}),
	z.object({
		id: z.literal('purchaseCount'),
		type: z.literal('NUMBER'),
		state: z.union([
			z.object({
				op: z.enum(['TRUTHY']).optional(),
				value: z.number().optional(),
			}),
			z.object({
				op: z.enum(['LT', 'GT']).optional(),
				value: z.number().optional(),
			}),
		]),
	}),
	z.object({
		id: z.literal('lastPurchaseDate'),
		type: z.literal('DATE'),
		state: z.union([
			z.object({
				op: z.enum(['IN_THE_LAST', 'MORE_THAN']).optional(),
				value: Duration.optional(),
			}),
			z.object({
				op: z.enum(['GT', 'LT']).optional(),
				value: stringToDate.optional(),
			}),
		]),
	}),
	z.object({
		id: z.literal('purchaseStores'),
		type: z.literal('SELECT'),
		state: z.object({
			op: z.literal('INCLUDES').optional(),
			value: z.string().array().optional(),
		}),
	}),
	z.object({
		id: z.literal('favoriteStores'),
		type: z.literal('SELECT'),
		state: z.object({
			op: z.literal('INCLUDES').optional(),
			value: z.string().array().optional(),
		}),
	}),
	z.object({
		id: z.literal('userCashbackShares'),
		type: z.literal('SELECT'),
		state: z.object({
			op: z.literal('INCLUDES').optional(),
			value: z.string().array().optional(),
		}),
	}),
	z.object({
		id: z.literal('audiences'),
		type: z.literal('SELECT'),
		state: z.object({
			op: z.literal('INCLUDES').optional(),
			value: z.string().array().optional(),
		}),
	}),
	z.object({
		id: z.literal('userId'),
		type: z.literal('NUMBER'),
		state: z.union([
			z.object({
				op: z.enum(['EQ']).optional(),
				value: z.number().optional(),
			}),
			z.object({
				op: z.enum(['INCLUDES']).optional(),
				value: z.number().array().optional(),
			}),
		]),
	}),
]);
export type FieldState = z.infer<typeof FieldState>;
