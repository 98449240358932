import { styled } from '../../theme';
import { Column } from '../Column';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';

export const Wrapper = styled(Column)`
	align-items: center;
	justify-content: center;
	padding: ${({ theme }) => theme.space.lg}px ${({ theme }) => theme.space.md}px;
	text-align: center;
`;

export const Title = styled(Heading).attrs((props) => ({
	...props,
	level: 4,
	color: props.theme.color.gray.base,
}))``;

export const Message = styled(Paragraph).attrs((props) => ({
	...props,
	color: props.theme.color.gray.base,
}))``;
