import {
	styled,
	WhiteBrightenedLoginButton,
	WhiteRegisterButton,
	GreenGradient,
	Text,
	Row,
} from '@mybonus/ui';

export const $GreenGradient = styled(GreenGradient)`
	flex-direction: column;
	display: flex;
	padding: ${({ theme }) => theme.space.md}px;

	> * {
		margin-bottom: ${({ theme }) => theme.space.md}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const ButtonWrapper = styled(Row)`
	> * {
		margin-right: ${({ theme }) => theme.space.md}px;

		&:last-child {
			margin-right: 0;
		}
	}

	align-items: center;
`;

export const $WhiteBrightenedLoginButton = styled(WhiteBrightenedLoginButton)`
	flex: 1;
`;

export const $WhiteRegisterButton = styled(WhiteRegisterButton)`
	flex: 1;
`;

export const $Text = styled(Text).attrs((props) => ({
	...props,
	color: props.theme.color.white.base,
}))`
	text-align: center;
`;
