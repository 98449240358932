import { Text } from '../../../primitives';
import { styled } from '../../../theme';
import { Row } from '../../Row';

export const Wrapper = styled(Row)`
	justify-content: space-between;
`;

export const Description = styled(Text).attrs((props) => ({
	...props,
	color: props.color,
}))``;

export const Amount = styled(Text).attrs((props) => ({
	...props,
	color: props.color || props.theme.color.midGray.base,
}))``;
