import type { Notification } from '../../NotificationList.types';
import { NotificationIcon } from './NotificationTypeIcon.parts';

export type NotificationTypeIconProps = {
	type: Notification['type'];
};

export function NotificationTypeIcon(props: NotificationTypeIconProps): JSX.Element {
	switch (props.type) {
		case 'SUBSCRIBE_REMINDER_CAMPAIGN':
		case 'SUBSCRIBE_STORE_CAMPAIGN_NEW':
		case 'SUBSCRIBE_STORE_CAMPAIGN_EXPIRE':
		case 'ACTIVATION_STORE_CAMPAIGN_NEW':
		case 'ACTIVATION_STORE_CAMPAIGN_EXPIRE':
			return <NotificationIcon name="IconRingBellWhite" />;
		case 'CASHBACK_UPDATE_PENDING':
		case 'CASHBACK_UPDATE_COLLECTIBLE':
		case 'CASHBACK_UPDATE_COLLECTED':
		case 'BONUS_BUDDY_NEW_CASHBACK':
			return <NotificationIcon name="IconPigWhite" />;
		case 'SUPPORT_CLAIM_REGISTERED':
		case 'CASHBACK_UPDATE_REJECTED':
		case 'WITHDRAWAL_UPDATE_FAILED':
			return <NotificationIcon name="IconInfoGreen" />;
		case 'USER_LIFE_CYCLE_INACTIVITY':
		case 'USER_LIFE_CYCLE_INSTALL_APP':
		case 'USER_LIFE_CYCLE_INSTALL_EXT':
		case 'USER_LIFE_CYCLE_APP_INSTALLED':
		case 'USER_LIFE_CYCLE_EXT_INSTALLED':
		case 'USER_LIFE_CYCLE_WELCOME_BONUS_REMINDER':
		case 'USER_LIFECYCLE_RECRUITMENT_REMINDER':
		case 'ANNOUNCEMENT_ANNOUNCEMENT':
		case 'MARKETING_TARGETED':
		case 'BONUS_BUDDY_NEW_BUDDY':
			return <NotificationIcon name="IconAnnouncementWhite" />;
	}
}
