import { useState } from 'react';

import { useNotificationContext } from '../../context';
import { IconButton } from '../IconButton';
import { PopdownMountingReference } from '../Popdown';
import { CountCircle, CountText, $Popdown, $NotificationList } from './NotificationPill.parts';

export function NotificationPill(props: { className?: string }) {
	const notification = useNotificationContext();
	const [open, setOpen] = useState(false);

	function handleSelect() {
		setOpen(false);
	}

	return (
		<PopdownMountingReference className={props.className}>
			<IconButton name="IconBellWhite" onClick={() => setOpen(!open)} size="xl">
				{typeof notification.unreadCount === 'number' && notification.unreadCount > 0 && (
					<CountCircle unreadCount={notification.unreadCount}>
						<CountText>
							{notification.unreadCount && notification.unreadCount > 99
								? '99+'
								: notification.unreadCount}
						</CountText>
					</CountCircle>
				)}
			</IconButton>
			<$Popdown
				open={open}
				onClose={() => setOpen(false)}
				position="left"
				arrow={{ position: 'right', px: 20 }}
			>
				<$NotificationList isVisible={open} onSelect={handleSelect} />
			</$Popdown>
		</PopdownMountingReference>
	);
}
