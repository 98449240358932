import { useEffect, useMemo, useState } from 'react';

import { APIOutput, useAPI, useQuery, type APIInput } from '../../api';
import { usePoll, useRedirect } from '../../hooks';
import type { PaymentProps } from './Payment';

const DEFAULT_TIMEOUT_MS = 40000;

export function usePayment(props: PaymentProps) {
	const { data, paymentRequestId } = props;

	const redirect = useRedirect();
	const { api } = useAPI();
	const input = useMemo(() => ({ paymentRequestId }), [paymentRequestId]);
	const [timeoutMs, setTimeoutMs] = useState(DEFAULT_TIMEOUT_MS);

	const status = useQuery<
		APIOutput['user']['payment']['status'],
		APIInput['user']['payment']['status']
	>('user.payment.status', (args) => api.user.payment.status.query(args), input);

	const poll = usePoll(() => status.load({ bypassCache: true, refetch: true }), {
		intervalMs: 3000,
		timeoutMs: timeoutMs,
	});

	useEffect(() => {
		if ('url' in data) {
			redirect.toURL(data.url);
		} else if ('paymentRequestToken' in data) {
			setTimeoutMs(1000 * 60 * 10); // Increase timeout since Swish may be opened on another device
			redirect.toApp({
				uri: `swish://paymentrequest?token=${data.paymentRequestToken}&callbackurl=${data.callbackUrl}`,
			});
		}

		poll.start();
	}, [data]);

	const paymentStatus = useMemo(() => status.data?.status || 'PENDING', [status.data]);

	useEffect(() => {
		if (paymentStatus !== 'PENDING') {
			poll.stop();
		}
	}, [paymentStatus]);

	function reset() {
		setTimeoutMs(DEFAULT_TIMEOUT_MS);
		poll.reset();
		status.reset();
	}

	return {
		isPending: paymentStatus === 'PENDING',
		paymentStatus,
		isTimeout: poll.isTimeout,
		reset,
	};
}
