import { getUUID } from '@mybonus/public';
import constate from 'constate';
import { useState } from 'react';

export type ToastOpts = {
	type: 'success' | 'warning' | 'error';
	timeout: number | 'never';
	message: string;
	details?: string;
};

export type ToastInstance = {
	opts: ToastOpts;
	time: number;
	id: string;
	isDismissing: boolean;
};

export type UseToastsOpts = {
	animationDuration?: number;
};

function useToasts(cfg: UseToastsOpts) {
	const animationDuration = cfg.animationDuration || 500;
	const [toasts, setToasts] = useState<ToastInstance[]>([]);

	function addToast(opts: ToastOpts) {
		const newToast = {
			time: Date.now(),
			id: getUUID(),
			opts,
			isDismissing: false,
		};

		setToasts((prevToasts) => [...prevToasts, newToast]);

		if (opts.timeout !== 'never') {
			setTimeout(() => dismissToast(newToast.id), opts.timeout);
		}
	}

	function dismissToast(id: string) {
		setToasts((prevToasts) =>
			prevToasts.map((t) => ({
				...t,
				isDismissing: t.id === id,
			})),
		);

		setTimeout(() => {
			setToasts((prevToasts) => prevToasts.filter((t) => t.id !== id));
		}, animationDuration);
	}

	return {
		list: toasts,
		addToast,
		dismissToast,
		animationDuration,
	};
}

export const [ToastsProvider, useToastsContext] = constate(useToasts);
