import { z } from 'zod';

import { DeviceId } from './device';
import { stringToNumber } from './zod-primitives';

export const AccountType = z.enum(['USER', 'ADMIN']);
export type AccountType = z.infer<typeof AccountType>;

export const UserAccount = z.object({
	type: z.literal('USER'),
	id: z.number(),
});
export type UserAccount = z.infer<typeof UserAccount>;

export const AdminAccount = z.object({
	type: z.literal('ADMIN'),
	id: z.number(),
	roles: z.string().array(),
	extra: z
		.object({
			mfa: z.boolean().optional(),
		})
		.optional(),
});
export type AdminAccount = z.infer<typeof AdminAccount>;

export const Account = z.union([UserAccount, AdminAccount]);
export type Account = z.infer<typeof Account>;

export const AccessGrant = z.object({
	deviceId: DeviceId,
	accessToken: z.string(),
	refreshToken: z.string(),
	otpSecret: z.string().optional(),
});
export type AccessGrant = z.infer<typeof AccessGrant>;

export const TokenPayloadExtraData = z.object({
	mfa: z.boolean().optional(),
});
export type TokenPayloadExtraData = z.infer<typeof TokenPayloadExtraData>;

export const TokenPayload = z.union([
	z.object({
		type: z.literal('USER'),
		id: stringToNumber,
		extra: TokenPayloadExtraData.optional(),
	}),
	z.object({
		type: z.literal('ADMIN'),
		id: stringToNumber,
		roles: z.string().array(),
		extra: TokenPayloadExtraData.optional(),
	}),
]);
export type TokenPayload = z.infer<typeof TokenPayload>;

export const AuthVendorType = z.enum(['APPLE', 'FACEBOOK']);
export type AuthVendorType = z.infer<typeof AuthVendorType>;

export const JWTData = z.object({
	exp: z.number(),
	iat: z.number(),
	unique: z.string(),
	data: TokenPayload,
});
export type JWTData = z.infer<typeof JWTData>;
