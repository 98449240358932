import { useAPI, useMutation, type APIInput } from '../../api';
import { useForm, emailValidation } from '../../hooks';

export function useRecoverForm(email: string) {
	const { api } = useAPI();

	const mutation = useMutation<
		APIInput['guest']['auth']['credentials']['recover']
	>((input) => api.guest.auth.credentials.recover.mutate(input));

	const form = useForm<{ email: string }>({
		initialValues: {
			email,
		},
		validations: {
			email: emailValidation(),
		},
		onSubmit: () => {
			mutation.mutate({ email: form.data.email });
		},
	});

	return { form, mutation };
}
