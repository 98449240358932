import type { CashbackLog } from '@mybonus/public';
import { memo } from 'react';
import { useTheme } from 'styled-components';

import type { Theme } from '../../../theme';
import { Title, Wrapper, ValueRow, PurchaseValue, CashbackValue } from './ListItem.parts';
import { Progress } from './Progress';

export type ListItemProps = {
	item: CashbackLog;
};

export const ListItem = memo(function ListItem(props: ListItemProps) {
	const { item } = props;
	const theme = useTheme() as Theme;

	const isCollected = item.status === 'COLLECTED';
	const textStyle = isCollected ? { color: theme.color.midGray.base } : undefined;
	const titleText = [item.store?.name || 'Återbäring', !!item.message && `(${item.message})`]
		.filter(Boolean)
		.join(' ');

	return (
		<Wrapper>
			<Title {...textStyle}>{titleText}</Title>
			<ValueRow>
				<PurchaseValue {...textStyle}>{item.purchaseValue}</PurchaseValue>
				<CashbackValue
					{...textStyle}
					color={item.status === 'DENIED' ? theme.color.red.base : textStyle?.color}
				>
					{item.cashbackValue}
				</CashbackValue>
			</ValueRow>
			<Progress item={item} />
		</Wrapper>
	);
});
