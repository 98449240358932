import { useAPI, useQuery, useAuthContext, useHead } from '@mybonus/ui';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export function useRedirectView() {
	const { api } = useAPI();
	const params = useParams();
	const auth = useAuthContext();
	const head = useHead();

	const storeInput = useMemo(() => ({ id: Number(params.id) }), [params.id]);
	const storeQuery = useQuery(
		'guest.store.single',
		(args) => api.guest.store.single.query(args),
		storeInput,
	);

	const store = storeQuery.data;

	if (store) {
		head.setSubtitle(`Slussar dig till ${store.name}`);
	}

	return {
		store,
		auth,
		link: params.link,
	};
}
