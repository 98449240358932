import {
	styled,
	Center,
	Logo,
	Row,
	Column,
	Anchor,
	Icon,
	Paragraph,
	Separator,
	DownloadAppRow,
} from '@mybonus/ui';

const FooterLogo = styled(Logo)`
	width: 150px;
`;

export const LogoLink = styled(Anchor).attrs((props) => ({
	...props,
	to: { local: '/' },
	children: <FooterLogo />,
}))`
	margin-bottom: ${({ theme }) => theme.space.md}px;
`;

export const Wrapper = styled(Center)`
	background-color: ${({ theme }) => theme.color.darkGray.base};
	width: 100%;
	padding: ${({ theme }) => theme.space.xl}px 0;
`;

export const AboutColumn = styled(Column)`
	@media ${({ theme }) => theme.device.laptop} {
		padding-right: ${({ theme }) => theme.space.xl}px;
	}
`;

export const LinksColumn = styled(Column)`
	@media ${({ theme }) => theme.device.laptop} {
		padding: 0 ${({ theme }) => theme.space.lg}px;
	}
`;

export const LinkList = styled(Column)`
	margin-right: ${({ theme }) => theme.space.sm}px;
`;

export const $Anchor = styled(Anchor).attrs((props) => ({
	...props,
	underlined: false,
}))`
	color: ${({ theme }) => theme.color.darkGray.base};
	display: block;
	margin-bottom: ${({ theme }) => theme.space.sm}px;
`;

const SocialAnchor = styled(Anchor).attrs((props) => ({
	...props,
}))`
	margin-right: ${({ theme }) => theme.space.md}px;
`;

export const InstagramAnchorIcon = styled(SocialAnchor).attrs((props) => ({
	...props,
	children: <Icon name="IconInstagramCircleWhite" size="xxxl" />,
	to: { url: 'https://www.instagram.com/mybonussweden', newTab: true },
}))``;

export const FacebookAnchorIcon = styled(SocialAnchor).attrs((props) => ({
	...props,
	children: <Icon name="IconFacebookCircleWhite" size="xxxl" />,
	to: { url: 'https://www.facebook.com/MyBonusSweden', newTab: true },
}))``;

export const SocialRow = styled(Row)`
	margin: ${({ theme }) => theme.space.md}px 0;
`;

export const $Separator = styled(Separator)`
	border-color: ${({ theme }) => theme.color.lightGray.base};
	margin: ${({ theme }) => theme.space.lg}px 0;
`;

export const $DownloadAppRow = styled(DownloadAppRow).attrs((props) => ({
	...props,
	size: 'sm',
	themeName: 'invert',
}))`
	margin-top: ${({ theme }) => theme.space.md}px;
`;

export const Copyright = styled(Paragraph)`
	text-align: center;
	font-size: ${({ theme }) => theme.fontSize.sm}px;
`;

export const InfoRow = styled(Row)`
	flex-direction: column;

	@media ${({ theme }) => theme.device.laptop} {
		flex-direction: row;
	}
`;
