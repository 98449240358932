import { Box } from '../../primitives';
import { styled } from '../../theme';
import { Center } from '../Center';
import { Paragraph } from '../Paragraph';

export const Wrapper = styled(Box)``;

export const WarningParagraph = styled(Paragraph).attrs((props) => ({
	...props,
	variant: 'error',
	style: 'bold',
}))``;

export const Label = styled(Paragraph).attrs((props) => ({
	...props,
}))`
	margin-left: ${({ theme }) => theme.space.sm}px;
	margin-top: ${({ theme }) => theme.space.sm}px;
`;

export const SuccessWrapper = styled(Center)`
	margin-left: ${({ theme }) => theme.space.md}px;
	flex-direction: column;
`;
