import { Box } from '../../primitives';
import { styled, css } from '../../theme';
import { createHeadingCSS } from '../Heading';
import { Paragraph, ParagraphCSS } from '../Paragraph';
import { Waiter } from '../Waiter';

// TODO: This is not really needed now when we have markdown support!
export const DivWithInjectedStyle = styled.div`
	h1 {
		${({ theme }) => createHeadingCSS(theme, 1)}
	}

	h2 {
		${({ theme }) => createHeadingCSS(theme, 2)}
	}

	${ParagraphCSS}

	p {
		${ParagraphCSS}
	}
`;

export const BulletListParagraph = styled(Paragraph)`
	margin-top: 0;
`;

const noTopSpace = css`
	> * {
		&:first-child {
			margin-top: 0;
		}
	}
`;

export const MarkdownWrapper = styled(Box)`
	${noTopSpace}
`;

export const CodeWrapper = styled(Waiter)`
	${noTopSpace}
`;
