import { z } from 'zod';

import { LocalizedText } from './internationalization';

export const SingleFaqGroup = z.object({
	id: z.number(),
	name: LocalizedText,
	prio: z.number(),
	active: z.number(),
});
export type SingleFaqGroup = z.infer<typeof SingleFaqGroup>;

export const ListFaqGroup = SingleFaqGroup.extend({
	name: z.string(),
});
export type ListFaqGroup = z.infer<typeof ListFaqGroup>;

export const SingleFaqItem = z.object({
	id: z.number(),
	question: LocalizedText,
	answer: LocalizedText,
	groupId: z.number().nullable(),
	prio: z.number(),
	active: z.number(),
});
export type SingleFaqItem = z.infer<typeof SingleFaqItem>;

export const ListFaq = SingleFaqItem.extend({
	question: z.string(),
	answer: z.string(),
});
export type ListFaq = z.infer<typeof ListFaq>;

export const ListFaqGroupWithItems = ListFaqGroup.extend({
	items: ListFaq.array(),
});
export type ListFaqGroupWithItems = z.infer<typeof ListFaqGroupWithItems>;
