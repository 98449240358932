import superjson from 'superjson';

export function tee<T>(items: T[], fn: (item: T) => boolean | number): [T[], T[]] {
	return items.reduce<[T[], T[]]>(
		(acc, item) => {
			acc[Number(Boolean(fn(item)))]!.push(item);
			return acc;
		},
		[[], []],
	);
}

export function snakeCaseToHuman(str: string): string {
	const parts = str.split('_');

	return parts.map((p) => p.charAt(0).toUpperCase() + p.substring(1).toLowerCase()).join(' ');
}

export function toPascalCase(str: string): string {
	return str
		.split(' ')
		.map((w) => w[0]!.toUpperCase() + w.substring(1).toLowerCase())
		.join('');
}

export function mustacheReplace(text: string, values: Record<string, string> = {}): string {
	return Object.keys(values).reduce((result, key) => {
		return replaceAll(result, `{{${key}}}`, values[key]!);
	}, text);
}

export function replaceAll(str: string, find: string, replace: string): string {
	return str.replace(new RegExp(find, 'g'), replace);
}

export function splitLines(input: string): string[] {
	return input.split(/\r?\n/);
}

export function hashString(str: string): string {
	let h = 0;
	let i = str.length;

	while (i) {
		h = ((h << 5) - h + str.charCodeAt(i--)) | 0;
	}

	return (h >>> 0) as unknown as string;
}

export function hashObject(query: unknown): string {
	return hashString(JSON.stringify(query) || '');
}

export function truncate(input: string, maxLength: number): string {
	return input.length > maxLength ? input.slice(0, maxLength - 3) + '...' : input;
}

export function cloneDeep<DataT>(data: DataT): DataT {
	return superjson.parse(superjson.stringify(data));
}

const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
const p = new RegExp(a.split('').join('|'), 'g');

// TODO: We may want to store the slug to avoid having to compute lot's of these when listing stores
// for example.
export function slugify(value: string): string {
	return value
		.toLowerCase()
		.replace(p, (c) => b.charAt(a.indexOf(c)))
		.replace(/[^\w .]+/g, '')
		.replace(/[ .]+/g, '-');
}

export function formatMetricDisplay(value: number): string {
	if (value > 1000000) {
		return `${Math.floor(value / 1000000)}M+`;
	} else if (value > 100000) {
		return `${Math.floor(value / 10000) * 10}k+`;
	} else if (value > 10000) {
		return `${Math.floor(value / 1000)}k+`;
	} else if (value > 1000) {
		return `${Math.floor(value / 100) * 100}+`;
	} else if (value > 100) {
		return `${Math.floor(value / 10) * 10}+`;
	} else {
		return String(value);
	}
}

export function escapeHtml(text: string) {
	const map = {
		'&': '&amp;',
		'<': '&lt;',
		'>': '&gt;',
		'"': '&quot;',
		"'": '&#039;',
	};

	return text.replace(/[&<>"']/g, (m) => map[m as keyof typeof map]);
}
