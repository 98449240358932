import * as Icons from '../../assets/icons';
import { LocalImage } from '../../primitives';
import { styled } from '../../theme';

export const iconSize = {
	xs: 8,
	sm: 12,
	md: 16,
	lg: 18,
	xl: 26,
	xxl: 32,
	xxxl: 42,
};

export type IconProps = {
	name: keyof typeof Icons;
	size?: keyof typeof iconSize;
	className?: string;
};

function Base(props: IconProps) {
	return <LocalImage uri={Icons[props.name]} className={props.className} />;
}

export const Icon = styled(Base)`
	height: ${(props) => iconSize[props.size || 'md']}px;
`;
