import { validateClaimTime, parseUserDateTimeInput, errorMessages } from '@mybonus/public';
import { useMemo, useEffect } from 'react';

import { useAPI, useMutation, useQuery, type APIInput, type APIOutput } from '../../../api';
import {
	useForm,
	dateValidation,
	timeValidation,
	amountValidation,
	useScrollToTop,
} from '../../../hooks';

const OTHER_STORE_ID = -1;

export function useClaimForm(onCreate: (trackEmail: string) => void) {
	const { api } = useAPI();
	const { scrollToTop } = useScrollToTop();

	const claimStores = useQuery(
		'user.claim.store.list',
		(args) => api.user.claim.store.list.query(args),
		undefined,
	);

	const storeOptions = useMemo(
		() =>
			(claimStores.data || [])
				.map((s) => ({ id: s.id, label: s.name }))
				.concat([{ id: OTHER_STORE_ID, label: 'Annan butik' }]),
		[claimStores.data],
	);

	const discountOptions = useMemo(
		() => [
			{ id: 'NO', label: 'Nej' },
			{ id: 'YES_MYBONUS', label: 'Ja, via MyBonus' },
			{ id: 'YES_STORE', label: 'Ja, via butikens hemsida' },
			{ id: 'YES_OTHER', label: 'Ja, på annat håll' },
		],
		[],
	);

	const giftcardOptions = useMemo(
		() => [
			{ id: 'NO', label: 'Nej' },
			{ id: 'YES', label: 'Ja' },
		],
		[],
	);

	const mutation = useMutation<
		APIInput['user']['claim']['create'],
		APIOutput['user']['claim']['create']
	>((input) => api.user.claim.create.mutate(input), {
		onSuccess: ({ data }) => {
			if (data?.trackEmail) {
				scrollToTop();
				onCreate(data.trackEmail);
			}

			form.reset();
		},
	});

	const form = useForm<{
		storeId: number;
		discountUsed: APIInput['user']['claim']['create']['discountUsed'] & 'YES_OTHER';
		giftcardUsed: APIInput['user']['claim']['create']['giftcardUsed'];
		date: string;
		time: string;
		orderNumber: string;
		purchaseSum: number;
		message: string | undefined;
		hasConfirmationReady: boolean;
		hasReadSpecialTerms: boolean | undefined;
	}>({
		validations: {
			storeId: {
				required: {
					message: 'Du måste välja en butik',
				},
				custom: {
					message: 'Du måste välja en av butikerna i listan',
					isValid: (value) => value !== `${OTHER_STORE_ID}`,
				},
			},
			discountUsed: {
				required: {
					message: 'Du måste ange om du använt rabattkod',
				},
				custom: {
					message: 'Du kan inte få återbäring om du har använt en rabattkod från en annan tjänst.',
					isValid: (value) => value !== 'YES_OTHER',
				},
			},
			giftcardUsed: {
				required: {
					message: 'Du måste ange om du använt presentkort',
				},
			},
			date: dateValidation(),
			time: timeValidation(),
			orderNumber: {
				required: {
					message: 'Du måste ange ordernummer',
				},
			},
			purchaseSum: amountValidation(),
			message: {},
			hasConfirmationReady: {},
			hasReadSpecialTerms: {},
		},
		onSubmit: () => {
			if (!purchaseDate) {
				return;
			}

			mutation.mutate({
				...form.data,
				storeId: Number(form.data.storeId),
				discountUsed: form.data.discountUsed,
				giftcardUsed: form.data.giftcardUsed,
				purchaseDate,
				purchaseSum: {
					currency: 'SEK',
					value: Number(form.data.purchaseSum),
				},
			});
		},
		onChange: () => {
			mutation.reset();
		},
	});

	useEffect(() => {
		if (form.data.storeId === OTHER_STORE_ID) {
			alert(
				'Tyvärr kan man enbart skicka in en förfrågan gällande butiker som man har besökt genom MyBonus.',
			);
		}
	}, [form.data.storeId]);

	useEffect(() => {
		if (form.data.discountUsed === 'YES_OTHER') {
			alert('Du kan inte få återbäring om du har använt en rabattkod från en annan tjänst.');
		}
	}, [form.data.discountUsed]);

	useEffect(() => {
		if (form.data.giftcardUsed === 'YES') {
			alert('Det kan hända att butiken nekar återbäring på köp som har gjorts med presentkort.');
		}
	}, [form.data.giftcardUsed]);

	const purchaseDate = useMemo(() => {
		if (form.data.date && form.data.time) {
			const c = `${form.data.date} ${form.data.time}`;

			if (c.length === 16) {
				return parseUserDateTimeInput(c);
			}
		}

		return null;
	}, [form.data.date, form.data.time]);

	useEffect(() => {
		if (purchaseDate) {
			try {
				validateClaimTime(purchaseDate);
			} catch (_err) {
				const err = _err as Error;

				if (err.name === 'ClaimTimeExpiredError') {
					alert(errorMessages.ClaimTimeExpiredError);
					return;
				} else if (err.name === 'ClaimTimeNotActiveError') {
					alert(errorMessages.ClaimTimeNotActiveError);
					return;
				} else {
					console.warn('Error name did not match', { err, name: err?.name });
					throw _err;
				}
			}
		}
	}, [purchaseDate]);

	const selectedStore = useMemo(() => {
		return claimStores.data?.find((s) => s.id === form.data.storeId);
	}, [claimStores.data, form.data.storeId]);

	function getFieldVariant(fieldKey: keyof typeof form.data): 'error' | undefined {
		return form.errors[fieldKey] ? 'error' : undefined;
	}

	const error = mutation.error || (form.hasErrors && 'Se över de rödmarkerade fälten ovan');

	return {
		error,
		form,
		mutation,
		getFieldVariant,
		storeOptions,
		discountOptions,
		giftcardOptions,
		selectedStore,
		trackEmail: mutation.data?.trackEmail,
	};
}
