import { SwitchSize, Slider, Dot, Wrapper } from './SwitchButton.parts';

export type SwitchButtonProps = {
	value: boolean;
	onChange?: () => void;
	disabled?: boolean;
	size?: SwitchSize;
};

export function SwitchButton(props: SwitchButtonProps) {
	const { onChange, ...rest } = props;

	return (
		<Wrapper onClick={onChange} {...rest}>
			<Slider {...rest}>
				<Dot {...rest} />
			</Slider>
		</Wrapper>
	);
}
