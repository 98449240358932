import type { ThemeName } from '@mybonus/public';

import { DownloadAppButton } from '../DownloadAppButton';
import { Wrapper } from './DownloadAppRow.parts';

export type DownloadAppRowProps = {
	size?: 'sm' | 'md';
	themeName?: ThemeName;
};

export function DownloadAppRow(props: DownloadAppRowProps & { className?: string }) {
	const { className, ...rest } = props;

	return (
		<Wrapper className={className}>
			<DownloadAppButton vendor="ios" {...rest} />
			<DownloadAppButton vendor="android" {...rest} />
		</Wrapper>
	);
}
