import { config } from '@mybonus/public';
import {
	styled,
	FloatBox,
	Heading,
	Paragraph,
	Anchor,
	Center,
} from '@mybonus/ui';

export const Title = styled(Heading).attrs((props) => ({
	...props,
	level: 1,
}))``;

export const $Center = styled(Center)`
	flex-direction: column;
	text-align: center;
`;

export const $FloatBox = styled(FloatBox)`
	max-width: 600px;
`;

export const Description = styled(Paragraph)``;

export const CustomerServiceAnchor = styled(Anchor).attrs((props) => ({
	...props,
	children: config.customerServiceEmail,
	to: { url: `mailto:${config.customerServiceEmail}` },
}))``;
