import { Box, Gradient } from '../../primitives';
import { styled, css } from '../../theme';
import type { CollapsibleProps } from './Collapsible';

type PartsProps = Omit<CollapsibleProps, 'children'> & {
	isHintEnabled: boolean;
};

export const Wrapper = styled(Box)<PartsProps>`
	overflow: hidden;
	position: relative;
	transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;

	max-height: ${({ collapsed, isHintEnabled, hintPx }) =>
		collapsed ? (isHintEnabled ? `${hintPx || 0}px` : 0) : `1000px`};

	opacity: ${({ collapsed, isHintEnabled }) =>
		isHintEnabled ? 1 : collapsed ? 0 : 1};
`;

export const Fade = styled(Gradient).attrs((props) => ({
	...props,
	colors: [props.theme.color.white.base, props.theme.color.transparent.base],
	angle: 360,
}))<PartsProps>`
	position: absolute;
	display: flex;
	align-items: flex-end;
	bottom: 0;
	z-index: 1;
	transition: opacity 0.2s ease-in-out;
	width: 100%;
	height: ${({ fadePx }) => fadePx || 0}px;

	${({ collapsed }) =>
		collapsed
			? css`
					opacity: 1;
			  `
			: css`
					opacity: 0;
					pointer-events: none;
			  `}
`;
