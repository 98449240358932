import { config } from '@mybonus/public';
import {
	PageContainer,
	Jumbotron,
	ImageQuestionPeople,
	ClaimFloatBox,
	useAuthContext,
	useHead,
} from '@mybonus/ui';
import { useNavigate } from 'react-router-dom';

import {
	HelpCenterBox,
	FloatBoxWrapper,
	HelpCenterButton,
	HelpCenterText,
} from './SupportView.parts';

export function SupportView() {
	const navigate = useNavigate();
	const auth = useAuthContext();
	useHead({ subtitle: 'Kundservice' });

	return (
		<PageContainer>
			<FloatBoxWrapper>
				<Jumbotron
					title="Kontakta kundservice"
					subtitle="Du är alltid välkommen att höra av dig till oss om du inte hittar svaret på din fråga eller om du behöver ytterligare hjälp."
					imageUri={ImageQuestionPeople}
				>
					<HelpCenterBox>
						<HelpCenterButton />
						<HelpCenterText />
					</HelpCenterBox>
				</Jumbotron>

				{!!auth.account && (
					<ClaimFloatBox onClaimButtonClick={() => navigate(config.urls.claim())} />
				)}
			</FloatBoxWrapper>
		</PageContainer>
	);
}
