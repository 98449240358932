import { z } from 'zod';

export const MessageTypeCode = z.enum([
	'USER_LIFE_CYCLE_INACTIVITY',
	'USER_LIFE_CYCLE_INSTALL_APP',
	'USER_LIFE_CYCLE_INSTALL_EXT',
	'USER_LIFE_CYCLE_APP_INSTALLED',
	'USER_LIFE_CYCLE_EXT_INSTALLED',
	'USER_LIFE_CYCLE_WELCOME_BONUS_REMINDER',
	'USER_LIFECYCLE_RECRUITMENT_REMINDER',
	'SUBSCRIBE_REMINDER_CAMPAIGN',
	'SUBSCRIBE_STORE_CAMPAIGN_NEW',
	'SUBSCRIBE_STORE_CAMPAIGN_EXPIRE',
	'ACTIVATION_STORE_CAMPAIGN_NEW',
	'ACTIVATION_STORE_CAMPAIGN_EXPIRE',
	'CASHBACK_UPDATE_PENDING',
	'CASHBACK_UPDATE_COLLECTIBLE',
	'CASHBACK_UPDATE_COLLECTED',
	'CASHBACK_UPDATE_REJECTED',
	'BONUS_BUDDY_NEW_BUDDY',
	'BONUS_BUDDY_NEW_CASHBACK',
	'SUPPORT_CLAIM_REGISTERED',
	'ANNOUNCEMENT_ANNOUNCEMENT',
	'WITHDRAWAL_UPDATE_FAILED',
	'MARKETING_TARGETED',
]);

export type MessageTypeCode = z.infer<typeof MessageTypeCode>;
