import { z } from 'zod';

import { LocalizedText } from './internationalization';
import { ThemeName } from './theme';

export const ListHero = z.object({
	id: z.number(),
	name: z.string(),
	active: z.boolean(),
	activeFrom: z.date().nullable(),
	activeTo: z.date().nullable(),
});
export type ListHero = z.infer<typeof ListHero>;

export const SingleHero = z.object({
	id: z.number(),
	theme: ThemeName,
	headline: z.string(),
	description: z.string().nullable(),
	button: z
		.object({
			label: z.string(),
			url: z.string(),
		})
		.nullable(),
	coverUrl: z.object({
		desktop: z.string().nullable(),
		mobile: z.string().nullable(),
	}),
});
export type SingleHero = z.infer<typeof SingleHero>;

export const SingleAdminHero = z.object({
	id: z.number(),
	headline: LocalizedText,
	description: LocalizedText,
	button: z.object({
		label: LocalizedText,
		url: z.string(), // Should be localized as well?
	}),
	coverUrl: z.object({
		desktop: z.number().nullable(),
		mobile: z.number().nullable(),
	}),
});
export type SingleAdminHero = z.infer<typeof SingleAdminHero>;
