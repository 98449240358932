import { useTheme } from 'styled-components';

import type { TextProps } from '../../primitives';
import type { Theme } from '../../theme';
import { Base } from './Paragraph.parts';

export { ParagraphCSS } from './Paragraph.parts';

export type ParagraphProps = TextProps & {
	variant?: 'error' | 'warning' | 'info';
};

export function Paragraph(props: ParagraphProps & { className?: string }) {
	const theme = useTheme() as Theme;

	const paragraphColors = {
		error: theme.color.red.base,
		warning: theme.color.yellow.base,
		info: theme.color.green.base,
	};

	const { children, variant, ...rest } = props;
	const color = variant ? paragraphColors[variant] : props.color;

	return (
		<Base {...rest} color={color}>
			{children}
		</Base>
	);
}
