import type { Offer } from '@mybonus/public';

import { Collapsible } from '../Collapsible';
import { OfferText } from '../OfferText';
import {
	Wrapper,
	Arrow,
	$Button,
	Item,
	ItemLabel,
	ItemValue,
} from './OfferList.parts';

export type OfferListProps = {
	collapsed: boolean;
	offers: Offer[];
	onToggleCollapse: () => void;
};

export function OfferList(props: OfferListProps) {
	return (
		<Wrapper>
			<Collapsible
				collapsed={props.collapsed}
				hintPx={100}
				fadePx={50}
				expander={(isCollapsed, toggleCollapsed) => (
					<$Button onClick={() => toggleCollapsed()}>
						<Arrow collapsed={isCollapsed} />
					</$Button>
				)}
			>
				{props.offers.map((offer, i) => (
					<Item key={i}>
						<ItemLabel>{offer.spec.label}</ItemLabel>
						<ItemValue>
							<OfferText offer={offer} />
						</ItemValue>
					</Item>
				))}
			</Collapsible>
		</Wrapper>
	);
}
