import { LanguageCode, config } from '@mybonus/public';
import { useMemo, createElement, Fragment, ReactNode } from 'react';
import rehypeReact, { Options as RehypeReactOptions } from 'rehype-react';
import remarkParse from 'remark-parse';
import remarkToRehype from 'remark-rehype';
import { unified } from 'unified';

import { useQuery, useAPI, APIInput } from '../../api';
import { Text, Anchor, RemoteImage } from '../../primitives';
import { BulletList } from '../BulletList';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';
import { BulletListParagraph } from './CmsContent.parts';

export function useCmsContent(
	code: string,
	languageCode: LanguageCode = config.defaultLanguageCode,
) {
	const { api } = useAPI();

	const input = useMemo<APIInput['guest']['cms']['content']['single']>(
		() => ({ code, languageCode }),
		[code, languageCode],
	);

	const query = useQuery(
		'guest.cms.content',
		(args) => api.guest.cms.content.single.query(args),
		input,
	);

	return query;
}

const listRenderer = (props: any) => (
	<BulletList
		{...props}
		children={props.children.filter((c: ReactNode) => c !== '\n')}
	/>
);

export function createParser(components?: RehypeReactOptions['components']) {
	return unified()
		.use(remarkParse) // Parse markdown
		.use(remarkToRehype) // Turn markdown into HTML
		.use(rehypeReact, {
			createElement,
			Fragment,
			components: {
				h1: (props: any) => <Heading level={1} {...props} />,
				h2: (props: any) => <Heading level={2} {...props} />,
				h3: (props: any) => <Heading level={4} {...props} />,
				h4: (props: any) => <Heading level={4} {...props} />,
				h5: (props: any) => <Heading level={6} {...props} />,
				h6: (props: any) => <Heading level={6} {...props} />,
				p: (props: any) => <Paragraph {...props} />,
				strong: (props: any) => <Text style="bold" {...props} />,
				em: (props: any) => <Text style="italic" {...props} />,
				a: (props: any) => (
					<Anchor
						to={
							props.href.startsWith('/')
								? { local: props.href }
								: { url: props.href }
						}
						{...props}
					/>
				),
				img: (props: any) => <RemoteImage url={props.src} {...props} />,
				ul: listRenderer,
				ol: listRenderer,
				li: (props: any) => <BulletListParagraph {...props} />,
				...(components as any),
			},
		});
}
