import type { LanguageCode } from '@mybonus/public';
import { useMemo } from 'react';

import { useAPI, useQuery, APIInput, APIError } from '../api';

export function useCmsContent<T extends readonly string[]>(opts: {
	codes: T;
	languageCode: LanguageCode;
}): {
	isLoading: boolean;
	error?: APIError;
	data?: Record<T[number], string>;
} {
	const { codes, languageCode } = opts;
	const { api } = useAPI();

	const input = useMemo<APIInput['guest']['cms']['content']['multi']>(
		() => ({ codes: Array.from(codes), languageCode }),
		[codes, languageCode],
	);

	const query = useQuery(
		'guest.cms.content',
		(args) => api.guest.cms.content.multi.query(args),
		input,
	);

	return query;
}
