import { PageContainer } from '@mybonus/ui';

import { GuestView } from './GuestView';
import { MemberView } from './MemberView';
import { useRedirectView } from './RedirectView.hooks';
import { $FloatBox, Header } from './RedirectView.parts';

export function RedirectView() {
	const { store, auth, link } = useRedirectView();

	return (
		<PageContainer>
			<$FloatBox>
				<Header />
				{!auth.isLoading && (
					<>
						{auth.account || link ? (
							<MemberView store={store} link={link} />
						) : (
							<GuestView store={store} />
						)}
					</>
				)}
			</$FloatBox>
		</PageContainer>
	);
}
