import { Box } from '../../primitives';
import { styled } from '../../theme';

export const IconLeftWrapper = styled(Box)`
	margin-right: ${({ theme }) => theme.space.sm}px;
`;

export const IconRightWrapper = styled(Box)`
	margin-left: ${({ theme }) => theme.space.sm}px;
`;
