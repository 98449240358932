import type { ReactNode } from 'react';

import { Hero } from '../Hero';
import { useSectionHero } from './SectionHero.hooks';

export type SectionHeroProps = {
	section: 'INDEX' | 'CAMPAIGNS';
	fallback?: ReactNode;
};

export function SectionHero(props: SectionHeroProps) {
	const { hero } = useSectionHero(props);
	const noHero = hero.data === null;

	if (noHero) {
		return <>{props.fallback}</>;
	} else {
		return <>{hero.data && <Hero hero={hero.data} />}</>;
	}
}
