import { useState, useEffect } from 'react';

import { Anchor, Text } from '../../primitives';
import {
	Wrapper,
	Option,
	$Popdown,
	$PopdownMountingReference,
	SelectedText,
	OptionAnchor,
} from './SortLabel.parts';

export type SortLabelProps = {
	selected: string;
	options: { id: string; label: string }[];
	onSelect: (id: string) => void;
};

export function SortLabel(props: SortLabelProps & { className?: string }) {
	const selected = props.options.find((o) => o.id === props.selected);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		setOpen(false);
	}, [props.selected]);

	return (
		<Wrapper className={props.className}>
			<$PopdownMountingReference>
				<Text>Sorterar efter </Text>
				<Anchor to={{ onClick: () => setOpen(!open) }} underlined={false}>
					<SelectedText>{selected?.label.toLowerCase()}</SelectedText>
				</Anchor>
				<$Popdown open={open} onClose={() => setOpen(false)}>
					{props.options.map((o) => (
						<Option key={o.id}>
							<Text>
								<OptionAnchor to={{ onClick: () => props.onSelect(o.id) }}>
									{o.label}
								</OptionAnchor>
							</Text>
						</Option>
					))}
				</$Popdown>
			</$PopdownMountingReference>
		</Wrapper>
	);
}
