import { dateToUserFormat } from '@mybonus/public';

import { Icon } from '../../Icon';
import type { Notification } from '../NotificationList.types';
import {
	Wrapper,
	IconColumn,
	ContentColumn,
	ActionColumn,
	TimeText,
	MessageText,
} from './NotificationItem.parts';
import { NotificationTypeIcon } from './NotificationTypeIcon';

export type NotificationItemProps = {
	notification: Notification;
	isFirst: boolean;
	onSelect: () => void;
};

export function NotificationItem({
	notification,
	isFirst,
	className,
	onSelect,
}: NotificationItemProps & { className?: string }) {
	return (
		<Wrapper
			className={className}
			isRead={notification.isRead}
			isFirst={isFirst}
			isIntent={!!notification.intent}
			onClick={onSelect}
		>
			<IconColumn>
				<NotificationTypeIcon type={notification.type} />
			</IconColumn>
			<ContentColumn>
				<TimeText>{dateToUserFormat(notification.createdAt)}</TimeText>
				<MessageText>{notification.message}</MessageText>
			</ContentColumn>
			<ActionColumn>
				{notification.intent && <Icon name="IconArrowRightGray" size="lg" />}
			</ActionColumn>
		</Wrapper>
	);
}
