import { Anchor, RemoteImage, Box } from '../../primitives';
import { styled } from '../../theme';
import { BestOfferLabel } from '../BestOfferLabel';

export const Grid = styled(Box)`
	padding: ${({ theme }) => theme.space.lg}px 0;
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 2fr));
	grid-gap: ${({ theme }) => theme.space.lg}px;
	align-items: end;

	@media ${({ theme }) => theme.device.tablet} {
		grid-template-columns: repeat(4, minmax(0, 4fr));
	}
`;

export const Item = styled(Anchor).attrs((props) => ({
	...props,
	underlined: false,
}))`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ItemLogo = styled(RemoteImage)`
	max-width: 100%;
	max-height: 32px;
`;

export const ItemOffer = styled(BestOfferLabel).attrs((props) => ({
	...props,
}))`
	margin-top: ${({ theme }) => theme.space.md}px;
`;
