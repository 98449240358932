import { Box, Text, Anchor } from '../../primitives';
import { styled } from '../../theme';
import { Column } from '../Column';

export const Title = styled(Text)``;

export const Subtitle = styled(Text).attrs((props) => ({
	size: 'sm',
	color: props.theme.color.midGray.base,
	...props,
}))``;

export const ContentColumn = styled(Column)`
	flex: 1;
	padding: ${({ theme }) => theme.space.md}px 0;

	> * {
		margin-bottom: ${({ theme }) => theme.space.xs}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const ActionColumn = styled(Box)`
	padding: ${({ theme }) => theme.space.md}px;
`;

export const Wrapper = styled(Anchor).attrs((props) => ({
	...props,
	underlined: false,
}))<{ isFirst: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	border-top: ${({ isFirst, theme }) =>
		isFirst ? 'none' : `1px solid ${theme.color.lightGray.base}`};
`;
