import { useAPI, useQuery } from '../../api';
import { usePagination } from '../../hooks';
import { LoadMoreArrow } from '../LoadMoreArrow';
import { NoContent } from '../NoContent';
import { Wrapper, $Waiter } from './CashbackList.parts';
import { ListItem } from './ListItem';

export function CashbackList() {
	const { api } = useAPI();

	const { pagination: input, setPage, page } = usePagination(1, 10);

	const listing = useQuery(
		'user.cashback.list',
		(args) => api.user.cashback.list.query(args),
		input,
		{
			mergeData: (data, newData) =>
				data ? { ...data, ...newData, items: data.items.concat(newData.items) } : newData,
			ttl: false,
		},
	);

	function loadMore() {
		setPage(page + 1);
	}

	function renderList() {
		return (
			<Wrapper>
				{(listing.data?.items || []).map((item) => (
					<ListItem key={item.id} item={item} />
				))}

				{(listing.data?.pagination.pages || 0) > page && (
					<LoadMoreArrow isLoading={listing.isLoading} onClick={loadMore} />
				)}
			</Wrapper>
		);
	}

	function renderEmpty() {
		return (
			<NoContent
				title="Ingen återbäring, ännu..."
				message="Strax efter att du gjort ditt köp via någon av MyBonus butiker kommer ditt köp dyka upp här."
			/>
		);
	}

	return (
		<$Waiter isLoading={listing.isLoading}>
			{listing.data?.items.length === 0 ? renderEmpty() : renderList()}
		</$Waiter>
	);
}
