export function getCampaignExpiryText(activeTo: Date | null): string {
	if (!activeTo) {
		return 'Fler än 7 dagar kvar';
	}

	const now = new Date();
	const hourDiff = Math.floor((activeTo.getTime() - now.getTime()) / 1000 / 60 / 60);

	if (hourDiff <= 0) {
		return 'Erbjudandet har löpt ut';
	}

	if (hourDiff > 24 * 7) {
		return 'Fler än 7 dagar kvar';
	}

	let restHours = hourDiff;
	let days;
	const parts: string[] = [];

	if (restHours > 24) {
		days = Math.floor(restHours / 24);
		restHours -= days * 24;
		parts.push(`${days} dag${days > 1 ? 'ar' : ''}`);
	}

	if (restHours > 0) {
		parts.push(`${restHours} timm${restHours > 1 ? 'ar' : 'e'}`);
	}

	return `${parts.join(' och ')} kvar`;
}
