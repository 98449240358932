import { styled, Column, Center, PageContainer, PageTitle } from '@mybonus/ui';

export const Wrapper = styled(Center)`
	flex-direction: column;
	justify-content: flex-start;
	height: 100%;
	background-color: ${({ theme }) => theme.color.lighterGray.base};
`;

export const Filters = styled(Column)`
	margin-bottom: ${({ theme }) => theme.space.lg}px;
`;

export const $PageContainer = styled(PageContainer)`
	padding-top: ${({ theme }) => theme.space.md}px;
`;

export const $PageTitle = styled(PageTitle)`
	max-width: 700px;
`;
