import type { ListRecruitment } from '@mybonus/public';
import type { IconProps } from '@mybonus/ui/components/Icon';

import {
	Wrapper,
	$Icon,
	Content,
	MainRow,
	NameText,
	DateText,
	DescriptionText,
} from './RecruitmentItem.parts';

export type RecruitmentItemProps = {
	recruitment: ListRecruitment;
};

function getIconAndDescription(recruitment: ListRecruitment): {
	icon: IconProps['name'];
	text: string;
} {
	if (!recruitment.active) {
		return {
			icon: 'IconCheckboxCheckedGray',
			text: 'Har varit din Buddy i ett helt år',
		};
	}

	if (recruitment.hasBecomeActiveMember) {
		return {
			icon: 'IconCheckboxCheckedGreen',
			text: 'Har kommit igång med MyBonus',
		};
	} else {
		return {
			icon: 'IconCheckboxLineGray',
			text: 'Har inte gjort sitt första köp',
		};
	}
}

export function RecruitmentItem(props: RecruitmentItemProps) {
	const { name, createdAt } = props.recruitment;

	const { icon, text } = getIconAndDescription(props.recruitment);

	return (
		<Wrapper>
			<$Icon name={icon} />
			<Content>
				<MainRow>
					<NameText>{name}</NameText>
					<DateText>{createdAt.toISOString().split('T')[0]}</DateText>
				</MainRow>
				<DescriptionText>{text}</DescriptionText>
			</Content>
		</Wrapper>
	);
}
