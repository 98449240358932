import { PopularStoreGrid, HowItWorksSection, Container, SectionHero } from '@mybonus/ui';

import { CampaignSection } from './CampaignSection';
import { HomeTopSection } from './HomeTopSection';
import { useHomeView } from './HomeView.hooks';
import { $StoreSearch, $Container, ToBrowseButton } from './HomeView.parts';
import { PaymentSection } from './PaymentSection';
import { PlatformSection } from './PlatformSection';
import { SignupButton } from './SignupButton';
import { StatsSection } from './StatsSection';

export function HomeView() {
	const { isGuest } = useHomeView();

	return (
		<>
			{isGuest ? <HomeTopSection /> : <SectionHero section="INDEX" fallback={<HomeTopSection />} />}
			<$Container>
				<$StoreSearch />

				<PopularStoreGrid />
				{isGuest ? <SignupButton /> : <ToBrowseButton />}
			</$Container>
			<Container width="contained">
				<HowItWorksSection />
			</Container>

			<$Container>
				<PlatformSection platform="APP" />
				<PlatformSection platform="EXT" />
				<PlatformSection platform="WEB" />
				<StatsSection />

				{isGuest && <SignupButton />}
			</$Container>

			<Container width="contained">
				<PaymentSection />
			</Container>

			<CampaignSection />
		</>
	);
}
