import { styled } from '../../theme';
import { Heading } from '../Heading';

export const JumboTitle = styled(Heading).attrs((props) => ({
	...props,
	level: 1,
	color: props.theme.color.white.base,
}))`
	font-family: ${({ theme }) => theme.font.black};
	font-size: 38px;
	line-height: 1.2;

	@media ${({ theme }) => theme.device.laptop} {
		font-size: 58px;
	}

	font-weight: 700;
`;
