import { ReactNode } from 'react';

import { Wrapper } from './GlowBox.parts';

export type GlowBoxProps = {
	children?: ReactNode;
	className?: string;
	glow: boolean;
	glowSize?: number;
	glowBorderSize?: number;
	borderRadius?: number;
};

export function GlowBox(props: GlowBoxProps) {
	const { glowBorderSize = 2, glowSize = 8 } = props;

	return (
		<Wrapper
			{...props}
			glowBorderSize={glowBorderSize}
			glowSize={glowSize}
			borderRadius={props.borderRadius}
		>
			{props.children}
		</Wrapper>
	);
}
