import { Box, textfieldPadding } from '../../../primitives';
import { styled } from '../../../theme';
import { Column } from '../../Column';
import { Icon } from '../../Icon';
import { Paragraph } from '../../Paragraph';
import { Row } from '../../Row';

export const NoResults = styled(Box).attrs((props) => ({
	...props,
	children: <Paragraph>Sökningen gav inga träffar.</Paragraph>,
}))`
	padding: ${({ theme }) => theme.space.md}px;
	text-align: center;
`;

export const List = styled(Column)`
	padding: 0 ${textfieldPadding.md.x}px;
`;

export const ListItem = styled(Row).attrs((props) => ({
	...props,
}))<{ isFirst: boolean }>`
	cursor: pointer;

	border-top: ${({ isFirst, theme }) =>
		isFirst ? 'none' : `1px solid ${theme.color.lightGray.base}`};

	align-items: center;
`;

export const ArrowRight = styled(Icon).attrs((props) => ({
	...props,
	name: 'IconArrowRightGray',
	size: 'lg',
}))``;
