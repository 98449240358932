import { Anchor, Text, Box } from '../../primitives';
import { styled } from '../../theme';
import { Row } from '../Row';

export const Wrapper = styled(Box)``;

export const LetterTitle = styled(Text).attrs((props) => ({
	...props,
	size: 'xl',
	style: 'bold',
}))``;

export const LetterCount = styled(Text).attrs((props) => ({
	...props,
	color: props.theme.color.gray.base,
	size: 'sm',
}))`
	margin-left: ${({ theme }) => theme.space.sm}px;
`;

export const Items = styled(Box)`
	margin-top: ${({ theme }) => theme.space.md}px;
	display: grid;
	grid-row-gap: ${({ theme }) => theme.space.sm}px;
	grid-column-gap: ${({ theme }) => theme.space.lg}px;

	grid-template-columns: repeat(1, 1fr);

	@media ${({ theme }) => theme.device.tablet} {
		grid-template-columns: repeat(2, 1fr);
	}

	@media ${({ theme }) => theme.device.laptop} {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export const Item = styled(Row)`
	align-items: center;
	justify-content: space-between;
	min-height: 22px;
`;

export const StoreLink = styled(Anchor).attrs((props) => ({
	...props,
	underlined: false,
}))``;

export const StoreName = styled(Text)``;
