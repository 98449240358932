import { createGlobalStyle, css } from 'styled-components';

export function createGlobalFonts(
	publicPath: string,
	fonts:
		| (
				| 'lineto-circular-book'
				| 'lineto-circular-book-medium'
				| 'lineto-circular-bold'
				| 'lineto-circular-bold-italic'
				| 'lineto-circular-book-italic'
				| 'lineto-circular-book-italic'
				| 'lineto-circular-medium-italic'
				| 'lineto-circular-black'
		  )[]
		| 'all' = 'all',
) {
	return createGlobalStyle`
		${
			fonts === 'all' || fonts.includes('lineto-circular-book')
				? css`
						@font-face {
							font-family: 'lineto-circular-book';
							font-weight: 300;
							src: url('${publicPath}/lineto-circular-book.woff');
						}
				  `
				: ''
		}

		${
			fonts === 'all' || fonts.includes('lineto-circular-book-medium')
				? css`
						@font-face {
							font-family: 'lineto-circular-book-medium';
							font-weight: 400;
							src: url('${publicPath}/lineto-circular-book-medium.woff');
						}
				  `
				: ''
		}

		${
			fonts === 'all' || fonts.includes('lineto-circular-bold')
				? css`
						@font-face {
							font-family: 'lineto-circular-bold';
							font-weight: 500;
							src: url('${publicPath}/lineto-circular-bold.woff');
						}
				  `
				: ''
		}

		${
			fonts === 'all' || fonts.includes('lineto-circular-bold-italic')
				? css`
						@font-face {
							font-family: 'lineto-circular-bold-italic';
							font-style: italic;
							font-weight: 500;
							src: url('${publicPath}/lineto-circular-bold-italic.woff');
						}
				  `
				: ''
		}

		${
			fonts === 'all' || fonts.includes('lineto-circular-book-italic')
				? css`
						@font-face {
							font-family: 'lineto-circular-book-italic';
							font-style: italic;
							font-weight: 300;
							src: url('${publicPath}/lineto-circular-book-italic.woff');
						}
				  `
				: ''
		}

		${
			fonts === 'all' || fonts.includes('lineto-circular-medium-italic')
				? css`
						@font-face {
							font-family: 'lineto-circular-medium-italic';
							font-weight: 400;
							font-style: italic;
							src: url('${publicPath}/lineto-circular-medium-italic.woff');
						}
				  `
				: ''
		}

		${
			fonts === 'all' || fonts.includes('lineto-circular-black')
				? css`
						@font-face {
							font-family: 'lineto-circular-black';
							font-weight: 700;
							src: url('${publicPath}/lineto-circular-black.woff');
						}
				  `
				: ''
		}

	`;
}
