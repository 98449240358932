import { z } from 'zod';

export const PlatformType = z.enum(['WEB', 'EXT', 'APP', 'UNKNOWN']);
export type PlatformType = z.infer<typeof PlatformType>;

export const PlatformAlias = z.enum([
	'WEB',
	'WEB_ADMIN',
	'EXT_CHROME',
	'EXT_FIREFOX',
	'EXT_OPERA',
	'EXT_SAFARI',
	'EXT_EDGE',
	'APP_IOS',
	'APP_ANDROID',
	'UNKNOWN',
]);

export type PlatformAlias = z.infer<typeof PlatformAlias>;

export const PlatformInfoFromAlias = PlatformAlias.transform(
	(alias): { platform_type: PlatformType; platform_alias: PlatformAlias } => {
		const [type] = alias.split('_');

		return {
			platform_type: PlatformType.parse(type),
			platform_alias: alias,
		};
	},
);
export type PlatformInfoFromAlias = z.infer<typeof PlatformInfoFromAlias>;
