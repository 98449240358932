import { useAuthContext } from '@mybonus/ui';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

export function MonitoringPersonalization() {
	const auth = useAuthContext();

	useEffect(() => {
		if (auth.account) {
			Sentry.setUser({ id: `${auth.account.id}` });
		} else {
			Sentry.setUser(null);
		}
	}, [auth.account]);

	return <></>;
}
