import { styled, Paragraph, Center } from '@mybonus/ui';

export const Wrapper = styled(Center).attrs((props) => ({
	...props,
	vertical: true,
}))`
	flex-direction: column;
`;

export const SubText = styled(Paragraph).attrs((props) => ({
	...props,
	size: 'xs',
	color: props.theme.color.gray.base,
}))`
	margin-top: ${({ theme }) => theme.space.md}px;
`;
