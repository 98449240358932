import { z } from 'zod';

import { Duration } from '../../time';
import { stringToDate } from '../../zod-primitives';

export const NumberFieldState = z.object({
	id: z.string(),
	type: z.literal('NUMBER'),
	state: z.union([
		z.object({
			op: z.enum(['UNSET', 'TRUTHY', 'FALSY', 'EQ', 'NEQ', 'GT', 'GTE', 'LT', 'LTE']),
			value: z.number().optional(),
		}),
		z.object({
			op: z.enum(['INSIDE', 'OUTSIDE']),
			value: z
				.object({
					from: z.number(),
					to: z.number(),
				})
				.optional(),
		}),
	]),
});
export type NumberFieldState = z.infer<typeof NumberFieldState>;

export const BoolFieldState = z.object({
	id: z.string(),
	type: z.literal('BOOL'),
	state: z.object({
		op: z.enum(['UNSET', 'TRUTHY', 'FALSY']),
		value: z.never().optional(),
	}),
});
export type BoolFieldState = z.infer<typeof BoolFieldState>;

export const DateFieldState = z.object({
	id: z.string(),
	type: z.literal('DATE'),
	state: z.union([
		// Absolute
		z.object({
			op: z.enum(['UNSET', 'EQ', 'NEQ', 'GT', 'GTE', 'LT', 'LTE']),
			value: stringToDate.optional(),
		}),
		// Relative
		z.object({
			op: z.enum(['IN_THE_LAST', 'MORE_THAN']),
			value: Duration.optional(),
		}),
		// Range
		z.object({
			op: z.enum(['INSIDE', 'OUTSIDE']),
			value: z
				.object({
					from: stringToDate,
					to: stringToDate,
				})
				.optional(),
		}),
	]),
});
export type DateFieldState = z.infer<typeof DateFieldState>;

export const SelectFieldState = z.object({
	id: z.string(),
	type: z.literal('SELECT'),
	state: z.object({
		op: z.enum(['INCLUDES', 'EXCLUDES']).optional(),
		value: z.union([z.string(), z.number()]).array().optional(),
	}),
});
export type SelectFieldState = z.infer<typeof SelectFieldState>;
