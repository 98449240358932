import { useState } from 'react';

import { TabBar } from '../TabBar';
import { CashbackTab } from './CashbackTab';
import { Tab, Wrapper } from './CashbackTabs.parts';
import { WithdrawalTab } from './WithdrawalTab';

const tabs = ['Återbäring', 'Utbetalningar'] as const;

export function CashbackTabs() {
	const [tab, setTab] = useState<typeof tabs[number]>(tabs[0]);

	function renderTab(): JSX.Element {
		switch (tab) {
			case 'Återbäring':
				return <CashbackTab />;
			case 'Utbetalningar':
				return <WithdrawalTab />;
		}
	}

	return (
		<Wrapper>
			<TabBar
				tabs={tabs}
				active={tab}
				onChange={(t) => setTab(t as typeof tabs[number])}
			/>
			<Tab>{renderTab()}</Tab>
		</Wrapper>
	);
}
