import { LoadMoreArrow } from '../../LoadMoreArrow';
import { NoContent } from '../../NoContent';
import { useRecruitmentList } from './ListSection.hooks';
import { Wrapper, Title, List, $Waiter } from './ListSection.parts';
import { RecruitmentItem } from './RecruitmentItem';

export function ListSection() {
	const { list, isInitialLoad, loadMore, hasMore, isLoading } = useRecruitmentList();

	return (
		<Wrapper>
			<Title />

			<$Waiter isLoading={isInitialLoad}>
				<List>
					{!isInitialLoad && !list.length ? (
						<NoContent message="Dina Buddies kommer dyka upp här när de går med." />
					) : (
						<>
							{list.map((recruitment) => (
								<RecruitmentItem key={recruitment.id} recruitment={recruitment} />
							))}

							{hasMore && <LoadMoreArrow onClick={loadMore} isLoading={isLoading} />}
						</>
					)}
				</List>
			</$Waiter>
		</Wrapper>
	);
}
