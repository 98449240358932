import { config } from '@mybonus/public';
import { ErrorMessage, PageContainer, Paragraph } from '@mybonus/ui';
import { useParams } from 'react-router-dom';

import { useFulfillEmailChangeView } from './FulfillEmailChangeView.hooks';
import { $FloatBox, $CmsContent, $Waiter } from './FulfillEmailChangeView.parts';

export function FulfillEmailChangeView() {
	const params = useParams();
	const { validation, mutation } = useFulfillEmailChangeView(String(params.code));

	return (
		<PageContainer>
			<$CmsContent code="fulfill_email_change" />

			<$FloatBox>
				{validation.data === false ? (
					<ErrorMessage
						error={`Länken har löpt ut, har redan använts eller är felaktig. Du kan försöka igen genom att byta email-adressen på ditt konto igen. Tänk på att länken i mailet enbart är giltig i ${config.fulfillEmailChangeCodeValidDays} dagar.`}
					/>
				) : (
					<$Waiter isLoading={validation.isLoading || mutation.isLoading}>
						{mutation.isSuccess && (
							<Paragraph variant="info">
								Email-adressen har verifierats och bytts på ditt konto!
							</Paragraph>
						)}
					</$Waiter>
				)}

				<ErrorMessage error={validation.error || mutation.error} />
			</$FloatBox>
		</PageContainer>
	);
}
