import styled from 'styled-components';

import type { LocalImageProps } from './LocalImage.types';

function Base(props: LocalImageProps & { className?: string }) {
	return <img src={props.uri} alt={props.alt} className={props.className} />;
}

export const LocalImage = styled(Base)`
	display: block;
`;
