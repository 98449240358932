import { ReactNode, useMemo } from 'react';

import { useFavoriteContext } from '../../context';
import { AnchorButton } from '../AnchorButton';
import { LoadMoreArrow } from '../LoadMoreArrow';
import { NoContent } from '../NoContent';
import { useFavoriteList } from './FavoriteList.hooks';
import { Wrapper, Footer, Items } from './FavoriteList.parts';
import { FavoriteListItem } from './FavoriteListItem';

export type FavoriteListProps = {
	onSelect: (store: { id: number; name: string }) => void;
	onNoContentClick: () => void;
	isVisible: boolean;
	limit?: number;
	footer?: ReactNode;
	grid?: boolean;
};

export function FavoriteList(props: FavoriteListProps & { className?: string }) {
	const opts = useMemo(
		() => ({ isVisible: props.isVisible, limit: props.limit }),
		[props.isVisible, props.limit],
	);
	const { list, hasMore, loadMore, isLoading, isInitialLoad } = useFavoriteList(opts);
	const favorites = useFavoriteContext();

	return (
		<Wrapper isLoading={isInitialLoad} className={props.className}>
			{!isInitialLoad && !list.length ? (
				<NoContent
					message="Lägg till favoritbutiker genom att trycka på hjärt-symbolen när du besöker en butik. På så vis blir blir du meddelad när nya kampanjer kommer ut."
					content={
						<AnchorButton
							variant="primaryHollow"
							to={{ onClick: props.onNoContentClick }}
							text="Upptäck butiker"
						/>
					}
				/>
			) : (
				<>
					<Items grid={props.grid}>
						{list.map((store) => (
							<FavoriteListItem
								isFavorite={favorites.isFavorite(store.id)}
								onFavoriteClick={() => favorites.toggleFavorite(store.id)}
								key={store.id}
								store={store}
								onClick={() => props.onSelect(store)}
							/>
						))}
					</Items>

					<Footer>
						{props.footer ? (
							props.footer
						) : (
							<>{hasMore && <LoadMoreArrow onClick={loadMore} isLoading={isLoading} />}</>
						)}
					</Footer>
				</>
			)}
		</Wrapper>
	);
}
