import { Anchor, Box, Text } from '../../primitives';
import { styled } from '../../theme';
import { Popdown, PopdownMountingReference } from '../Popdown';
import { Row } from '../Row';

export const Wrapper = styled(Row)``;

export const SelectedText = styled(Text).attrs((props) => ({
	...props,
	color: props.theme.color.green.base,
}))``;

export const $Popdown = styled(Popdown).attrs((props) => ({
	...props,
	arrow: { position: 'right' },
	position: 'right',
}))`
	padding: ${({ theme }) => theme.space.md}px;
	width: auto;
`;

export const $PopdownMountingReference = styled(PopdownMountingReference)`
	width: 100%;
`;

export const Option = styled(Box)``;

export const OptionAnchor = styled(Anchor).attrs((props) => ({
	...props,
	underlined: false,
}))`
	padding: ${({ theme }) => theme.space.sm}px 0;
	display: inline-block;
`;
