import { styled, ActivePathAnchor, Waiter } from '@mybonus/ui';

export const $Waiter = styled(Waiter)`
	display: flex;
	z-index: 0;
`;

export const $ActivePathAnchor = styled(ActivePathAnchor)`
	margin-right: ${({ theme }) => theme.space.lg}px;
`;
