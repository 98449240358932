import { styled } from '../../theme';
import { SlideBanner } from '../SlideBanner';

export const $SlideBanner = styled(SlideBanner)`
	height: 110px;

	@media ${({ theme }) => theme.device.laptop} {
		height: 140px;
	}
`;
