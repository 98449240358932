import type { tRPCRouter } from '@mybonus/api/src/controllers/trpc';
import type { TRPCClient } from '@mybonus/public';
import { createTRPCProxyClient, type TRPCClientError } from '@trpc/client';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import constate from 'constate';
import { useMemo } from 'react';

export type { tRPCRouter } from '@mybonus/api/src/controllers/trpc';

export type APIInput = inferRouterInputs<tRPCRouter>;
export type APIOutput = inferRouterOutputs<tRPCRouter>;
export type APIError = TRPCClientError<tRPCRouter>;

export type APIContextOpts = {
	client: TRPCClient;
};

function api(opts: APIContextOpts) {
	const api = useMemo(
		() => createTRPCProxyClient<tRPCRouter>(opts.client.createConfig()),
		[],
	);

	return { api, client: opts.client };
}

export const [APIProvider, useAPI] = constate(api);
