import {
	ListStore,
	ListCampaign,
	imageUrlWithSize,
	getCampaignExpiryText,
	config,
} from '@mybonus/public';
import { useTheme } from 'styled-components';

import { Anchor } from '../../primitives';
import type { Theme } from '../../theme';
import { Collapsible } from '../Collapsible';
import {
	Wrapper,
	Image,
	Content,
	BottomBar,
	TimeLeftText,
	TimeLeftIcon,
	Headline,
	Description,
	Terms,
	Space,
	TimeLeft,
	ImageWrapper,
	ExpandButton,
	Arrow,
	ExpandText,
	GiftcardBanner,
} from './CampaignCard.parts';
import { StoreBar } from './StoreBar';
import { Voucher } from './Voucher';

export type CampaignCardProps = {
	store: ListStore;
	campaign: ListCampaign;
	horizontal?: boolean;
	collapse?: boolean;
	big?: boolean;
	navigateOut?: boolean;
};

export function CampaignCard(props: CampaignCardProps & { className?: string }) {
	const { store, campaign, horizontal } = props;
	const theme = useTheme() as Theme;

	return (
		<Wrapper
			horizontal={horizontal}
			className={props.className}
			glow={campaign.isFeatured}
			big={props.big}
		>
			{!horizontal && <StoreBar store={store} />}
			<Anchor
				to={{
					local:
						store.type === 'NETWORK' && props?.navigateOut
							? config.urls.redirect({
									storeId: store.id,
									campaignId: campaign.id,
							  })
							: config.urls.store({ storeId: store.id, storeName: store.name }),
				}}
			>
				<ImageWrapper>
					<Image
						horizontal={horizontal}
						url={imageUrlWithSize(campaign.imageUrl || '', {
							w: '800',
							format: 'jpg',
						})}
						alt={`${store.name} kampanj`}
					/>

					{store.type === 'GIFTCARD' && <GiftcardBanner />}
				</ImageWrapper>
			</Anchor>
			<Content>
				<Collapsible
					hintPx={props.collapse ? 140 : undefined}
					fadePx={80}
					expander={(isCollapsed, toggleCollapsed) =>
						isCollapsed && (
							<ExpandButton onClick={() => toggleCollapsed()}>
								<Arrow
									name={campaign.voucherCode ? 'IconArrowDownOrange' : 'IconArrowDownBlack'}
									collapsed={isCollapsed}
								/>
								<ExpandText color={campaign.voucherCode ? theme.color.orange.base : undefined}>
									{campaign.voucherCode ? 'Visa rabattkod' : 'Visa mer'}
								</ExpandText>
							</ExpandButton>
						)
					}
				>
					<Headline>{campaign.headline}</Headline>
					<Description>{campaign.description}</Description>
					{!!campaign.terms && <Terms>{campaign.terms}</Terms>}
					<Space />
					{campaign.voucherCode && <Voucher code={campaign.voucherCode} />}
				</Collapsible>
			</Content>
			<BottomBar>
				<TimeLeft>
					<TimeLeftIcon />
					<TimeLeftText>{getCampaignExpiryText(campaign.activeTo)}</TimeLeftText>
				</TimeLeft>
			</BottomBar>
		</Wrapper>
	);
}
