import { Anchor } from '../../primitives';
import { styled } from '../../theme';
import { EmailTextfield } from '../EmailTextfield';
import { FloatBox } from '../FloatBox';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';
import { TextButton } from '../TextButton';

export const $EmailTextfield = styled(EmailTextfield)`
	text-align: center;
`;

export const LoginButton = styled(TextButton).attrs(() => ({
	variant: 'primary',
	text: 'Logga in',
	type: 'submit',
	onClick: () => {
		// noop, handled by form onSubmit
	},
}))`
	width: 100%;
`;

export const $FloatBox = styled(FloatBox)`
	margin-top: 16px;
	max-width: 400px;
	width: 100%;
	text-align: center;
`;

export const Title = styled(Heading).attrs((props) => ({
	...props,
	children: 'Logga in',
	level: 1,
}))``;

export const NotMemberText = styled(Paragraph).attrs((props) => ({
	...props,
	children: (
		<>
			Inte medlem? <Anchor to={{ local: '/register' }}>Gå med nu</Anchor>
		</>
	),
}))``;

export const OrText = styled(Paragraph).attrs(() => ({
	children: 'eller via din e-post',
}))`
	padding-top: ${({ theme }) => theme.space.sm}px;
	padding-bottom: ${({ theme }) => theme.space.xs}px;
`;
