import { Box } from '../../primitives';
import { styled } from '../../theme';
import { Column } from '../Column';
import { Row } from '../Row';

export const Wrapper = styled(Column)`
	margin: ${({ theme }) => theme.space.sm}px 0;
`;

export const Item = styled(Row)`
	align-items: flex-start;
`;

export const Bullet = styled(Box)`
	width: 6px;
	height: 6px;
	border-radius: 999px;
	background-color: ${({ theme }) => theme.color.darkGray.base};
	margin-top: ${({ theme }) => theme.space.sm}px;
	margin-right: ${({ theme }) => theme.space.sm}px;
	margin-bottom: ${({ theme }) => theme.space.sm}px;
	margin-left: ${({ theme }) => theme.space.xs}px;
	flex-shrink: 0;
`;
