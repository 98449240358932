import { z } from 'zod';

export const CurrencyCode = z.enum(['SEK']);
export type CurrencyCode = z.infer<typeof CurrencyCode>;

export const CurrencyAmount = z.object({
	currency: CurrencyCode,
	amount: z.number(),
});
export type CurrencyAmount = z.infer<typeof CurrencyAmount>;
