import type { tRPCRouter } from '@mybonus/api/src/controllers/trpc';
import { TRPCClient, createLocalStorageAuthStorage } from '@mybonus/public';
import { createTRPCProxyClient } from '@trpc/client';

import { env } from '../shared/env';

export const client = new TRPCClient({
	// TODO: Override this in SSR environment to do communication internally inside the cluster.
	url: `${env.API_BASE_URL}/trpc`,
	platform: {
		alias: env.APP_PLATFORM_ALIAS,
		version: env.APP_VERSION,
	},
	authStorage: createLocalStorageAuthStorage(),
	accountType: 'USER',
});

export const raw = createTRPCProxyClient<tRPCRouter>(client.createConfig());
