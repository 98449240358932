import { Text } from '../../primitives';
import { styled, css, Theme } from '../../theme';
import type { HeadingProps } from './Heading';

export const createHeadingCSS = (
	theme: Theme,
	level: HeadingProps['level'],
) => css`
	font-family: ${level > 1 ? theme.font.bold : theme.font.base};
	font-size: ${(() => {
		switch (level) {
			case 1:
				return 32;
			case 2:
				return 28;
			case 4:
				return 20;
			case 6:
				return 16;
		}
	})()}px;
	font-weight: 400;
	line-height: 1.4;
	margin-top: 12px;
	margin-bottom: 8px;
`;

export const H1 = styled(Text).attrs((props) => ({
	element: props.element || 'h1',
}))`
	${({ theme }) => createHeadingCSS(theme, 1)}
`;

export const H2 = styled(Text).attrs((props) => ({
	element: props.element || 'h2',
}))`
	${({ theme }) => createHeadingCSS(theme, 2)}
`;

export const H4 = styled(Text).attrs((props) => ({
	element: props.element || 'h4',
}))`
	${({ theme }) => createHeadingCSS(theme, 4)}
`;

export const H6 = styled(Text).attrs((props) => ({
	element: props.element || 'h6',
}))`
	${({ theme }) => createHeadingCSS(theme, 6)}
`;
