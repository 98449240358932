import type { ReactNode } from 'react';

import { Background, Slider } from './Drawer.parts';

export type DrawerProps = {
	open: boolean;
	onClose: () => void;
	children?: ReactNode;
};

export function Drawer(props: DrawerProps) {
	return (
		<Background {...props} onClick={props.onClose}>
			<Slider
				{...props}
				onClick={(e) => {
					// Stop clicks going to the overlay
					e.stopPropagation();
				}}
			>
				{props.children}
			</Slider>
		</Background>
	);
}
