import { styled } from '../../theme';
import { Center } from '../Center';
import { Container } from '../Container';

export const $Container = styled(Container)`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Background = styled(Center)<{ noTopSpace?: boolean }>`
	align-items: flex-start;
	flex: 1;
	height: 100%;
	background-color: ${({ theme }) => theme.color.lighterGray.base};
	padding-top: ${({ theme, noTopSpace }) => (noTopSpace ? 0 : theme.space.lg)}px;
	padding-bottom: ${({ theme }) => theme.space.xl}px;

	@media ${({ theme }) => theme.device.laptop} {
		padding-top: ${({ theme, noTopSpace }) => (noTopSpace ? 0 : theme.space.xl)}px;
	}
`;
