import { RemoteImage, Box, Text, Button } from '../../primitives';
import { styled, css } from '../../theme';
import { floatBoxStyle } from '../FloatBox';
import { GlowBox } from '../GlowBox';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
import { Paragraph } from '../Paragraph';
import { Row } from '../Row';

export const Wrapper = styled(GlowBox).attrs((props) => ({
	...props,
	borderRadius: 18,
}))<{ horizontal?: boolean; big?: boolean }>`
	${floatBoxStyle};

	padding: 0 !important;
	display: flex;
	flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
	${({ big }) =>
		!big &&
		css`
			text-align: center;
		`}
`;

export const ImageWrapper = styled(Box)`
	background-color: transparent;
	flex-basis: 50%;
	cursor: pointer;
	position: relative;
`;

export const GiftcardBannerText = styled(Text).attrs((props) => ({
	...props,
	color: 'white',
	style: 'bold',
	size: 'sm',
	children: 'PRESENTKORT',
}))``;

export const GiftcardBanner = styled(Box).attrs((props) => ({
	...props,
	children: <GiftcardBannerText />,
}))`
	background-color: ${({ theme }) => theme.color.green.base};
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: ${({ theme }) => theme.space.xs}px;
`;

export const Image = styled(RemoteImage)<{ horizontal?: boolean }>`
	width: 100%;
	height: 100%;
	object-fit: cover;
	${({ horizontal }) =>
		horizontal
			? css`
					border-top-left-radius: ${({ theme }) => theme.rounding.md}px;
					border-bottom-left-radius: ${({ theme }) => theme.rounding.md}px;
			  `
			: ''};
`;

export const Content = styled(Box)`
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
`;

export const ExpandButton = styled(Button).attrs((props) => ({
	...props,
	variant: 'transparent',
}))`
	width: 100%;
	padding: ${({ theme }) => theme.space.sm}px;
	justify-content: center;
`;

export const ExpandText = styled(Text).attrs((props) => ({
	...props,
}))`
	margin-top: ${({ theme }) => theme.space.xs}px;
`;

export const Arrow = styled(Icon).attrs((props) => ({
	...props,
	size: 'xs',
}))<{ collapsed: boolean }>`
	transition: opacity 0.2s ease-in-out;
	margin-top: ${({ theme }) => theme.space.xs}px;
	margin-right: ${({ theme }) => theme.space.sm}px;
	opacity: ${({ collapsed }) => (collapsed ? 1 : 0)};
`;

export const Headline = styled(Heading).attrs((props) => ({
	...props,
	level: 4,
}))`
	margin: 0;
	padding: ${({ theme }) => theme.space.md}px ${({ theme }) => theme.space.md}px 0;
	font-size: 18px;
`;

export const Description = styled(Paragraph)`
	padding: 0 ${({ theme }) => theme.space.md}px;
`;

export const Terms = styled(Paragraph).attrs((props) => ({
	...props,
	size: 'xs',
	color: props.theme.color.gray.active,
}))`
	padding: 0 ${({ theme }) => theme.space.md}px ${({ theme }) => theme.space.sm}px
		${({ theme }) => theme.space.md}px;
`;

export const BottomBar = styled(Box)`
	display: flex;
	justify-content: space-between;
	padding: ${({ theme }) => theme.space.md}px;
	border-top: 1px solid ${({ theme }) => theme.color.lightGray.base};
`;

export const TimeLeft = styled(Row)`
	align-items: center;
`;

export const TimeLeftText = styled(Text).attrs((props) => ({
	...props,
	size: 'sm',
}))``;

export const TimeLeftIcon = styled(Icon).attrs((props) => ({
	...props,
	name: 'IconTimerBlack',
}))`
	margin-right: ${({ theme }) => theme.space.sm}px;
`;

export const Space = styled(Box)`
	flex: 1 1 auto;
`;
