import { Button } from '../../primitives';
import { styled } from '../../theme';

export const Wrapper = styled(Button).attrs((props) => ({
	variant: 'whiteBrightened',
	...props,
}))`
	text-align: left;
	padding: ${({ theme }) => theme.space.sm}px;
	height: 56px;
	width: 56px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
`;
