import { ErrorMessage, FloatBox, AnchorButton, PageContainer, Spinner } from '@mybonus/ui';

import { useRegisterRecruitmentView } from './RegisterRecruitmentView.hooks';

export function RegisterRecruitmentView() {
	const { isLoading, error } = useRegisterRecruitmentView();

	return (
		<PageContainer>
			{isLoading && <Spinner size="lg" />}

			{!!error && (
				<FloatBox>
					<ErrorMessage error={error} />

					<AnchorButton variant="primary" to={{ local: '/' }} text="Gå till startsidan" />
				</FloatBox>
			)}
		</PageContainer>
	);
}
