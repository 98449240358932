import { Box } from '../../primitives';
import { styled } from '../../theme';
import type { DrawerProps } from './Drawer';

export const Background = styled(Box)<DrawerProps>`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 3;
	opacity: ${(props) => (props.open ? '1' : '0')};
	pointer-events: ${(props) => (props.open ? 'auto' : 'none')};
	transition: opacity 0.2s ease-in-out;
	background-color: rgba(0, 0, 0, 0.18);
`;

export const Slider = styled.div<DrawerProps>`
	position: fixed;
	z-index: 4;
	top: 0;
	bottom: 0;
	right: 0;
	left: 15%;
	background-color: #fff;
	transition: transform 0.3s ease-in-out;
	transform: translate(${(props) => (props.open ? '0' : '760px')});
	overflow-y: scroll;
`;
