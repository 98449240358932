import { useState, useMemo } from 'react';

import { APIInput, useAPI, useQuery } from '../../api';
import { useEventListener, useIsomorphicLayoutEffect } from '../../hooks';
import { $SlideBanner } from './StoreSlideBanner.parts';
import { StoreSlideBannerItem } from './StoreSlideBannerItem';

export function StoreSlideBanner() {
	const { api } = useAPI();

	const input = useMemo<APIInput['guest']['store']['list']>(
		() => ({
			pagination: { limit: 16, offset: 0 },
			order: { by: 'POPULARITY', dir: 'DESC' },
		}),
		[],
	);

	const stores = useQuery('guest.store.list', (args) => api.guest.store.list.query(args), input);

	const [numVisible, setNumVisible] = useState(4);

	function handleSize() {
		setNumVisible(window.innerWidth / 180);
	}

	useEventListener('resize', handleSize);

	useIsomorphicLayoutEffect(() => {
		handleSize();
	}, []);

	return (
		<$SlideBanner numVisible={numVisible} timePerSlide={6000}>
			{(stores.data?.items || []).map((s) => (
				<StoreSlideBannerItem key={s.id} store={s} />
			))}
		</$SlideBanner>
	);
}
