import { Box, Button } from '../../primitives';
import { styled, css } from '../../theme';
import { Icon } from '../Icon';
import { Paragraph } from '../Paragraph';
import { Row } from '../Row';

export const Wrapper = styled(Box)`
	width: 100%;
`;

export const $Button = styled(Button).attrs((props) => ({
	...props,
	variant: 'transparent',
}))`
	width: 100%;
`;

export const Arrow = styled(Icon).attrs((props) => ({
	...props,
	name: 'IconArrowDownBlack',
	size: 'sm',
}))<{ collapsed: boolean }>`
	transition: transform 0.2s ease-in-out;

	${({ collapsed }) =>
		!collapsed &&
		css`
			transform: rotate(180deg);
		`}
`;

export const Item = styled(Row)`
	justify-content: space-between;
	margin: ${({ theme }) => theme.space.sm}px 0;
`;

const ItemText = styled(Paragraph)`
	margin: 0;
`;

export const ItemLabel = styled(ItemText)``;
export const ItemValue = styled(ItemText)``;
