import { Box } from '../../primitives';
import { styled } from '../../theme';
import { CampaignCard } from '../CampaignCard';

export const Wrapper = styled(Box)`
	display: grid;
	align-items: start;
	grid-gap: ${({ theme }) => theme.space.lg}px;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	z-index: 1; // Fixes glow effect on prio 1 campaigns

	@media ${({ theme }) => theme.device.tablet} {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@media ${({ theme }) => theme.device.laptop} {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
`;

export const $CampaignCard = styled(CampaignCard)`
	min-height: 480px;
`;
