import { z } from 'zod';

import { FieldState } from './field/state';

export * from './field';
export * from './sync';

export const Op = z.enum([
	'UNSET',
	'TRUTHY',
	'FALSY',
	'STARTS_WITH',
	'ENDS_WITH',
	'CONTAINS',
	'EQ',
	'NEQ',
	'GT',
	'LT',
	'LTE',
	'GTE',
	// Ranges
	'INSIDE',
	'OUTSIDE',
	// Lists
	'INCLUDES',
	'EXCLUDES',
	// Relative dates
	'IN_THE_LAST',
	'MORE_THAN',
]);
export type Op = z.infer<typeof Op>;

export const NodeType = z.enum(['GROUP', 'RULE']);
export type NodeType = z.infer<typeof NodeType>;

export type TreeNode = RuleNode | GroupNode;

export const Conjunction = z.enum(['AND', 'OR']);
export type Conjunction = z.infer<typeof Conjunction>;

export type GroupNode = {
	nodeType: 'GROUP';
	negated: boolean;
	conjunction: Conjunction;
	children: TreeNode[];
};

export type RuleNode = {
	nodeType: 'RULE';
	negated: boolean;
	field?: FieldState;
};

export const TreeNode: z.ZodType<TreeNode> = z.lazy(() =>
	z.discriminatedUnion('nodeType', [
		z.object({
			nodeType: z.literal('GROUP'),
			negated: z.boolean(),
			conjunction: Conjunction,
			children: TreeNode.array(),
		}),
		z.object({
			nodeType: z.literal('RULE'),
			negated: z.boolean(),
			// Field may not be selected yet, so lets make it optional
			field: FieldState.optional(),
		}),
	]),
);

export type Query = GroupNode;

export const AudiencePreview = z.object({
	participants: z
		.object({
			email: z.string(),
			id: z.number(),
		})
		.array(),
	totalCount: z.number(),
});
export type AudiencePreview = z.infer<typeof AudiencePreview>;
