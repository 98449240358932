import { z } from 'zod';

export const Locale = z.enum(['sv-SE']);

export type Locale = z.infer<typeof Locale>;

export const LanguageCode = z.enum(['sv', 'en']);
export type LanguageCode = z.infer<typeof LanguageCode>;

export const LocalizedText = z.record(
	LanguageCode,
	z.object({
		id: z.number(),
		text: z.string(),
	}),
);
export type LocalizedText = z.infer<typeof LocalizedText>;

export const LocalizedTextInput = z.record(
	LanguageCode,
	z.object({
		text: z.string(),
	}),
);

export type LocalizedTextInput = z.infer<typeof LocalizedTextInput>;
