import { useAuthContext } from '../../../context';
import { useClipboard } from '../../../hooks';
import { Wrapper, CopyButton, $Text, CodeText } from './Voucher.parts';

export type VoucherProps = {
	code: string;
};

export function Voucher(props: VoucherProps) {
	const auth = useAuthContext();
	const clipboard = useClipboard(props.code);

	function handleClick() {
		if (auth.account) {
			clipboard.handleCopy();
		}
	}

	return (
		<Wrapper>
			<$Text center={!auth.account}>
				{auth.account ? (
					<>
						Din rabattkod: <CodeText>{props.code}</CodeText>
					</>
				) : (
					<>Logga in för att se rabattkoden</>
				)}
			</$Text>
			{!!auth.account && (
				<CopyButton
					text={clipboard.copied ? 'Kopierad!' : 'Kopiera'}
					onClick={handleClick}
				/>
			)}
		</Wrapper>
	);
}
