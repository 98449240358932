import { Center } from '../Center';
import { Icon } from '../Icon';
import { MoreButton } from './LoadMoreArrow.parts';

export type LoadMoreArrowProps = {
	isLoading: boolean;
	onClick: () => void;
};

export function LoadMoreArrow(props: LoadMoreArrowProps) {
	return (
		<MoreButton {...props}>
			<Center>
				<Icon name="IconArrowDownGreen" size="sm" />
			</Center>
		</MoreButton>
	);
}
