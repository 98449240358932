import type { PaymentVendorType } from '@mybonus/public';
import { useState } from 'react';

import { APIOutput, useAPI, useMutation, type APIInput } from '../../api';

export function useGiftcardOrder(initialOfferId?: number) {
	const { api } = useAPI();

	const [offerId, setOfferId] = useState<number | undefined>(initialOfferId);
	const [error, setError] = useState<string | undefined>();

	const mutation = useMutation<
		APIInput['user']['giftcard']['order'],
		APIOutput['user']['giftcard']['order']
	>((args) => api.user.giftcard.order.mutate(args));

	function makeOrder(paymentVendor: PaymentVendorType) {
		if (!offerId) {
			setError('Du måste välja ett värde på presentkortet');
			return;
		}

		setError(undefined);

		mutation.mutate({
			storeGiftcardVendorOfferId: offerId,
			paymentVendor,
		});
	}

	function reset() {
		setError(undefined);
		mutation.reset();
	}

	const err = mutation.error || error;

	return {
		mutation,
		reset,
		err,
		makeOrder,
		offerId,
		setOfferId,
	};
}
