import { JoinPrompt, APIOutput, useUrl } from '@mybonus/ui';

export type GuestViewProps = {
	store?: APIOutput['guest']['store']['single'];
};

export function GuestView(props: GuestViewProps) {
	const { store } = props;
	const url = useUrl();

	return (
		<JoinPrompt
			message={`Bli medlem hos MyBonus och få ${store?.bestOffer?.text.toLowerCase()} återbäring på ditt köp hos ${
				store?.name
			}. Att bli medlem är helt gratis!`}
			currentUrlPath={url.path}
		/>
	);
}
