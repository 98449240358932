import { Textfield, Text } from '../../primitives';
import { Checkbox } from '../Checkbox';
import { ErrorMessage } from '../ErrorMessage';
import { Form } from '../Form';
import { FormRow } from '../FormRow';
import { Paragraph } from '../Paragraph';
import { TextButton } from '../TextButton';
import { useAccountRemovalInitForm } from './AccountRemovalInitForm.hooks';
import { Wrapper, WarningParagraph, Label } from './AccountRemovalInitForm.parts';

export function AccountRemovalInitForm() {
	const { mutation, form, getFieldVariant, error } = useAccountRemovalInitForm();

	return (
		<Wrapper>
			{mutation.isSuccess ? (
				<>
					<Paragraph variant="info">Mejl på väg!</Paragraph>
					<Paragraph>
						Ett mejl har skickats till den e-post du angav. Du måste bekräfta borttagningen av
						kontot genom att trycka på länken i mejlet.
					</Paragraph>

					<Paragraph>
						Om du inte fått något mejl, kolla skräpposten eller kontakta kundservice.
					</Paragraph>
				</>
			) : (
				<>
					<Paragraph>
						Vill du inte längre vara medlem hos MyBonus? Här kan du avsluta ditt konto.
					</Paragraph>

					<Paragraph>
						När du avslutar ditt konto anonymiseras all data som kan kopplas till dig som person.
					</Paragraph>

					<Paragraph>
						Om du har återbäring kvar på ditt konto kommer detta tillfalla MyBonus.
					</Paragraph>

					<WarningParagraph>
						Om du avslutar ditt konto kommer det inte att vara möjligt att återskapa det.
					</WarningParagraph>

					<ErrorMessage error={error} />

					<Form onSubmit={form.handleSubmit}>
						<Label>E-postadress för ditt konto</Label>
						<Textfield
							type="email"
							placeholder="namn@exempel.com"
							disabled={mutation.isLoading}
							value={form.data.email || ''}
							variant={getFieldVariant('email')}
							onChange={form.handleChange('email')}
						/>

						<FormRow>
							<Checkbox
								value={form.data.hasUnderstood}
								onChange={form.handleChange('hasUnderstood')}
							/>
							<Text>
								Jag har läst och förstår att mitt konto inte kommer gå att återskapa efter att det
								är borttaget.
							</Text>
						</FormRow>

						<TextButton
							variant="primary"
							disabled={
								mutation.isLoading ||
								!form.data.email ||
								!form.data.hasUnderstood ||
								!!mutation.isSuccess
							}
							isLoading={mutation.isLoading}
							onClick={form.handleSubmit}
							text="Ta bort mitt konto"
						/>
					</Form>
				</>
			)}
		</Wrapper>
	);
}
