import { useState, useEffect } from 'react';

import { useAPI, useMutation, type APIInput, type APIOutput } from '../../api';
import { useAccountContext } from '../../context';

export function useAccountSummary() {
	const account = useAccountContext();
	const { api } = useAPI();
	const [flow, setFlow] = useState<'initial' | 'identification' | 'withdrawal'>(
		'initial',
	);

	const withdrawal = useMutation<
		APIInput['user']['withdrawal']['create'],
		APIOutput['user']['withdrawal']['create']
	>((args) => api.user.withdrawal.create.mutate(args));

	useEffect(() => {
		if (flow === 'identification' && account.isIdentified) {
			setFlow('withdrawal');
			withdrawal.mutate({ vendor: 'TRUSTLY' });
		}
	}, [account.overview.data?.user.identification]);

	function handleWithdraw() {
		account.invalidate();
		setFlow('identification');
	}

	return {
		flow,
		overview: account.overview,
		withdrawal,
		handleWithdraw,
		isWithdrawable:
			account.overview.data?.cashbackSummary.amountUntilWithdrawalLimit
				.value === 0,
	};
}
