import { useDevice } from '../../hooks';
import { useCategoryCloud } from './CategoryCloud.hooks';
import { Wrapper, Item, $Waiter } from './CategoryCloud.parts';

export type CategoryCloudProps = {
	selectedCategoryId: number | undefined;
	onSelect: (categoryId: number | undefined) => void;
};

export function CategoryCloud(props: CategoryCloudProps) {
	const { categories, query } = useCategoryCloud();
	const device = useDevice();

	return (
		<$Waiter isLoading={query.isLoading}>
			<Wrapper>
				{categories.map((c, key) => (
					<Item
						variant={c.id === props.selectedCategoryId ? 'primary' : 'gray'}
						onClick={() => props.onSelect(c.id)}
						key={key}
						text={c.name}
						size={device.min('laptop') ? 'md' : 'sm'}
					/>
				))}
			</Wrapper>
		</$Waiter>
	);
}
