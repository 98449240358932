import { useAPI, useQuery, usePagination, APIInput, useAuthContext } from '@mybonus/ui';
import { useMemo } from 'react';

export function useCampaignSection() {
	const { api } = useAPI();
	const auth = useAuthContext();
	const { pagination } = usePagination(1, 3);

	const input = useMemo<APIInput['guest']['campaign']['list']>(
		() => ({
			order: {
				by: 'PRIORITY',
				dir: 'ASC',
			},
			...pagination,
		}),
		[],
	);

	const opts = useMemo(
		() => ({
			refreshFromClient: !!auth.account,
		}),
		[auth.account],
	);

	const query = useQuery(
		'guest.campaigns.list',
		(args) => api.guest.campaign.list.query(args),
		input,
		opts,
	);

	return {
		isLoading: query.isLoading,
		campaigns: query.data?.items || [],
	};
}
