import { useMemo } from 'react';

import { useAPI, useQuery } from '../../api';
import { useAuthContext } from '../../context';

export function usePopularStoreGrid() {
	const { api } = useAPI();
	const auth = useAuthContext();

	const input = useMemo(
		() => ({
			order: {
				by: 'POPULARITY' as const,
				dir: 'DESC' as const,
			},
			pagination: {
				offset: 0,
				limit: 8,
			},
		}),
		[],
	);

	const opts = useMemo(
		() => ({
			refreshFromClient: !!auth.account,
		}),
		[auth.account],
	);

	const query = useQuery(
		'guest.store.list',
		(args) => api.guest.store.list.query(args),
		input,
		opts,
	);
	const stores = query.data?.items || [];

	return {
		isLoading: query.isLoading,
		stores,
	};
}
