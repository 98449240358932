import type { PaymentVendorType } from '@mybonus/public';
import { useEffect, useState } from 'react';

import { Payment } from '../Payment';
import { GiftcardOrderSuccess } from './GiftcardOrderSuccess';
import { useGiftcardOrder } from './OrderGiftcardButton.hooks';
import { OrderButton, Wrapper, ErrorMessage, $Waiter } from './OrderGiftcardButton.parts';
import { PaymentSelect } from './PaymentSelect';

export type OrderGiftcardButtonProps = {
	offerId?: number;
	disabled?: boolean;
};

export function OrderGiftcardButton(props: OrderGiftcardButtonProps & { className?: string }) {
	const [step, setStep] = useState<'initial' | 'select' | 'pay'>('initial');
	const giftcard = useGiftcardOrder(props.offerId);

	function onPaymentVendorSelect(paymentVendor: PaymentVendorType) {
		setStep('pay');
		giftcard.makeOrder(paymentVendor);
	}

	useEffect(() => {
		if (props.offerId) {
			giftcard.setOfferId(props.offerId);
		}
	}, [props.offerId]);

	function onTryAgain() {
		giftcard.reset();
		setStep('initial');
	}

	function onOrderAnother() {
		giftcard.reset();
		setStep('initial');
	}

	function render() {
		switch (step) {
			case 'initial':
				return (
					<OrderButton
						disabled={props.disabled}
						onClick={() => setStep('select')}
						className={props.className}
					/>
				);
			case 'select':
				return (
					<PaymentSelect onSelect={onPaymentVendorSelect} isLoading={giftcard.mutation.isLoading} />
				);
			case 'pay': {
				const data = giftcard.mutation.data;
				return (
					<$Waiter isLoading={giftcard.mutation.isLoading}>
						{data && (
							<Payment
								paymentRequestId={data.paymentRequestId}
								vendor={data.vendor}
								data={data.data}
								success={<GiftcardOrderSuccess onOrderAnother={onOrderAnother} />}
								onTryAgain={onTryAgain}
							/>
						)}
					</$Waiter>
				);
			}
		}
	}

	return (
		<Wrapper>
			{render()}
			<ErrorMessage error={giftcard.err} />
		</Wrapper>
	);
}
