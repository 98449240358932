import { isBrowser } from '../utils/env';
import type { AuthStorage } from './types';

export function createLocalStorageAuthStorage(storageKey = 'mb_auth_v3'): AuthStorage {
	return {
		setData: async (data: string) => {
			if (!isBrowser()) return;

			window.localStorage.setItem(storageKey, data);
		},
		getData: async () => {
			if (!isBrowser()) return null;

			return window.localStorage.getItem(storageKey);
		},
		clearData: async () => {
			if (!isBrowser()) return;

			window.localStorage.removeItem(storageKey);
		},
	};
}
