import { Text } from '../../../primitives';
import { styled } from '../../../theme';
import { Column } from '../../Column';
import { Row } from '../../Row';

export const Wrapper = styled(Column)`
	> * {
		margin-bottom: ${({ theme }) => theme.space.sm}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const Title = styled(Text).attrs((props) => ({
	...props,
	style: 'bold',
}))``;

export const ValueRow = styled(Row)`
	justify-content: space-between;
`;

export const PurchaseValue = styled(Text)``;
export const CashbackValue = styled(Text).attrs((props) => ({
	...props,
	style: 'bold',
	color: props.color || props.theme.color.green.base,
}))``;
