import { Wrapper, FlowButton } from './FlowSelect.parts';

export type FlowSelectProps = {
	onSelect: (flow: 'device' | 'external') => void;
};

export function FlowSelect(props: FlowSelectProps) {
	// TODO: Use user agent to determine if we should show open on this device

	return (
		<Wrapper>
			<FlowButton
				text="Mobilt BankID på annan enhet"
				onClick={() => props.onSelect('external')}
			/>
			<FlowButton
				text="Öppna BankID"
				onClick={() => props.onSelect('device')}
			/>
		</Wrapper>
	);
}
