import { styled, Text, Box } from '@mybonus/ui';

export const Wrapper = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: ${({ theme }) => theme.space.lg}px 0;

	@media ${({ theme }) => theme.device.tablet} {
		flex-direction: row;
	}
`;

export const Metric = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: ${({ theme }) => theme.space.lg}px 0;
`;

export const Value = styled(Text).attrs((props) => ({
	...props,
	color: props.theme.color.green.base,
}))`
	font-family: ${({ theme }) => theme.font.black};
	font-size: 48px;
`;

export const Label = styled(Text)`
	margin-top: ${({ theme }) => theme.space.md}px;
`;
