import { z } from 'zod';

export const ListWithdrawal = z.object({
	id: z.number(),
	status: z.enum(['PENDING', 'FAILED', 'SUCCESS', 'ABORTED']),
	amount: z.string(),
	vendor: z.enum(['TRUSTLY']),
	createdAt: z.date(),
});

export type ListWithdrawal = z.infer<typeof ListWithdrawal>;
