import { Button } from '../../primitives';
import { styled, keyframes } from '../../theme';
import { Icon } from '../Icon';

const heartbeat = keyframes`
	{
		0%   { transform: scale(1)    }
		20%  { transform: scale(1.15) }
		40%  { transform: scale(1)    }
		60%  { transform: scale(1.15) }
		80%  { transform: scale(1)    }
		100% { transform: scale(1)    }
	}
`;

export const $Icon = styled(Icon).attrs((props) => ({
	...props,
	size: 'xl',
}))`
	padding: ${({ theme }) => theme.space.md}px;
	cursor: pointer;
	overflow: hidden;

	&:hover {
		animation: ${heartbeat} 1.5s infinite;
	}
`;

export const $Button = styled(Button).attrs((props) => ({
	...props,
	variant: 'transparent',
}))`
	padding: 0;
`;
