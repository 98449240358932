import { useTheme } from 'styled-components';

import { Theme } from '../../theme';
import { $Button, $Icon } from './FavoriteButton.parts';

export type FavoriteButtonProps = {
	isFavorite: boolean;
	onClick: () => void;
};

export function FavoriteButton(props: FavoriteButtonProps) {
	const theme = useTheme() as Theme;

	return (
		<$Button onClick={props.onClick}>
			<$Icon name={props.isFavorite ? theme.icon.IconHeartFilled : theme.icon.IconHeart} />
		</$Button>
	);
}
