import { styled } from '../../theme';
import { AnchorButton } from '../AnchorButton';

export const WhiteBrightenedLoginButton = styled(AnchorButton).attrs(
	(props) => ({
		...props,
		variant: 'whiteBrightened',
		text: 'Logga in',
		to: { local: '/login' },
		color: props.theme.color.white.base,
	}),
)``;
