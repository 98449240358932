import type { PaymentVendorType } from '@mybonus/public';

import { Paragraph } from '../../Paragraph';
import { Wrapper, VendorButton } from './PaymentSelect.parts';

const paymentVendors: Record<PaymentVendorType, string> = {
	SWISH: 'Swish',
	STRIPE: 'Betalkort / Klarna (Stripe)',
	TRUSTLY: 'Banköverföring (Trustly)',
};

export type PaymentSelectProps = {
	onSelect: (paymentVendor: PaymentVendorType) => void;
	isLoading: boolean;
};

export function PaymentSelect(props: PaymentSelectProps) {
	return (
		<Wrapper>
			<Paragraph>Välj ett betalsätt:</Paragraph>

			{Object.keys(paymentVendors).map((vendor, i) => (
				<VendorButton
					text={paymentVendors[vendor as keyof typeof paymentVendors]}
					key={i}
					onClick={() => props.onSelect(vendor as keyof typeof paymentVendors)}
					variant="transparent"
					isLoading={props.isLoading}
				/>
			))}
		</Wrapper>
	);
}
