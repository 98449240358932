import { Button, Text, Box, Anchor } from '../../primitives';
import { styled } from '../../theme';
import { Paragraph } from '../Paragraph';
import { Popdown as _Popdown } from '../Popdown';
import { Separator } from '../Separator';

export const Popdown = styled(_Popdown).attrs((props) => ({
	...props,
	position: 'right',
	arrow: { color: props.theme.color.lightGray.base, px: 22 },
}))``;

export const WrappingButton = styled(Button).attrs((props) => ({
	variant: 'whiteBrightened',
	...props,
}))`
	text-align: left;
	padding: ${({ theme }) => theme.space.sm}px ${({ theme }) => theme.space.xl}px
		${({ theme }) => theme.space.sm}px ${({ theme }) => theme.space.sm}px;
`;

export const PopdownReady = styled(Box)`
	border-top-left-radius: ${({ theme }) => theme.rounding.md}px;
	border-top-right-radius: ${({ theme }) => theme.rounding.md}px;
	padding: ${({ theme }) => theme.space.md}px 0;
	background-color: ${({ theme }) => theme.color.lightGray.base};
`;

export const CashbackSummaries = styled(Box)`
	padding: ${({ theme }) => theme.space.md}px 0;
`;

export const CashbackSummaryRow = styled(Box)`
	display: flex;
	justify-content: space-between;
	padding: ${({ theme }) => theme.space.sm}px ${({ theme }) => theme.space.md}px;
`;

export const MoneyText = styled(Text).attrs((props) => ({
	color: props.theme.color.green.base,
	...props,
}))``;

export const LinkSeparator = styled(Separator)``;

export const PopdownAnchor = styled(Anchor).attrs((props) => ({
	...props,
	underlined: false,
}))``;

export const AnchorText = styled(Paragraph)`
	padding: 0 ${({ theme }) => theme.space.md}px;
`;

export const ToAccountAnchor = styled(PopdownAnchor).attrs((props) => ({
	...props,
	to: { local: '/account' },
	children: 'Gå till mitt konto',
}))``;

export const ToSettingsAnchor = styled(PopdownAnchor).attrs((props) => ({
	...props,
	to: { local: '/account/settings' },
	children: 'Inställningar',
}))``;

export const LogoutAnchor = styled(PopdownAnchor).attrs((props) => ({
	...props,
	children: 'Logga ut',
}))`
	color: ${({ theme }) => theme.color.orange.base};
`;
