import constate from 'constate';
import { useState, useCallback } from 'react';
import { z } from 'zod';

export type UseFacebookAuthOpts = {
	fbAppId: string;
};

export const FacebookAuthResponse = z.object({
	status: z.string(),
	authResponse: z.object({
		accessToken: z.string(),
		userID: z.string(),
	}),
});
export type FacebookAuthResponse = z.infer<typeof FacebookAuthResponse>;

const SDK_LOAD_TIMEOUT_MS = 20000;

function useFacebookAuth(opts: UseFacebookAuthOpts) {
	const [isLoading, setIsLoading] = useState(false);
	const [accessToken, setAccessToken] = useState<string>();
	const [error, setError] = useState<Error>();

	const loadSdk = useCallback(() => {
		return new Promise<void>((resolve, reject) => {
			const win = window as any;

			if (win.FB) {
				return resolve(); // Only init once
			}

			win.fbAsyncInit = function () {
				win.FB.init({
					appId: opts.fbAppId,
					cookie: true,
					xfbml: true,
					version: 'v20.0',
				});

				return resolve();
			};

			const script = document.createElement('script');
			script.src = 'https://connect.facebook.net/en_US/sdk.js';
			script.async = true;
			script.id = 'facebook-jssdk';
			script.defer = true;
			document.body.appendChild(script);

			window.setTimeout(
				() => reject(new Error('Det tog för lång tid att hämta inloggningsrutan')),
				SDK_LOAD_TIMEOUT_MS,
			);
		});
	}, []);

	const fbLogin = useCallback(() => {
		return new Promise<FacebookAuthResponse>((resolve, reject) => {
			(window as any).FB.login(
				(response: unknown) => {
					const res = FacebookAuthResponse.safeParse(response);

					if (res.success && res.data.status === 'connected') {
						return resolve(res.data);
					} else {
						return reject(new Error('Det gick inte logga in med Facebook'));
					}
				},
				{ scope: 'public_profile,email' },
			);
		});
	}, []);

	const login = useCallback(async () => {
		if (isLoading) {
			return; // Prevent double action
		}

		setIsLoading(true);
		setAccessToken(undefined);
		setError(undefined);

		return loadSdk()
			.then(fbLogin)
			.catch((err) => {
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [isLoading]);

	return {
		login,
		isLoading,
		accessToken,
		error,
	};
}

export const [FacebookAuthProvider, useFacebookAuthContext] = constate(useFacebookAuth);
