import { Wrapper } from './HamburgerButton.parts';

export type HamburgerButtonProps = {
	onClick: () => void;
	open?: boolean;
	size: 'sm' | 'md' | 'lg' | 'xl';
};

export function HamburgerButton(props: HamburgerButtonProps) {
	return (
		<Wrapper {...props}>
			<div />
			<div />
			<div />
		</Wrapper>
	);
}
