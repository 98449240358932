import { config } from '@mybonus/public';

import { useAPI, useQuery } from '../../../api';
import { useAccountContext, useAuthContext } from '../../../context';
import { useRedirect, useUrl } from '../../../hooks';
import type { ShareSectionProps } from './ShareSection';

export function useSharing(props: ShareSectionProps) {
	const redirect = useRedirect();
	const account = useAccountContext();
	const url = useUrl();

	const code = String(account.overview.data?.user.link);
	const shareUrl = code ? config.urls.invite(props.webBaseUrl, code) : null;

	function handleFacebookShareClick() {
		redirect.toURL(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`);
	}

	function handleMessengerShareClick() {
		redirect.toURL(
			`https://www.facebook.com/dialog/share?app_id=${props.fbAppId}&display=page&href=${shareUrl}&redirect_uri=${url.url}`,
		);
	}

	return {
		code,
		handleFacebookShareClick,
		handleMessengerShareClick,
	};
}

export function useRecruitmentSummary() {
	const { api } = useAPI();
	const auth = useAuthContext();

	const summary = useQuery(
		'user.recruitment.summary',
		(args) => api.user.recruitment.summary.query(args),
		undefined,
		{ preventAutoload: !auth.account },
	);

	return {
		summary,
	};
}
