import { ReactNode, useRef, useState } from 'react';

import { useIsomorphicLayoutEffect } from '../../hooks';
import { Wrapper, Fade } from './Collapsible.parts';

export type CollapsibleProps = {
	collapsed?: boolean;
	hintPx?: number;
	fadePx?: number;
	expander?: (isCollapsed: boolean, toggleCollapsed: () => void) => ReactNode;
	children?: ReactNode;
};

export function Collapsible(props: CollapsibleProps) {
	const { children, ...rest } = props;
	const ref = useRef<HTMLDivElement | null>(null);
	const isHintEnabled = !!props?.hintPx;
	const [isCollapsed, setIsCollapsed] = useState(isHintEnabled);
	const hintPx = props.hintPx || 0;

	useIsomorphicLayoutEffect(() => {
		if (typeof props.collapsed !== 'undefined') {
			setIsCollapsed(props.collapsed);
		}
	}, [props.collapsed]);

	useIsomorphicLayoutEffect(() => {
		if (ref.current && isHintEnabled) {
			const height = ref.current.clientHeight;
			const isFitting = height < hintPx;
			setIsCollapsed(!isFitting);
		}
	}, [children]);

	function toggleCollapsed() {
		setIsCollapsed(!isCollapsed);
	}

	return (
		<>
			<Wrapper {...rest} collapsed={isCollapsed} isHintEnabled={isHintEnabled}>
				<div ref={ref}>{children}</div>

				{props.hintPx && (
					<Fade
						{...rest}
						collapsed={isCollapsed}
						isHintEnabled={isHintEnabled}
					></Fade>
				)}
			</Wrapper>
			{props.expander?.(isCollapsed, toggleCollapsed)}
		</>
	);
}
