import styled from 'styled-components';

import { ImageAvatar } from '../../assets';
import { Box, LocalImage, RemoteImage } from '../../primitives';

export const Wrapper = styled(Box)<{ size: number }>`
	width: ${({ size }) => size}px;
	height: ${({ size }) => size}px;
`;

export const $RemoteImage = styled(RemoteImage)`
	border-radius: 1000px;
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export const DefaultImage = styled(LocalImage).attrs((props) => ({
	...props,
	uri: ImageAvatar,
}))`
	border-radius: 1000px;
	width: 100%;
	height: 100%;
	object-fit: cover;
`;
