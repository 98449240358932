import { Box, Text } from '../../primitives';
import { styled } from '../../theme';

export const Wrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: stretch;
`;

export const Right = styled(Box)`
	display: flex;
	flex-direction: column;
	padding: ${({ theme }) => theme.space.xs}px ${({ theme }) => theme.space.sm}px;
	justify-content: space-between;
`;

export const WhiteText = styled(Text).attrs((props) => ({
	color: props.theme.color.white.base,
	...props,
}))`
	white-space: nowrap;
`;

export const CashbackText = styled(WhiteText).attrs((props) => ({
	size: 'sm',
	style: 'bold',
	...props,
}))`
	margin-left: ${({ theme }) => theme.space.xs}px;
`;

export const Fullname = styled(WhiteText).attrs((props) => ({
	style: 'bold',
	...props,
}))``;

export const Sub = styled(Box)`
	display: flex;
`;
