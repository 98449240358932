import { useAccountContext } from '../../context';
import { BankIdButton } from '../BankIdButton';
import { ErrorMessage } from '../ErrorMessage';
import { Waiter } from '../Waiter';
import { Withdrawal } from '../Withdrawal';
import { useAccountSummary } from './AccountSummary.hooks';
import {
	$GreenGradient,
	$Avatar,
	PersonBox,
	Fullname,
	SettingsAnchor,
	WithdrawBox,
	ReadyLabel,
	ReadyValue,
	WithdrawButton,
	SummariesBox,
	StateDescriptionText,
} from './AccountSummary.parts';
import { SummaryItem } from './SummaryItem';

export function AccountSummary() {
	const { overview, handleWithdraw, withdrawal, flow, isWithdrawable } = useAccountSummary();
	const account = useAccountContext();
	const user = overview.data?.user;
	const summary = overview.data?.cashbackSummary;
	const pendingWithdrawal = overview.data?.pendingWithdrawal;

	function renderWithdrawalFlow() {
		switch (flow) {
			case 'initial':
			case 'identification':
				return (
					<>
						<WithdrawButton
							onClick={handleWithdraw}
							disabled={
								withdrawal.isLoading ||
								!summary ||
								!isWithdrawable ||
								!account.isIdentified ||
								!!pendingWithdrawal
							}
							text={pendingWithdrawal ? 'Bearbetas...' : 'Betala ut'}
						/>
						{!isWithdrawable && !pendingWithdrawal && (
							<StateDescriptionText>
								{summary?.amountUntilWithdrawalLimit.text} till uttagsgränsen
							</StateDescriptionText>
						)}
					</>
				);
			case 'withdrawal':
				return <Withdrawal data={withdrawal.data?.result} />;
		}
	}

	return (
		<$GreenGradient>
			<Waiter isLoading={overview.isLoading}>
				<PersonBox>
					<$Avatar avatar={user?.avatar} />
					<Fullname>{user?.fullname || user?.email}</Fullname>
					<SettingsAnchor />
				</PersonBox>
			</Waiter>
			<WithdrawBox>
				<ReadyLabel>
					{pendingWithdrawal ? 'Utbetalning bearbetas...' : 'Redo för utbetalning'}
				</ReadyLabel>
				<ReadyValue>
					{pendingWithdrawal ? pendingWithdrawal.amount : summary?.collectible.text}
				</ReadyValue>
				{renderWithdrawalFlow()}
				<ErrorMessage error={withdrawal.error} />

				{isWithdrawable && !account.isIdentified && (
					<BankIdButton
						onIdentified={account.invalidate}
						reason="Du måste identifiera dig för att göra uttag."
					/>
				)}
			</WithdrawBox>
			<SummariesBox>
				<SummaryItem
					title="Inkommande"
					subtitle="Inväntar godkännande från butik"
					value={summary?.incoming.text || ''}
				/>
				<SummaryItem
					title="Bonus Buddies"
					subtitle="Återbäring från rekryterade vänner"
					value={summary?.bonusBuddy.text || ''}
				/>
				<SummaryItem
					isLast
					title="Utbetalt"
					subtitle="All tidigare utbetald återbäring"
					value={summary?.collected.text || ''}
				/>
			</SummariesBox>
		</$GreenGradient>
	);
}
