import { Box } from '../../primitives';
import { styled } from '../../theme';

export const Wrapper = styled(Box)`
	display: flex;
	width: 100%;
	max-width: 270px;

	> * {
		margin-right: ${({ theme }) => theme.space.md}px;
		flex: 1;

		&:last-child {
			margin-right: 0;
		}
	}
`;
