import { Box } from '../../primitives';
import { styled } from '../../theme';

export const Wrapper = styled(Box)`
	background-color: ${({ theme }) => theme.color.lightOrange.base};
	border-radius: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: ${({ theme }) => theme.space.xs}px ${({ theme }) => theme.space.md}px;
`;
