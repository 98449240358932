import type { BestOffer } from '@mybonus/public';

import { OfferText } from '../OfferText';

export type BestOfferTextProps = {
	bestOffer: BestOffer;
	lower?: boolean;
	size?: 'sm' | 'md' | 'lg';
};

export function BestOfferText(props: BestOfferTextProps) {
	const { bestOffer, lower } = props;
	const prefix = bestOffer.isDifferentOffers
		? lower
			? 'upp till'
			: 'Upp till'
		: undefined;

	return (
		<OfferText prefix={prefix} offer={bestOffer.offer} size={props.size} />
	);
}
