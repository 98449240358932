import { Box } from '../../primitives';
import { styled, css } from '../../theme';
import type { CheckboxProps } from './Checkbox';

export const checkboxSize = {
	sm: {
		area: 18,
		dot: 12,
	},
	md: {
		area: 20,
		dot: 14,
	},
	lg: {
		area: 24,
		dot: 18,
	},
};

export type CheckboxSize = keyof typeof checkboxSize;

type CheckboxPartProps = Omit<CheckboxProps, 'onChange'>;

export const Wrapper = styled(Box)<CheckboxPartProps>`
	${({ disabled }) =>
		!disabled &&
		css`
			cursor: pointer;
		`}
	background-color: ${({ theme }) => theme.color.lightGray.base};
	border-radius: ${({ size }) => `${checkboxSize[size || 'md'].area / 3}px`};
	height: ${({ size }) => checkboxSize[size || 'md'].area}px;
	width: ${({ size }) => checkboxSize[size || 'md'].area}px;
	display: flex;
	flex: 0 0 auto;
	justify-content: center;
	align-items: center;
`;

export const Dot = styled(Box)<CheckboxPartProps>`
	transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
	transform: scale(${({ value }) => (value ? 1 : 0)});
	opacity: ${({ value }) => (value ? 1 : 0)};
	background-color: ${({ theme }) => theme.color.green.base};
	border-radius: ${({ size }) => `${checkboxSize[size || 'md'].dot / 3}px`};
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	height: ${({ size }) => checkboxSize[size || 'md'].dot}px;
	width: ${({ size }) => checkboxSize[size || 'md'].dot}px;
`;
