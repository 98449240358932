import { styled } from '../../../theme';
// import { ListStore } from '../../ListStore';
import { Row } from '../../Row';

export const Wrapper = styled(Row).attrs((props) => ({
	...props,
	apart: true,
}))<{ isFavorite: boolean }>`
	transition: opacity 0.2s ease-in-out;
	opacity: ${({ isFavorite }) => (isFavorite ? 1 : 0.5)};
	border-bottom: 1px solid ${({ theme }) => theme.color.lightGray.base};
	cursor: pointer;
`;
