import { color as colormap } from './color';

export function createButtonTheme({ color }: { color: typeof colormap }) {
	return {
		default: {
			bg: {
				base: color.red.base,
				active: color.red.active,
			},
			fg: {
				base: color.white.base,
			},
		},
		primary: {
			bg: {
				base: color.green.base,
				active: color.green.active,
			},
			fg: {
				base: color.white.base,
			},
		},
		primaryLight: {
			bg: {
				base: color.lightGreen.base,
				active: color.lightGreen.active,
			},
			fg: {
				base: color.green.base,
			},
		},
		whiteBrightened: {
			bg: {
				base: color.whiteBrightened.base,
				active: color.whiteBrightened.active,
			},
			fg: {
				base: color.white.base,
			},
		},
		white: {
			bg: {
				base: color.white.base,
				active: color.white.active,
			},
			fg: {
				base: color.green.base,
				active: color.green.active,
			},
		},
		gray: {
			bg: {
				base: color.lightGray.base,
				active: color.lightGray.active,
			},
			fg: {
				base: color.gray.base,
				active: color.gray.active,
			},
		},
		alternative: {
			bg: {
				base: color.orange.base,
				active: color.orange.active,
			},
			fg: {
				base: color.white.base,
			},
		},
		yellow: {
			bg: {
				base: color.yellow.base,
				active: color.yellow.active,
			},
			fg: {
				base: color.white.base,
			},
		},
		primaryHollow: {
			fg: {
				base: color.green.base,
				active: color.green.active,
			},
			border: {
				base: color.green.base,
				active: color.green.active,
			},
		},
		alternativeHollow: {
			fg: {
				base: color.orange.base,
				active: color.orange.active,
			},
			border: {
				base: color.orange.base,
				active: color.orange.active,
			},
		},
		facebook: {
			bg: {
				base: color.facebook.base,
				active: color.facebook.active,
			},
			fg: {
				base: color.white.base,
			},
		},
		facebookMessenger: {
			bg: {
				base: color.facebookMessenger.base,
				active: color.facebookMessenger.active,
			},
			fg: {
				base: color.white.base,
			},
		},
		bankid: {
			bg: {
				base: color.bankid.base,
			},
			fg: {
				base: color.white.base,
			},
		},
		transparent: {
			bg: {
				base: color.transparent.base,
			},
			fg: {
				base: color.green.base,
			},
		},
	};
}
