import { FirstStoreLetter, ListStore, config } from '@mybonus/public';
import { useRef, useMemo } from 'react';

import { APIInput, APIOutput, useAPI, useQuery } from '../../api';
import { data } from '../../config';
import { useIsVisible } from '../../hooks';
import { BestOfferLabel } from '../BestOfferLabel';
import { LoadMoreArrow } from '../LoadMoreArrow';
import { Waiter } from '../Waiter';
import {
	Wrapper,
	LetterTitle,
	LetterCount,
	Items,
	Item,
	StoreLink,
	StoreName,
} from './StoreLetterGroup.parts';

export type StoreLetterGroupProps = {
	letter: FirstStoreLetter;
	stores: APIOutput['guest']['store']['abc'];
	isLoading: boolean;
	onExpand: () => void;
	isExpanded: boolean;
};

export function StoreLetterGroup(props: StoreLetterGroupProps) {
	const storeCount = props.stores.length;
	const isExpandable = storeCount > data.storeLetterGroupSize;

	const showCount = useMemo(
		() => (props.isExpanded ? storeCount : Math.min(data.storeLetterGroupSize, storeCount)),
		[props.isExpanded],
	);

	const ref = useRef<HTMLDivElement | null>(null);
	const entry = useIsVisible(ref, {});
	const isVisible = !!entry?.isIntersecting;

	const { api } = useAPI();
	const input = useMemo<APIInput['guest']['store']['list']>(
		() => ({
			filter: {
				ids: props.stores.slice(0, showCount).map((s) => s.id),
			},
		}),
		[props.stores, showCount],
	);

	const storeList = useQuery(
		'guest.store.list',
		(args) => api.guest.store.list.query(args),
		input,
		{ preventAutoload: !isVisible },
	);

	const storeMap = useMemo(
		() =>
			storeList.data
				? storeList.data.items.reduce<Record<number, ListStore>>((map, s) => {
						map[s.id] = s;
						return map;
				  }, {})
				: {},
		[storeList.data],
	);

	return (
		<div ref={ref}>
			<Wrapper>
				<LetterTitle>{props.letter.toUpperCase()}</LetterTitle>
				<LetterCount>{storeCount} st</LetterCount>

				<Items>
					{props.stores.slice(0, showCount).map((store) => (
						<StoreLink
							key={`${props.letter}-${store.id}`}
							to={{
								local: config.urls.store({
									storeId: store.id,
									storeName: store.name,
								}),
							}}
						>
							<Item>
								<StoreName>{store.name}</StoreName>

								<Waiter isLoading={!storeMap[store.id]} hideSpinner>
									{storeMap[store.id] && storeMap[store.id]?.bestOffer && (
										<BestOfferLabel size="sm" bestOffer={storeMap[store.id]!.bestOffer!} />
									)}
								</Waiter>
							</Item>
						</StoreLink>
					))}
				</Items>

				{!props.isExpanded && isExpandable && (
					<LoadMoreArrow isLoading={false} onClick={props.onExpand} />
				)}
			</Wrapper>
		</div>
	);
}
