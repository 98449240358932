import { styled, Container, Row } from '@mybonus/ui';

export const Wrapper = styled(Row)`
	background-color: #fff;
	padding: 12px;
	justify-content: center;
	display: none;

	@media ${({ theme }) => theme.device.laptop} {
		display: flex;
	}
`;

export const $Container = styled(Container).attrs((props) => ({
	...props,
}))`
	display: flex;
	flex-direction: row;
`;
