import { useRef, useEffect, useState } from 'react';

export function useCountdown(
	callback: () => void,
	opts: {
		durationSeconds: number;
	},
) {
	const interval = useRef<number>();
	const [second, setSecond] = useState(opts.durationSeconds);

	// Teardown
	useEffect(() => {
		return () => {
			stop();
		};
	}, []);

	useEffect(() => {
		if (second === 0) {
			callback();
			stop();
		}
	}, [second]);

	function start() {
		interval.current = window.setInterval(() => {
			setSecond((prev) => Math.max(0, prev - 1));
		}, 1000);
	}

	function stop() {
		if (interval.current) {
			clearInterval(interval.current);
		}
	}

	function reset() {
		stop();
		setSecond(opts.durationSeconds);
	}

	return {
		second,
		durationSeconds: opts.durationSeconds,
		start,
		stop,
		reset,
	};
}
