import { z } from 'zod';

import { SingleHero } from './hero';
import { LocalizedText } from './internationalization';

export const ListAdminPage = z.object({
	id: z.number(),
	slug: z.string(),
	active: z.boolean(),
	order: z.number(),
	showInMenu: z.boolean(),
});
export type ListAdminPage = z.infer<typeof ListAdminPage>;

export const ListPage = z.object({
	id: z.number(),
	slug: z.string(),
	title: z.string(),
	order: z.number(),
});
export type ListPage = z.infer<typeof ListPage>;

export const SingleAdminPage = z.object({
	id: z.number(),
	slug: z.string(),
	tagId: z.number(),
	heroId: z.number().nullable(),
	content: LocalizedText,
	title: LocalizedText,
	order: z.number(),
	showInMenu: z.boolean(),
	active: z.boolean(),
});
export type SingleAdminPage = z.infer<typeof SingleAdminPage>;

export const SinglePage = z.object({
	id: z.number(),
	slug: z.string(),
	tagId: z.number(),
	hero: SingleHero.nullable(),
	content: z.string(),
	title: z.string(),
	order: z.number(),
});
export type SinglePage = z.infer<typeof SinglePage>;
