import { buttonPadding } from '../../../primitives';
import { styled } from '../../../theme';
import { Center } from '../../Center';
import { TextButton } from '../../TextButton';

export const Wrapper = styled(Center)`
	margin-top: ${({ theme }) => theme.space.md}px;
	flex-direction: column;
`;

export const FlowButton = styled(TextButton).attrs((props) => ({
	...props,
	variant: 'transparent',
}))`
	padding: ${() => {
		const { y } = buttonPadding.md;
		return `${y}px 0`;
	}};
`;
