import { styled, FloatBox, Heading, BackAnchor, Column } from '@mybonus/ui';

export const FloatWrapper = styled(Column)`
	> * {
		margin-bottom: ${({ theme }) => theme.space.md}px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	align-items: center;
	width: 100%;
	max-width: 500px;
`;

export const Title = styled(Heading).attrs((props) => ({
	...props,
	level: 1,
}))`
	margin-top: 0;
`;

export const $FloatBox = styled(FloatBox)`
	width: 100%;
`;

export const $MenuFloatBox = styled($FloatBox)`
	padding: ${({ theme }) => theme.space.sm}px ${({ theme }) => theme.space.md}px;
`;

export const $BackAnchor = styled(BackAnchor).attrs((props) => ({
	...props,
	to: { local: '/account' },
}))`
	width: 100%;
	margin-left: ${({ theme }) => theme.space.md}px;
`;
