import { useEffect, useMemo } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

import { useAuthContext } from '../../context';
import { useUrl } from '../../hooks';
import { $Waiter } from './AccountRoute.parts';

export function AccountRoute() {
	const auth = useAuthContext();
	const navigate = useNavigate();
	const url = useUrl();

	const isAuthenticated = useMemo(() => auth.account && auth.account.type === 'USER', [auth]);

	useEffect(() => {
		if (!isAuthenticated && !auth.isLoading) {
			navigate(`/login?redirect=${encodeURIComponent(url.path)}`);
		}
	}, [isAuthenticated, auth.isLoading]);

	return <$Waiter isLoading={auth.isLoading}>{isAuthenticated && <Outlet />}</$Waiter>;
}
