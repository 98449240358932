import { styled, Center, Heading, AnchorButton, Container } from '@mybonus/ui';

export const Background = styled(Center)`
	background-color: ${({ theme }) => theme.color.lighterGray.base};
`;

export const $Container = styled(Container)`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: ${({ theme }) => theme.space.lg}px;
	padding-bottom: ${({ theme }) => theme.space.lg}px;

	> * {
		margin-bottom: ${({ theme }) => theme.space.lg}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const Title = styled(Heading).attrs((props) => ({
	...props,
	level: 2,
}))``;

export const ToCampaignsButton = styled(AnchorButton).attrs((props) => ({
	...props,
	text: 'Alla kampanjer',
	to: { local: '/campaigns' },
	size: 'lg',
	variant: 'primary',
}))``;
