import { styled, FloatBox, Column } from '@mybonus/ui';

export const FloatWrapper = styled(Column)`
	align-items: center;
	width: 100%;
`;

export const $FloatBox = styled(FloatBox)`
	width: 100%;
	padding: 0;
`;
