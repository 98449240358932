import { styled, RemoteImage, Center, Text, TextButton, Paragraph } from '@mybonus/ui';

export const Content = styled(Center)`
	width: 100%;
	flex-direction: column;
	padding: ${({ theme }) => theme.space.lg}px;

	> * {
		margin-bottom: ${({ theme }) => theme.space.md}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const StoreLogo = styled(RemoteImage)`
	max-width: 200px;
	margin: ${({ theme }) => theme.space.lg}px 0;
`;

export const Footer = styled(Center)`
	width: 100%;
	flex-direction: column;
	padding: ${({ theme }) => theme.space.md}px;
	background-color: ${({ theme }) => theme.color.lightGray.base};
	border-bottom-left-radius: ${({ theme }) => theme.rounding.md}px;
	border-bottom-right-radius: ${({ theme }) => theme.rounding.md}px;
`;

export const OfferText = styled(Text).attrs((props) => ({
	...props,
	size: 'lg',
	color: props.theme.color.green.base,
}))``;

export const TermsHeading = styled(Text).attrs((props) => ({
	...props,
	style: 'bold',
	size: 'lg',
}))``;

export const AcceptButton = styled(TextButton).attrs((props) => ({
	...props,
	text: 'Jag har läst villkoren',
	variant: 'primary',
}))``;

export const RedirectGuidanceText = styled(Paragraph).attrs((props) => ({
	...props,
	size: 'sm',
}))`
	text-align: center;
`;
