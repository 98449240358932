import { Waiter } from '../Waiter';
import { useBankId } from './BankIdButton.hooks';
import {
	InitButton,
	Description,
	ErrorMessage,
	AbortAnchor,
	CompleteCheck,
	Wrapper,
} from './BankIdButton.parts';
import { DeviceFlow } from './DeviceFlow';
import { ExternalFlow } from './ExternalFlow';
import { FlowSelect } from './FlowSelect';

export type BankIdButtonProps = {
	onIdentified: () => void;
	reason?: string;
};

export function BankIdButton(props: BankIdButtonProps & { className?: string }) {
	const {
		isPending,
		isComplete,
		handleAbort,
		handleInit,
		error,
		flow,
		setFlow,
		qr,
		autoStartToken,
	} = useBankId(props);

	function renderFlow() {
		switch (flow) {
			case 'initial':
				return (
					<>
						<InitButton className={props.className} onClick={handleInit} />

						{!error && (
							<Description>
								{props.reason || 'Du måste identifiera dig för att fortsätta.'}
							</Description>
						)}
					</>
				);
			case 'select':
				return <FlowSelect onSelect={setFlow} />;
			case 'device':
				return <DeviceFlow autoStartToken={autoStartToken} />;
			case 'external':
				return <ExternalFlow qr={qr} />;
		}
	}

	return (
		<Wrapper>
			{!isComplete && renderFlow()}
			<Waiter isLoading={!isPending} hideSpinner>
				{isPending && <AbortAnchor to={{ onClick: handleAbort }} />}
			</Waiter>
			<Waiter isLoading={!isComplete} hideSpinner>
				{isComplete && <CompleteCheck />}
			</Waiter>
			<ErrorMessage error={error} />
		</Wrapper>
	);
}
