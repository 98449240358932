import { LocalImage } from '../../primitives';
import { styled } from '../../theme';

const heights = {
	sm: 42,
	md: 54,
};

export const $LocalImage = styled(LocalImage)<{ size: 'sm' | 'md' }>`
	max-height: ${({ size }) => heights[size]}px;
	width: 100%;
`;
