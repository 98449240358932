import { FirstStoreLetter, StoreOrder, ABCStore, stringToNumber } from '@mybonus/public';
import { useAPI, useHead, useQuery, useUrl } from '@mybonus/ui';
import { useMemo } from 'react';
import { z } from 'zod';

const SORT_ORDERS: { id: StoreOrder; label: string }[] = [
	{ id: 'POPULARITY', label: 'Popularitet' },
	{ id: 'BEST_OFFER', label: 'Högst återbäring' },
	{ id: 'CREATED', label: 'Senast tillagd' },
];

export const QueryParamState = z.object({
	categoryId: stringToNumber.optional(),
	sortBy: StoreOrder.default('POPULARITY'),
	expand: z.string().default(''),
});
export type QueryParamState = z.infer<typeof QueryParamState>;

export function useBrowseView() {
	const { api } = useAPI();
	const { queryObject, updateQueryObject } = useUrl();
	useHead({ subtitle: 'Butiker' });

	const queryState = QueryParamState.safeParse(queryObject);
	const state: QueryParamState = queryState.success
		? queryState.data
		: {
				categoryId: undefined,
				sortBy: 'POPULARITY',
				expand: '',
		  };

	const query = useQuery('guest.store.abc', (args) => api.guest.store.abc.query(args), undefined);

	const groups = useMemo(() => {
		let stores = query.data || [];

		if (state.categoryId) {
			const categoryId = state.categoryId;
			stores = stores.filter((s) => s.categoryIds.includes(categoryId));
		}

		if (state.sortBy === 'POPULARITY') {
			stores.sort((a, b) => b.popularityScore - a.popularityScore);
		} else if (state.sortBy === 'CREATED') {
			stores.sort((a, b) => b.id - a.id);
		} else if (state.sortBy === 'BEST_OFFER') {
			stores.sort((a, b) => b.offerScore - a.offerScore);
		}

		const grouped = stores.reduce<Record<string, ABCStore[]>>((groups, store) => {
			const name = store.name.toLowerCase().trim();
			const letter = String(/^[a-ö]/.test(name) ? name[0] : '#');

			if (!groups[letter]) {
				groups[letter] = [];
			}

			groups[letter]!.push(store);

			return groups;
		}, {});

		return FirstStoreLetter.options
			.map((letter) => {
				const stores = grouped[letter];

				return stores
					? {
							letter,
							stores,
					  }
					: null;
			})
			.filter(Boolean) as { letter: FirstStoreLetter; stores: ABCStore[] }[];
	}, [query.data, state.categoryId, state.sortBy]);

	function expandLetter(letter: FirstStoreLetter) {
		updateQueryObject({ expand: `${state.expand}${letter}` });
	}

	function setSortBy(value: StoreOrder) {
		updateQueryObject({ sortBy: value, expand: undefined });
	}

	function setCategoryId(value?: number | undefined) {
		updateQueryObject(
			value
				? { categoryId: String(value), expand: undefined }
				: { categoryId: undefined, expand: undefined },
		);
	}

	function isLetterExpanded(letter: FirstStoreLetter) {
		return state.expand.indexOf(letter) !== -1;
	}

	return {
		groups,
		isLoading: query.isLoading,
		sortOrder: SORT_ORDERS,
		sortBy: state.sortBy,
		setSortBy,
		categoryId: state.categoryId,
		setCategoryId,
		expandLetter,
		isLetterExpanded,
	} as const;
}
