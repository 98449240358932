import { Selectfield, Textfield, Text, Box } from '../../../primitives';
import { Checkbox } from '../../Checkbox';
import { CmsContent } from '../../CmsContent';
import { Form } from '../../Form';
import { FormRow } from '../../FormRow';
import { Heading } from '../../Heading';
import { Paragraph } from '../../Paragraph';
import { useClaimForm } from './MainForm.hooks';
import {
	SaveButton,
	DateTimeRow,
	Datefield,
	Timefield,
	Label,
	$ErrorMessage,
} from './MainForm.parts';

export type MainFormProps = {
	onCreate: (trackEmail: string) => void;
};

export function MainForm(props: MainFormProps) {
	const {
		error,
		form,
		mutation,
		storeOptions,
		discountOptions,
		giftcardOptions,
		getFieldVariant,
		selectedStore,
	} = useClaimForm(props.onCreate);

	const isFieldsLocked = mutation.isLoading;
	const preventSubmit =
		isFieldsLocked ||
		!form.data.hasConfirmationReady ||
		(!!selectedStore?.termsSpecial && !form.data.hasReadSpecialTerms);

	return (
		<Form onSubmit={form.handleSubmit}>
			<Label>Vilken butik gäller det?</Label>
			<Selectfield
				placeholder="Välj butik"
				options={storeOptions}
				disabled={isFieldsLocked}
				variant={getFieldVariant('storeId')}
				onChange={form.handleChange('storeId')}
			/>

			<Label>Använde du några rabatter vid köpet?</Label>
			<Selectfield
				placeholder="Välj ett alternativ"
				options={discountOptions}
				disabled={isFieldsLocked}
				variant={getFieldVariant('discountUsed')}
				onChange={form.handleChange('discountUsed')}
			/>

			<Label>Använde du några presentkort vid köpet?</Label>
			<Selectfield
				placeholder="Välj ett alternativ"
				options={giftcardOptions}
				disabled={isFieldsLocked}
				variant={getFieldVariant('giftcardUsed')}
				onChange={form.handleChange('giftcardUsed')}
			/>

			<Label>
				Ange tidpunkt för köpet. Använd tiden från orderbekräftelsen
			</Label>
			<DateTimeRow>
				<Datefield
					placeholder="yyyy-mm-dd"
					disabled={isFieldsLocked}
					value={form.data.date || ''}
					variant={getFieldVariant('date')}
					onChange={form.handleChange('date')}
				/>

				<Timefield
					placeholder="hh:mm"
					disabled={isFieldsLocked}
					value={form.data.time || ''}
					variant={getFieldVariant('time')}
					onChange={form.handleChange('time')}
				/>
			</DateTimeRow>

			<Label>
				Ordernummer. Du hittar det på din orderbekräftelse. Har du ingen
				orderbekräftelse? Ange din e-post adress.
			</Label>
			<Textfield
				placeholder="xxxxxxxx"
				disabled={isFieldsLocked}
				type="text"
				value={form.data.orderNumber || ''}
				variant={getFieldVariant('orderNumber')}
				onChange={form.handleChange('orderNumber')}
			/>

			<Label>Ordervärde - använd samma valuta som köpet</Label>
			<Textfield
				type="number"
				placeholder="199,9"
				disabled={isFieldsLocked}
				value={form.data.purchaseSum || ''}
				variant={getFieldVariant('purchaseSum')}
				onChange={form.handleChange('purchaseSum')}
			/>

			<Textfield
				type="text"
				multiline={{ rows: 4 }}
				placeholder="Övriga kommentarer..."
				disabled={isFieldsLocked}
				value={form.data.message || ''}
				variant={getFieldVariant('message')}
				onChange={form.handleChange('message')}
			/>

			<Box>
				<Heading level={4}>Ha din orderbekräftelse redo</Heading>
				<Paragraph>
					I nästa steg kommer du bli ombedd att skicka in en orderbekräftelse.
					Se till att ha den redo innan du registrerar ditt köp.
				</Paragraph>

				<FormRow>
					<Checkbox
						value={form.data.hasConfirmationReady}
						onChange={form.handleChange('hasConfirmationReady')}
					/>
					<Text>Jag har min orderbekräftelse redo</Text>
				</FormRow>
			</Box>

			{selectedStore && selectedStore.termsSpecial && (
				<Box>
					<Heading level={4}>{selectedStore.name}s särskilda villkor</Heading>
					<CmsContent html={selectedStore.termsSpecial} />

					<FormRow>
						<Checkbox
							value={!!form.data.hasReadSpecialTerms}
							onChange={form.handleChange('hasReadSpecialTerms')}
						/>

						<Text>
							Jag har läst igenom {selectedStore.name}s särskilda villkor
						</Text>
					</FormRow>
				</Box>
			)}

			<SaveButton disabled={preventSubmit} onClick={form.handleSubmit} />
			<$ErrorMessage error={error} />
		</Form>
	);
}
