import { Text } from '../../primitives';
import { AnchorButton } from '../AnchorButton';
import { Checkbox } from '../Checkbox';
import { ErrorMessage } from '../ErrorMessage';
import { Form } from '../Form';
import { FormRow } from '../FormRow';
import { Paragraph } from '../Paragraph';
import { TextButton } from '../TextButton';
import { useAccountRemovalFulfillForm } from './AccountRemovalFulfillForm.hooks';
import { Wrapper, WarningParagraph, SuccessWrapper } from './AccountRemovalFulfillForm.parts';

export function AccountRemovalFulfillForm() {
	const { mutation, form, error } = useAccountRemovalFulfillForm();

	return (
		<Wrapper>
			{mutation.isSuccess ? (
				<SuccessWrapper>
					<Paragraph variant="info">Ditt konto har blivit borttaget!</Paragraph>
					<AnchorButton variant="primary" to={{ local: '/' }} text="Gå till startsidan" />
				</SuccessWrapper>
			) : (
				<Form onSubmit={form.handleSubmit}>
					<WarningParagraph>
						Du är påväg att ta bort ditt konto permanent hos MyBonus.
					</WarningParagraph>

					<Paragraph>
						Om du inte vill avsluta ditt konto hos MyBonus kan du stänga ner denna sida.
					</Paragraph>

					<FormRow>
						<Checkbox
							value={form.data.hasUnderstood}
							onChange={form.handleChange('hasUnderstood')}
						/>
						<Text>
							Jag har läst och förstår att mitt konto inte kommer gå att återskapa efter att det är
							borttaget.
						</Text>
					</FormRow>

					<ErrorMessage error={error} />

					<TextButton
						variant="alternative"
						disabled={mutation.isLoading || !form.data.hasUnderstood}
						isLoading={mutation.isLoading}
						onClick={form.handleSubmit}
						text="Ta bort mitt konto"
					/>
				</Form>
			)}
		</Wrapper>
	);
}
