import type { SingleStore } from '@mybonus/public';
import { BestOfferText } from '@mybonus/ui';

import { Description } from './NetworkDescription.parts';

export type NetworkDescriptionProps = {
	store: SingleStore;
};

export function NetworkDescription(props: NetworkDescriptionProps) {
	const { store } = props;
	const isStoreWide = store.bestOffer?.offer.isStoreWide;
	const isMultipleOffers = store.bestOffer?.isMultipleOffers;

	return (
		<Description>
			{store.name} erbjuder {store.bestOffer && <BestOfferText lower bestOffer={store.bestOffer} />}{' '}
			återbäring på {isMultipleOffers || isStoreWide ? 'alla' : 'särskilda'} köp.
			{isMultipleOffers ? (
				<> Se detaljer för respektive kategori nedan.</>
			) : (
				<>{!isStoreWide && <> Se detaljer nedan.</>}</>
			)}
		</Description>
	);
}
