import { createButtonTheme } from './button';
import { color, create } from './color';
import { device, deviceSize } from './device';
import { font, fontSize } from './font';
import { icon } from './icon';
import { rounding } from './rounding';
import { space } from './space';

const main = {
	color,
	font,
	fontSize,
	space,
	rounding,
	deviceSize,
	device,
	button: createButtonTheme({ color }),
	icon,
};

export type Theme = typeof main;

export function invertTheme(theme: Theme): Theme {
	return {
		...theme,
		color: {
			...theme.color,
			white: theme.color.darkGray,
			darkGray: theme.color.white,
		},
	};
}

export function blackTheme(theme: Theme): Theme {
	return {
		...theme,
		color: {
			...theme.color,
			primaryGradientFrom: create([359, 3, 12, 1]),
			primaryGradientTo: create([359, 3, 20, 1]),
			coverSectionOverlay: create([359, 3, 20, 0.3]),
			lightGradientFrom: create([359, 3, 12, 0.1]),
			lightGradientTo: create([359, 3, 20, 0.1]),
		},
		icon: {
			...icon,
			IconHeart: 'IconHeartRed',
			IconHeartFilled: 'IconHeartFilledRed',
		},
	};
}

export function christmasTheme(theme: Theme): Theme {
	const colormap = {
		...theme.color,
		primaryGradientFrom: create([0, 66, 41, 1]),
		primaryGradientTo: create([0, 74, 52, 1]),
		coverSectionOverlay: create([359, 3, 20, 0.3]),
		lightGradientFrom: create([0, 32, 50, 0.08]),
		lightGradientTo: create([0, 100, 39, 0.08]),
		orange: create([0, 74, 52, 1]),
		red: create([0, 74, 52, 1]),
	};

	const result = {
		...theme,
		color: colormap,
		icon: {
			...icon,
			IconHeart: 'IconHeartRed' as const,
			IconHeartFilled: 'IconHeartFilledRed' as const,
		},
	};

	result.button = {
		...result.button,
		...createButtonTheme({ color: result.color }),
	};

	return result;
}

export const mainTheme = main;
