import { useAPI, useQuery } from '@mybonus/ui';

export function useStatsSection() {
	const { api } = useAPI();
	const query = useQuery(
		'guest.stats.overview',
		(args) => api.guest.stats.overview.query(args),
		undefined,
	);

	return query;
}
