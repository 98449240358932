import { Toasts, ToastsProps } from '../Toasts';
import { Overlay } from './ToastsOverlay.parts';

export type ToastsOverlayProps = ToastsProps;

export function ToastsOverlay(props: ToastsOverlayProps) {
	return (
		<Overlay>
			<Toasts />
		</Overlay>
	);
}
