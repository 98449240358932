import { config, imageUrlWithSize } from '@mybonus/public';

import { Waiter } from '../Waiter';
import { usePopularStoreGrid } from './PopularStoreGrid.hooks';
import { Grid, Item, ItemLogo, ItemOffer } from './PopularStoreGrid.parts';

export function PopularStoreGrid(props: { className?: string }) {
	const { stores, isLoading } = usePopularStoreGrid();

	return (
		<Waiter isLoading={isLoading} className={props.className}>
			<Grid>
				{stores.map((s) => (
					<Item
						to={{
							local: config.urls.store({ storeId: s.id, storeName: s.name }),
						}}
						key={s.id}
					>
						{s.logoUrl && (
							<ItemLogo
								url={imageUrlWithSize(s.logoUrl, {
									h: '100',
									format: 'png',
								})}
								alt={s.name}
							/>
						)}
						{s.bestOffer && <ItemOffer bestOffer={s.bestOffer} />}
					</Item>
				))}
			</Grid>
		</Waiter>
	);
}
