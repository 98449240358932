import { useAccountContext } from '../../../context';
import { WithdrawalList } from '../../WithdrawalList';
import {
	Wrapper,
	Description,
	SummaryBox,
	WithdrawnValue,
} from './WithdrawalTab.parts';

export function WithdrawalTab() {
	const account = useAccountContext();
	const overview = account.overview;

	return (
		<Wrapper>
			<Description>
				Här hittar du all återbäring du redan har fått utbetald. Handla mer
				eller skaffa fler Bonus Buddies för ännu mer återbäring.
			</Description>
			<SummaryBox>
				<WithdrawnValue>
					{overview.data?.cashbackSummary.collected.text}
				</WithdrawnValue>
			</SummaryBox>

			<WithdrawalList />
		</Wrapper>
	);
}
