import { Box, LocalImage } from '../../primitives';
import { styled, css } from '../../theme';
import { CmsContent } from '../CmsContent';
import { Column } from '../Column';
import { JumboTitle } from '../JumboTitle';
import { Paragraph } from '../Paragraph';

export const Wrapper = styled(Box)`
	background-color: ${({ theme }) => theme.color.lighterGray.base};
	border-radius: ${({ theme }) => theme.rounding.md}px;
	padding: ${({ theme }) => theme.space.md}px;
	width: 100%;

	@media ${({ theme }) => theme.device.mobileM} {
		padding: ${({ theme }) => theme.space.lg}px;
	}

	@media ${({ theme }) => theme.device.tablet} {
		padding: ${({ theme }) => theme.space.xl}px;
	}
`;

export const Steps = styled(Box)`
	display: flex;
	flex-direction: column;
	margin-top: ${({ theme }) => theme.space.lg}px;

	> * {
		margin-bottom: ${({ theme }) => theme.space.lg}px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	@media ${({ theme }) => theme.device.tablet} {
		flex-direction: row;
		${({ theme }) =>
			css`
				padding: ${theme.space.lg}px ${theme.space.lg}px 0;
			`}

		> * {
			margin-right: ${({ theme }) => theme.space.lg}px;
			margin-bottom: 0;

			&:last-child {
				margin-right: 0;
			}
		}
	}
`;

export const Step = styled(Column)`
	align-items: center;
	text-align: center;
`;

export const StepImage = styled(LocalImage)`
	width: 70%;
	max-width: 250px;
	margin-bottom: ${({ theme }) => theme.space.lg}px;
`;

export const MainTitle = styled(JumboTitle)`
	color: ${({ theme }) => theme.color.green.base};
	font-size: 32px;

	@media ${({ theme }) => theme.device.tablet} {
		font-size: 38px;
	}
`;

export const IntroCmsContent = styled(CmsContent).attrs((props) => ({
	...props,
	components: {
		h1: (props: any) => <MainTitle {...props} />,
		p: (props: any) => <Paragraph size="lg" {...props} />,
	},
}))``;
