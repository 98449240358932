import type { ListWithdrawal } from '@mybonus/public';
import type { Theme } from '@mybonus/ui/theme';
import { useTheme } from 'styled-components';

import { Wrapper, Description, Amount } from './ListItem.parts';

export type ListItemProps = {
	item: ListWithdrawal;
};

function getColor(theme: Theme, status: ListWithdrawal['status']) {
	switch (status) {
		case 'PENDING':
			return theme.color.orange.base;
		case 'SUCCESS':
			return theme.color.green.base;
		case 'FAILED':
			return theme.color.red.base;
		case 'ABORTED':
			return theme.color.midGray.base;
	}
}

function getStatus(status: ListWithdrawal['status']) {
	switch (status) {
		case 'PENDING':
			return 'Bearbetas';
		case 'FAILED':
			return 'Misslyckad';
		case 'ABORTED':
			return 'Avbruten';
		case 'SUCCESS':
			return null;
	}
}

export function ListItem(props: ListItemProps) {
	const { status } = props.item;

	const theme = useTheme() as Theme;
	const clr = getColor(theme, status);

	return (
		<Wrapper>
			<Description color={clr}>
				{props.item.createdAt.toISOString().split('T')[0]} {getStatus(status)}
			</Description>
			<Amount color={clr}>{props.item.amount}</Amount>
		</Wrapper>
	);
}
