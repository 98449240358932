import { Box } from '../../primitives';
import { styled } from '../../theme';
import { FloatBox } from '../FloatBox';
import { Heading } from '../Heading';
import { TextButton } from '../TextButton';

export const $FloatBox = styled(FloatBox)`
	padding: 0;
	width: 100%;
`;

export const TopArea = styled(Box)`
	background-color: ${({ theme }) => theme.color.yellow.base};
	border-top-left-radius: ${({ theme }) => theme.rounding.md}px;
	border-top-right-radius: ${({ theme }) => theme.rounding.md}px;
	padding: ${({ theme }) => theme.space.md}px;

	@media ${({ theme }) => theme.device.laptop} {
		padding: ${({ theme }) => theme.space.lg}px
			${({ theme }) => theme.space.xl}px;
	}
`;

export const $Heading = styled(Heading).attrs((props) => ({
	...props,
	level: 2,
	color: props.theme.color.white.base,
}))``;

export const Content = styled(Box)`
	display: flex;
	flex-direction: column;
	padding: ${({ theme }) => theme.space.lg}px ${({ theme }) => theme.space.sm}px;

	@media ${({ theme }) => theme.device.laptop} {
		flex-direction: row;
		padding: ${({ theme }) => theme.space.lg}px;
	}
`;

export const Left = styled(Box)`
	flex: 1 0;
	padding: 0 ${({ theme }) => theme.space.md}px;

	@media ${({ theme }) => theme.device.laptop} {
		padding: ${({ theme }) => theme.space.md}px
			${({ theme }) => theme.space.lg}px;
	}
`;

export const Right = styled(Left)``;

export const ClaimButton = styled(TextButton).attrs((props) => ({
	...props,
	variant: 'yellow',
	text: 'Rapportera ditt köp här',
}))`
	width: 100%;

	@media ${({ theme }) => theme.device.laptop} {
		width: auto;
	}
`;
