import type { CampaignOrder } from '@mybonus/public';
import { APIInput, useAPI, useAuthContext, useHead, useQuery } from '@mybonus/ui';
import { useMemo, useState } from 'react';

const SORT_ORDERS: { id: CampaignOrder; label: string }[] = [
	{ id: 'PRIORITY', label: 'Ingen sortering' },
	{ id: 'POPULARITY', label: 'Popularitet' },
	{ id: 'BEST_OFFER', label: 'Högst återbäring' },
	{ id: 'TIME_LEFT', label: 'Minst återstående tid' },
];

// TODO: Maybe a context/query param so that we can press back and end up at the same place?
export function useCampaignsView() {
	const { api } = useAPI();
	const auth = useAuthContext();

	useHead({ subtitle: 'Kampanjer' });

	const [sortBy, setSortBy] = useState<CampaignOrder>('PRIORITY');

	const input = useMemo<APIInput['guest']['campaign']['list']>(
		() => ({
			order: {
				by: sortBy,
				dir: sortBy === 'PRIORITY' || sortBy === 'TIME_LEFT' ? 'ASC' : 'DESC',
			},
		}),
		[sortBy],
	);

	const opts = useMemo(
		() => ({
			refreshFromClient: !!auth.account,
		}),
		[auth.account],
	);

	const query = useQuery(
		'guest.campaigns.list',
		(args) => api.guest.campaign.list.query(args),
		input,
		opts,
	);

	return {
		isLoading: query.isLoading,
		campaigns: query.data?.items || [],
		sortBy,
		setSortBy,
		sortOrder: SORT_ORDERS,
	};
}
