import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ErrorMessage } from '../ErrorMessage';
import { Form } from '../Form';
import { PageContainer } from '../PageContainer';
import { PageTitle } from '../PageTitle';
import { Paragraph } from '../Paragraph';
import { useRecoverForm } from './RecoverView.hooks';
import {
	Title,
	RecoverButton,
	BackToLoginText,
	$FloatBox,
	$EmailTextfield,
} from './RecoverView.parts';

export function RecoverView() {
	const location = useLocation();
	const { form, mutation } = useRecoverForm(location.state?.email || '');

	useEffect(() => {
		form.reset();
	}, [mutation.isSuccess]);

	return (
		<PageContainer>
			<PageTitle>
				<Title />
				<BackToLoginText />
			</PageTitle>

			<$FloatBox>
				<Form onSubmit={form.handleSubmit}>
					<$EmailTextfield
						value={form.data.email || ''}
						variant={form.errors.email ? 'error' : undefined}
						onChange={form.handleChange('email')}
					/>

					<ErrorMessage
						error={
							(form.hasErrors &&
								'Se över informationen ovan och försök igen') ||
							mutation.error
						}
					/>

					<RecoverButton isLoading={mutation.isLoading} />

					{!form.hasErrors && mutation.isSuccess && (
						<Paragraph variant="info">
							Kolla efter ett mail från oss i din inkorg för vidare
							instruktioner.
						</Paragraph>
					)}
				</Form>
			</$FloatBox>
		</PageContainer>
	);
}
