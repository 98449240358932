import { isTRPCClientError } from '@mybonus/public';
import { useMemo } from 'react';

import type { APIError } from '../../api';
import { Paragraph } from '../Paragraph';

export type ErrorMessageProps = {
	error: null | undefined | false | Error | APIError | string;
};

export function ErrorMessage(props: ErrorMessageProps & { className?: string }) {
	const { error } = props;

	if (!error) {
		return <></>;
	}

	const msg = useMemo(() => {
		if (isTRPCClientError(error)) {
			return error.data.message;
		} else if (error instanceof Error) {
			return error.message;
		} else if (typeof error === 'string') {
			return error;
		}

		return 'Ett oväntat problem uppstod.';
	}, [error]);

	return (
		<Paragraph variant="error" className={props.className}>
			{msg}
		</Paragraph>
	);
}
