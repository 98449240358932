import { useMemo } from 'react';

import { useAPI, useQuery, type APIInput } from '../../../api';
import { ListStore } from '../../ListStore';
import { Popdown, PopdownProps } from '../../Popdown';
import { Waiter } from '../../Waiter';
import { defaultHeight } from '../style';
import { List, ListItem, ArrowRight, NoResults } from './StorePopdown.parts';

export type StorePopdownProps = {
	input: APIInput['guest']['store']['list'];
	onSelect: (data: { id: number; name: string }) => void;
} & Pick<PopdownProps, 'open' | 'onClose' | 'exceptRefs'>;

export function StorePopdown(props: StorePopdownProps) {
	const { api } = useAPI();

	const storeQueryOpts = useMemo(() => ({ preventAutoload: !props.open }), [props.open]);

	const stores = useQuery(
		'guest.store.list',
		(args) => api.guest.store.list.query(args),
		props.input,
		storeQueryOpts,
	);

	const hasFreetextFilter = useMemo(() => {
		return !!props.input?.filter?.freetext?.length;
	}, [props.input]);

	return (
		<Popdown
			borderRadius={defaultHeight / 2}
			open={props.open}
			onClose={props.onClose}
			exceptRefs={props.exceptRefs}
		>
			<Waiter isLoading={stores.isLoading}>
				{hasFreetextFilter && (
					<>
						{stores.data?.items.length ? (
							<List>
								{stores.data.items.map((s, i) => (
									<ListItem
										key={s.id}
										onClick={() => props.onSelect({ id: s.id, name: s.name })}
										isFirst={i === 0}
									>
										<ListStore store={s} />
										<ArrowRight />
									</ListItem>
								))}
							</List>
						) : (
							<NoResults />
						)}
					</>
				)}
			</Waiter>
		</Popdown>
	);
}
