import { useEffect } from 'react';

import { useMutation, useAPI, type APIInput, type APIOutput } from '../../api';
import { useAccountContext } from '../../context';
import { useForm } from '../../hooks';

export function useAccountRemovalInitForm() {
	const account = useAccountContext();
	const { api } = useAPI();
	const form = useForm<{
		email: string;
		hasUnderstood: boolean;
	}>({
		onSubmit: () => {
			if (!form.data.email) {
				return;
			}

			mutation.mutate(form.data);
		},
		onChange: () => {
			mutation.reset();
		},
	});

	const mutation = useMutation<
		APIInput['guest']['account']['anonymize']['init'],
		APIOutput['guest']['account']['anonymize']['init']
	>((args) => api.guest.account.anonymize.init.mutate(args), {
		onSuccess: () => {
			form.reset();
		},
	});

	const accountEmail = account.overview.data?.user.email;

	useEffect(() => {
		if (accountEmail) {
			form.setData({ ...form.data, email: accountEmail });
		}
	}, [accountEmail, form.data]);

	const error = mutation.error || (form.hasErrors && 'Se över de rödmarkerade fälten ovan');

	function getFieldVariant(fieldKey: keyof typeof form.data): 'error' | undefined {
		return form.errors[fieldKey] ? 'error' : undefined;
	}

	return {
		mutation,
		form,
		error,
		getFieldVariant,
	};
}
