import { PageContainer, PageTitle, useHead, AccountRemovalInitForm } from '@mybonus/ui';

import { $FloatBox, Title, FloatWrapper } from './AccountRemovalInitView.parts';

export function AccountRemovalInitView() {
	useHead({ subtitle: 'Avsluta medlemskap' });

	return (
		<PageContainer>
			<PageTitle>
				<Title>Avsluta medlemskap</Title>
			</PageTitle>

			<FloatWrapper>
				<$FloatBox>
					<AccountRemovalInitForm />
				</$FloatBox>
			</FloatWrapper>
		</PageContainer>
	);
}
