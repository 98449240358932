import type { ReactNode } from 'react';
import { useTheme } from 'styled-components';

import { Gradient } from '../../primitives';
import { Theme } from '../../theme';

export type GreenGradientProps = {
	children?: ReactNode;
	angle?: number;
};

export function GreenGradient(props: GreenGradientProps & { className?: string }) {
	const theme = useTheme() as Theme;

	return (
		<Gradient
			colors={[theme.color.primaryGradientFrom.base, theme.color.primaryGradientTo.base]}
			angle={props.angle}
			className={props.className}
		>
			{props.children}
		</Gradient>
	);
}
