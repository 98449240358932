import { styled, Column } from '@mybonus/ui';

export const FloatBoxWrapper = styled(Column)`
	> * {
		margin-bottom: ${({ theme }) => theme.space.lg}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;
