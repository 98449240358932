import { z } from 'zod';

import { BestOffer, Offer } from './offer';

export const StoreOrder = z.enum(['POPULARITY', 'BEST_OFFER', 'CREATED', 'NAME']);

export type StoreOrder = z.infer<typeof StoreOrder>;

export const StoreType = z.enum(['NETWORK', 'GIFTCARD', 'UNKNOWN']);
export type StoreType = z.infer<typeof StoreType>;

export const ListStore = z.object({
	id: z.number(),
	name: z.string(),
	type: StoreType,
	logoUrl: z.string().nullable(),
	bestOffer: BestOffer.nullable(),
	// TODO: This should be part of the single store load instead, not listing
	warningMessage: z.string().nullable(),
	// TODO: This should be part of the single store load instead, not listing
	visitUrl: z.string().nullable(),
	// TODO: Remove this when GQL controller is gone
	isFavorite: z.boolean(),
	termsSpecial: z.string().nullable(),
	hasNativeCampaign: z.boolean(),
});
export type ListStore = z.infer<typeof ListStore>;

export const ABCStore = z.object({
	id: z.number(),
	name: z.string(),
	offerScore: z.number(),
	popularityScore: z.number(),
	categoryIds: z.number().array(),
});
export type ABCStore = z.infer<typeof ABCStore>;

export const ListBrowserExtensionStore = z.object({
	id: z.number(),
	urlRegex: z.string(),
});
export type ListBrowserExtensionStore = z.infer<typeof ListBrowserExtensionStore>;

export const SingleStore = ListStore.extend({
	termsTime: z.string().nullable(),
	termsWithdrawal: z.string().nullable(),
	description: z.string().nullable(),
	offers: Offer.array(),
	excludeFromBrowserExtension: z.boolean(),
	coverUrl: z.string().nullable(),
});
export type SingleStore = z.infer<typeof SingleStore>;

export const ListClaimStore = z.object({
	id: z.number(),
	name: z.string(),
	allowClaims: z.boolean(),
	termsSpecial: z.string().nullable(),
});
export type ListClaimStore = z.infer<typeof ListClaimStore>;

export const ListSlimStore = z.object({
	id: z.number(),
	name: z.string(),
});
export type ListSlimStore = z.infer<typeof ListSlimStore>;

export const FirstStoreLetter = z.enum([
	'a',
	'b',
	'c',
	'd',
	'e',
	'f',
	'g',
	'h',
	'i',
	'j',
	'k',
	'l',
	'm',
	'n',
	'o',
	'p',
	'q',
	'r',
	's',
	't',
	'u',
	'v',
	'w',
	'x',
	'y',
	'z',
	'å',
	'ä',
	'ö',
	'#',
]);
export type FirstStoreLetter = z.infer<typeof FirstStoreLetter>;
