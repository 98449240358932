import type { ListCampaign } from '@mybonus/public';

import { Wrapper, $CampaignCard } from './CampaignGrid.parts';

export type CampaignGridProps = {
	campaigns: ListCampaign[];
};

export function CampaignGrid(props: CampaignGridProps) {
	return (
		<Wrapper>
			{props.campaigns.map((c) => (
				<$CampaignCard key={c.id} store={c.store} campaign={c} collapse />
			))}
		</Wrapper>
	);
}
