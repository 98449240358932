import {
	PageContainer,
	PageTitle,
	ChangePasswordForm,
	useHead,
} from '@mybonus/ui';

import {
	$FloatBox,
	$BackAnchor,
	Title,
	FloatWrapper,
} from './PasswordSettingsView.parts';

export function PasswordSettingsView() {
	useHead({ subtitle: 'Ändra lösenord' });

	return (
		<PageContainer>
			<PageTitle>
				<Title>Ändra lösenord</Title>
			</PageTitle>

			<FloatWrapper>
				<$BackAnchor />
				<$FloatBox>
					<ChangePasswordForm />
				</$FloatBox>
			</FloatWrapper>
		</PageContainer>
	);
}
