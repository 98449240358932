import { Hero, Waiter, CampaignGrid } from '@mybonus/ui';

import { usePageView } from './PageView.hooks';
import { Wrapper, Content, $CmsContent, $PageContainer, $Waiter } from './PageView.parts';

export function PageView() {
	const { page, hero, campaigns } = usePageView();

	return (
		<$Waiter isLoading={page.isLoading}>
			<Wrapper>
				{hero && <Hero hero={hero} />}

				<$PageContainer>
					<Content>{page.data?.content && <$CmsContent markdown={page.data?.content} />}</Content>

					<Waiter isLoading={campaigns.isLoading}>
						<CampaignGrid campaigns={campaigns.data?.items || []} />
					</Waiter>
				</$PageContainer>
			</Wrapper>
		</$Waiter>
	);
}
