import { Text } from '../../primitives';
import { AccountOverviewPill } from '../AccountOverviewPill';
import { PopdownMountingReference } from '../Popdown';
import { Waiter } from '../Waiter';
import { useAccountPill } from './AccountPill.hooks';
import {
	WrappingButton,
	Popdown,
	PopdownReady,
	CashbackSummaryRow,
	MoneyText,
	CashbackSummaries,
	AnchorText,
	LinkSeparator,
	ToAccountAnchor,
	LogoutAnchor,
	ToSettingsAnchor,
} from './AccountPill.parts';

export function AccountPill() {
	const { auth, overview, open, setOpen } = useAccountPill();
	return (
		<PopdownMountingReference>
			<WrappingButton onClick={() => setOpen(!open)}>
				<Waiter isLoading={overview.isLoading} hideSpinner>
					<AccountOverviewPill overview={overview.data} />
				</Waiter>
			</WrappingButton>
			<Popdown open={open} onClose={() => setOpen(false)}>
				<PopdownReady>
					<CashbackSummaryRow>
						<Text>Redo för utbetalning</Text>
						<MoneyText style="bold">{overview.data?.cashbackSummary.collectible.text}</MoneyText>
					</CashbackSummaryRow>
				</PopdownReady>
				<CashbackSummaries>
					<CashbackSummaryRow>
						<Text>Inkommande</Text>
						<MoneyText>{overview.data?.cashbackSummary.incoming.text}</MoneyText>
					</CashbackSummaryRow>
					<CashbackSummaryRow>
						<Text>Bonus Buddies</Text>
						<MoneyText>{overview.data?.cashbackSummary.bonusBuddy.text}</MoneyText>
					</CashbackSummaryRow>
					<CashbackSummaryRow>
						<Text>Utbetalt</Text>
						<MoneyText>{overview.data?.cashbackSummary.collected.text}</MoneyText>
					</CashbackSummaryRow>
				</CashbackSummaries>
				<LinkSeparator />
				<AnchorText>
					<ToAccountAnchor />
				</AnchorText>
				<AnchorText>
					<ToSettingsAnchor />
				</AnchorText>
				<LinkSeparator />
				<AnchorText>
					<LogoutAnchor to={{ onClick: () => auth.logout() }} />
				</AnchorText>
			</Popdown>
		</PopdownMountingReference>
	);
}
