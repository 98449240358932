import { styled, CmsContent, FloatBox, Waiter } from '@mybonus/ui';

export const $FloatBox = styled(FloatBox)`
	margin-top: 16px;
	width: 100%;
	max-width: 400px;
`;

export const $Waiter = styled(Waiter)`
	text-align: center;
`;

export const $CmsContent = styled(CmsContent)`
	text-align: center;
	max-width: 400px;
`;
