import { useHead, useAuthContext } from '@mybonus/ui';

export function useHomeView() {
	const auth = useAuthContext();
	useHead({ subtitle: 'Cashback på alla dina köp online' });

	return {
		isGuest: !auth.account,
	};
}
