import { LoadMoreArrow } from '../LoadMoreArrow';
import { NoContent } from '../NoContent';
import { NotificationItem } from './NotificationItem';
import { useNotificationList } from './NotificationList.hooks';
import { List } from './NotificationList.parts';
import type { Notification } from './NotificationList.types';

export type NotificationListProps = {
	isVisible: boolean;
	onSelect?: () => void;
};

export function NotificationList(props: NotificationListProps & { className?: string }) {
	const { list, hasMore, loadMore, isLoading, isInitialLoad, onSelect } = useNotificationList({
		isVisible: props.isVisible,
	});

	function handleSelect(notification: Notification) {
		props.onSelect?.();
		onSelect(notification);
	}

	return (
		<List isLoading={isInitialLoad} className={props.className}>
			{!isInitialLoad && !list.length ? (
				<NoContent message="Här kommer du se dina meddelanden från oss." />
			) : (
				<>
					{list.map((notification, i) => (
						<NotificationItem
							isFirst={i === 0}
							key={notification.id}
							notification={{ ...notification, id: i }}
							onSelect={() => handleSelect(notification)}
						/>
					))}

					{hasMore && <LoadMoreArrow onClick={loadMore} isLoading={isLoading} />}
				</>
			)}
		</List>
	);
}
