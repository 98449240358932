import type { ReactNode } from 'react';

import { Wrapper, Item, Bullet } from './BulletList.parts';

export type BulletListProps = {
	children?: ReactNode[];
};

export function BulletList(props: BulletListProps) {
	return (
		<Wrapper>
			{(props.children || []).map((c, i) => (
				<Item key={i}>
					<Bullet />
					{c}
				</Item>
			))}
		</Wrapper>
	);
}
