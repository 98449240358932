import { Box } from '../../primitives';
import { styled, css } from '../../theme';
import type { PopdownProps } from './Popdown';

export const Triangle = styled(Box)<Omit<PopdownProps, 'children'>>`
	width: 0px;
	height: 0px;
	border: 7px solid;
	border-top-color: transparent;
	border-left-color: transparent;
	border-right-color: transparent;
	border-bottom-color: ${({ arrow }) => arrow?.color || 'white'};
	position: absolute;
	top: -14px;

	${({ arrow }) => css`
		${arrow?.position || 'left'}: ${arrow?.px || 16}px;
	`}
`;

export const Wrapper = styled.div<Omit<PopdownProps, 'children'>>`
	width: 100%;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
	background-color: #fff;
	border-radius: ${(props) => props.borderRadius || props.theme.rounding.md}px;
	position: absolute;
	z-index: 4;
	top: calc(100% + ${(props) => (props.arrow ? 12 : 0)}px);
	transition: opacity 0.2s ease-in-out;

	${({ arrow }) =>
		!arrow &&
		css`
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		`}

	${({ position, positionOffset }) => css`
		${(position || 'left') === 'left' ? 'right' : 'left'}: ${positionOffset || 0}px;
	`}

	pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
	opacity: ${({ open }) => (open ? '1' : '0')};
`;

export const PopdownMountingReference = styled(Box)`
	position: relative;
`;
