import { Textfield, Button, textfieldPadding } from '../../primitives';
import { styled } from '../../theme';
import { Form } from '../Form';
import { Icon, iconSize } from '../Icon';
import { animatedCorner } from './style';

export const Wrapper = styled(Form)<{ open: boolean; height?: number }>`
	position: relative;
	display: flex;
	flex-direction: row;
	background-color: #fff;
	align-items: center;
	position: relative;
	width: 100%;

	> * {
		margin-bottom: 0;
	}

	${animatedCorner('right')}
	${animatedCorner('left')}
`;

export const Searchfield = styled(Textfield).attrs((props) => ({
	...props,
	type: 'text',
	variant: 'transparent',
}))`
	height: 100%;
	padding-right: 0;
	flex: 1 1 auto;
	width: 100%;
`;

export const SearchIcon = styled(Icon).attrs((props) => ({
	...props,
	size: 'xl',
}))`
	padding: ${({ theme }) => theme.space.md}px ${textfieldPadding.sm.x}px;
	width: ${iconSize.xl}px;
`;

export const SearchButton = styled(Button).attrs((props) => ({
	...props,
	variant: 'transparent',
}))`
	padding: 0;
`;
