import { Text } from '../../../primitives';
import { styled, css } from '../../../theme';
import { Row } from '../../Row';
import { TextButton } from '../../TextButton';

export const Wrapper = styled(Row)`
	align-items: center;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.color.lightOrange.base};
	padding: ${({ theme }) => theme.space.sm}px ${({ theme }) => theme.space.md}px;
`;

export const $Text = styled(Text).attrs((props) => ({
	...props,
	color: props.theme.color.orange.base,
}))<{ center: boolean }>`
	padding: ${({ theme }) => theme.space.sm}px 0;

	${({ center }) =>
		center
			? css`
					text-align: center;
					width: 100%;
			  `
			: ''}
`;

export const CodeText = styled(Text).attrs((props) => ({
	...props,
	color: props.theme.color.orange.base,
	style: 'bold',
}))``;

export const CopyButton = styled(TextButton).attrs((props) => ({
	...props,
	size: 'xs',
	variant: 'alternativeHollow',
}))`
	background-color: transparent;
	border: 1px solid ${({ theme }) => theme.color.orange.base};
`;
