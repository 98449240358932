import { z } from 'zod';

export const SuccessHTTPStatus = z.number().lte(302);

export type SuccessHTTPStatus = z.infer<typeof SuccessHTTPStatus>;

export const HTTPMethod = z.enum([
	'GET',
	'HEAD',
	'OPTIONS',
	'PUT',
	'POST',
	'PATCH',
	'DELETE',
	'CONNECT',
	'TRACE',
]);
export type HTTPMethod = z.infer<typeof HTTPMethod>;
