import { styled, Box, Row } from '@mybonus/ui';

export const $Row = styled(Row)`
	align-items: center;

	> * {
		margin-right: ${({ theme }) => theme.space.sm}px;

		&:last-child {
			margin-right: 0;
		}
	}
`;

export const IconWrapper = styled(Box)`
	position: relative;
`;

export const Badge = styled(Box)`
	position: absolute;
	right: -4px;
	top: -4px;
	width: 10px;
	height: 10px;
	background-color: ${({ theme }) => theme.color.green.base};
	border-radius: 99px;
	display: flex;
	justify-content: center;
	align-items: center;
`;
