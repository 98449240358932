import {
	styled,
	FloatBox,
	Heading,
	Paragraph,
	TextButton,
	Anchor,
	Column,
	Text,
	Box,
} from '@mybonus/ui';

export const Overlay = styled(Box)<{ show: boolean }>`
	position: fixed;
	transition: opacity 0.2s ease-in-out;
	z-index: 3;
	opacity: ${({ show }) => (show ? 1 : 0)};
	pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.18);
`;

export const Popup = styled(FloatBox)<{ show: boolean }>`
	position: fixed;
	display: flex;
	flex-direction: column;
	transition: opacity 0.2s ease-in-out, transform 0.3s ease-in-out;
	pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
	z-index: 4;
	padding: ${({ theme }) => theme.space.md}px;
	bottom: ${({ theme }) => theme.space.md}px;
	left: ${({ theme }) => theme.space.md}px;
	right: ${({ theme }) => theme.space.md}px;

	opacity: ${({ show }) => (show ? 1 : 0)};
	transform: translateY(${({ show }) => (show ? '0' : '50%')});

	@media ${({ theme }) => theme.device.laptop} {
		transform: translateX(${({ show }) => (show ? '0' : '-50%')});
		bottom: ${({ theme }) => theme.space.lg}px;
		left: ${({ theme }) => theme.space.lg}px;
		right: unset;
		max-width: 700px;
		flex-direction: row;
	}
`;

export const Title = styled(Heading).attrs((props) => ({
	...props,
	level: 4,
}))`
	margin-top: 0;
`;

export const Description = styled(Paragraph)`
	margin-top: 0;
	margin-bottom: 0;
`;

export const InfoColumn = styled(Column)`
	margin-bottom: ${({ theme }) => theme.space.md}px;

	@media ${({ theme }) => theme.device.laptop} {
		margin-bottom: 0;
		margin-right: ${({ theme }) => theme.space.md}px;
	}
`;

export const ButtonColumn = styled(Column)`
	align-items: stretch;
	flex: 1;

	> * {
		margin-bottom: ${({ theme }) => theme.space.sm}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const AcceptButton = styled(TextButton).attrs((props) => ({
	...props,
	variant: 'primary',
	size: 'sm',
	text: 'Tillåt cookies',
}))``;

export const AcceptNecessaryButton = styled(TextButton).attrs((props) => ({
	...props,
	variant: 'primaryHollow',
	size: 'sm',
	text: 'Endast nödvändiga cookies',
}))``;

export const RejectButton = styled(Anchor).attrs((props) => ({
	...props,
	color: props.theme.color.gray.base,
	children: (
		<Text size="sm" color={props.theme.color.gray.base}>
			Tillåt inget
		</Text>
	),
}))`
	text-align: center;
	margin-top: 4px;
`;
