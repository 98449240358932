import {
	Sub,
	SubRow,
	Title,
	TitleRow,
	Value,
	Wrapper,
} from './SummaryItem.parts';

export type SummaryItemProps = {
	title: string;
	subtitle: string;
	value: string;
	isLast?: boolean;
};

export function SummaryItem(props: SummaryItemProps) {
	return (
		<Wrapper isLast={props.isLast}>
			<TitleRow>
				<Title>{props.title}</Title>
			</TitleRow>

			<SubRow>
				<Sub>{props.subtitle}</Sub>
				<Value>{props.value}</Value>
			</SubRow>
		</Wrapper>
	);
}
