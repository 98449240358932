import { config } from '@mybonus/public';

import { LogoChrome } from '../../assets';
import { AnchorButton } from '../AnchorButton';
import { Wrapper, VendorLogo } from './DownloadPluginButton.parts';

export type DownloadPluginButtonProps = {
	vendor: 'chrome';
};

const logos = {
	chrome: {
		logo: LogoChrome,
		url: config.plugin.chrome.url,
	},
};

export function DownloadPluginButton(
	props: DownloadPluginButtonProps & { className?: string },
) {
	const { logo, url } = logos[props.vendor];

	return (
		<Wrapper className={props.className}>
			<AnchorButton
				to={{ url, newTab: true }}
				text="Hämta MyBonus Autobäring"
				variant="primary"
			/>
			<VendorLogo uri={logo} />
		</Wrapper>
	);
}
