import { useToastsContext } from '../../context';
import { Toast } from './Toast';
import { ToastList } from './Toasts.parts';

export type ToastsProps = Record<string, never>;

export function Toasts(props: ToastsProps) {
	const toasts = useToastsContext();

	return (
		<ToastList>
			{toasts.list.map((t) => (
				<Toast {...t} key={t.id} onClick={() => toasts.dismissToast(t.id)} />
			))}
		</ToastList>
	);
}
